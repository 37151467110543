import MtrlList from '@/views/used_material/MtrlList.vue'
import MtrlEdit from '@/views/used_material/MtrlEdit.vue'
import MtrlDetail from '@/views/used_material/MtrlDetail.vue'

export default [
    {
        path: '/usdmtrl/list',
        name: 'MtrlList',
        component: MtrlList
    },
    {
        path: '/usdmtrl/edit',
        name: 'MtrlEdit',
        component: MtrlEdit
    },
    {
        path: '/usdmtrl/edit/:usdMtrlId',
        name: 'MtrlEdit',
        component: MtrlEdit
    },
    {
        path: '/usdmtrl/store/:usdMtrlId',
        name: 'MtrlDetail',
        component: MtrlDetail
    },
]