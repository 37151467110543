<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">사용자 메뉴접근권한 설정</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>접근권한목록</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-9">사용자유형</span></div>
                <div class="x_div_center" style="width:60%"><span class="fkr-set1 fs-9">허용메뉴</span></div>
                <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-9">등록일</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in codeListPaged" :key="index" class="x_div_center" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center" style="width:20%;"><span class="fkr-set2 fs-9">{{item.usrRole.name}}</span></div>
                  <div class="x_div_center" style="width:60%; justify-content:left">
                    <span class="fkr-set2 fs-9" style="display:flex;">
                      <p v-for="(auth, i) in item.processList" :key="i" style="margin: 0 15px 0 0">{{ auth.name }}&nbsp;
                        <a href="javascript:void(0)" @click="deleteAuth(item.usrRole.code, auth.code)"><i class="fas fa-backspace"></i></a>
                      </p>
                      <a v-if="!edit[index]" href="javascript:void(0)" @click="$set(edit, index, true)" style="margin-left: 10px"><i class="fas fa-plus-circle"></i></a>
                      <select v-if="edit[index]" class="custom-select ff-eng1 fs-085" v-model="prcSelected" style="width:120px; height:27px; padding: 1px 0 1px 5px; margin:0">
                        <option v-for="(prc, j) in prcList" :key="j" :value="prc.code">{{ prc.name }}</option>
                      </select>
                      <a v-if="edit[index]" href="javascript:void(0)" @click="addAuth(item.usrRole.code, prcSelected, index)" style="margin-left: 10px"><i class="fas fa-check"></i></a>
                      <a v-if="edit[index]" href="javascript:void(0)" @click="$set(edit, index, false)" style="margin-left: 10px"><i class="fas fa-undo"></i></a>
                    </span>
                  </div>
                  <div class="x_div_center" style="width:20%;"><span class="fkr-set2 fs-9">{{item.usrRole.crtDt? item.usrRole.crtDt.split('T')[0] : ''}}</span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; renderList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "AuthList",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: true,
        codeList: [],
        codeListPaged: [],
        prcList: [],
        prcSelected: null,
        edit: [],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);

    const returnvalue = await Api.post('/api/v1/common/get/code/family', {ascByOrderNum: true, codeCtgry: "PRC"});
    if(returnvalue.result) {
        this.prcList = returnvalue.data;
        const rtnvalue = await Api.post('/api/v1/common/get/code/family', {ascByOrderNum: true, codeCtgry: "PRCS"});
        if(rtnvalue.result) {
            this.prcList = this.prcList.concat(rtnvalue.data)
            this.prcSelected = this.prcList[0].code
        }
        else {
            alert(rtnvalue.errormsg);
        }   
    }
    else {
        alert(returnvalue.errormsg);
    }
    
    await this.fetchCodes();
    this.codeList.forEach(() => { this.edit.push(false) })
    this.setPaging();
    this.renderList();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn != 'S'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async fetchCodes() {
      this.fetching = true;
      this.codeList = [];
      this.ctgryList = [];
      const returnvalue = await Api.get('/api/v1/admin/usr/mng/authmap/list');
      if(returnvalue.result) {
          this.codeList = returnvalue.data;
      }
      else {
          alert(returnvalue.errormsg);
      }
      this.fetching = false;
    },

    renderList() {
      this.codeListPaged = [];
      for(var j = (this.currentPage-1) * this.pageSize; j < (this.codeList.length > this.currentPage * this.pageSize? this.currentPage * this.pageSize : this.codeList.length); j++) {
        this.codeListPaged.push(this.codeList[j]);
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.codeList.length / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        this.setPaging();
        this.renderList();
      }
    },
  
    async addAuth(usrRoleCd, prcCd, index) {
      const rtnval = await Api.get('/api/v1/admin/usr/mng/authmap/set?usrRoleCd=' + usrRoleCd + '&prcCd=' + prcCd)
      if(rtnval.result) {
        alert('정상적으로 권한등록되었습니다.');
        await this.fetchCodes();
        this.setPaging();
        this.renderList();
      }
      else {
        alert(rtnval.errormsg)
      }
      this.$set(this.edit, index, false)
    },

    async deleteAuth(usrRoleCd, prcCd) {
      if(confirm('해당 접근권한을 삭제하시겠습니까?')) {
        const rtnval = await Api.get('/api/v1/admin/usr/mng/authmap/delete?usrRoleCd=' + usrRoleCd + '&prcCd=' + prcCd)
        if(rtnval.result) {
          alert('정상적으로 삭제되었습니다.');
          await this.fetchCodes();
          this.setPaging();
          this.renderList();
        }
        else {
          alert(rtnval.errormsg)
        }
      }
    },
  }
}
</script>

<style scoped>
.mib_box_a {
    width: auto;
}
</style>
