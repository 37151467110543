<template>
    <div class="m3_contetns" style="margin-top: 50px">

        <div class="m_ipt_box">

            <div class="mib_mod">
                <div class="pg_m_set" v-if="fetching">
                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div v-else class="w-100">

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">고객명</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" v-model="customerNm"></div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">연락처</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" v-model="phone"></div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">E-Mail</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" v-model="email"></div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">유입경로</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex; flex-wrap:wrap">
                            <li class="custom-checkbox">
                                <input type="checkbox" id="visitChannel1" name="visitChannel" class="custom-control-input" v-model="visitChannel" value="1" >
                                <label class="custom-control-label" for="visitChannel1"><span class="fkr-set2">유튜브</span></label>
                            </li>
                            <li class="custom-checkbox">
                                <input type="checkbox" id="visitChannel2" name="visitChannel" class="custom-control-input" v-model="visitChannel" value="2" >
                                <label class="custom-control-label" for="visitChannel2"><span class="fkr-set2">네이버</span></label>
                            </li>
                            <li class="custom-checkbox">
                                <input type="checkbox" id="visitChannel3" name="visitChannel" class="custom-control-input" v-model="visitChannel" value="3" >
                                <label class="custom-control-label" for="visitChannel3"><span class="fkr-set2">플랫폼</span></label>
                            </li>
                            <li class="custom-checkbox">
                                <input type="checkbox" id="visitChannel4" name="visitChannel" class="custom-control-input" v-model="visitChannel" value="4" >
                                <label class="custom-control-label" for="visitChannel4"><span class="fkr-set2">전시회</span></label>
                            </li>
                            <li class="custom-checkbox">
                                <input type="checkbox" id="visitChannel5" name="visitChannel" class="custom-control-input" v-model="visitChannel" value="5" >
                                <label class="custom-control-label" for="visitChannel5"><span class="fkr-set2">빌드마켓</span></label>
                            </li>
                            <li style="display:flex;">
                                <span class="fkr-set2" style="margin-right:10px">기타</span>
                                <input type="text" class="form-control fkr-set2" style="width:90px; height:26px" v-model="visitChannelEtc">
                            </li>
                        </div>
                    </div>

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">용도</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex; flex-wrap:wrap">
                            <li class="custom-checkbox">
                                <input type="checkbox" id="usage1" name="usage" class="custom-control-input" v-model="usageChecked" value="1" >
                                <label class="custom-control-label" for="usage1"><span class="fkr-set2">주거용</span></label>
                            </li>
                            <li class="custom-checkbox">
                                <input type="checkbox" id="usage2" name="usage" class="custom-control-input" v-model="usageChecked" value="2" >
                                <label class="custom-control-label" for="usage2"><span class="fkr-set2">상업용</span></label>
                            </li>
                            <li class="custom-checkbox">
                                <input type="checkbox" id="usage4" name="usage" class="custom-control-input" v-model="usageChecked" value="4" >
                                <label class="custom-control-label" for="usage4"><span class="fkr-set2">학교</span></label>
                            </li>
                            <li class="custom-checkbox">
                                <input type="checkbox" id="usage3" name="usage" class="custom-control-input" v-model="usageChecked" value="3" >
                                <label class="custom-control-label" for="usage3"><span class="fkr-set2">기타</span></label>
                            </li>
                        </div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">건축예정지</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex; flex-wrap:wrap">
                            <button class="fkr-set1 fc-w1" @click="searchAddr()" style="width:75px; height:26px; margin: 5px 15px 5px 0; background-color:#eee7e7; border:1px solid #4e0000; color:#4e0000; border-radius:4px;">주소검색</button>
                            <input type="text" class="form-control fkr-set2" :style="'height:36px; padding-left:10px; width:' + (isMobile? '180px' : '310px')" v-model="addr" placeholder="직접 입력할 수 있습니다.">
                        </div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">연면적</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex;">
                            <input type="text" class="form-control fkr-set2" style="width:80px; height:36px; margin-right: 5px;" @change="calcArea(true)" v-model="totalGroundAreaPy">
                            <span class="x_div_center fen-set2">&nbsp;평&nbsp;(&nbsp;</span>
                            <input type="text" class="form-control fkr-set2" style="width:80px; height:36px; margin-right: 5px;" @change="calcArea(false)" v-model="totalGroundArea">
                            <span class="x_div_center fen-set2">㎡) </span>
                        </div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">층수</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex;">
                            <input type="text" class="form-control fkr-set2" style="width:80px; height:36px; margin-right: 5px;" v-model="floors">
                            <span class="x_div_center fen-set2">&nbsp;층</span>
                        </div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">건축 일정</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex;">
                            <select class="custom-select ff-eng1 fs-085" v-model="buildScheduleYear" style="width:80px; height:27px; padding: 1px 0 1px 10px; margin:0 10px 0 0">
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                            </select>
                            <select class="custom-select ff-eng1 fs-085" v-model="buildScheduleMonth" style="width:80px; height:27px; padding: 1px 0 1px 10px; margin:0 10px 0 0">
                                <option value="1.0">1월</option>
                                <option value="2.0">2월</option>
                                <option value="3.0">3월</option>
                                <option value="4.0">4월</option>
                                <option value="5.0">5월</option>
                                <option value="6.0">6월</option>
                                <option value="7.0">7월</option>
                                <option value="8.0">8월</option>
                                <option value="9.0">9월</option>
                                <option value="10.0">10월</option>
                                <option value="11.0">11월</option>
                                <option value="12.0">12월</option>
                                <option value="3.5">1분기</option>
                                <option value="6.5">2분기</option>
                                <option value="9.5">3분기</option>
                                <option value="12.5">4분기</option>
                                <option value="6.9">상반기</option>
                                <option value="12.9">하반기</option>
                                <option value="0.0">미정</option>
                            </select>
                        </div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">구조</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex; flex-wrap:wrap">
                            <li class="custom-radio">
                                <input type="radio" id="structure1" name="structure" class="custom-control-input" v-model="structureSelected" value="1" >
                                <label class="custom-control-label" for="structure1"><span class="fkr-set2">강구조</span></label>
                            </li>
                            <li class="custom-radio">
                                <input type="radio" id="structure2" name="structure" class="custom-control-input" v-model="structureSelected" value="2" >
                                <label class="custom-control-label" for="structure2"><span class="fkr-set2">목구조</span></label>
                            </li>
                        </div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">예산</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex;">
                            <input type="text" class="form-control fkr-set2" style="width:80px; height:36px; margin-right: 5px;" v-model="expectedCost">
                            <span class="x_div_center fen-set2">&nbsp;억</span>
                        </div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">연락선호시간</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" v-model="preferredContactTime"></div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">비고</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><textarea class="form-control fkr-set2" :style="'max-height:100px; width:' + (isMobile? '180px' : '400px')" v-model="etc"></textarea></div>
                    </div>
                    <div class="x_div_center pt-4">
                        <div class="px-2"><button class="fkr-set1" style="width:90px; height:37px; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000;" @click="moveBack()">취소</button></div>
                        <div class="px-2" v-if="$route.params.prjId"><button class="fkr-set1" style="width:90px; height:37px; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000;" @click="moveToTaskEdit()">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                        <div class="px-2" v-else><button class="fkr-set1" style="width:90px; height:37px; background-color:#4e0000; border:0; border-radius:4px; color:#fff;" @click="moveToTaskEdit()">프로젝트 생성</button></div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'
export default {
    name: 'PrjEdit',
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    watch: {
        phone(val) {
            this.phone = val.replace(/-/g, '').replace(/\s/g, '');
        }
    },
    data() {
        return {
            fetching: true,
            submitting: false,
            isMobile: false,
            customerNm: null,
            phone: null,
            email: null,
            visitChannel: [],
            visitChannelEtc: null,
            usageChecked: [],
            addr: null,
            totalGroundArea: null,
            totalGroundAreaPy: null,
            floors: null,
            buildScheduleYear: null,
            buildScheduleMonth: null,
            structureSelected: null,
            expectedCost: null,
            preferredContactTime: null,
            etc: null,
            daumObj: null,
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }

        if(!this.user.info.cnslrLv) {
            alert('접근권한이 없습니다.');
            location.href = '/';
        }

        this.isMobile = window.innerWidth < 768;

        this.daumObj = new window.daum.Postcode({
            oncomplete: (data) => {
                var addr = ''; // 주소 변수
                var extraAddr = ''; // 참고항목 변수

                //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
                if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
                    addr = data.roadAddress;
                } else { // 사용자가 지번 주소를 선택했을 경우(J)
                    addr = data.jibunAddress;
                }

                // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
                if(data.userSelectedType === 'R'){
                    // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                    // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                    if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                        extraAddr += data.bname;
                    }
                    // 건물명이 있고, 공동주택일 경우 추가한다.
                    if(data.buildingName !== '' && data.apartment === 'Y'){
                        extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                    }
                    // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                    if(extraAddr !== ''){
                        extraAddr = ' (' + extraAddr + ')';
                    }
                }
                this.addr = addr + extraAddr;
            }
        });

        if(this.$route.params.prjId) {
            const rtnval = await Api.post('/api/v1/primprj/find/primprj', {primPrjId: this.$route.params.prjId});
            if(rtnval.result){
                this.customerNm = rtnval.data.customerNm;
                this.phone = rtnval.data.phone;
                this.email = rtnval.data.email;
                var visitChannel = rtnval.data.visitChannel;
                var usage = rtnval.data.usage;
                this.addr = rtnval.data.addr;
                this.totalGroundArea = rtnval.data.totalGroundArea;
                this.floors = rtnval.data.floors;
                this.buildScheduleYear = rtnval.data.buildScheduleYear;
                this.buildScheduleMonth = rtnval.data.buildScheduleMonth;
                var structure = rtnval.data.structure;
                this.expectedCost = rtnval.data.expectedCost;
                this.preferredContactTime = rtnval.data.preferredContactTime;
                this.etc = rtnval.data.etc? rtnval.data.etc.replace(/<br>/g, '\n') : null;
            }
            else {
                alert(rtnval.errormsg);
            }

            if(visitChannel) {
                var visitChannelArray = visitChannel.split(',')
                visitChannelArray.forEach((channel) => {
                    if(['유튜브','네이버','플랫폼','전시회','빌드마켓'].indexOf(channel) < 0) {
                        this.visitChannelEtc = channel
                    }
                    else {
                        switch(channel) {
                            case '유튜브' : this.visitChannel.push(1);
                                break;
                            case '네이버' : this.visitChannel.push(2);
                                break;
                            case '플랫폼' : this.visitChannel.push(3);
                                break;
                            case '전시회' : this.visitChannel.push(4);
                                break;
                            case '빌드마켓' : this.visitChannel.push(5);
                                break;
                        }
                    }
                })
            }

            var usageArray = usage.split(',')
            usageArray.forEach((usage) => {
                switch(usage.trim()) {
                    case '주거용' : this.usageChecked.push(1);
                        break;
                    case '상업용' : this.usageChecked.push(2);
                        break;
                    case '기타' : this.usageChecked.push(3);
                        break;
                    case '학교' : this.usageChecked.push(4);
                        break;
                }
            })

            switch(structure) {
                case '강구조' : this.structureSelected = 1;
                    break;
                case '목구조' : this.structureSelected = 2;
                    break;
            }
        }
        else {
            this.buildScheduleYear = new Date().getFullYear();
            this.buildScheduleMonth = new Date().getMonth() +1;
        }
        this.fetching = false;

        this.totalGroundAreaPy = parseInt(this.totalGroundArea / 3.3);

        if(this.buildScheduleMonth %1 == 0) {
            this.buildScheduleMonth = this.buildScheduleMonth.toFixed(1)
        }
    },
    methods: {
        searchAddr() {
          this.daumObj.open();
        },
        moveBack() {
            if(this.$route.params.prjId) {
                location.href = '/panda/prj/' + this.$route.params.prjId;
            }
            else {
                location.href = '/panda/prj-list';
            }
        },
        calcArea(pyToSquare) {
            if(pyToSquare) {
                this.totalGroundArea = (this.totalGroundAreaPy*3.3).toFixed(1)
            }
            else {
                this.totalGroundAreaPy = (this.totalGroundArea/3.3).toFixed(0)
            }
        },
        validateAddr() {
            if(['서울', '부산', '대구', '인천', '대전', '광주', '울산', '세종'].indexOf(this.addr.split(' ')[0]) >=0) {
                var addrTmp = this.addr.split(' ');
                addrTmp[0] += '시';
                this.addr = addrTmp.join(' ');
                return true;
            }
            else {
                if(this.addr.indexOf('시 ') <0 && this.addr.indexOf('군 ') <0) {
                    alert('시, 또는 군 행정구역단위를 주소에 포함시키세요.\n(단위명 뒤에 띄어쓰기(스페이스)가 포함되어야 함)');
                    return false;
                }
                else {
                    return true;
                }    
            }
        },
        async moveToTaskEdit() {
            if(!this.customerNm) {
                alert('고객명은 필수입력값입니다.');
                return;
            }
            if(!this.phone && !this.email) {
                alert('연락처 또는 이메일 중 하나를 입력해주세요.');
                return;
            }
            if(this.phone && this.phone.length < 10) {
                alert('전화번호는 10자리 이상으로 입력해주세요.');
                return;
            }
            if(this.usageChecked.length == 0) {
                alert('용도를 선택해주세요.');
                return;
            }
            if(this.expectedCost && /^[0-9.]+$/.test(this.expectedCost) == false){
                alert('예산은 숫자만 입력할 수 있습니다(소숫점 허용).');
                return;
            }
            if(this.addr && !this.validateAddr()) {
                return;
            }

            var usageArray = [];
            this.usageChecked.forEach((val) => {
                switch(parseInt(val)) {
                    case 1 : usageArray.push('주거용');
                        break;
                    case 2 : usageArray.push('상업용');
                        break;
                    case 3 : usageArray.push('기타');
                        break;
                    case 4 : usageArray.push('학교');
                        break;
                }
            })

            var visitChannelArray = [];
            this.visitChannel.forEach((channel) => {
                switch(parseInt(channel)) {
                    case 1 : visitChannelArray.push('유튜브');
                        break;
                    case 2 : visitChannelArray.push('네이버');
                        break;
                    case 3 : visitChannelArray.push('플랫폼');
                        break;
                    case 4 : visitChannelArray.push('전시회');
                        break;
                    case 5 : visitChannelArray.push('빌드마켓');
                        break;
                }
            })
            if(this.visitChannelEtc) {
                visitChannelArray.push(this.visitChannelEtc)
            }

            var newPrimPrjObj = {
                primPrjId: this.$route.params.prjId,
                customerNm: this.customerNm,
                phone: this.phone? this.phone : null,
                email: this.email? this.email : null,
                visitChannel: visitChannelArray.join(','),
                usage: usageArray.join(', '),
                addr: this.addr,
                totalGroundArea: this.totalGroundArea,
                floors: this.floors,
                buildScheduleYear: this.buildScheduleYear,
                buildScheduleMonth: this.buildScheduleMonth,
                structure: this.structureSelected == 1? '강구조' : (this.structureSelected == 2? '목구조' : null),
                expectedCost: this.expectedCost,
                preferredContactTime: this.preferredContactTime,
                etc: this.etc? this.etc.replace(/\n/g, '<br>') : null,
            }
            if(this.$route.params.prjId) {
                this.submitting = true;
                const returnvalue = await Api.post('/api/v1/primprj/update/primprj', newPrimPrjObj);
                if(returnvalue.result){
                    alert('수정사항이 저장되었습니다.');
                    location.href = '/panda/prj/' + this.$route.params.prjId;
                }
                else {
                    alert(returnvalue.errormsg);
                }
                this.submitting = false;
            }
            else {
                sessionStorage.setItem('newPrimPrjObj', JSON.stringify(newPrimPrjObj));
                alert('첫번째 상담을 작성합니다.');
                location.href = '/panda/task'
            }
        }
    }
}
</script>

<style scoped>
li {
    margin: 5px 15px 5px 0;
}
</style>
