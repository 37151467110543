<template>
    <div class="ca_main">
		<div class="x_div_center">
			<div class="pg_stage">

				<div class="d-flex pt-5">
					<div style="flex:0 0 calc(100% - 100px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 참여 관리</span></div>
					<div class="x_div_center"><div class="text-end" style="width:100px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
				</div>

				<div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

				<div style="height:14px"></div>

				<div class="x_div_center py-5">
					<div class="text-center"><span class="fkr-set2 fs-10">고객과 계약을 진행합니다.</span></div>
				</div>

				<div>

					<div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
						<table class="table-borderless w-100">
						<tr style="height:55px; border-bottom:1px solid #c9c9c9;">
							<td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="fkr-set2"><strong>계약서</strong></span></td>
							<td class="text-center" width="70%">
                                <form method="post" action="/api/v1/common/filedownload">
                                    <input type="hidden" name="fileName" :value="fnlPath"/>
                                    <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fkr-set2">{{fnlOrgNm}}<i v-if="fetching" class='fa fa-spinner fa-spin'></i></span></a>
                                </form>
                            </td>
						</tr>
						</table>
					</div>

				</div>

				<div class="x_div_end"><div class="py-4"><button class="x_div_center" onclick="location.href='/project/bidding/partner/contract-list'" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">목록</span></button></div></div>
				
				<div style="height:50px"></div>

			</div>
		</div>
	</div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'

const processCode = {
    DS: 'DS',
    MF: 'MA',
    CT: 'CN',
	IN: 'IN'
}

export default {
    name: 'BiddingContract',
    data() {
        return {
            url : '',
            usrRole : this.user.info.usrRole,
            prcCd : processCode[this.user.info.usrRole],
            fetching : true,
            fnlOrgNm : null,
			fnlPath : '',
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    created() {
        this.url = location.href;
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }
        else if(Auth.partnerRoleTypes.indexOf(this.usrRole) <0) {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }

        this.fetchContractDetail();
    },
    methods: {
		async fetchContractDetail() {
            this.fetching = true;
            const rtnvalue = await Api.post('/api/v1/process/get', {prjId: this.$route.params.prjId, prcCd: this.prcCd});
            this.fetching = false;
            if(rtnvalue.result) {
                if(rtnvalue.data.attachFiles) {
                    rtnvalue.data.attachFiles.forEach((file) => {
                        if(file.prcAttchFlCtgry.code == 'CT') {
                            this.fnlOrgNm = file.attchNm;
                            this.fnlPath = file.attchWebPath;
                        }
                    })
                }
            }
            else {
                alert(rtnvalue.errormsg)
            }
        }
    }
}
</script>