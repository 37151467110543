<template>
    <div class="d-flex">
        <LNB :user="user"/>
        <div class="ca_main">
            <div class="x_div_center">
                <div class="pg_stage">

                    <div class="x_div_center pt-5">
						<div class="" style="width:72%"><span class="fkr-set1 fs-16" style="color:#333333">{{prjNm}}</span></div>
						<div class="text-end" style="width:28%"><span class="fkr-set2" style="color:#484848">{{prcNm}} > <span class="fw-bold">{{prcStpNm}}</span></span></div>
					</div>

                    <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                    <div style="height:50px"></div>

                    <div class="pg_m_set" v-if="fetching">
                        <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                    </div>

                    <div class="accordion" id="accordion_project" v-else>

                        <div :id="'set' + (i+1)" v-for="(cat, i) in showList" :key="i">
                            <div class="accordion-header" :id="'h' + (i+1)" style="border-bottom:1px solid #c9c9c9; cursor:pointer">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#set' + (i+1) + '_m'" aria-expanded="true" :aria-controls="'set' + (i+1) + '_m'">
                                    <span class="fs-12 fw-bold">{{filesCtgryList[i].name}}</span>
                                </button>
                            </div>

                            <div :id="'set' + (i+1) + '_m'" class="accordion-collapse collapse show py-2" data-bs-parent="#accordion_project">

                                <div v-for="(mat, j) in cat" :key="j">
                                    <div class="accordion-body pj_sg">
                                        <div class="x_div_center pj_ig" v-for="(hat, k) in mat" :key="k">
                                            <div v-show="hat.name" :style="viewWidth >= 1024 && (user.info.usrRole == 'OW' || user.info.usrRole == 'SL') ? ('height: ' + cardHeight + 'px; width:100%; position: relative; overflow: hidden; border-radius:8px;') : ''" :class="'ig' + (checkDeleted(i,j,k)? ' diagonal' : '')" @mouseover="mouseOvered = [i,j,k]" @mouseout="mouseOvered = [null, null, null]">
                                                <a href="javascript:void(0)" @click="openImgModal(hat.fileLink, hat.sideDiff, i, hat.idx)"><img :src="hat.fileLink" :style="resolveImgStyle(hat.sideDiff) + (!(user.info.usrRole == 'OW' || user.info.usrRole == 'SL')? '' : 'filter:brightness(100%);') + (checkDeleted(i,j,k)? 'opacity: 0.5;' : '')" class="rg"></a>
                                                <div class="ig_d" v-show="!(user.info.usrRole == 'OW' || user.info.usrRole == 'SL') && checkDelBtn(i,j,k) && !checkDeleted(i,j,k)"><a href="javascript:void(0)" @click="deleteFileItem(i,j,k)"><img src="@/assets/img_legacy/r_del.png"></a></div>
                                            </div>
                                            <div v-if="!(user.info.usrRole == 'OW' || user.info.usrRole == 'SL') && hat.newSlot" class="x_div_center py-2" :style="'height: ' + cardHeight + 'px'">
                                                <label :for="'s-file' + i">
                                                    <div class="s-upload">
                                                        <i class="fa-solid fa-circle-plus fs-15" style="color:#4e0000"></i>
                                                    </div>
                                                </label>
                                                <input type="file" style="display:none" @change="fileHandling($event, i, j, k)" :name="'s-file' + i" :id="'s-file' + i">
                                            </div>
                                            <!-- <div class="pj_bg"></div> -->
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="x_div_end" v-if="!(user.info.usrRole == 'OW' || user.info.usrRole == 'SL')">
                        <div class="py-2 pe-1"><button class="fkr-set2 fs-095" @click="saveFiles()" :disabled="submitting" style="width:130px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">파일 일괄 저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                    </div>
                    <div style="height:50px"></div>

                    <!-- img modal -->
                    <div id="v-view" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
                        <div class="modal-dialog modal-lg" role="document" style="max-width: 90vw">
                            <div class="modal-content">
                                <div style="height:10px"></div>
                                <div class="x_div_end py-2 px-4">
                                    <div class="text-end" style="width:100px"><a href="#@" @click="closeModal()"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                                </div>
                                <div class="x_div_center" style="display:flex">
                                    <a href="#@" @click="shiftModal('prev')"><i class="fa-solid fa-angle-left fs-15"></i></a>
                                    <div class="x_div_center py-2 px-4">
                                        <img :src="modalImgLink" :style="modalImgStyle">
                                    </div>
                                    <a href="#@" @click="shiftModal('next')"><i class="fa-solid fa-angle-right fs-15"></i></a>
                                </div>
                                <div style="height:20px"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import LNB from '@/components/LNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'PrcStpMultiple',
    components: {
		LNB
	},
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            prcTree: null,
            prjId: null,
            prjNm: '',
            prcId: null,
            prcNm: '',
            prcStpId: null,
            prcStpCd: null,
            prcStpNm: '',
            status: 0,
            cardHeight: 0,
            viewWidth: 0,
            filesCtgryList: [],
            filesCtgryIndexMap: [],
            resultList: [],
            fileList: [],
            showList: [],
            deleteFlIdList: [],
            mouseOvered: [null, null, null],
            submitting: false,
            fetching: true,
            modalImgLink: '',
            modalImgStyle: '',
            modalCordinate: []
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           if(this.$route.query.prjId){
               this.$router.push({ path: '/member/login', query: { fromTalk: encodeURIComponent("/project" + location.href.split("/project")[1]) } });
           }
           else {
               location.href = '/member/login'
           }
        }

        if(this.$route.query.prjId){
            var rtnval = await Api.get('/api/v1/process/list/prc/tree?prjId=' + this.$route.query.prjId);
            if(rtnval.result) {
                sessionStorage.setItem('prcTree', JSON.stringify(rtnval.data));
                sessionStorage.setItem('prjId', this.$route.query.prjId);
                sessionStorage.setItem('prjNm', rtnval.data.prjNm);
                var rtnvalue = await Api.get('/api/v1/user/list/authorized/prc?usrRole=' + this.user.info.usrRole);
                if(rtnvalue.result) {
                    var accessiblePrc = [];
                    rtnvalue.data.processes.forEach((prc) => {
                        accessiblePrc.push(prc.code);
                    })
                    sessionStorage.setItem('accessiblePrc', JSON.stringify(accessiblePrc));
                    location.href = location.href.split("?")[0];
                }
                else {
                    alert(rtnvalue.errormsg)
                }
            }
            else {
                alert(rtnval.errormsg)
            }
        }
        else {
            this.prjId = sessionStorage.getItem('prjId');
            this.prjNm = sessionStorage.getItem('prjNm');
            this.prcTree = JSON.parse(sessionStorage.getItem('prcTree'));
        }
        
        this.prcTree.processList.forEach((prc) => {
            if(prc.code == this.$route.params.prcCd) {
                this.prcNm = prc.name;
                prc.prcStpList.forEach((prcStp) => {
                    if(prcStp.code == this.$route.params.prcStpCd) {
                        this.prcStpNm = prcStp.name;
                    }
                })
            }
        })
        this.prcTree.prcScndryList.forEach((prc) => {
            if(prc.code == this.$route.params.prcCd) {
                this.prcNm = prc.name;
                prc.prcStpList.forEach((prcStp) => {
                    if(prcStp.code == this.$route.params.prcStpCd) {
                        this.prcStpNm = prcStp.name;
                    }
                })
            }
        })

        if(this.prjId == 'null') {
            alert('My Projects 페이지에서 프로젝트를 선택하셔야 합니다.');
            location.href = '/project/dashboard'
        }

        this.prcStpCd = this.$route.params.prcStpCd;

        var prcCd = ['DE','IS'].indexOf(this.$route.params.prcCd) >= 0? 'MA' : this.$route.params.prcCd;
        var rtnval = await Api.post('/api/v1/process/get', {prjId: this.prjId, prcCd: prcCd});
        if(rtnval.result) {
            this.prcId = rtnval.data.prcId;
        }
        else {
            alert(rtnval.errormsg)
        }

        rtnval = await Api.post('/api/v1/common/get/code/family', {codeCtgry: 'PRSTFC', ascByOrderNum: true});
		if(rtnval.result) {
			var index = 0;
			rtnval.data.forEach((code) => {
				if(code.upperCode == this.$route.params.prcStpCd) {
					this.filesCtgryList.push(code);
					this.filesCtgryIndexMap[code.orderNum] = index++;
				}
			})
		}
		else {
			alert(rtnval.errormsg);
		}

        this.cardHeight = this.getCardHeight();
        this.viewWidth = window.innerWidth;
        this.fetchFileList();
    },
    methods: {
        getCardHeight() {
            return Number(window.$('.pg_stage').css('width').split('px')[0]) /4;
        },
        async fetchFileList() {
            this.resultList = [];
            this.fileList = [];
            for(var i=0; i < this.filesCtgryList.length; i++) {
                this.resultList[i] = [];
                this.fileList[i] = [];
            }
            this.fetching = true;
            const rtnval = await Api.post('/api/v1/prcess/step/get', {prjId: this.prjId, code: this.$route.params.prcStpCd});
            this.fetching = false;
            if(rtnval.result) {
                if(rtnval.data.prcStpId) {
                    this.prcStpId = rtnval.data.prcStpId;
                    this.status = rtnval.data.stts;
                    this.fetching = true;
                    const returnvalue = await Api.post('/api/v1/common/get/attachments', {
                        parentId: rtnval.data.prcStpId,
                        parentUnit: 'PRCSTP',
                        ascByCrtDt: true,
                        limit: -1
                    });
                    if(returnvalue.result) {
                        var listLength = 0;
                        if(returnvalue.data.attchments) {
                            listLength = returnvalue.data.attchments.length;
                            returnvalue.data.attchments.forEach((item) => {
                                var tokens = item.attchOrgNm.split('.');
                                var mimeType = tokens[tokens.length-1].toLowerCase();
                                var fileType = 'image/' + mimeType;
            
                                const byteCharacters = atob(item.attchImg);
                                const byteNumbers = new Array(byteCharacters.length);
                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);
                                const blob = new Blob([byteArray], {type: fileType});
                                const fileLink = URL.createObjectURL(blob);
                                const img = new Image();
                                img.src = fileLink;
                                const intervalId = window.setInterval(() => {
                                    if(img.width * img.height > 0) {
                                        this.resultList[this.filesCtgryIndexMap[item.attchFlCtgry.orderNum]].push({id: item.id, name: item.attchOrgNm, fileLink: fileLink, file: null, newSlot: false, sideDiff: img.width - img.height})
                                        window.clearInterval(intervalId);
                                        listLength = listLength-1;
                                    }
                                }, 100)
                            })
                        }
                        const intervalId = window.setInterval(() => {
                            if(listLength == 0) {
                                this.renderList();
                                this.fetching = false;
                                window.clearInterval(intervalId);
                            }
                        }, 500)
                        // this.$forceUpdate();
                    }
                    else {
                        alert(returnvalue.errormsg);
                    }
                }
                else {
                    this.renderList();
                }
            }
            else {
                alert(rtnval.errormsg)
            }
        },
        renderList() {

            for(var i=0; i<this.filesCtgryList.length; i++) {
                var row = [];
                this.resultList[i].sort((a, b) => {return a.id - b.id})
                for(var j=0; j < this.resultList[i].length; j++) {
                    this.resultList[i][j].idx = j;
                    row.push(this.resultList[i][j])
                    if(j%4 == 3) {
                        this.fileList[i].push(row);
                        row = [];
                    }
                }
                if(row.length == 0) {
                    row = [
                        {id: null, name: null, fileLink: null, file: null, newSlot: true},
                        {id: null, name: null, fileLink: null, file: null, newSlot: false},
                        {id: null, name: null, fileLink: null, file: null, newSlot: false},
                        {id: null, name: null, fileLink: null, file: null, newSlot: false}
                    ]
                }
                else {
                    row.push({id: null, name: null, fileLink: null, file: null, newSlot: true})
                    j++;

                    while(j%4 != 0) {
                        row.push({id: null, name: null, fileLink: null, file: null, newSlot: false})
                        j++
                    }
                }
                this.fileList[i].push(row);
            }
            this.showList = [];
            this.showList = this.fileList;
        },
        checkDelBtn(i,j,k) {
            var rtnval = true;
            if(this.mouseOvered[0] != i) {
                rtnval = false;
            }
            if(this.mouseOvered[1] != j) {
                rtnval = false;
            }
            if(this.mouseOvered[2] != k) {
                rtnval = false;
            }
            return rtnval;
        },
        fileHandling(e, i, j, k) {
            this.fileList[i][j][k].newSlot = false;
            this.fileList[i][j][k].file = e.target.files[0];
            this.fileList[i][j][k].fileLink = URL.createObjectURL(e.target.files[0]);
            this.fileList[i][j][k].name = e.target.files[0].name;
            if(k < 3) {
                this.fileList[i][j][k+1].newSlot = true;
                this.fileList[i][j][k+1].file = null;
                this.fileList[i][j][k+1].fileLink = null;
                this.fileList[i][j][k+1].name = null;
            }
            else {
                this.fileList[i].push([
                    {id: null, name: null, fileLink: null, file: null, newSlot: true},
                    {id: null, name: null, fileLink: null, file: null, newSlot: false},
                    {id: null, name: null, fileLink: null, file: null, newSlot: false},
                    {id: null, name: null, fileLink: null, file: null, newSlot: false}
                ])
            }

            this.showList = [];
            this.showList = this.fileList;
        },
        deleteFileItem(i, j, k) {
            if(this.fileList[i][j][k].id) {
                this.deleteFlIdList.push(this.fileList[i][j][k].id);
            }
            this.fileList[i][j][k].file = null;

            this.showList = [];
            this.showList = this.fileList;
        },
        checkDeleted(i, j, k) {
            if(this.fileList[i][j][k].id) {
                return this.deleteFlIdList.indexOf(this.fileList[i][j][k].id) >= 0
            }
            else {
                return this.fileList[i][j][k].file == null
            }
        },
        async saveFiles() {
            const formData = new FormData();
            var attchFlCtgry = [];
            var attchFlTyp = [];
            var attchOrgNmList = [];
            var prcStpId = [];
            var uploadingIndex = 0;
            for(var i=0; i<this.filesCtgryList.length; i++) {
                for(var j=0; j < this.fileList[i].length; j++) {
                    for(var k=0; k<4; k++) {
                        if(this.fileList[i][j][k].file) {
                            formData.append("attchmntList", this.fileList[i][j][k].file);
                            attchFlCtgry.push(this.filesCtgryList[i].code);
                            attchFlTyp.push('OR');
                            attchOrgNmList.push(this.fileList[i][j][k].name);
                            if(i > uploadingIndex) {
                                uploadingIndex = i;
                            } 
                        }
                    }
                }
            }
            if(attchOrgNmList.length > 0) {
                var status = uploadingIndex +1 == this.filesCtgryList.length ? 2 : 1
                this.submitting = true;
                const rtnval = await Api.post('/api/v1/prcess/step/set', {prcStpId: this.prcStpId, prcId: this.prcId, code: this.$route.params.prcStpCd, stts: status});
                if(rtnval.result) {
                    this.status = status;
                    this.prcStpId = rtnval.data;
                    attchOrgNmList.forEach(() => {
                        prcStpId.push(this.prcStpId);
                    })
                    var request = {
                        attchFlCtgry: attchFlCtgry,
                        attchFlTyp: attchFlTyp,
                        attchOrgNmList: attchOrgNmList,
                        deleteFlIdList: this.deleteFlIdList,
                        parentId: prcStpId
                    }
                    formData.append(
                        "request",
                        new Blob([JSON.stringify(request)], { type: "application/json" })
                    );
                    const rtnvalue = await Api.post('/api/v1/common/set/attachments', formData)
                    if(rtnvalue.result) {
                        window.scrollTo(0, 0);
                        await this.fetchFileList();
                    }
                    else {
                        alert(rtnvalue.errormsg)
                    }
                }
                else {
                    console(rtnval.errormsg)
                }
                this.submitting = false;
            }
            else {
                alert("파일을 하나 이상 첨부해주세요.");
            }
        },

        openImgModal(link, diff, catIdx, imgIdx) {
            if(this.viewWidth >= 1024 && (this.user.info.usrRole == 'OW' || this.user.info.usrRole == 'SL')) {
                this.modalCordinate = [catIdx, imgIdx]
                this.modalImgLink = link;
                if(diff >= 0) {
                    this.modalImgStyle = 'width:80vw;'
                }
                else {
                    this.modalImgStyle = 'height:90vh;'
                }
                window.$('#v-view').modal('show');
            }
        },

        shiftModal(direction) {
            if(direction == 'prev') {
                if(this.modalCordinate[1] > 0) {
                    this.modalCordinate[1]--;
                    this.modalImgLink = this.resultList[this.modalCordinate[0]][this.modalCordinate[1]].fileLink
                    if(this.resultList[this.modalCordinate[0]][this.modalCordinate[1]].sideDiff >= 0) {
                        this.modalImgStyle = 'width:80vw;'
                    }
                    else {
                        this.modalImgStyle = 'height:90vh;'
                    }
                }
            }
            else {
                if(this.modalCordinate[1] + 1 < this.resultList[this.modalCordinate[0]].length) {
                    this.modalCordinate[1]++;
                    this.modalImgLink = this.resultList[this.modalCordinate[0]][this.modalCordinate[1]].fileLink
                    if(this.resultList[this.modalCordinate[0]][this.modalCordinate[1]].sideDiff >= 0) {
                        this.modalImgStyle = 'width:80vw;'
                    }
                    else {
                        this.modalImgStyle = 'height:90vh;'
                    }
                }
            }
        },

        closeModal() {
            window.$('#v-view').modal('hide');
        },
        
        resolveImgStyle(sideDiff) {
            if(this.viewWidth >= 1024 && (this.user.info.usrRole == 'OW' || this.user.info.usrRole == 'SL')) {
                if(sideDiff){
                    if(sideDiff >= 0) {
                        return 'position: absolute; top: 0; left: -25%; width: auto; height: ' + this.cardHeight + 'px; '
                    }
                    else {
                        return 'position: absolute; top: -25%; left: 0; width: 100%; height: auto; '
                    }
                }
                else {
                    return 'width:100%; border-radius:8px; '    
                }
            }
            else {
                return 'width:100%; border-radius:8px; '
            }
        }
    }
}
</script>

<style scoped>
.diagonal {
    width: 100%;
    height: 100%;
    background: linear-gradient(
    to top right,
    #fff calc(50% - 1px),
    red,
    #fff calc(50% + 1px)
    );
}
</style>