<template>
  <div id="customHomeRecommendation" class="custom-home-recommendation" :class="{pc: !isMobile }">
    <a href="/plan" rel="noopener noreferrer">
      <div class="more-area">
      <div class="image">
          <div class="text-how-item">
            <p>나에게 딱 맞는 집은 어떤 집일까 ?</p>
            <span>맞춤 주택 추천받기</span>
            <div style="display:flex">
              <div class="text-more-item" style="margin-right:15px">
                <span>더 알아보기</span>
              </div>
              <div class="text-more-item">
                <a href="/files/M3Systems_MHouse_leaflet_231115.pdf" target="_blank" :style="isMobile? 'top: -3px; position: relative;' : ''">
                  <span style="display:unset">팜플렛 다운로드</span>
                </a>
              </div>
            </div>
          </div>
      </div>
      </div>
    </a>
  </div>
</template>
<script>
import store from '@/store'

export default {
  name: "CustomHomeRecommendation",
  computed: {
    isMobile() {
      return store.state.isMobile;
    }
  },  
}
</script>