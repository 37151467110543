<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 목록 조회</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <div class="x_div_center" style="width:270px; height:34px; border:1px solid #d6d6d6; border-radius:4px; margin-left: 20px; margin-right: 20px;">
              <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findPrj()"><i class="fa-solid fa-magnifying-glass"></i></div>
              <div class="x_div_center">
                  <Datepicker :endDate="findEndDtInput" @update-date="setStrtDt" class="form-control fkr-set2 fc-w5 text-center" style="width:95px; height:30px; border-radius:4px; border:1px solid #c9c9c9" placeholder="시작일자"/>
                  <span>&nbsp;~&nbsp;</span>
                  <Datepicker :startDate="findStrtDtInput" @update-date="setEndDt" class="form-control fkr-set2 fc-w5 text-center" style="width:95px; height:30px; border-radius:4px; border:1px solid #c9c9c9" placeholder="종료일자"/>
              </div>
            </div>
            <select class="custom-select ff-eng1 fs-085" v-model="processType" @change="switchFilter()" style="width:120px; height:37px; margin-right: 20px">
              <option value='DS'>설계</option>
              <option value='MA'>제조</option>
              <option value='CN'>시공</option>
              <option value='IN'>인테리어</option>
            </select>
            <select class="custom-select ff-eng1 fs-085" v-model="ascending" @change="switchFilter()" style="width:120px; height:37px; margin-right: 20px">
              <option value=true>날짜순</option>
              <option value=false>최신순</option>
            </select>
            <span class="fkr-set2 fs-10" style="padding-top:5px; margin-right: 20px">검색결과: {{ numOfPrj }} 건</span>
            <span class="fkr-set2 fs-9" style="padding-top:7px;">※종료된 비딩은 미표시됩니다.</span>
            <div class="ps-2" style="margin-left: auto; margin-right: 20px"><button class="fkr-set2 fs-095" @click="exportExcel()" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">Excel 다운로드</button></div>
          </div>
          


            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:12%"><span class="fkr-set1 fs-9">프로젝트 이름</span></div>
                <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">위치</span></div>
                <div class="x_div_center" style="width:12%"><span class="fkr-set1 fs-9">용도</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">비딩 등록일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">건축주 이름</span></div>
                <div class="x_div_center" style="width:12%"><span class="fkr-set1 fs-9">건축주 이메일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">전화번호</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in prjList" :key="index" class="x_div_center" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center" style="width:12%"><a href="javascript:void(0)" @click="moveToPrj(item.prjId);"><span class="fkr-set2 fs-9">{{item.prjNm.split(';')[0]}}</span></a></div>
                  <div class="x_div_center" style="width:30%"><span class="fkr-set2 fs-9">{{item.prjNm.split(';')[1]}}</span></div>
                  <div class="x_div_center" style="width:12%"><span class="fkr-set2 fs-9">{{item.purpose}}</span></div>
                  <div class="x_div_center" style="width:10%"><span class="fkr-set2 fs-9">{{item.crtDt.split('T')[0]}}</span></div>
                  <div class="x_div_center" style="width:10%"><span class="fkr-set2 fs-9">{{item.ownrNm}}</span></div>
                  <div class="x_div_center" style="width:12%"><span class="fkr-set2 fs-9">{{item.ownrEmail}}</span></div>
                  <div class="x_div_center" style="width:10%"><span class="fkr-set2 fs-9">{{item.ownrPhone}}</span></div>
                </div>
              </div>



            </div>



          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Datepicker from '@/components/Datepicker.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "BddngListMng",
  components: {
		AdminLNB, Datepicker
	},
  data(){
    return {
        prjList:[],
        numOfPrj: 0,
        findStrtDtInput: '',
        findEndDtInput: '',
        processType: 'DS',
        ascending: true,
        fetching: false
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async findPrj(){
      if(!this.findStrtDtInput || !this.findEndDtInput){
        alert('조회기간을 선택하세요.');
        return;
      }

      this.fetching = true;
      var rtnval = await Api.post('/api/v1/project/find/bddng/daterange/list', {startDt: this.findStrtDtInput, endDt: this.findEndDtInput, type: this.processType, asc: this.ascending});
      if(rtnval.result){
        this.prjList = rtnval.data.findPrjAndUsrDtoList;
        this.numOfPrj = rtnval.data.numOfList;
      }
      else {
        alert(rtnval.errormsg);
      }
      this.fetching = false;
    },
    
    setStrtDt(date) {
        this.findStrtDtInput = new Date(date).toISOString();
    },
    setEndDt(date) {
        var d = new Date(date);
        d.setDate(d.getDate() +1);
        this.findEndDtInput = d.toISOString();
    },
    switchFilter() {
      this.findPrj();
    },
    moveToPrj(prjId) {
        sessionStorage.setItem('prjId', prjId);
        window.open('about:blank').location.href='/project/summary/' + prjId
    },
    exportExcel(){ 
        // step 1. workbook 생성
        var wb = window.XLSX.utils.book_new();

        // step 2. 시트 만들기 
        var newWorksheet = window.XLSX.utils.json_to_sheet(this.prjList);
        
        // step 3. workbook에 새로만든 워크시트에 이름을 주고 붙인다.  
        window.XLSX.utils.book_append_sheet(wb, newWorksheet, 'Sheet1');

        // step 4. 엑셀 파일 만들기 
        var wbout = window.XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

        // step 5. 엑셀 파일 내보내기 
        window.saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), 'Project_List_' + new Date().toISOString() + '.xlsx');
    },

    s2ab(s) { 
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;    
    }
  }
}
</script>

