<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">시공 위임 관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div class="pt-3" v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">

                <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                  <td class="text-center br_0" width="17%"><span class="ff-kor1" style="font-size:12pt"><strong>고객ID</strong></span></td>
                  <td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>프로젝트명</strong></span></td>
                  <td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>건축위치</strong></span></td>
                  <td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>건축물타입</strong></span></td>
                  <td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>신청일자</strong></span></td>
                  <td class="text-center" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>시공사 지정</strong></span></td>
                </tr>

                <tr v-for="(item, index) in prjList" :key="index" style="height:55px; border-bottom:1px solid #c9c9c9;" >
                  <td style="display: none">{{item.prcId}}}</td>
                  <td class="text-center br_0"><span class="ff-eng1" style="font-size:12pt">{{item.ownrEmail}}</span></td>
                  <td class="text-center br_0"><span class="ff-kor1" style="font-size:10pt">{{item.prjNm.split(';')[0]}}</span></td>
                  <td class="text-center br_0"><span class="ff-eng1" style="font-size:10pt">{{item.addr}}</span></td>
                  <td class="text-center br_0"><span class="ff-kor1" style="font-size:10pt">{{item.purpose}}</span></td>
                  <td class="text-center br_0"><span class="ff-eng1" style="font-size:10pt">{{item.bddngCrtDt.split('T')[0]}}</span></td>
                  <td class="text-center">
                    <div v-if="item.prtnerId" class="x_div_center py-2"><button @click="onClickCntrctReg(item, true)" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000"><span class="fa-kor-Noto fs-085">보기</span></button></div>
                    <div v-else class="x_div_center py-2"><button @click="onClickCntrctReg(item, false)" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000"><span class="fa-kor-Noto fs-085">지정하기</span></button></div>
                  </td>
                </tr>

              </table>
            </div>

          </div>

          <!-- 시공사 위임 시작 -->
          <div id="cntrct-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">

                <div class="w-100">
                  <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                    <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">시공사 위임</span></div>
                    <div class="" style="width:100px"></div>
                  </div>

                  <div style="height:20px"></div>

                  <div class="x_div_center p-4">
                    <div class="x_div_center p-4" style="border-top:1px solid #c9c9c9; border-bottom:1px solid #c9c9c9;">
                      <div class="table-responsive w-100" style="border:1px solid #c9c9c9; border-radius:8px">
                        <table class="table-borderless w-100" style="table-layout:fixed">
                          <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                            <td class="text-center br_0" width="20%"><span class="ff-kor1" style="font-size:12pt"><strong>시공사명</strong></span></td>
                            <td class="text-center br_0" width="26%"><span class="ff-kor1" style="font-size:12pt"><strong>주소</strong></span></td>
                            <td class="text-center br_0" width="23%"><span class="ff-kor1" style="font-size:12pt"><strong>지명원</strong></span></td>
                            <td class="text-center br_0" width="23%"><span class="ff-kor1" style="font-size:12pt"><strong>홈페이지</strong></span></td>
                            <td class="text-center" width="8%"></td>
                          </tr>
                          <tr v-for="(item, index) in cntrctrList" :key="index"  style="height:55px;">
                            <td style="display: none">{{item.cntrctrId}}</td>
                            <td class="text-center br_0" id="bsnss-prtnr"><span class="ff-eng1" style="font-size:12pt">{{item.cntrctrNm}}</span></td>
                            <td class="text-center br_0"><span class="ff-kor1" style="font-size:10pt">{{item.addr1}} {{item.addr2}}</span></td>
                            <td class="text-center br_0">
                              <form method="post" action="/api/v1/user/prof/filedownload">
                                <input v-if="item.profImgPath != null" type="hidden" name="fileName" :value="item.profImgPath.replace('platform/usr/prof/', '')"/>
                                <input v-else type="hidden" name="fileName" value="" />
                                <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{item.profOrgNm}} </span></a>
                              </form>
                            </td>
                            <td class="text-center br_0"><a :href="item.hmpg" target="_blank"><span class="ff-eng1" style="width: 100%; display: block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{item.hmpg}}</span></a></td>
                            <td class="text-center">
                              <div class="custom-control custom-radio"><input name="cntrctRadio" :checked="(cntrctrId != null) ? (item.cntrctrId == cntrctrId) : false" type="radio" :id="'radio'+index" :value="item.cntrctrId" @change="radioChange($event)" class="custom-control-input"><label :for="'radio'+index" class="custom-control-label"></label></div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div v-if="!isCntrctrIdExist" class="x_div_center"><div class="py-4"><button @click="updCntrctr" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">선택</span></button></div></div>
                  <div v-if="isCntrctrIdExist" class="x_div_center"><div class="py-4"><button @click="hideModal" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">닫기</span></button></div></div>

                  <div style="height:20px"></div>

                </div>

              </div>
            </div>
          </div>
          <!-- 시공사 위임 끝 -->

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from "@/api/auth";
import Api from "@/api";

export default {
  name: "CntrctMng",
  components:{
    AdminLNB
  },
  data(){
    return {
      prjList:[],
      cntrctrList:[],
      prcId:0,
      ownrEmail:'',
      ownrId:0,
      prjNm:'',
      addr:'',
      purpose:'',
      cntrctDlgtDt:'',
      cntrctrId:0,
      isCntrctrIdExist:false,
      fetching: true
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }
    //await this.checkAdmin();
    await this.getPrjList();
  },
  methods:{
    async getPrjList(){
      this.fetching = true;
      var rtnval = await Api.get('/api/v1/project/list/matching/prj?prcCd=CN');
      this.fetching = false;
      if(rtnval.result){
        this.prjList = (rtnval.data);
      }
    },

    async onClickCntrctReg(item, isCntrctrIdExist){
      if(isCntrctrIdExist){
        this.isCntrctrIdExist = true;
      }else{
        this.isCntrctrIdExist = false;
      }

      this.prcId = item.prcId;

      if(item.prtnerId != null){
        this.cntrctrId = item.prtnerId;
      }

      var rtnval = await Api.get('/api/v1/admin/cntrct/mng/findAll/cntrctr/list');

      if(rtnval.result){
        this.cntrctrList = rtnval.data.resultList;
        window.$('#cntrct-modal').modal('show');
      }
    },

    async updCntrctr(){
      var rtnval = await Api.post('/api/v1/process/set', {prcId: this.prcId, prtnrId: this.cntrctrId, startBid: false});

      if(rtnval.result){
        await this.getPrjList();
        await this.hideModal();
      }else{
        alert(rtnval.errormsg);
      }
    },

    async hideModal() {
      window.$('#cntrct-modal').modal('hide');
    },

    radioChange(event){
      this.cntrctrId = event.target.value;
    },

    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },
  },

}
</script>

<style scoped>

</style>