<template>
    <div class="d-flex">
        <LNB :user="user"/>
        <div class="ca_main">
            <div class="x_div_center">
                <div class="pg_stage">

                    <div class="x_div_center pt-5">
						<div class="" style="width:75%"><span class="fkr-set1 fs-16" style="color:#333333">{{prjNm}}</span></div>
						<div class="text-end" style="width:25%"><span class="fkr-set2" style="color:#484848">설계 > <span class="fw-bold">{{prcStpNm}}</span></span></div>
					</div>

                    <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                    <div style="height:80px"></div>

                    <div>
                        <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                            <table class="table-borderless w-100">
                            <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                                <td class="text-center br_0" style="width:20%; background-color:#eaeaea"><span class="fkr-set1 fs-09">예상일정</span></td>
                                <td class="text-center" style="width:80%">
                                    <span class="fkr-set1 fc-w10" v-if="(user.info.usrRole == 'OW' || user.info.usrRole == 'SL') || (user.info.usrRole == 'DS' && estmtStrtDt)">{{(estmtStrtDt? estmtStrtDt.split('T')[0] : '') + ' ~ ' + (estmtEndDt? estmtEndDt.split('T')[0] : '')}}</span>
                                    <div class="x_div_center" v-else>
                                        <div><div class="x_div_center">
                                            <Datepicker :endDate="estmtEndDtInput" @update-date="setStrtDt" class="form-control fkr-set2 fc-w5 text-center" style="width:95px; height:36px; border-radius:4px; border:1px solid #c9c9c9" placeholder="시작일자"/>
                                            <span>&nbsp;~&nbsp;</span>
                                            <Datepicker :startDate="estmtStrtDtInput" @update-date="setEndDt" class="form-control fkr-set2 fc-w5 text-center" style="width:95px; height:36px; border-radius:4px; border:1px solid #c9c9c9" placeholder="종료일자"/>
                                        </div></div>
                                        <div class="ps-2"><button class="fkr-set2 fs-095" @click="setEstmDate()" style="width:50px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장</button></div>
                                    </div>
                                </td>
                            </tr>
                            <tr style="height:55px">
                                <td class="text-center br_0" style="background-color:#eaeaea"><span class="fkr-set1 fs-09">상태</span></td>
                                <td class="text-center" v-if="(user.info.usrRole == 'OW' || user.info.usrRole == 'SL')"><span class="fkr-set1 fc-w10">{{resolveStatusName(status)}}</span></td>
                                <td class="text-center" v-else>
                                    <div class="x_div_center">
                                        <div>
                                            <div class="x_div_center">
                                                <select class="custom-select ff-eng1 fs-085" v-model="statusInput" style="width:160px; height:37px">
                                                    <option value="0">착수</option>
                                                    <option value="1">진행</option>
                                                    <option value="2">완료</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="ps-2"><button class="fkr-set2 fs-095" @click="setStatus()" style="width:100px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">상태 변경</button></div>
                                    </div>
                                </td>
                            </tr>
                            </table>
                        </div>
                    </div>

                    <div style="height:20px"></div>

                    <div>
                        <div class="pg_m_set" style="margin-top: 20px" v-if="fetching">
                            <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                        </div>
                        <div class="table-responsive" style=" border-radius:8px" v-else>
                            <table class="table-borderless w-100" style="table-layout: fixed;">
                            <tr style="height:55px; background-color:#eaeaea; border-bottom:1px solid #c9c9c9">
                                <td class="text-center br_0" width="10%"></td>
                                <td class="text-center br_0" width="30%" v-for="(type, i) in dsgnFilesTypeList" :key = i><span class="fkr-set1 fs-09">{{ type.name }}</span></td>
                            </tr>
                            <tr style="height:55px; border-bottom:1px solid #c9c9c9;" v-for="(item, index) in fileList" :key="index">
                                <td class="text-center br_0" width="10%"><span class="fkr-set1 fs-09">{{item.ctgryNm}}</span></td>
                                <td :class="'text-center' + (i == 2 ? '' : ' br_0')" v-for="(file, i) in item.subList" :key="i" width="30%">
                                    <div class="x_div_center" v-if="(user.info.usrRole == 'OW' || user.info.usrRole == 'SL') || (user.info.usrRole == 'DS' && file.name)" style="width:80%; left: 10%; position: relative;">
                                        <div class="x_div_center" style="width:80%"><span class="fkr-set2" style="width:80%; display: block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"><a :href="file.fileLink" :download="file.name">{{file.name}}</a></span></div>
                                        <div class="x_div_center px-2" v-if="user.info.usrRole == 'DS'"><a href="javascript:void(0)" @click="removeFile(index, i, file.id)" class="rm_frm"><i class="fa-solid fa-circle-minus fs-10" style="color:#C00"></i></a></div>
                                    </div>
                                    <div class="x_div_center" v-if="(user.info.usrRole == 'DS' && status != 0 && !file.name)">
                                        <input type="file" :id="'file_' + index + '_' + i" @change="fileHandling($event, index, i)" class="form-control fkr-set2 fc-w5 text-center" style="width:70%; height:36px; border-radius:4px; background-color:#f6f2f2; border:1px solid #4e0000" placeholder="">
                                    </div>
                                </td>
                            </tr>
                            </table>
                        </div>
                    </div>

                    <div class="x_div_end" v-if="user.info.usrRole == 'DS'">
                        <div class="py-2 pe-1"><button class="fkr-set2 fs-095" @click="saveFiles()" style="width:140px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">파일 일괄 저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                    </div>

                    <div style="height:50px"></div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LNB from '@/components/LNB.vue'
import Datepicker from '@/components/Datepicker.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'PrcStpDsgn',
    components: {
		LNB,
        Datepicker
	},
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            prcTree: null,
            prjId: null,
            prjNm: '',
            prcId: null,
            prcStpId: null,
            prcStpNm: '',
            status: 0,
            statusInput: 0,
            estmtStrtDt: '',
            estmtEndDt: '',
            estmtStrtDtInput: '',
            estmtEndDtInput: '',
            dsgnFilesCtgryList : [],
			dsgnFilesCtgryIndexMap : [],
			dsgnFilesTypeList : [],
			dsgnFilesTypeIndexMap : [],
            fileList: [],
            renderedList: [],
            deleteFlIdList: [],
            fetching: true,
            submitting: false
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           if(this.$route.query.prjId){
               this.$router.push({ path: '/member/login', query: { fromTalk: encodeURIComponent("/project" + location.href.split("/project")[1]) } });
           }
           else {
               location.href = '/member/login'
           }
        }

        if(this.user.info.usrRole != 'OW' && this.user.info.usrRole != 'SL' && this.user.info.usrRole != 'DS') {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }

        if(this.$route.query.prjId){
            var rtnval = await Api.get('/api/v1/process/list/prc/tree?prjId=' + this.$route.query.prjId);
            if(rtnval.result) {
                sessionStorage.setItem('prcTree', JSON.stringify(rtnval.data));
                sessionStorage.setItem('prjId', this.$route.query.prjId);
                sessionStorage.setItem('prjNm', rtnval.data.prjNm);
                var rtnvalue = await Api.get('/api/v1/user/list/authorized/prc?usrRole=' + this.user.info.usrRole);
                if(rtnvalue.result) {
                    var accessiblePrc = [];
                    rtnvalue.data.processes.forEach((prc) => {
                        accessiblePrc.push(prc.code);
                    })
                    sessionStorage.setItem('accessiblePrc', JSON.stringify(accessiblePrc));
                    location.href = location.href.split("?")[0];
                }
                else {
                    alert(rtnvalue.errormsg)
                }
            }
            else {
                alert(rtnval.errormsg)
            }
        }
        else {
            this.prjId = sessionStorage.getItem('prjId');
            this.prjNm = sessionStorage.getItem('prjNm');
            this.prcTree = JSON.parse(sessionStorage.getItem('prcTree'));
        }

        this.prcTree.processList.forEach((prc) => {
            if(prc.code == this.$route.params.prcCd) {
                prc.prcStpList.forEach((prcStp) => {
                    if(prcStp.code == this.$route.params.prcStpCd) {
                        this.prcStpNm = prcStp.name;
                    }
                })
            }
        })

        if(this.prjId == 'null') {
            alert('My Projects 페이지에서 프로젝트를 선택하셔야 합니다.');
            location.href = '/project/dashboard'
        }

        if(this.$route.params.prcStpCd == 'DA') {
            location.href = '/project/DS/DA/dsgn-approval';
        }

        var rtnval = await Api.post('/api/v1/process/get', {prjId: this.prjId, prcCd: this.$route.params.prcCd});
        if(rtnval.result) {
            this.prcId = rtnval.data.prcId;
        }
        else {
            alert(rtnval.errormsg)
        }

        rtnval = await Api.post('/api/v1/common/get/code/family', {codeCtgry: 'PRSTFC', ascByOrderNum: true});
		if(rtnval.result) {
			var index = 0;
			rtnval.data.forEach((code) => {
				if(!code.upperCode) {
                    if(code.code != 'CS' && this.$route.params.prcStpCd == 'DP') {
                        return;
                    }
					this.dsgnFilesCtgryList.push(code);
					this.dsgnFilesCtgryIndexMap[code.orderNum] = index++;
				}
			})
		}
		else {
			alert(rtnval.errormsg);
		}

		rtnval = await Api.post('/api/v1/common/get/code/family', {codeCtgry: 'PRSTFT', ascByOrderNum: true});
		if(rtnval.result) {
			rtnval.data.forEach((code, index) => {
                if(code.code == 'OR' && ['DP', 'DM'].indexOf(this.$route.params.prcStpCd) >= 0) {
                    return;
                }
				this.dsgnFilesTypeList.push(code);
				this.dsgnFilesTypeIndexMap[code.orderNum] = index;
			})
		}
		else {
			alert(rtnval.errormsg);
		}

        await this.fetchDsgnDtlList();
        this.fetching = false;
    },
    methods: {
        resolveStatusName(status) {
            var rtnval = '';
            switch(status) {
                case 0 : rtnval = '착수'
                    break;
                case 1 : rtnval = '진행'
                    break;
                case 2 : rtnval = '완료'
                    break;
            }
            return rtnval;
        },
        initDsgnFileList() {
			this.fileList = [];
			this.dsgnFilesCtgryList.forEach((code) => {
				var subList = [];
				this.dsgnFilesTypeList.forEach(() => {
					subList.push({id: null, name: null, nameNew: null, file:null, fileLink: null});
				})
				this.fileList.push(
					{	
                        ctgryCd: code.code,
						ctgryNm: code.name,
						subList: subList
					}
				)
			})
		},
        async fetchDsgnDtlList() {
            this.initDsgnFileList();

            const rtnvalue = await Api.post('/api/v1/prcess/step/get', {prjId: this.prjId, code: this.$route.params.prcStpCd});
            if(rtnvalue.result) {
                if(rtnvalue.data.prcStpId) {
                    this.prcStpId = rtnvalue.data.prcStpId;
                    this.estmtStrtDt = rtnvalue.data.strtDt;
                    this.estmtEndDt = rtnvalue.data.endDt;
                    this.status = rtnvalue.data.stts;
                    this.statusInput = rtnvalue.data.stts;
    
                    const returnvalue = await Api.post('/api/v1/common/get/attachments', {
                        parentId: rtnvalue.data.prcStpId,
                        parentUnit: 'PRCSTP',
                        ascByCrtDt: true,
                        limit: -1
                    });
                    if(returnvalue.result) {
                        if(returnvalue.data.attchments) {
                            returnvalue.data.attchments.forEach((item) => {
                                var tokens = item.attchOrgNm.split('.');
                                var mimeType = tokens[tokens.length-1].toLowerCase();
                                var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;
        
                                const byteCharacters = atob(item.attchImg);
                                const byteNumbers = new Array(byteCharacters.length);
                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);
                                const blob = new Blob([byteArray], {type: fileType});
                                const fileLink = URL.createObjectURL(blob);
                                this.fileList[this.dsgnFilesCtgryIndexMap[item.attchFlCtgry.orderNum]].subList[this.dsgnFilesTypeIndexMap[item.attchFlTyp.orderNum]] = {id: item.id, name: item.attchOrgNm, nameNew: null, file:null, fileLink: fileLink};
                            })
                        }
                        this.$forceUpdate();
                    }
                    else {
                        alert(returnvalue.errormsg);
                    }
                }
            }
            else {
                alert(rtnvalue.errormsg);
            }
        },
        setStrtDt(date) {
            this.estmtStrtDtInput = new Date(date).toISOString();
        },
        setEndDt(date) {
            this.estmtEndDtInput = new Date(date).toISOString();
        },
        async setEstmDate() {
            if(!this.estmtStrtDtInput || !this.estmtEndDtInput) {
                alert('시작예정일과 종료예정일을 정확히 입력하세요.');
                return 1;
            }

            const rtnval = await Api.post('/api/v1/prcess/step/set', {prcStpId: this.prcStpId, prcId: this.prcId, code: this.$route.params.prcStpCd, strtDt: this.estmtStrtDtInput, endDt: this.estmtEndDtInput});
            if(rtnval.result) {
                this.fetchDsgnDtlList();
            }
            else {
                alert(rtnval.errormsg);
            }
        },
        async setStatus() {
            if(this.statusInput - this.status > 1) {
                alert('진행 상태 설정이후 완료 상태로 변경할 수 있습니다.');
                return 1;
            }

            if(this.statusInput - this.status < 0) {
                alert('이전 상태로 되돌릴 수는 없습니다.');
                return 1;
            }

            if(this.statusInput - this.status == 0) {
                return 1;
            }

            const rtnval = await Api.post('/api/v1/prcess/step/set', {prcStpId: this.prcStpId, prcId: this.prcId, code: this.$route.params.prcStpCd, stts: this.statusInput});
            if(rtnval.result) {
                alert('상태 변경이 정상적으로 완료되었습니다.');
                this.status = this.statusInput;
            }
            else {
                console(rtnval.errormsg)
            }
        },
        fileHandling(e, row, col) {
            var tokens = e.target.files[0].name.split('.');
            var fileType = tokens[tokens.length-1].toLowerCase();
            var allowed = ['jpg', 'jpeg', 'png', 'pdf', 'zip']
            if(allowed.indexOf(fileType) < 0){
                alert('이미지파일 또는 pdf형식 외의 파일은\nzip 형태로 압축하여 업로드 해 주시기 바랍니다.');
                window.$('#file_' + row + '_' + col).val('')
                return 1;
            }

            this.fileList[row].subList[col].file = e.target.files[0];
            this.fileList[row].subList[col].nameNew = e.target.files[0].name;
        },
        removeFile(row, col, fildId) {
            if(this.deleteFlIdList.indexOf(fildId) < 0) {
                this.deleteFlIdList.push(fildId);
            }
            this.fileList[row].subList[col].name = null;

            this.$forceUpdate();
        },
        async saveFiles() {
            const formData = new FormData();
            var attchFlCtgry = [];
            var attchFlTyp = [];
            var attchOrgNmList = [];
            var prcStpId = [];
            this.fileList.forEach((row) => {
                row.subList.forEach((col, index) => {
                    if(col.nameNew) {
                        formData.append("attchmntList", col.file);
                        attchFlCtgry.push(row.ctgryCd);
                        attchFlTyp.push(this.dsgnFilesTypeList[index].code);
                        attchOrgNmList.push(col.nameNew);
                        prcStpId.push(this.prcStpId);
                    }
                })
            })
            if(attchOrgNmList.length > 0 || this.deleteFlIdList.length > 0) {
                var request = {
                    attchFlCtgry: attchFlCtgry,
                    attchFlTyp: attchFlTyp,
                    attchOrgNmList: attchOrgNmList,
                    deleteFlIdList: this.deleteFlIdList,
                    parentId: prcStpId
                }
                formData.append(
                    "request",
                    new Blob([JSON.stringify(request)], { type: "application/json" })
                );
                this.submitting = true;
                const rtnvalue = await Api.post('/api/v1/common/set/attachments', formData)
                if(rtnvalue.result) {
                    await this.fetchDsgnDtlList();
                }
                else {
                    alert(rtnvalue.errormsg)
                }
                this.submitting = false;
            }
            else {
                alert("파일을 하나 이상 첨부해주세요.");
            }
        }
    },
}
</script>