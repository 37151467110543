<template>
  <div>
    <div style="height:56px"></div>

    <div class="x_div_center">

      <div class="ca_main">
        <div class="x_div_center">
          <div class="pg_stage">

            <div class="d-flex pt-5"><span class="ff-kor1"
                style="font-weight:700; font-size:22pt; color:#333333">기획하기</span></div>

            <div style="height:5px; background-color:lightgray; border-radius:10rem"></div>

            <div style="height:14px"></div>

            <div class="x_div_center pt-5">
              <button v-if="planImg.length == 0 && isSimuleOpened" 
                style="align:right; width:140px; height:40px; border:1px solid #000; background-color:lightgray; border-radius:4px; color:#000;">
                <a style="color:#000" href="javascript:void(0)"
                  @click="showHelpModal();"><span class="fkr-set1 fs-095">시뮬레이션 조작법</span></a></button>
            </div>
            <div class="x_div_center pt-5" style="padding-top: 10px !important">
              <div class="x_div_center px-2">
                <div :class="'x_div_center pg_num_box' + (currentStep == 0 ? '_on ' : '')"><span
                    class="fs-eng fs-13 fc-w1">1</span></div>
              </div>
              <div class="x_div_center px-2">
                <div :class="'x_div_center pg_num_box' + (currentStep == 1 ? '_on ' : '')"><span
                    class="fs-eng fs-13 fc-w1">2</span></div>
              </div>
              <div class="x_div_center px-2">
                <div :class="'x_div_center pg_num_box' + (currentStep == 2 || currentStep == 9 || currentStep == 10 ? '_on ' : '')"><span
                    class="fs-eng fs-13 fc-w1">3</span></div>
              </div>
              <div class="x_div_center px-2" v-if="!plan.q1_ans || plan.q1_ans <= 2">
                <div :class="'x_div_center pg_num_box' + (currentStep == 3 || (currentStep == 5 && plan.q1_ans == 2)? '_on ' : '')"><span
                    class="fs-eng fs-13 fc-w1">4</span></div>
              </div>
              <div class="x_div_center px-2" v-if="!plan.q1_ans || plan.q1_ans == 1">
                <div :class="'x_div_center pg_num_box' + (currentStep == 4 ? '_on ' : '')"><span
                    class="fs-eng fs-13 fc-w1">5</span></div>
              </div>
              <div class="x_div_center px-2" v-if="!plan.q1_ans || plan.q1_ans == 1">
                <div :class="'x_div_center pg_num_box' + (currentStep == 5 ? '_on ' : '')"><span
                    class="fs-eng fs-13 fc-w1">6</span></div>
              </div>
            </div>

            <!-------  Form  -------------------------------------->
            <form @change="onChangeVal" style="position:relative">

              <!---------- 0. 건물 위치 -------------------------------------------->
              <div style="min-height:auto" v-show="currentStep == 0">
                <div class="x_div_center pt-4">
                  <div class="x_div_center"><span class="fkr-set1 fs-15">계획중이신 건물의 위치를 <br
                        v-if="isMobile" />지정해주세요.</span></div>
                </div>
                <div style="height:50px"></div>
                <span class="fkr-set2" v-if="isMobile">메뉴확장버튼( <i class="fa-sharp fa-solid fa-bars" /> )을 눌러서 사이드바 메뉴를 열고
                  닫을 수 있습니다.<br /><br /></span>
                <div class="custom-control custom-checkbox x_div_center w-100">
                  <input type="checkbox" class="custom-control-input" id="ckL" v-model="noLand">
                  <label class="custom-control-label" for="ckL" :style="'padding-top:' + (isMobile ? '1px' : '')"><span
                      :class="'fkr-set2 fw-bold fc-w5 ' + (isMobile ? '' : 'fs-11')" style="color:#4e0000">※ 현재 보유한 토지가 없을
                      경우 체크해주세요.</span></label>
                </div>
                <!-- <div class="mib_box_a py-3">
                  <div class="mib_a1 text-end"><span class="fkr-set1">주소</span></div>
                  <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                  <div class="mib_a3">

                    <div class="d-flex">
                      <div class="py-1"><input type="text" class="form-control ff-eng1" v-model="postcode" style="width:100px; height:36px" placeholder="" readonly></div>
                      <div class="py-1 ps-1"><button class="fkr-set1 fc-w1" @click="searchAddr()" style="width:75px; height:36px; background-color:#4f5962; border:0">주소검색</button></div>
                    </div>

                    <div class="">
                      <div class="py-1"><input type="text" class="form-control fkr-set2" v-model="addr" style="width:100%; height:36px" placeholder="기본주소" readonly></div>
                    </div>
                  </div>
                </div> -->
              </div>

              <!---------- 1. 건물 용도 -------------------------------------------->
              <div v-show="currentStep == 1">

                <div class="x_div_center pt-4">
                  <div class="x_div_center"><span class="fkr-set1 fs-15">계획중이신 건물의 용도를 <br
                        v-if="isMobile" />선택해주세요.</span></div>
                </div>

                <div style="height:50px"></div>

                <div class="sw-1">
                  <div class="sw-2">
                    <div class="sw-4">
                      <input type="radio" name="plan_q1" id="plan_q1_val1" v-model="plan.q1_ans" value=1>
                      <label for="plan_q1_val1" class="scale_none">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/plan_img1.png" width="85%" class=""></div>
                        <div class="text-center py-3"><span class="ff-kor1 fs-10 fw-bold" style="color:#000">주거용
                            건물</span></div>
                      </label>
                    </div>
                    <div class="sw-4">
                      <input type="radio" name="plan_q1" id="plan_q1_val3" v-model="plan.q1_ans" value=3>
                      <label for="plan_q1_val3" class="scale_none">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/plan_img2.png" width="85%" class=""></div>
                        <div class="text-center py-3"><span class="ff-kor1 fs-10 fw-bold" style="color:#000">상업용
                            건물</span></div>
                      </label>
                    </div>
                  </div>

                  <div class="sw-2">
                    <div class="sw-4">
                      <input type="radio" name="plan_q1" id="plan_q1_val2" v-model="plan.q1_ans" value=2>
                      <label for="plan_q1_val2" class="scale_none">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/plan_img3.png" width="85%" class=""></div>
                        <div class="text-center py-3"><span class="ff-kor1 fs-10 fw-bold" style="color:#000">사무용
                            건물</span></div>
                      </label>
                    </div>
                    <div class="sw-4">
                      <input type="radio" name="plan_q1" id="plan_q1_val4" v-model="plan.q1_ans" value=4 disabled>
                      <!-- <label for="plan_q1_val4" class="scale_none"> -->
                      <label for="plan_q1_val4" style="opacity: 0.5">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/plan_img4.png" width="85%" class=""></div>
                        <div class="text-center py-3"><span class="ff-kor1 fs-10 fw-bold" style="color:#000">그 외</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <!---------- 2. 전용면적 & 건축형태 -------------------------------------------->
              <div v-show="currentStep == 2">

                <div class="x_div_center pt-4">
                  <div class="x_div_center"><span class="fkr-set1 fs-15">전용 면적 및 주택 스타일을 <br
                        v-if="isMobile" />선택해주세요.</span></div>
                </div>

                <div style="height:50px"></div>

                <div class="w-100 x_div_center py-3">
                  <div style="border-bottom:2px solid #c9c9c9">
                    <h3 class="fkr-set1 fs-12">희망하는 면적</h3>
                  </div>
                </div>

                <div style="height:50px"></div>

                <vue-slider v-model="plan.q2_ans" :min="0" :max="165" :adsorb="true" :interval="33 / 2"
                  :marks="isMobile ? marks_m : marks" :included="true" :tooltip="isMobile ? 'always' : 'none'"
                  :tooltip-formatter="formatter"></vue-slider>

                <div style="height:100px"></div>

                <div class="w-100 x_div_center py-3">
                  <div style="border-bottom:2px solid #c9c9c9">
                    <h3 class="fkr-set1 fs-12">희망하는 주택 스타일</h3>
                  </div>
                </div>

                <div class="x_div_center">
                  <div class="form-group x_div_center">
                    <div class="custom-control custom-checkbox x_div_center w-100">
                      <!-- <input type="checkbox" class="custom-control-input" id="ck0" v-model="q4_nopreference">
                      <label class="custom-control-label" for="ck0" style="padding-top:1px"><span class="fkr-set2 fw-bold fc-w5">선호사항 없음</span></label> -->
                    </div>
                  </div>
                </div>

                <div style="height:50px"></div>

                <div class="sw-1">
                  <div class="sw-2">
                    <div class="sw-4">
                      <input type="radio" name="plan_q4" id="plan_q4_val2" v-model="plan.q4_ans" value=1>
                      <label for="plan_q4_val2" class="scale" style="width:94%">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl2_img1.jpg"
                            width="100%" style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                        <div class="text-center py-3" style="white-space:nowrap"><span class="fkr-set1">단아하고 모던한
                            스타일</span><br><span class="fkr-set1 fs-10" style="color:#000">단층 (평지붕)</span></div>
                      </label>
                    </div>
                    <div class="sw-4">
                      <input type="radio" name="plan_q4" id="plan_q4_val1" v-model="plan.q4_ans" value=2>
                      <label for="plan_q4_val1" class="scale" style="width:94%">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl2_img2.jpg" width="100%"
                            style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                        <div class="text-center py-3" style="white-space:nowrap"><span class="fkr-set1">전원주택
                            스타일</span><br><span class="fkr-set1 fs-10" style="color:#000">단층 (박공지붕/다락)</span></div>
                      </label>
                    </div>
                  </div>
                  <div class="sw-b"></div>
                  <div class="sw-2">
                    <div class="sw-4">
                      <input type="radio" name="plan_q4" id="plan_q4_val3" v-model="plan.q4_ans" value=3>
                      <label for="plan_q4_val3" class="scale" style="width:94%">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl2_img3.jpg" width="100%"
                            style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                        <div class="text-center py-3" style="white-space:nowrap"><span class="fkr-set1">실용성을 높인 모던한
                            스타일</span><br><span class="fkr-set1 fs-10" style="color:#000">복층 (평지붕)</span></div>
                      </label>
                    </div>
                    <div class="sw-4">
                      <input type="radio" name="plan_q4" id="plan_q4_val4" v-model="plan.q4_ans" value=4>
                      <label for="plan_q4_val4" class="scale" style="width:94%">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl2_img4.jpg" width="100%"
                            style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                        <div class="text-center py-3" style="white-space:nowrap"><span class="fkr-set1">세미 모던
                            스타일</span><br><span class="fkr-set1 fs-10" style="color:#000">복층 (박공지붕)</span></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <!---------- 3. 실 요구사항 & 우선순위 -------------------------------------------->
              <div v-show="currentStep == 3">
                <div class="x_div_center w-100 pt-4">
                  <div class="x_div_center"><span class="fkr-set1 fs-14">필요한 공간 및 개수를 선택해주세요.</span></div>
                </div>

                <div style="height:30px"></div>

                <div class="w-100 x_div_center py-3">
                  <div style="border-bottom:2px solid #c9c9c9">
                    <h3 class="fkr-set1 fs-12">희망하는 방 개수</h3>
                  </div>
                </div>

                <div class="x_div_center">
                  <div class="form-group x_div_center">
                    <div class="custom-control custom-checkbox x_div_center w-100">
                      <input type="checkbox" class="custom-control-input" id="ck1" :disabled="plan.q3_1_ans == 0"
                        v-model="plan.q3_1_isnecessary">
                      <label class="custom-control-label" for="ck1" style="padding-top:1px"><span
                          class="fkr-set2 fw-bold fc-w5">필수조건으로 지정</span></label>
                    </div>
                  </div>
                </div>

                <div style="height:50px"></div>

                <!-- 방 개수 -->
                <div class="sw-1">
                  <div class="sw-2">
                    <div class="sw-4">
                      <input type="radio" name="plan_q3_1" id="plan_q3_1_val1" v-model="plan.q3_1_ans" value=1>
                      <label for="plan_q3_1_val1" class="x_div_center scale" style="width:94%; height:120px">
                        <span class="ff-kor1 fs-12 fw-bold">방 1개</span>
                      </label>
                    </div>
                    <div class="sw-4">
                      <input type="radio" name="plan_q3_1" id="plan_q3_1_val2" v-model="plan.q3_1_ans" value=2>
                      <label for="plan_q3_1_val2" class="x_div_center scale" style="width:94%; height:120px">
                        <span class="ff-kor1 fs-12 fw-bold">방 2개</span>
                      </label>
                    </div>
                  </div>
                  <div class="sw-b"></div>
                  <div class="sw-2">
                    <div class="sw-4">
                      <input type="radio" name="plan_q3_1" id="plan_q3_1_val3" v-model="plan.q3_1_ans" value=3>
                      <label for="plan_q3_1_val3" class="x_div_center scale" style="width:94%; height:120px">
                        <span class="ff-kor1 fs-12 fw-bold">방 3개</span>
                      </label>
                    </div>
                    <div class="sw-4">
                      <input type="radio" name="plan_q3_1" id="plan_q3_1_val4" v-model="plan.q3_1_ans" value=4>
                      <label for="plan_q3_1_val4" class="x_div_center scale" style="width:94%; height:120px">
                        <span class="ff-kor1 fs-12 fw-bold">방 4개</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div style="height:50px"></div>

                <div class="w-100 x_div_center py-3">
                  <div style="border-bottom:2px solid #c9c9c9">
                    <h3 class="fkr-set1 fs-12">희망하는 화장실 개수</h3>
                  </div>
                </div>

                <div class="x_div_center">
                  <div class="form-group px-1">
                    <div class="custom-control custom-checkbox x_div_center w-100">
                      <input type="checkbox" class="custom-control-input" id="ck2" :disabled="plan.q3_2_ans == 0"
                        v-model="plan.q3_2_isnecessary">
                      <label class="custom-control-label" for="ck2" style="padding-top:1px"><span
                          class="fkr-set2 fw-bold fc-w5">필수조건으로 지정</span></label>
                    </div>
                  </div>
                  <div class="form-group px-1">
                    <div class="custom-control custom-checkbox x_div_center w-100">
                      <!-- <input type="checkbox" class="custom-control-input" id="ck3" v-model="q3_2_nopreference">
                      <label class="custom-control-label" for="ck3" style="padding-top:1px"><span class="fkr-set2 fw-bold fc-w5">선호사항 없음</span></label> -->
                    </div>
                  </div>
                </div>

                <div style="height:50px"></div>

                <!-- 화장실 개수 -->
                <div class="sw-1">
                  <div class="sw-2">
                    <div class="sw-4">
                      <input type="radio" name="plan_q3_2" id="plan_q3_2_val1" v-model="plan.q3_2_ans" value=1>
                      <label for="plan_q3_2_val1" class="scale" style="width:94%">
                        <div class="x_div_center py-2"><img src="@/assets/img_legacy/pl3_img1.png" height="100px"></div>
                        <div class="x_div_center py-3" style="white-space:nowrap"><span class="fkr-set1 fs-085">화장실
                            1개</span></div>
                      </label>
                    </div>
                    <div class="sw-4">
                      <input type="radio" name="plan_q3_2" id="plan_q3_2_val2" v-model="plan.q3_2_ans" value=2>
                      <label for="plan_q3_2_val2" class="scale" style="width:94%">
                        <div class="x_div_center py-2"><img src="@/assets/img_legacy/pl3_img2.png" height="100px"></div>
                        <div class="x_div_center py-3" style="white-space:nowrap"><span class="fkr-set1 fs-085">화장실
                            1.5개</span></div>
                      </label>
                    </div>
                  </div>
                  <div class="sw-b"></div>
                  <div class="sw-2">
                    <div class="sw-4">
                      <input type="radio" name="plan_q3_2" id="plan_q3_2_val3" v-model="plan.q3_2_ans" value=3>
                      <label for="plan_q3_2_val3" class="scale" style="width:94%">
                        <div class="x_div_center py-2"><img src="@/assets/img_legacy/pl3_img3.png" height="100px"></div>
                        <div class="x_div_center py-3" style="white-space:nowrap"><span class="fkr-set1 fs-085">화장실
                            2개</span></div>
                      </label>
                    </div>
                    <div class="sw-4">
                      <input type="radio" name="plan_q3_2" id="plan_q3_2_val4" v-model="plan.q3_2_ans" value=4>
                      <label for="plan_q3_2_val4" class="scale" style="width:94%">
                        <div class="x_div_center py-2"><img src="@/assets/img_legacy/pl3_img4.png" height="100px"></div>
                        <div class="x_div_center py-3" style="white-space:nowrap"><span class="fkr-set1 fs-085">화장실
                            2.5개</span></div>
                      </label>
                    </div>
                  </div>
                </div>

                <div style="height:50px"></div>

                <div class="w-100 x_div_center py-3">
                  <div style="border-bottom:2px solid #c9c9c9">
                    <h3 class="fkr-set1 fs-12">안방 부속실 (다중 선택 가능)</h3>
                  </div>
                </div>

                <div class="x_div_center">
                  <div class="form-group px-1">
                    <div class="custom-control custom-checkbox x_div_center w-100">
                      <input type="checkbox" class="custom-control-input" name="plan_q3_3_all" id="plan_q3_3_all"
                        v-model="q3_3_all" @change="onChangeQ3_3_all">
                      <label class="custom-control-label" for="plan_q3_3_all" style="padding-top:1px"><span
                          class="fkr-set2 fw-bold fc-w5">모두선택</span></label>
                    </div>
                  </div>
                  <div class="form-group px-1">
                    <div class="custom-control custom-checkbox x_div_center w-100">
                      <!-- <input type="checkbox" class="custom-control-input" id="ck5" v-model="q3_3_nopreference">
                      <label class="custom-control-label" for="ck5" style="padding-top:1px"><span class="fkr-set1 fc-w5">선호사항 없음</span></label> -->
                    </div>
                  </div>
                </div>

                <div style="height:50px"></div>

                <!-- 안방 부속실 -->
                <div class="sw-1">
                  <div class="sw-5a">
                    <div class="sw-5aa">
                      <input type="checkbox" name="plan_q3_3" id="plan_q3_3_val1" v-model="plan.q3_3_ans" value=1
                        @change="onChangeQ3_3_1">
                      <label for="plan_q3_3_val1" class="scale" style="width:94%">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl3_img5.jpg" width="100%"
                            style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                        <div class="x_div_center py-3"><span class="fkr-set1 fs-10">파우더룸</span></div>
                      </label>
                    </div>
                    <div class="sw-5aa">
                      <input type="checkbox" name="plan_q3_3" id="plan_q3_3_val2" v-model="plan.q3_3_ans" value=2
                        @change="onChangeQ3_3_1">
                      <label for="plan_q3_3_val2" class="scale" style="width:94%">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl3_img6.jpg" width="100%"
                            style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                        <div class="x_div_center py-3"><span class="fkr-set1 fs-10">드레스룸</span></div>
                      </label>
                    </div>
                  </div>
                  <div class="sw-b"></div>
                  <div class="sw-5b">
                    <div class="sw-5bb">
                      <input type="checkbox" name="plan_q3_3" id="plan_q3_3_val3" v-model="plan.q3_3_ans" value=3
                        @change="onChangeQ3_3_1">
                      <label for="plan_q3_3_val3" class="scale" style="width:94%">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl3_img7.jpg" width="100%"
                            style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                        <div class="x_div_center py-3"><span class="fkr-set1 fs-10">욕실 / 화장실</span></div>
                      </label>
                    </div>
                    <div class="sw-5bb">
                      <input type="checkbox" name="plan_q3_3" id="plan_q3_3_val4" v-model="plan.q3_3_ans" value=4
                        @change="onChangeQ3_3_1">
                      <label for="plan_q3_3_val4" class="scale" style="width:94%">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl3_img8.jpg" width="100%"
                            style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                        <div class="x_div_center py-3"><span class="fkr-set1 fs-10">개별발코니</span></div>
                      </label>
                    </div>
                  </div>
                  <div class="sw-b"></div>
                  <div class="sw-5c">
                    <div class="sw-5cc">
                      <input type="checkbox" name="plan_q3_3" id="plan_q3_3_val5" v-model="plan.q3_3_ans" value=5
                        @change="onChangeQ3_3_2">
                      <label for="plan_q3_3_val5" class="scale" style="width:94%">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl3_none.jpg" width="100%"
                            style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                        <div class="x_div_center py-3"><span class="fkr-set1 fs-10">필요 없음</span></div>
                      </label>
                    </div>
                  </div>
                </div>

                <div style="height:50px"></div>

                <div class="w-100 x_div_center py-3">
                  <div style="border-bottom:2px solid #c9c9c9">
                    <h3 class="fkr-set1 fs-12">기타 요구사항 (다중 선택 가능)</h3>
                  </div>
                </div>

                <div class="x_div_center">
                  <div class="form-group px-1">
                    <div class="custom-control custom-checkbox x_div_center w-100">
                      <input type="checkbox" class="custom-control-input" name="plan_q5_all" id="plan_q5_all"
                        v-model="q5_all" @change="onChangeQ5_all">
                      <label class="custom-control-label" for="plan_q5_all" style="padding-top:1px"><span
                          class="fkr-set2 fw-bold fc-w5">모두선택</span></label>
                    </div>
                  </div>
                  <div class="form-group px-1">
                    <div class="custom-control custom-checkbox x_div_center w-100">
                      <!-- <input type="checkbox" class="custom-control-input" id="ck7" v-model="q5_nopreference">
                      <label class="custom-control-label" for="ck7" style="padding-top:1px"><span class="fkr-set2 fw-bold fc-w5">선호사항 없음</span></label> -->
                    </div>
                  </div>
                </div>

                <div style="height:50px"></div>

                <!-- 기타 요구사항 -->
                <div class="sw-1">
                  <div class="sw-2">
                    <div class="sw-4">
                      <input type="checkbox" name="plan_q5" id="plan_q5_val2" v-model="plan.q5_ans" value=2
                        @change="onChangeQ5">
                      <label for="plan_q5_val2" class="scale" style="width:94%">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl3_img10.jpg" width="100%"
                            style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                        <div class="text-center py-3" style="white-space:nowrap"><span class="fkr-set1">와이드
                            다이닝룸</span><br><span class="fkr-set1 fs-10" style="color:#000">넓은 식당 공간</span></div>
                      </label>
                    </div>
                    <div class="sw-4">
                      <input type="checkbox" name="plan_q5" id="plan_q5_val3" v-model="plan.q5_ans" value=3
                        @change="onChangeQ5">
                      <label for="plan_q5_val3" class="scale" style="width:94%">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl3_img11.jpg" width="100%"
                            style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                        <div class="text-center py-3" style="white-space:nowrap"><span class="fkr-set1">중정</span><br><span
                            class="fkr-set1 fs-10" style="color:#000">집 가운데 정원</span></div>
                      </label>
                    </div>
                  </div>
                  <div class="sw-b"></div>
                  <div class="sw-2">
                    <div class="sw-4">
                      <input type="checkbox" name="plan_q5" id="plan_q5_val4" v-model="plan.q5_ans" value=4
                        @change="onChangeQ5">
                      <label for="plan_q5_val4" class="scale" style="width:94%">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl3_img12.jpg" width="100%"
                            style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                        <div class="text-center py-3" style="white-space:nowrap"><span class="fkr-set1">거실과 주방
                            연결</span><br><span class="fkr-set1 fs-10" style="color:#000">카페 같은 거실</span></div>
                      </label>
                    </div>
                    <div class="sw-4">
                      <input type="checkbox" name="plan_q5" id="plan_q5_val5" v-model="plan.q5_ans" value=5
                        @change="onChangeQ5">
                      <label for="plan_q5_val5" class="scale" style="width:94%">
                        <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl3_img13.jpg" width="100%"
                            style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                        <div class="text-center py-3" style="white-space:nowrap"><span class="fkr-set1">공간 남향
                            배치</span><br><span class="fkr-set1 fs-10" style="color:#000">햇살 가득한 남향 공간</span></div>
                      </label>
                    </div>
                  </div>
                </div>

              </div>

              <!---------- 4. 우선순위 -------------------------------------------->
              <div v-show="currentStep == 4">

                <div class="x_div_center pt-4">
                  <div class="x_div_center"><span class="fkr-set1 fs-15">앞에서 답하신 질문들 중 중요하게 <br v-if="isMobile" />생각하시는
                      순서대로 선택해주세요.</span></div>
                </div>

                <div style="height:50px"></div>

                <div class="bs_tab">
                  <div class="bs_tab1" style="border:1px solid #c9c9c9; margin-bottom: 15px;">
                    <table class="table-borderless w-100" style="table-layout:fixed;">
                      <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#ffffff">
                        <td colspan="2" class="text-center" width="32%">1순위</td>
                      </tr>
                      <tr style="height:55px; background-color:#ffffff">
                        <td colspan="2" class="text-center" width="32%">
                          <select class="custom-select ff-eng1 fs-085" v-model="plan.priority_1"
                            style="width:200px; height:40px; border-bottom-width: 1px;">
                            <option value="q2">희망하는 면적</option>
                            <option value="q4">주택 스타일</option>
                            <option value="q3_1">희망하는 방 개수</option>
                            <option value="q3_2">화장실 개수</option>
                            <option value="q3_3">안방 부속실</option>
                            <option value="q5">기타 요구사항</option>
                          </select>
                        </td>
                      </tr>
                      <tr style="height:200px; border-bottom:1px solid #c9c9c9; background-color:#ffffff">
                        <td colspan="2" class="text-center" width="32%" style="padding:80px"
                          v-if="priorityImage[0] == 'q2'">
                          <vue-slider v-model="plan.q2_ans" :min="plan.q2_ans[0]" :max="plan.q2_ans[1]" :adsorb="true"
                            :interval="plan.q2_ans[1] - plan.q2_ans[0]"
                            :marks="{ [plan.q2_ans[0]]: plan.q2_ans[0] + '㎡', [plan.q2_ans[1]]: plan.q2_ans[1] + '㎡' }"
                            :included="true" :tooltip="'none'" :tooltip-formatter="formatter"></vue-slider>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[0] == 'q4'">
                          <div v-if="plan.q4_ans == 1" style="width:50%">
                            <div style="position:relative; left:50%">
                              <img src="@/assets/img_legacy/pl2_img1.jpg" width="100%"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">단층 (평지붕)</span>
                            </div>
                          </div>
                          <div v-if="plan.q4_ans == 2" style="width:50%">
                            <div style="position:relative; left:50%">
                              <img src="@/assets/img_legacy/pl2_img2.jpg" width="100%"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">단층 (박공지붕/다락)</span>
                            </div>
                          </div>
                          <div v-if="plan.q4_ans == 3" style="width:50%">
                            <div style="position:relative; left:50%">
                              <img src="@/assets/img_legacy/pl2_img3.jpg" width="100%"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">복층 (평지붕)</span>
                            </div>
                          </div>
                          <div v-if="plan.q4_ans == 4" style="width:50%">
                            <div style="position:relative; left:50%">
                              <img src="@/assets/img_legacy/pl2_img4.jpg" width="100%"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">복층 (박공지붕)</span>
                            </div>
                          </div>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[0] == 'q3_1'">
                          <div v-if="plan.q3_1_ans == 1">
                            <div>
                              <img src="@/assets/img_legacy/q3_img1.png" height="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">방 1개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_1_ans == 2">
                            <div>
                              <img src="@/assets/img_legacy/q3_img2.png" height="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">방 2개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_1_ans == 3">
                            <div>
                              <img src="@/assets/img_legacy/q3_img3.png" height="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">방 3개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_1_ans == 4">
                            <div>
                              <img src="@/assets/img_legacy/q3_img4.png" height="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">방 4개</span>
                            </div>
                          </div>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[0] == 'q3_2'">
                          <div v-if="plan.q3_2_ans == 1">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img1.png" height="100px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">화장실 1개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_2_ans == 2">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img2.png" height="100px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">화장실 1.5개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_2_ans == 3">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img3.png" height="100px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">화장실 2개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_2_ans == 4">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img4.png" height="100px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">화장실 2.5개</span>
                            </div>
                          </div>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[0] == 'q3_3'">
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 1">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img5.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">파우더룸</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 2">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img6.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">드레스룸</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 3">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img7.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">욕실 / 화장실</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 4">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img8.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">개별발코니</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 5">
                            <div>
                              <img src="@/assets/img_legacy/pl3_none.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">필요 없음</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length == 0">
                            <span class="fkr-set1 fs-10" style="color:#000">선택 없음</span>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 1">
                            <span class="fkr-set1 fs-10" style="color:#000">외 {{ plan.q3_3_ans.length - 1 }}항목</span>
                          </div>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[0] == 'q5'">
                          <div v-if="plan.q5_ans.length > 0 && plan.q5_ans[0] == 2">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img10.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">넓은 식당 공간</span>
                            </div>
                          </div>
                          <div v-if="plan.q5_ans.length > 0 && plan.q5_ans[0] == 3">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img11.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">집 가운데 정원</span>
                            </div>
                          </div>
                          <div v-if="plan.q5_ans.length > 0 && plan.q5_ans[0] == 4">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img12.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">카페 같은 거실</span>
                            </div>
                          </div>
                          <div v-if="plan.q5_ans.length > 0 && plan.q5_ans[0] == 5">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img13.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">햇살 가득한 남향 공간</span>
                            </div>
                          </div>
                          <div v-if="plan.q5_ans.length == 0">
                            <span class="fkr-set1 fs-10" style="color:#000">선택 없음</span>
                          </div>
                          <div v-if="plan.q5_ans.length > 1">
                            <span class="fkr-set1 fs-10" style="color:#000">외 {{ plan.q5_ans.length - 1 }}항목</span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="bs_tab1" style="border:1px solid #c9c9c9; margin-bottom: 15px;">
                    <table class="table-borderless w-100" style="table-layout:fixed;">
                      <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#ffffff">
                        <td colspan="2" class="text-center" width="32%">2순위</td>
                      </tr>
                      <tr style="height:55px; background-color:#ffffff">
                        <td colspan="2" class="text-center" width="32%">
                          <select class="custom-select ff-eng1 fs-085" v-model="plan.priority_2"
                            style="width:200px; height:40px; border-bottom-width: 1px;">
                            <option value="q2">희망하는 면적</option>
                            <option value="q4">주택 스타일</option>
                            <option value="q3_1">희망하는 방 개수</option>
                            <option value="q3_2">화장실 개수</option>
                            <option value="q3_3">안방 부속실</option>
                            <option value="q5">기타 요구사항</option>
                          </select>
                        </td>
                      </tr>
                      <tr style="height:200px; border-bottom:1px solid #c9c9c9; background-color:#ffffff">
                        <td colspan="2" class="text-center" width="32%" style="padding:80px"
                          v-if="priorityImage[1] == 'q2'">
                          <vue-slider v-model="plan.q2_ans" :min="plan.q2_ans[0]" :max="plan.q2_ans[1]" :adsorb="true"
                            :interval="plan.q2_ans[1] - plan.q2_ans[0]"
                            :marks="{ [plan.q2_ans[0]]: plan.q2_ans[0] + '㎡', [plan.q2_ans[1]]: plan.q2_ans[1] + '㎡' }"
                            :included="true" :tooltip="'none'" :tooltip-formatter="formatter"></vue-slider>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[1] == 'q4'">
                          <div v-if="plan.q4_ans == 1" style="width:50%">
                            <div style="position:relative; left:50%">
                              <img src="@/assets/img_legacy/pl2_img1.jpg" width="100%"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">단층 (평지붕)</span>
                            </div>
                          </div>
                          <div v-if="plan.q4_ans == 2" style="width:50%">
                            <div style="position:relative; left:50%">
                              <img src="@/assets/img_legacy/pl2_img2.jpg" width="100%"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">단층 (박공지붕/다락)</span>
                            </div>
                          </div>
                          <div v-if="plan.q4_ans == 3" style="width:50%">
                            <div style="position:relative; left:50%">
                              <img src="@/assets/img_legacy/pl2_img3.jpg" width="100%"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">복층 (평지붕)</span>
                            </div>
                          </div>
                          <div v-if="plan.q4_ans == 4" style="width:50%">
                            <div style="position:relative; left:50%">
                              <img src="@/assets/img_legacy/pl2_img4.jpg" width="100%"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">복층 (박공지붕)</span>
                            </div>
                          </div>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[1] == 'q3_1'">
                          <div v-if="plan.q3_1_ans == 1">
                            <div>
                              <img src="@/assets/img_legacy/q3_img1.png" height="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">방 1개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_1_ans == 2">
                            <div>
                              <img src="@/assets/img_legacy/q3_img2.png" height="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">방 2개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_1_ans == 3">
                            <div>
                              <img src="@/assets/img_legacy/q3_img3.png" height="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">방 3개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_1_ans == 4">
                            <div>
                              <img src="@/assets/img_legacy/q3_img4.png" height="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">방 4개</span>
                            </div>
                          </div>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[1] == 'q3_2'">
                          <div v-if="plan.q3_2_ans == 1">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img1.png" height="100px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">화장실 1개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_2_ans == 2">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img2.png" height="100px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">화장실 1.5개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_2_ans == 3">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img3.png" height="100px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">화장실 2개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_2_ans == 4">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img4.png" height="100px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">화장실 2.5개</span>
                            </div>
                          </div>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[1] == 'q3_3'">
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 1">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img5.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">파우더룸</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 2">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img6.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">드레스룸</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 3">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img7.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">욕실 / 화장실</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 4">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img8.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">개별발코니</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 5">
                            <div>
                              <img src="@/assets/img_legacy/pl3_none.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">필요 없음</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length == 0">
                            <span class="fkr-set1 fs-10" style="color:#000">선택 없음</span>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 1">
                            <span class="fkr-set1 fs-10" style="color:#000">외 {{ plan.q3_3_ans.length - 1 }}항목</span>
                          </div>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[1] == 'q5'">
                          <div v-if="plan.q5_ans.length > 0 && plan.q5_ans[0] == 2">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img10.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">넓은 식당 공간</span>
                            </div>
                          </div>
                          <div v-if="plan.q5_ans.length > 0 && plan.q5_ans[0] == 3">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img11.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">집 가운데 정원</span>
                            </div>
                          </div>
                          <div v-if="plan.q5_ans.length > 0 && plan.q5_ans[0] == 4">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img12.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">카페 같은 거실</span>
                            </div>
                          </div>
                          <div v-if="plan.q5_ans.length > 0 && plan.q5_ans[0] == 5">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img13.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">햇살 가득한 남향 공간</span>
                            </div>
                          </div>
                          <div v-if="plan.q5_ans.length == 0">
                            <span class="fkr-set1 fs-10" style="color:#000">선택 없음</span>
                          </div>
                          <div v-if="plan.q5_ans.length > 1">
                            <span class="fkr-set1 fs-10" style="color:#000">외 {{ plan.q5_ans.length - 1 }}항목</span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="bs_tab1" style="border:1px solid #c9c9c9; margin-bottom: 15px;">
                    <table class="table-borderless w-100" style="table-layout:fixed;">
                      <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#ffffff">
                        <td colspan="2" class="text-center" width="32%">3순위</td>
                      </tr>
                      <tr style="height:55px; background-color:#ffffff">
                        <td colspan="2" class="text-center" width="32%">
                          <select class="custom-select ff-eng1 fs-085" v-model="plan.priority_3"
                            style="width:200px; height:40px; border-bottom-width: 1px;">
                            <option value="q2">희망하는 면적</option>
                            <option value="q4">주택 스타일</option>
                            <option value="q3_1">희망하는 방 개수</option>
                            <option value="q3_2">화장실 개수</option>
                            <option value="q3_3">안방 부속실</option>
                            <option value="q5">기타 요구사항</option>
                          </select>
                        </td>
                      </tr>
                      <tr style="height:200px; border-bottom:1px solid #c9c9c9; background-color:#ffffff">
                        <td colspan="2" class="text-center" width="32%" style="padding:80px"
                          v-if="priorityImage[2] == 'q2'">
                          <vue-slider v-model="plan.q2_ans" :min="plan.q2_ans[0]" :max="plan.q2_ans[1]" :adsorb="true"
                            :interval="plan.q2_ans[1] - plan.q2_ans[0]"
                            :marks="{ [plan.q2_ans[0]]: plan.q2_ans[0] + '㎡', [plan.q2_ans[1]]: plan.q2_ans[1] + '㎡' }"
                            :included="true" :tooltip="'none'" :tooltip-formatter="formatter"></vue-slider>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[2] == 'q4'">
                          <div v-if="plan.q4_ans == 1" style="width:50%">
                            <div style="position:relative; left:50%">
                              <img src="@/assets/img_legacy/pl2_img1.jpg" width="100%"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">단층 (평지붕)</span>
                            </div>
                          </div>
                          <div v-if="plan.q4_ans == 2" style="width:50%">
                            <div style="position:relative; left:50%">
                              <img src="@/assets/img_legacy/pl2_img2.jpg" width="100%"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">단층 (박공지붕/다락)</span>
                            </div>
                          </div>
                          <div v-if="plan.q4_ans == 3" style="width:50%">
                            <div style="position:relative; left:50%">
                              <img src="@/assets/img_legacy/pl2_img3.jpg" width="100%"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">복층 (평지붕)</span>
                            </div>
                          </div>
                          <div v-if="plan.q4_ans == 4" style="width:50%">
                            <div style="position:relative; left:50%">
                              <img src="@/assets/img_legacy/pl2_img4.jpg" width="100%"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">복층 (박공지붕)</span>
                            </div>
                          </div>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[2] == 'q3_1'">
                          <div v-if="plan.q3_1_ans == 1">
                            <div>
                              <img src="@/assets/img_legacy/q3_img1.png" height="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">방 1개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_1_ans == 2">
                            <div>
                              <img src="@/assets/img_legacy/q3_img2.png" height="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">방 2개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_1_ans == 3">
                            <div>
                              <img src="@/assets/img_legacy/q3_img3.png" height="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">방 3개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_1_ans == 4">
                            <div>
                              <img src="@/assets/img_legacy/q3_img4.png" height="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">방 4개</span>
                            </div>
                          </div>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[2] == 'q3_2'">
                          <div v-if="plan.q3_2_ans == 1">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img1.png" height="100px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">화장실 1개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_2_ans == 2">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img2.png" height="100px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">화장실 1.5개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_2_ans == 3">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img3.png" height="100px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">화장실 2개</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_2_ans == 4">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img4.png" height="100px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">화장실 2.5개</span>
                            </div>
                          </div>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[2] == 'q3_3'">
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 1">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img5.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">파우더룸</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 2">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img6.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">드레스룸</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 3">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img7.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">욕실 / 화장실</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 4">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img8.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">개별발코니</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 0 && plan.q3_3_ans[0] == 5">
                            <div>
                              <img src="@/assets/img_legacy/pl3_none.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">필요 없음</span>
                            </div>
                          </div>
                          <div v-if="plan.q3_3_ans.length == 0">
                            <span class="fkr-set1 fs-10" style="color:#000">선택 없음</span>
                          </div>
                          <div v-if="plan.q3_3_ans.length > 1">
                            <span class="fkr-set1 fs-10" style="color:#000">외 {{ plan.q3_3_ans.length - 1 }}항목</span>
                          </div>
                        </td>
                        <td colspan="2" class="text-center" width="32%" v-else-if="priorityImage[2] == 'q5'">
                          <div v-if="plan.q5_ans.length > 0 && plan.q5_ans[0] == 2">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img10.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">넓은 식당 공간</span>
                            </div>
                          </div>
                          <div v-if="plan.q5_ans.length > 0 && plan.q5_ans[0] == 3">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img11.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">집 가운데 정원</span>
                            </div>
                          </div>
                          <div v-if="plan.q5_ans.length > 0 && plan.q5_ans[0] == 4">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img12.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">카페 같은 거실</span>
                            </div>
                          </div>
                          <div v-if="plan.q5_ans.length > 0 && plan.q5_ans[0] == 5">
                            <div>
                              <img src="@/assets/img_legacy/pl3_img13.jpg" width="120px"
                                style="border-top-left-radius:1rem; border-top-right-radius:1rem">
                              <br><span class="fkr-set1 fs-10" style="color:#000">햇살 가득한 남향 공간</span>
                            </div>
                          </div>
                          <div v-if="plan.q5_ans.length == 0">
                            <span class="fkr-set1 fs-10" style="color:#000">선택 없음</span>
                          </div>
                          <div v-if="plan.q5_ans.length > 1">
                            <span class="fkr-set1 fs-10" style="color:#000">외 {{ plan.q5_ans.length - 1 }}항목</span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div style="height:50px"></div>

              </div>

              <div v-show="currentStep == 0 || currentStep == 5">
                <div id="ifrSec">
                  <iframe id="ifr" class="ifr" ref="ifrm" src=""></iframe>
                  <canvas class="ifr" ref="myCanvas" id="my-canvas" style="background:lightblue" @mouseenter="disableScroll" @mouseleave="enableScroll"></canvas>
                  <div id="planInfo" class="ifr">
                    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" :style="isSimuleOpened == 1 || isMobile? 'margin-top: 60px' : ''" v-show="planImg.length">
                        <div class="carousel-indicators">
                            <button v-for="(v, i) in planImg" :key="i" type="button" data-bs-target="#carouselExampleCaptions" :data-bs-slide-to="i" :class="i == 0 ? 'active' : ''" :aria-current="i == 0 ? 'true' : ''" :aria-label="'Slide ' + (i+1)"></button>
                        </div>
                        <div class="carousel-inner">
                            <div v-for="(v, i) in planImg" :key="i" :class="'carousel-item' + (i == 0 ? ' active' : '')">
                                <div class="iw-1" style="display:flex; justify-content:center">
                                    <img :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + v" :style="isMobile? 'width:90%' : 'overflow-y:hidden'">
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                  </div>
                  <button v-show="isSimuleOpened" type="button" @click="closeSimule()"
                    style="width:100%; height:40px; margin-top: -7px; background-color:#000; border-color:#000 ; border-radius:4px; color:#fff">{{planImg.length == 0? '시뮬레이션 ' : ''}}접기</button>
                </div>
              </div>

            </form>
            <!---------- 5. 추천설계안 -------------------------------------------->

            <div v-show="currentStep == 5 && !fetching && suggestion.length > 0">
              <div class="x_div_center pt-4">
                <div class="x_div_center"><span class="fkr-set1 fs-15">추천된 디자인을 확인하고, <br v-if="isMobile" />원하는 설계안을
                    선택해주세요.</span></div>
              </div>

              <div class="x_div_center pt-4" v-if="plan.q1_ans == 1">
                <div class="x_div_center"><span class="fkr-set1 fs-12" style="color:#4e0000">설계를 제외한 제조 기간은 <br
                      v-if="isMobile" />8주 ~ 16주입니다.</span></div>
              </div>

              <div class="sw-1 py-5">
                <div class="sw-3 px-2" v-for="(item, index) in suggestion" :key="index" :style="isMobile? 'margin-bottom:20px;' : ''">
                  <input type="radio" name="suggestion" :id="'suggestion_val' + index" v-model="suggestion_ans" :value="index">
                  <label :for="'suggestion_val' + index" class="x_div_center scale" style="width:95%">
                    <div class="py-3" style="width:85%">
                      <div class="pb-3"><img
                          :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + item.imgPath1"
                          width="100%"></div>
                      <div class="pb-3" :style="plan.q1_ans == 2? 'padding-bottom:0 !important;' : ''"><img
                          :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + item.imgPath2"
                          width="100%"></div>
                      <div class="text-center" style="margin-bottom: 1rem" v-if="plan.q1_ans == 2">
                        <span class="fen-set1 fs-10" style="color:#337ab7; cursor:pointer;" @click="showPlanInfo(item,false)">view more..</span>
                      </div>
                      <div class="text-center"><span class="fkr-set1 fs-095" style="color:#000">적합도 1등 설계안</span> <span
                          class="fkr-set1 fc-w5">(적합도 {{ item.score }}%)</span></div>
                      <div class="text-center"><span class="fkr-set2 fs-13">면적 : {{ (item.area /
                        3.3).toFixed(0) }}평</span></div>
                      <!--                         <div class="text-center"><span class="fkr-set2 fs-13">평당 제조가 : {{ suggestion.design1cost.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") }}원</span></div>-->
                      <div class="x_div_center pt-3" v-if="plan.q1_ans == 1">
                        <div class="w-50 pe-1"><button @click="simulate(item.planId)"
                            :style="'width:100%; height:40px; border:1px solid #000; border-radius:4px; color:#000; background-color:' + (currentDesignId == item.planId ? 'lightgray;' : '#fff;')"><span
                              class="fkr-set1 fs-095">시뮬레이션</span></button></div>
                      </div>
                      <div class="x_div_center pt-3" v-else>
                        <div class="w-50 pe-1"><button @click="showPlanInfo(item,true)"
                            :style="'width:100%; height:40px; border:1px solid #000; border-radius:4px; color:#000; background-color:' + (currentDesignId == item.planId ? 'lightgray;' : '#fff;')"><span
                              class="fkr-set1 fs-095">평면도 보기</span></button></div>
                        <div class="w-50 pe-1"><button @click="simulateOffice(item)"
                            style="width:100%; height:40px; border:1px solid #000; border-radius:4px; color:#fff; background-color:#000;"><span
                              class="fkr-set1 fs-095">시뮬레이션</span></button></div>
                      </div>
                    </div>
                  </label>
                </div>
                <!-- <div class="sw-b2"></div> -->
              </div>
              <div class="x_div_center"><span class="fkr-set1 fs-15" style="color:#4e0000">당기 평당 제조 공급가 <br
                    v-if="isMobile" />: 625~695만원</span></div>
              <div class="x_div_center"><span class="fkr-set1 fs-12" style="color:#4e0000">자재비용, 옵션에 따라 달라질 수 있습니다.</span>
              </div>
              <br />
              <div class="x_div_center"><span class="fkr-set1 fs-12" style="color:#4e0000">평당 제조가는 <br
                    v-if="isMobile" />모듈 제작 비용만을 의미합니다.</span></div>
              <div class="x_div_center"><span class="fkr-set1 fs-12" style="color:#4e0000">전체 비용은 설계비, 제조비, 운송설치비, <br
                    v-if="isMobile" />현장 공사비 등으로 구성됩니다.</span></div>
              <!--                  <div class="w-50 ps-1"><button :style="'width:100%; height:40px; border:1px solid #4e0000; background-color:#fff; border-radius:4px; color:#4e0000;' + (isMobileDevice? 'opacity: 0.5' : '')" :disabled="isMobileDevice"><a style="color:#4e0000" href="javascript:void(0)" @click="showModulePlanModal();"><span class="fkr-set1 fs-095">모듈편집SW실행</span></a></button></div>-->
              <div class="x_div_end py-2">
                <button v-if="plan.q1_ans == 1"
                  :style="'align:right; width:120px; height:40px; border:1px solid #000; background-color:#fff; border-radius:4px; color:#000;' + (isMobileDevice ? 'opacity: 0.5' : '')"
                  :disabled="isMobileDevice"><a style="color:#000" href="javascript:void(0)"
                    @click="showModulePlanModal();"><span class="fkr-set1 fs-095">모듈편집SW실행</span></a></button>
                <button
                  :style="'align:right; width:140px; height:40px; border:1px solid #000; background-color:lightgray; border-radius:4px; color:#000; margin-left: 15px'"><a
                    style="color:#000" href="javascript:void(0)" @click="savePlan();"><span
                      class="fkr-set1 fs-095">추천설계안 임시저장</span></a></button>
              </div>
              <div class="x_div_center"><span class="fkr-set1 fs-11" style="color:#4e0000">※ 프로젝트를 생성하시면</span></div>
              <div class="x_div_center"><span class="fkr-set1 fs-11" style="color:#4e0000">건축 각 과정(설계, 시공, 인테리어)에서 <br
                    v-if="isMobile" />입찰을 진행하실 수 있습니다.</span></div>
            </div>

            <div v-show="currentStep == 5 && !fetching && suggestion.length == 0">
              <div class="x_div_center w-100 pt-4">
                <div class="x_div_center"><span class="fkr-set1 fs-14">조건에 맞는 추천 설계안이 없습니다.<br>기획하기를 다시 진행해주세요.</span>
                </div>
              </div>
            </div>

            <!---------- 9. 사무용 선택옵션 -------------------------------------------->
            <div v-show="currentStep == 9">

              <div class="x_div_center pt-4">
                <div class="x_div_center"><span class="fkr-set1 fs-10" style="color:#4e0000">철근콘크리트공법 대비 <br v-if="isMobile" />
                  건축 기간을 절반 이하로 줄여드립니다.</span></div>
              </div>
              <div style="height:50px"></div>

              <div class="x_div_center w-100 pt-4">
                <div class="x_div_center"><span class="fkr-set1 fs-14">건축면적을 선택해주세요.</span></div>
              </div>

              <div style="height:50px"></div>

              <vue-slider v-model="plan.q2_ans" :min="0" :max="500" :adsorb="true" :interval="100"
                  :marks="isMobile ? marks_office_m : marks_office" :included="true" :tooltip="isMobile ? 'always' : 'none'"
                  :tooltip-formatter="formatter" :min-range="100" :max-range="100"></vue-slider>

              <div style="height:80px"></div>

              <div class="x_div_center w-100 pt-4">
                <div class="x_div_center"><span class="fkr-set1 fs-14">층수 및 지하유무를 선택해주세요.</span></div>
              </div>

              <div style="height:30px"></div>

              <div class="sw-1">
                <div class="sw-2">
                  <div class="sw-4">
                    <div class="x_div_center py-2"><img src="@/assets/img_legacy/plan_office_storey.png" height="100px"></div>
                    <div class="x_div_center py-3" style="white-space:nowrap">
                      <div style="border-bottom:2px solid #c9c9c9">
                        <h3 class="fkr-set1 fs-12">층수</h3>
                      </div>
                    </div>
                    <div class="x_div_center py-2">
                        <select class="custom-select ff-eng1 fs-085" v-model="plan.floors"
                          style="width:120px; height:36px;">
                          <option v-for="(v, i) in floorListOffice" :key="i" :value="v">{{v}}</option>
                        </select>
                    </div>
                  </div>
                  <div class="sw-4">
                    <div class="x_div_center py-2"><img src="@/assets/img_legacy/plan_office_underground.png" height="100px">
                    </div>
                    <div class="x_div_center py-3" style="white-space:nowrap">
                      <div style="border-bottom:2px solid #c9c9c9">
                        <h3 class="fkr-set1 fs-12">지하유무</h3>
                      </div>
                    </div>
                    <div class="x_div_center py-2">
                      <div class="custom-control custom-radio">
                        <input class="custom-control-input" type="radio" name="underground_office" id="underground_office_val1"
                          v-model="plan.underground" value="1">
                        <label class="custom-control-label" for="underground_office_val1">있음&nbsp;&nbsp;&nbsp;</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input class="custom-control-input" type="radio" name="underground_office" id="underground_office_val2"
                          v-model="plan.underground" value="0">
                        <label class="custom-control-label" for="underground_office_val2">없음</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style="height:50px"></div>

              <!-- <div class="x_div_center w-100 pt-4">
                <div class="x_div_center"><span class="fkr-set1 fs-14">대지면적 및 용적률을 입력해주세요. <br v-if="isMobile"/>(선택사항)</span></div>
              </div>

              <div style="height:30px"></div>

              <div class="sw-1">
                <div class="sw-2">
                  <div class="sw-4">
                    <div class="x_div_center py-2"><img src="@/assets/img_legacy/plan_office_site_area.png" height="100px"></div>
                    <div class="x_div_center py-3" style="white-space:nowrap">
                      <div style="border-bottom:2px solid #c9c9c9">
                        <h3 class="fkr-set1 fs-12">대지면적(㎡)</h3>
                      </div>
                    </div>
                    <div class="x_div_center py-2">
                      <input type="text" v-model="plan.siteArea" :disabled="plan.buildingLandRatio"
                        class="form-control fkr-set2 fc-w5 text-center" style="width:120px; height:36px; border-radius:4px">
                    </div>
                  </div>
                  <div class="sw-4">
                    <div class="x_div_center py-2"><img src="@/assets/img_legacy/plan_office_floor_area_ratio.png" height="100px">
                    </div>
                    <div class="x_div_center py-3" style="white-space:nowrap">
                      <div style="border-bottom:2px solid #c9c9c9">
                        <h3 class="fkr-set1 fs-12">용적률(%)</h3>
                      </div>
                    </div>
                    <div class="x_div_center py-2">
                      <input type="text" v-model="plan.floorAreaRatio" :disabled="plan.buildingLandRatio"
                        class="form-control fkr-set2 fc-w5 text-center" style="width:120px; height:36px; border-radius:4px">
                    </div>
                  </div>
                </div>
              </div>

              <div style="height:50px"></div> -->

              <div class="x_div_center w-100 pt-4">
                <div class="x_div_center"><span class="fkr-set1 fs-14">코어유닛(계단실 및 엘리베이터)의 <br v-if="isMobile"/>방향을 선택해주세요.</span></div>
              </div>

              <div style="height:50px"></div>

              <div class="sw-1">
                <div class="sw-5a">
                  <div class="sw-5aa">
                    <input type="radio" name="core_location" id="core_location_val1" v-model="plan.core" value="U">
                    <label for="core_location_val1" class="scale" style="width:94%">
                      <div class="x_div_center pb-2"><img src="@/assets/img_legacy/plan_office_core_up.png" width="100px"></div>
                      <div class="x_div_center py-3"><span class="fkr-set1 fs-10">위</span></div>
                    </label>
                  </div>
                  <div class="sw-5aa">
                    <input type="radio" name="core_location" id="core_location_val2" v-model="plan.core" value="B">
                    <label for="core_location_val2" class="scale" style="width:94%">
                      <div class="x_div_center pb-2"><img src="@/assets/img_legacy/plan_office_core_bottom.png" width="100px"></div>
                      <div class="x_div_center py-3"><span class="fkr-set1 fs-10">아래</span></div>
                    </label>
                  </div>
                </div>
                <div class="sw-b"></div>
                <div class="sw-5b">
                  <div class="sw-5bb">
                    <input type="radio" name="core_location" id="core_location_val3" v-model="plan.core" value="L">
                    <label for="core_location_val3" class="scale" style="width:94%">
                      <div class="x_div_center pb-2"><img src="@/assets/img_legacy/plan_office_core_left.png" width="100px"></div>
                      <div class="x_div_center py-3"><span class="fkr-set1 fs-10">왼쪽</span></div>
                    </label>
                  </div>
                  <div class="sw-5bb">
                    <input type="radio" name="core_location" id="core_location_val4" v-model="plan.core" value="R">
                    <label for="core_location_val4" class="scale" style="width:94%">
                      <div class="x_div_center pb-2"><img src="@/assets/img_legacy/plan_office_core_right.png" width="100px"></div>
                      <div class="x_div_center py-3"><span class="fkr-set1 fs-10">오른쪽</span></div>
                    </label>
                  </div>
                </div>
                <div class="sw-b"></div>
                <div class="sw-5b">
                  <div class="sw-5bb">
                    <input type="radio" name="core_location" id="core_location_val5" v-model="plan.core" value="C">
                    <label for="core_location_val5" class="scale" style="width:94%">
                      <div class="x_div_center pb-2"><img src="@/assets/img_legacy/plan_office_core_center.png" width="100px"></div>
                      <div class="x_div_center py-3"><span class="fkr-set1 fs-10">중앙</span></div>
                    </label>
                  </div>
                  <div class="sw-5bb">
                    <input type="radio" name="core_location" id="core_location_val6" v-model="plan.core" value=''>
                    <label for="core_location_val6" class="scale" style="width:94%">
                      <div class="x_div_center pb-2"><img src="@/assets/img_legacy/pl3_none.jpg" width="100px"
                          style="border-top-left-radius:1rem; border-top-right-radius:1rem"></div>
                      <div class="x_div_center py-3"><span class="fkr-set1 fs-10">선택 없음</span></div>
                    </label>
                  </div>
                </div>
              </div>

              <div style="height:30px"></div>

            </div>

            <!---------- 10. 층수 / 복합시설여부 -------------------------------------------->
            <div v-show="currentStep == 10">

              <div class="x_div_center pt-4">
                <div class="x_div_center"><span class="fkr-set1 fs-10" style="color:#4e0000">철근콘크리트공법 대비 <br
                      v-if="isMobile" />건축 기간을 절반 이하로 줄여드립니다.</span></div>
              </div>
              <div style="height:30px"></div>

              <div class="x_div_center w-100 pt-4">
                <div class="x_div_center"><span class="fkr-set1 fs-14">층수<span v-if="plan.q1_ans == 3"> 및 복합시설여부</span>를
                    선택해주세요.</span></div>
              </div>

              <div style="height:30px"></div>

              <div class="sw-1">
                <div class="sw-2">
                  <div class="sw-4">
                    <div class="x_div_center py-2"><img src="@/assets/img_legacy/plan_office_storey.png" height="100px"></div>
                    <div class="x_div_center py-3" style="white-space:nowrap">
                      <div style="border-bottom:2px solid #c9c9c9">
                        <h3 class="fkr-set1 fs-12">층수</h3>
                      </div>
                    </div>
                    <div class="x_div_center py-2"><input type="text" v-model="plan.floors"
                        class="form-control fkr-set2 fc-w5 text-center" style="width:50%; height:36px; border-radius:4px">
                    </div>
                  </div>
                  <div class="sw-4">
                    <div class="x_div_center py-2"><img src="@/assets/img_legacy/plan_office_underground.png" height="100px">
                    </div>
                    <div class="x_div_center py-3" style="white-space:nowrap">
                      <div style="border-bottom:2px solid #c9c9c9">
                        <h3 class="fkr-set1 fs-12">지하유무</h3>
                      </div>
                    </div>
                    <div class="x_div_center py-2">
                      <div class="custom-control custom-radio">
                        <input class="custom-control-input" type="radio" name="underground" id="underground_val1"
                          v-model="plan.underground" value="1">
                        <label class="custom-control-label" for="underground_val1">있음&nbsp;&nbsp;&nbsp;</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input class="custom-control-input" type="radio" name="underground" id="underground_val2"
                          v-model="plan.underground" value="0">
                        <label class="custom-control-label" for="underground_val2">없음</label>
                      </div>
                    </div>
                  </div>
                  <div class="sw-4" v-if="plan.q1_ans == 3">
                    <div class="x_div_center py-2"><img src="@/assets/img_legacy/plan_office_complex.png" height="100px"></div>
                    <div class="x_div_center py-3" style="white-space:nowrap">
                      <div style="border-bottom:2px solid #c9c9c9">
                        <h3 class="fkr-set1 fs-12">주상복합여부</h3>
                      </div>
                    </div>
                    <div class="x_div_center py-2">
                      <div class="custom-control custom-radio">
                        <input class="custom-control-input" type="radio" name="complex" id="complex_val1"
                          v-model="plan.q1_ans_addnl" value="1">
                        <label class="custom-control-label" for="complex_val1">예&nbsp;&nbsp;&nbsp;</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input class="custom-control-input" type="radio" name="complex" id="complex_val2"
                          v-model="plan.q1_ans_addnl" value="0">
                        <label class="custom-control-label" for="complex_val2">아니오</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style="height:80px"></div>

              <!-- <div v-if="plan.q1_ans == 3">
                  <div class="w-100 x_div_center py-3"><div style="border-bottom:2px solid #c9c9c9"><h3 class="fkr-set1 fs-12">복합시설 구성 옵션(복수선택 가능)</h3></div></div>

                  <div style="height:30px"></div>

                  <div class="sw-1">
                    <div class="sw-2">
                      <div class="sw-4">
                        <input type="checkbox" name="plan_q1_addnl" id="plan_q1_addnl_val1" v-model="plan.q1_ans_addnl" value=1>
                        <label for="plan_q1_addnl_val1" class="scale_none">
                          <div class="x_div_center pb-2"><img src="@/assets/img_legacy/plan_img1.png" width="85%" class=""></div>
                          <div class="text-center py-3"><span class="ff-kor1 fs-10 fw-bold" style="color:#4e0000">주거시설</span></div>
                        </label>
                      </div>
                      <div class="sw-4" v-if="plan.q1_ans == 3">
                        <input type="checkbox" name="plan_q1_addnl" id="plan_q1_addnl_val2" v-model="plan.q1_ans_addnl" value=2>
                        <label for="plan_q1_addnl_val2" class="scale_none">
                          <div class="x_div_center pb-2"><img src="@/assets/img_legacy/plan_img3.png" width="85%" class=""></div>
                          <div class="text-center py-3"><span class="ff-kor1 fs-10 fw-bold" style="color:#4e0000">사무시설</span></div>
                        </label>
                      </div>
                      <div class="sw-4" v-if="plan.q1_ans == 2">
                        <input type="checkbox" name="plan_q1_addnl" id="plan_q1_addnl_val3" v-model="plan.q1_ans_addnl" value=3>
                        <label for="plan_q1_addnl_val3" class="scale_none">
                          <div class="x_div_center pb-2"><img src="@/assets/img_legacy/plan_img2.png" width="85%" class=""></div>
                          <div class="text-center py-3"><span class="ff-kor1 fs-10 fw-bold" style="color:#4e0000">상업시설</span></div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div style="height:60px"></div>
                </div> -->
              <div class="x_div_center"><span class="fkr-set1 fs-10" style="color:#4e0000">※ 프로젝트를 생성하시면</span></div>
              <div class="x_div_center"><span class="fkr-set1 fs-10" style="color:#4e0000">건축 각 과정(설계, 시공)에서 <br
                    v-if="isMobile" />입찰을 진행하실 수 있습니다.</span></div>

            </div>

            <!---------- 버튼 그룹 -------------------------------------------->
            <div :class="scrollSpyOn ? 'pg_stage scrollSpy' : ''">
              <div v-show="currentStep == 0">
                <div class="x_div_center pt-5">
                  <button class="x_div_center" id="goNext" @click="nextStep()"
                    style="width:100%; height:80px; border:0; background-color:#000; border-radius:4px">
                    <span class="fkr-set1 fs-12 fc-w1">다음단계 &gt;</span>
                  </button>
                </div>
              </div>
              <div v-show="currentStep >= 1 && currentStep <= 3">
                <div class="x_div_center pt-5">
                  <div class="w-50 px-3"><button class="x_div_center" @click="prevStep()"
                      style="width:100%; height:80px; border:1px solid #000; background-color:#fff; border-radius:4px; color:#000"><span
                        class="fkr-set1 fs-12">&lt; 이전단계</span></button></div>
                  <div class="w-50 px-3"><button class="x_div_center" @click="nextStep()"
                      style="width:100%; height:80px; border:0; background-color:#000; border-radius:4px"
                      id="goToNext"><span class="fkr-set1 fs-12 fc-w1">다음단계 &gt;</span></button></div>
                </div>
              </div>
              <div v-show="currentStep == 4 || currentStep == 9">
                <div class="x_div_center pt-5">
                  <div class="w-50 px-3"><button class="x_div_center" @click="prevStep()"
                      style="width:100%; height:80px; border:1px solid #000; background-color:#fff; border-radius:4px; color:#000"><span
                        class="fkr-set1 fs-12">&lt; 이전단계</span></button></div>
                  <div class="w-50 px-3"><button class="x_div_center" @click="onSubmit"
                      style="width:100%; height:80px; border:0; background-color:#000; border-radius:4px"
                      id="submitBtn"><span class="fkr-set1 fs-12 fc-w1">추천 설계안 보기</span></button></div>
                </div>
              </div>
              <div v-show="currentStep == 5 || currentStep == 10">
                <div class="x_div_center pt-5">
                  <div class="w-50 px-3"><button class="x_div_center" @click="prevStep()"
                      style="width:100%; height:80px; border:1px solid #000; background-color:#fff; border-radius:4px; color:#000"><span
                        class="fkr-set1 fs-12">&lt; 이전단계</span></button></div>
                  <div class="w-50 px-3"><button class="x_div_center" @click="onSuggestionClick"
                      :disabled="submitting || (['1','2'].indexOf(plan.q1_ans) >= 0 && suggestion_ans == null) || (plan.q1_ans == 3 && !this.plan.floors)"
                      id="submitFinal"
                      :style="'width:100%; height:80px; border:0; background-color:#000; border-radius:4px;' + ((['1','2'].indexOf(plan.q1_ans) >= 0 && suggestion_ans == null) || (plan.q1_ans == 3 && !this.plan.floors) ? ' opacity: 0.5' : '')"><span
                        class="fkr-set1 fs-12 fc-w1">프로젝트 만들기<i v-if="submitting" style="margin-left: 10px"
                          class='fa fa-spinner fa-spin'></i></span></button></div>
                </div>
              </div>
            </div>

            <div id="bottomSpace" style="height:50px"></div>
          </div>

          <div id="v-ModluePlan" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true" style="z-index:100000001;">
            <div class="modal-dialog" role="document">
              <div class="modal-content">

                <div class="w-100">
                  <div class="x_div_center w-100"
                    style="background-color:lightgray; border-top-left-radius:8px; border-top-right-radius:8px">
                    <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">Moduleplan SW</span></div>
                    <div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()"
                        style="color:#000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                  </div>

                  <div class="x_div_center py-2 px-4">
                    <div class="w-100 py-3">
                      <div class="py-2 px-2 fkr-set2 fs-11">M3 Systems의 코디네이터가 사용하는 계획설계도구이지만 사용자가 원하시면 직접 사용해보실 수
                        있습니다.<br><br>다운로드형 Windows 소프트웨어입니다. 설치시 약 500MB의 여유공간이 필요합니다.<br><br>또한 Chrome 브라우저에서 바로 연동하기
                        위해서는 ClickOnce 확장 프로그램 설치가 필요합니다.</div>
                    </div>
                  </div>

                  <div class="x_div_center py-4">
                    <div class="px-2"><a href="/files/Moduleplan_manual_20230227.pdf" target="_blank"><button
                          class="x_div_center"
                          style="width:120px; height:30px; border:0; background-color:#fff; border:1px solid #000; border-radius:4px; color:#000"
                          data-dismiss="modal"><span class="fkr-set1">사용자매뉴얼</span></button></a></div>
                    <div class="px-2"><a
                        href="https://chrome.google.com/webstore/detail/clickonce-for-google-chro/kekahkplibinaibelipdcikofmedafmb/related"
                        target="_blank"><button class="x_div_center"
                          style="width:120px; height:30px; border:0; background-color:lightgray; border:1px solid #000; border-radius:4px; color:#000"
                          data-dismiss="modal"><span class="fkr-set1">ClickOnce 설치</span></button></a></div>
                    <div class="px-2"><button @click="loadCospec()" class="x_div_center"
                        style="width:120px; height:30px; border:0; background-color:#000; border-radius:4px; color:#fff"
                        data-dismiss="modal"><span class="fkr-set1">Moduleplan 실행</span></button></div>
                  </div>

                  <div style="height:20px"></div>

                </div>
              </div>
            </div>
          </div>

          <div id="v-view" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true" style="z-index:100000001;">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">

                <div class="w-100">
                  <div class="x_div_center w-100"
                    style="background-color:lightgray; border-top-left-radius:8px; border-top-right-radius:8px">
                    <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">시스템 점검 안내</span></div>
                    <div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()"
                        style="color:#000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                  </div>

                  <div class="x_div_center py-2 px-4">
                    <div class="w-100 x_div_center py-3">
                      <div class="x_div_center px-2"><img src="@/assets/img_legacy/mobuilder_inspection_annauncement.png"
                          width="100%" style="border-raidus:8px"></div>
                    </div>
                  </div>

                  <div class="x_div_center py-2 px-4">
                    <div class="w-100 py-3" style="border-top:1px solid #c9c9c9">
                      <div class="py-2 px-2 fkr-set2 fs-11">현재 국가공간정보포털이 시스템 점검기간<b>(1.20(금) ~ 1.24(화))</b>인 관계로 대지정보 조회가
                        불가능합니다.<br>건물 위치 지정 단계는 생략하시고 다음 단계로 진행해주시기 바랍니다.</div>
                    </div>
                  </div>

                  <div class="x_div_center py-4">
                    <div class="px-2"><button @click="closeModal()" class="x_div_center"
                        style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #000; border-radius:4px; color:#000"
                        data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
                  </div>

                  <div style="height:20px"></div>

                </div>
              </div>
            </div>
          </div>

          <div id="v-help" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true" style="z-index:100000001;">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">

                <div class="w-100">
                  <div class="x_div_center w-100"
                    style="background-color:lightgray; border-top-left-radius:8px; border-top-right-radius:8px">
                    <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">{{helpTitle[helpIndex]}}</span></div>
                    <div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()"
                        style="color:#000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                  </div>

                  <div v-show="helpIndex == 0">
                    <div class="pg_a_set3" style="padding: 10px">
                      <div class="pg_a_set3a">
                        <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                          <div class="x_div_center"><img src="@/assets/img_legacy/s_plan/simulation_rotate.jpg"
                            width="100%" style="border-raidus:8px"></div>
                        </div>
                      </div>
                      <div class="pg_a_set3c"></div>
                      <div class="pg_a_set3b">
                        <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                          <div class="w-100 py-3" style="border-bottom:1px solid #c9c9c9">
                            <div class="py-2 px-2 fkr-set2 fs-11">화면 회전 : {{isMobileDevice? '한손으로 터치하여 이동' : '마우스 좌클릭 상태에서 이동'}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="pg_a_set3" style="padding: 10px">
                      <div class="pg_a_set3a">
                        <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                          <div class="x_div_center"><img src="@/assets/img_legacy/s_plan/simulation_pan.jpg"
                            width="100%" style="border-raidus:8px"></div>
                        </div>
                      </div>
                      <div class="pg_a_set3c"></div>
                      <div class="pg_a_set3b">
                        <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                          <div class="w-100 py-3" style="border-bottom:1px solid #c9c9c9">
                            <div class="py-2 px-2 fkr-set2 fs-11">화면 이동 : {{isMobileDevice? '양손으로 터치하여 이동' : '마우스 우클릭 상태에서 이동'}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="pg_a_set3" style="padding: 10px">
                      <div class="pg_a_set3a">
                        <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                          <div class="x_div_center"><img src="@/assets/img_legacy/s_plan/simulation_magnify.jpg"
                            width="100%" style="border-raidus:8px"></div>
                        </div>
                      </div>
                      <div class="pg_a_set3c"></div>
                      <div class="pg_a_set3b">
                        <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                          <div class="w-100 py-3" style="border-bottom:1px solid #c9c9c9">
                            <div class="py-2 px-2 fkr-set2 fs-11">화면 확대 : {{isMobileDevice? '양손으로 터치하여 확대/축소' : '마우스 휠 조작'}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-show="helpIndex == 1">
                    <div class="x_div_center py-2 px-4">
                      <div class="w-100 py-3" style="border-bottom:1px solid #c9c9c9">
                        <div class="py-2 px-2 fkr-set2 fs-11">시뮬레이션 화면 상단의 ‘2D’ 버튼을 클릭하여 2D 모드로 진입합니다.
                            <br>2D 모드에서 건축물을 클릭하여 노란색 핸들을 불러냅니다.</div>
                      </div>
                    </div>

                    <div class="pg_a_set3" style="padding: 10px">
                      <div class="pg_a_set3a">
                        <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                          <div class="x_div_center"><img src="@/assets/img_legacy/s_plan/model_move.jpg"
                            width="100%" style="border-raidus:8px"></div>
                        </div>
                      </div>
                      <div class="pg_a_set3c"></div>
                      <div class="pg_a_set3b">
                        <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                          <div class="w-100 py-3" style="border-bottom:1px solid #c9c9c9">
                            <div class="py-2 px-2 fkr-set2 fs-11">건물 이동 : 핸들을 중심으로 건물을 {{isMobileDevice? '마우스 좌클릭한' : '마우스 좌클릭한'}} 상태에서 움직임으로써 건물의 위치를 변경할 수 있습니다.</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="pg_a_set3" style="padding: 10px">
                      <div class="pg_a_set3a">
                        <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                          <div class="x_div_center"><img src="@/assets/img_legacy/s_plan/model_rotate.jpg"
                            width="100%" style="border-raidus:8px"></div>
                        </div>
                      </div>
                      <div class="pg_a_set3c"></div>
                      <div class="pg_a_set3b">
                        <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                          <div class="w-100 py-3" style="border-bottom:1px solid #c9c9c9">
                            <div class="py-2 px-2 fkr-set2 fs-11">건물 회전 : 핸들 테두리(노란색 원)를 {{isMobileDevice? '좌클릭한' : '좌클릭한'}} 상태에서 움직임으로써 건물의 방향을 변경할 수 있습니다.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-show="helpIndex == 2">
                    <div class="x_div_center py-2 px-4">
                      <div class="w-100 x_div_center py-3">
                        <div class="x_div_center px-2"><img src="@/assets/img_legacy/s_plan/floorplan.png"
                            width="100%" style="border-raidus:8px"></div>
                      </div>
                    </div>
  
                    <div class="x_div_center py-2 px-4">
                      <div class="w-100 py-3" style="border-bottom:1px solid #c9c9c9">
                        <div class="py-2 px-2 fkr-set2 fs-11">시뮬레이션 좌측의 붉은 레이어 버튼을 클릭한 후 층별 단면도를 선택합니다. 
                            해당 메뉴 최상단의 ‘전체’ 버튼을 클릭할 경우 원래의 상태로 건물 모델이 복구됩니다.<br>
                            단면도 상태에서는 실내 가구들에 대한 개별 조작(위치 이동/회전 등)이 가능합니다. 
                            조작 방법은 건물 자체의 조작방법과 동일합니다.</div>
                      </div>
                    </div>
                  </div>

                  <div class="x_div_center py-4">
                    <div class="px-2"><button @click="closeModal()" class="x_div_center"
                        style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #000; border-radius:4px; color:#000"
                        data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
                    <div class="px-2"><button @click="showNextHelp()" class="x_div_center" v-if="plan.q1_ans == 1"
                        style="width:90px; height:30px; border:0; background-color:lightgray; border:1px solid #000; border-radius:4px; color:#000"
                        data-dismiss="modal"><span class="fkr-set1">다음</span></button></div>
                  </div>

                  <div style="height:20px"></div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <button @click="testExpand()" style="position:fixed; right:50px; bottom:50px; z-index:1032;">expand</button> -->
  </div>
</template>

<script>
import router from '@/router';  // 추가 (조주원)
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import modelListOffice from '@/data/model-list-office.json';
import { Viewer, GLTFLoaderPlugin } from '@xeokit/xeokit-sdk';
import store from '@/store';
import Auth from '@/api/auth'
import Api from '@/api'

window.$('.scale_none').each(function () {
  var nowImg = window.$(this).find('img');  //호버한 부분의 img파일 찾기
  var srcName = nowImg.attr('src');  //호버한 부분의 이미지 주소값 src
  var newSrc = srcName.substring(0, srcName.lastIndexOf('.'));
  //.png , .jpg 와같이 파일명 자르기. 뒤에서부터 시작해서 '.'점있는 곳 까지 컷!

  window.$(this).hover(function () {
    window.$(this).find('img').attr('src', newSrc + '_on.' + /[^.]+$/.exec(srcName));
  }, function () {
    window.$(this).find('img').attr('src', newSrc + '.' + /[^.]+$/.exec(srcName));
  });
});

var gIfrmMobuilder = null;

export default {
  name: 'use-case-plan',
  components: {
    VueSlider,
  },
  props: {
    user: {
      type: Object,
      default: null
    },
    isMobileDevice: {
      type: Boolean,
      default: false
    }
  },
  metaInfo: {
    title: 'UseCase Plan',
    meta: [
      {
        name: 'description',
        content: 'Welcome to Vue.js frontend.'
      }
    ]
  },
  data() {
    return {
      submitting: false,
      isMobile: false,
      postcode: '',
      addr: '',
      currentDesignId: '',
      currentModelId: '',
      noLand: false,
      scrollSpyOn: true,
      helpTitle: ['기본 화면 조작', '건물의 조작', '평면도 보기'],
      helpIndex: 0,
      modelListOffice: modelListOffice,
      floorListOffice: [],
      planImg: [],
      viewer: null,
      gltfLoader: null,
      gltfModel: null,
      //----------  수정 여기부터 (이아진) ----------
      plan: {
        addr: '',
        addr_jibun: '',
        siteArea: null,
        buildingLandRatio: null,
        floorAreaRatio: null,
        floors: null,
        underground: 0,
        core: '',
        q1_ans: 0,
        // q1_ans_addnl: [],
        q1_ans_addnl: 0,
        q2_ans: [66, 99],
        q3_1_ans: 0,
        q3_2_ans: 0,
        q3_3_ans: [],
        q4_ans: 0,
        q5_ans: [],
        q2_priority: null,
        q3_1_priority: null,
        q3_2_priority: null,
        q3_3_priority: null,
        q4_priority: null,
        q5_priority: null,
        priority_1: '',
        priority_2: '',
        priority_3: '',
        q2_isnecessary: false,
        q3_1_isnecessary: false,
        q3_2_isnecessary: false,
        q3_3_isnecessary: false,
        q4_isnecessary: false,
        q5_isnecessary: false,
        uid: ''
      },
      q4_nopreference: false,
      q3_2_nopreference: false,
      q3_3_nopreference: false,
      q5_nopreference: false,
      q3_3_all: false,
      q5_all: false,
      conditionStyle: '',
      conditionBath: 0,
      conditionAncillary: [],
      conditionOthers: [],
      priorityImage: ['', '', ''],
      marks: {
        '0': '0',
        '16.5': '16.5㎡(5평)',
        '33': '33㎡(10평)',
        '49.5': '49.5㎡(15평)',
        '66': '66㎡(20평)',
        '82.5': '82.5㎡(25평)',
        '99': '99㎡(30평)',
        '115.5': '115.5㎡(35평)',
        '132': '132㎡(40평)',
        '148.5': '148.5㎡(45평)',
        '165': '∞'
      },
      marks_m: {
        '0': '0',
        '16.5': '5평',
        '33': '10평',
        '49.5': '15평',
        '66': '20평',
        '82.5': '25평',
        '99': '30평',
        '115.5': '35평',
        '132': '40평',
        '148.5': '45평',
        '165': '∞'
      },
      marks_office: {
        0: "0",
        100: "100㎡(30.25평)",
        200: "200㎡(60.5평)",
        300: "300㎡(90.75평)",
        400: "400㎡(121.0평)",
        500: "∞"
      },
      marks_office_m: {
        0: "0",
        100: "30.25평",
        200: "60.5평",
        300: "90.75평",
        400: "121.0평",
        500: "∞"
      },
      formatter: '{value}㎡',
      windowWidth: 0,
      currentStep: 0,
      submitValid: false,
      fetching: false,
      isSimuleOpened: false,
      ifrSecPosBak: '',
      ifrHeightBak: '',
      s3region: process.env.VUE_APP_S3_REGION,
      s3bucket: process.env.VUE_APP_S3_BUCKET,
      suggestion: [],
      suggestion_ans: null,
      scheduleId: null,
      gTimesetMbCallback: null,
      buildMode: process.env.NODE_ENV
    }
  },
  computed: {
  },
  watch: {
    addr: function (val) {
      if (val) {
        document.getElementById("goNext").disabled = false;
        window.$('#goNext').css('opacity', 1);
      }
    },
    noLand: function (val) {
      if (val) {
        window.$('#ifr').css('height', 0);
        window.$('#ifr').css('border', 0);
        document.getElementById("goNext").disabled = false;
        window.$('#goNext').css('opacity', 1);
        this.scrollSpyOn = false;
      }
      else {
        window.$('#ifr').css('height', this.isMobile ? '600px' : '1000px')
        window.$('#ifr').css('border', '1px solid #eee');
        this.scrollSpyOn = true;
        if (!this.plan.addr_jibun) {
          document.getElementById("goNext").disabled = true;
          window.$('#goNext').css('opacity', 0.5);
        }
      }
    },
    q4_nopreference: function (val) {
      if (val) {
        this.plan.q4_ans = 0;
        this.plan.q4_isnecessary = false;
      }
    },
    'plan.q4_ans': function (val) {
      if (val) {
        this.q4_nopreference = false;
      }
    },
    'plan.q4_isnecessary': function (val) {
      if (val) {
        this.q4_nopreference = false;
      }
    },

    q3_2_nopreference: function (val) {
      if (val) {
        this.plan.q3_2_ans = 0;
        this.plan.q3_2_isnecessary = false;
      }
    },
    'plan.q3_2_ans': function (val) {
      if (val) {
        this.q3_2_nopreference = false;
      }
    },
    'plan.q3_2_isnecessary': function (val) {
      if (val) {
        this.q3_2_nopreference = false;
      }
    },
    q3_3_nopreference: function (val) {
      if (val) {
        this.plan.q3_3_ans = [];
        this.q3_3_all = false;
        this.plan.q3_3_isnecessary = false;
      }
    },
    'plan.q3_3_ans': function (val) {
      if (val[0] == '5') {
        this.plan.q3_3_isnecessary = false;
      }
    },
    'plan.q3_3_isnecessary': function (val) {
      if (val) {
        this.q3_3_nopreference = false;
      }
    },
    q5_nopreference: function (val) {
      if (val) {
        this.plan.q5_ans = [];
        this.q5_all = false;
        this.plan.q5_isnecessary = false;
      }
    },
    'plan.q5_ans': function (val) {
      if (val.length >= 1) {
        this.q5_nopreference = false;
      }
      else {
        this.plan.q5_isnecessary = false;
      }
    },
    'plan.q5_isnecessary': function (val) {
      if (val) {
        this.q5_nopreference = false;
      }
    },
    'plan.priority_1': function (val) {
      this.priorityImage[0] = val;
    },
    'plan.priority_2': function (val) {
      this.priorityImage[1] = val;
    },
    'plan.priority_3': function (val) {
      this.priorityImage[2] = val;
    }
  },
  created() {
    store.commit('setMenuId', 'M200');
    this.windowWidth = window.innerWidth;
    this.modelListOffice.forEach((model) => {
      if(this.floorListOffice.indexOf(model.number_of_floor) <0) {
        this.floorListOffice.push(model.number_of_floor);
      }
    })
    this.floorListOffice.sort((a,b) => a-b);
  },
  mounted() {

    if (this.user && this.user.info.usrRole != 'OW') {
      alert('고객회원 계정으로 로그인 후 이용해주세요.');
      location.href = '/';
    }
    this.isMobile = window.innerWidth < 768;

    var curruntDate = new Date();
    var testFinishDate = new Date('2023-01-24T00:00:00.000Z')

    if (curruntDate < testFinishDate) {
      window.$('#v-view').modal('show');
    }

    const $vue = this;
    window.addEventListener("scroll", function () {
      $vue.decideScrollSpy();
    });

    window.$('#my-canvas').css('height', 0);
    window.$('#my-canvas').css('border', 0);
    window.$('#planInfo').css('height', 0);
    window.$('#planInfo').css('border', 0);

    this.viewer = new Viewer({
      canvasId: "my-canvas",
      transparent: true,
    });

    this.gltfLoader = new GLTFLoaderPlugin(this.viewer);
    this.$refs.ifrm.src = "https://mobuild.cafe24.com/mobuilder/index/public/?ma=builder&mu=modeUserMain";
    const gIframe = document.getElementById("ifr");

    gIframe.addEventListener("load", function () {
      gIfrmMobuilder = (gIframe.contentWindow) ? gIframe.contentWindow :
        (gIframe.contentDocument.document) ? gIframe.contentDocument.document : gIframe.contentDocument;
    });


    window.addEventListener('message', (e) => {
      // console.log('================== post message ====================')
      // console.log(e.data);
      if (e.data.functionName == 'fInfoFromMobuilder') {
        const jsonObj = JSON.parse(e.data.para[1]);
        if (e.data.para[0] == 'land') {
          this.plan.addr = jsonObj.addressDoro ? jsonObj.addressDoro : jsonObj.addressJibun;
          this.plan.addr_jibun = jsonObj.addressJibun;
          this.plan.siteArea = jsonObj.areaLand;
          this.plan.buildingLandRatio = jsonObj.ratioCoverMax;
          this.plan.floorAreaRatio = jsonObj.ratioFloorAreaMax;
        }
        else if (e.data.para[0] == 'building') {
          //TBD
        }
        this.onChangeVal()
      }
      else if (e.data.functionName == 'fExpandMobuilderFrame') {
        this.expandIframe(e.data.para[0]);
      }
      else if (e.data.functionName == 'fMobuilderCallback') {
        console.log("call fMobuilderCallback in postMessage(upward) event listener");
        this.fMobuilderCallback("모빌더 로딩완료");
      }
    });

    this.daumObj = new window.daum.Postcode({
      oncomplete: (data) => {
        var addr = ''; // 주소 변수
        var extraAddr = ''; // 참고항목 변수

        //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
        if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
          addr = data.roadAddress;
        } else { // 사용자가 지번 주소를 선택했을 경우(J)
          alert('현재 도로명 주소만 지원됩니다.');
          return 1
          // addr = data.jibunAddress;
        }

        // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
        if (data.userSelectedType === 'R') {
          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
            extraAddr += data.bname;
          }
          // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== '' && data.apartment === 'Y') {
            extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
          }
          // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraAddr !== '') {
            extraAddr = ' (' + extraAddr + ')';
          }
        }
        this.postcode = data.zonecode;
        this.addr = addr + extraAddr;
        this.plan.addr = this.addr;
      }
    });

    window.addEventListener('resize', this.handleResize);

    if (curruntDate >= testFinishDate) {
      document.getElementById("goNext").disabled = true;
      window.$('#goNext').css('opacity', 0.5);
    }

    window.scrollTo(0, 0);

    const plan = JSON.parse(localStorage.getItem('tempPlanSellection'));
    const suggestion = JSON.parse(localStorage.getItem('tempSuggestion'));
    const activeSave = localStorage.getItem('activeSave')

    if (plan) {
      var loadSave = true;
      if (activeSave == 1) {
        loadSave = confirm('임시저장 데이터가 존재합니다.\n불러오시겠습니까?')
      }
      if (loadSave) {
        this.plan = plan;
        this.suggestion = suggestion;
        if (typeof this.plan.q2_isnecessary == 'string') {
          this.plan.q2_isnecessary = this.plan.q2_isnecessary == 'false' ? false : true;
          this.plan.q3_1_isnecessary = this.plan.q3_1_isnecessary == 'false' ? false : true;
          this.plan.q3_2_isnecessary = this.plan.q3_2_isnecessary == 'false' ? false : true;
          this.plan.q3_3_isnecessary = this.plan.q3_3_isnecessary == 'false' ? false : true;
          this.plan.q4_isnecessary = this.plan.q4_isnecessary == 'false' ? false : true;
          this.plan.q5_isnecessary = this.plan.q5_isnecessary == 'false' ? false : true;
        }
        this.q4_nopreference = (this.plan.q4_ans == 0 || this.plan.q4_ans == '0') ? true : false;
        this.q3_2_nopreference = (this.plan.q3_2_ans == 0 || this.plan.q3_2_ans == '0') ? true : false;
        this.q3_3_nopreference = (this.plan.q3_3_ans == 999 || this.plan.q3_3_ans == '999') ? true : false;
        this.q5_nopreference = (this.plan.q5_ans == 999 || this.plan.q5_ans == '999') ? true : false;
        if (Array.isArray(this.plan.q3_3_ans)) {
          if (this.plan.q3_3_ans.length == 4) {
            this.q3_3_all = true;
          }
        } else {
          this.plan.q3_3_ans = [this.plan.q3_3_ans];
        }
        if (Array.isArray(this.plan.q5_ans)) {
          if (this.plan.q5_ans.length == 4) {
            this.q5_all = true;
          }
        } else {
          this.plan.q5_ans = [this.plan.q5_ans];
        }

        this.addr = this.plan.addr

        localStorage.removeItem('tempSuggestion')
        localStorage.removeItem('tempPlanSellection')

        window.$('#ifr').css('height', 0);
        window.$('#ifr').css('border', 0);
        window.$('#ifrSec').css('position', 'absolute');
        if (this.plan.q1_ans == 2) {
          this.currentStep = 5;
        }
        else if (this.plan.q1_ans == 3) {
          this.currentStep = 10;
        }
        else {
          for (var i = 0; i < 5; i++) {
            this.nextStep()
          }
        }
      }
      else {
        localStorage.removeItem('tempSuggestion')
        localStorage.removeItem('tempPlanSellection')
      }
    }
  },
  methods: {
    searchAddr() {
      // this.daumObj.open();
    },
    closeModal() {
      window.$('#v-view').modal('hide');
      window.$('#v-help').modal('hide');
      window.$('#v-ModluePlan').modal('hide');
    },
    showNextHelp() {
      this.helpIndex = (this.helpIndex + 1) %3;
      document.getElementById('v-help').scrollTo(0, 0);
    },
    //----------  수정 여기부터 (이아진) ----------
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    fMobuilderCallback(m) {
      console.log("fMobuilderCallback called");

      if (gIfrmMobuilder) {

        window.clearTimeout(this.gTimesetMbCallback);
        console.log(m);

        const gjMenuSet = {
          "project": {            // 프로젝트
            "outline": true,    // - 사업 개요
            "info": true,       // - 사업 정보
          },
          "land": {               // 대지
            "address": true,    // - 주소 입력
            "danji": false,     // - 단지 선택 <-- 숨기기
            "info": true        // - 대지 정보
          },
          "model": {              // 모델
            "outline": true,    // - 모델 개요
            "info": true,       // - 모델 정보
            "build": true       // - 모델 수정
          }
        }

        gIfrmMobuilder.postMessage({
          functionName: "fSidebarSetMenu",
          para: [JSON.stringify(gjMenuSet), window.location.host]
        }, "*");

        gIfrmMobuilder.postMessage({
          functionName: "fSelectSidebarMenu",
          para: ['land', 'address', '']
        }, "*");

        if (!this.isMobile) {
          gIfrmMobuilder.postMessage({
            functionName: "fSidebarOpen",
            para: []
          }, "*");
        }
      }
      else {
        this.gTimesetMbCallback = setTimeout(() => {
          console.log("setTimeout callback function called");
          this.fMobuilderCallback("모빌더 로딩 완료");
        }, 500)
      }
    },
    onChangeVal() {
      if (this.currentStep == 0) {
        if (this.plan.addr) {
          document.getElementById("goNext").disabled = false;
          window.$('#goNext').css('opacity', 1);
        }
      }
      else if (this.currentStep == 1) {
        if (this.plan.q1_ans != 0) {
          document.getElementById("goToNext").disabled = false;
          window.$('#goToNext').css('opacity', 1);
        }
      }
      else if (this.currentStep == 2) {
        if (this.plan.q4_ans != 0 || this.q4_nopreference) {
          document.getElementById("goToNext").disabled = false;
          window.$('#goToNext').css('opacity', 1);
        }
        else {
          document.getElementById("goToNext").disabled = true;
          window.$('#goToNext').css('opacity', 0.5);
        }
      }
      else if (this.currentStep == 3) {
        if ((this.plan.q5_ans.length != 0 || this.q5_nopreference) && (this.plan.q3_3_ans.length != 0 || this.q3_3_nopreference) && (this.plan.q3_2_ans != 0 || this.q3_2_nopreference) && (this.plan.q3_1_ans != 0 || this.q3_1_nopreference)) {
          document.getElementById("goToNext").disabled = false;
          window.$('#goToNext').css('opacity', 1);
        }
        else {
          document.getElementById("goToNext").disabled = true;
          window.$('#goToNext').css('opacity', 0.5);
        }
      }
      else if (this.currentStep == 4) {
        // if(!this.plan.q2_priority || !this.plan.q4_priority || !this.plan.q3_1_priority || !this.plan.q3_2_priority || !this.plan.q3_3_priority || !this.plan.q5_priority) {
        if (this.plan.priority_1 == this.plan.priority_2 || this.plan.priority_1 == this.plan.priority_3 || this.plan.priority_2 == this.plan.priority_3) {
          // document.getElementById("submitBtn").disabled = true;
          // window.$('#submitBtn').css('opacity', 0.5);
          this.submitValid = false;
        }
        else {
          document.getElementById("submitBtn").disabled = false;
          window.$('#submitBtn').css('opacity', 1);
          this.submitValid = true;
        }
      }
    },
    prevStep() {
      // this.scrollSpyOn = true;
      if (this.currentStep == 9 || this.currentStep == 10) {
        if(this.currentStep == 9) {
          this.plan.floors = null;
        }
        this.currentStep = 1;
      }
      else if (this.currentStep == 5 && this.plan.q1_ans == 2) {
        window.$('#ifr').css('height', this.isMobile ? '600px' : '1000px');
        window.$('#ifr').css('border', '1px solid #eee');
        window.$('#ifrSec').css('position', 'relative');
        window.$('#ifrSec').css('z-index', 1);
        this.isSimuleOpened = false;
        this.currentStep = 9;
      }
      else {
        this.currentStep--;
      }
      this.onChangeVal();
      window.scrollTo(0, 0);

      if (this.currentStep == 4) {
        window.$('#ifr').css('height', this.isMobile ? '600px' : '1000px');
        window.$('#ifr').css('border', '1px solid #eee');
        window.$('#ifrSec').css('position', 'relative');
        window.$('#ifrSec').css('z-index', 1);
        this.isSimuleOpened = false;

        gIfrmMobuilder.postMessage({
          functionName: "fSelectSidebarMenu",
          para: ['land', 'address', '']
        }, "*");

        if (!this.isMobile) {
          gIfrmMobuilder.postMessage({
            functionName: "fSidebarOpen",
            para: []
          }, "*");
        }
      }
      window.setTimeout(() => {
        this.decideScrollSpy();
      }, 100);
    },
    nextStep() {
      if (this.currentStep == 0 && this.noLand) {
        this.plan.addr = '';
        this.plan.addr_jibun = '';
        this.plan.siteArea = null;
        this.plan.buildingLandRatio = null;
        this.plan.floorAreaRatio = null;
      }
      document.getElementById("goToNext").disabled = true;
      window.$('#goToNext').css('opacity', 0.5);
      if (this.currentStep == 1 && (this.plan.q1_ans == 2 || this.plan.q1_ans == 3)) {
        this.suggestion_ans = null;
        // this.plan.q1_ans_addnl = [];
        this.plan.q1_ans_addnl = 0;
        if(this.plan.q1_ans == 2) {
          this.plan.q2_ans = [100, 200];
          this.plan.floors = 3;
          this.currentStep = 9;
        }
        else {
          this.currentStep = 10;
        }
      }
      else {
        this.plan.q2_ans = [66, 99];
        this.plan.floors = null;
        this.plan.underground = 0;
        this.currentStep++;
      }
      this.onChangeVal();
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        this.decideScrollSpy();
      }, 100);
    },
    decideScrollSpy() {
      const vh = window.innerHeight;
      const target = document.getElementById('footer'); // 요소의 id 값이 target이라 가정
      const clientRect = target.getBoundingClientRect(); // DomRect 구하기 (각종 좌표값이 들어있는 객체)
      const relativeTop = clientRect.top; // Viewport의 시작지점을 기준으로한 상대좌표 Y 값.
      if (vh > relativeTop) {
        this.scrollSpyOn = false;
      }
      else {
        this.scrollSpyOn = true;
      }
    },
    onChangeQ3_3_1() {
      var val1 = document.getElementById("plan_q3_3_val1");
      var val2 = document.getElementById("plan_q3_3_val2");
      var val3 = document.getElementById("plan_q3_3_val3");
      var val4 = document.getElementById("plan_q3_3_val4");
      var val5 = document.getElementById("plan_q3_3_val5");
      var valAll = document.getElementById("plan_q3_3_all");

      if (val1.checked && val2.checked && val3.checked && val4.checked)
        valAll.checked = true;
      else {
        valAll.checked = false;
        if (val1.checked || val2.checked || val3.checked || val4.checked) {
          val5.checked = false;
          this.plan.q3_3_ans = this.plan.q3_3_ans.filter((element) => element !== "5")
        }
      }
      this.q3_3_nopreference = false;
    },
    onChangeQ3_3_2() {
      var val1 = document.getElementById("plan_q3_3_val1");
      var val2 = document.getElementById("plan_q3_3_val2");
      var val3 = document.getElementById("plan_q3_3_val3");
      var val4 = document.getElementById("plan_q3_3_val4");
      var val5 = document.getElementById("plan_q3_3_val5");
      var valAll = document.getElementById("plan_q3_3_all");

      if (val5.checked) {
        val1.checked = false;
        val2.checked = false;
        val3.checked = false;
        val4.checked = false;
        valAll.checked = false;
        this.plan.q3_3_ans = ["5"];
      }
      this.q3_3_nopreference = false;
    },
    onChangeQ3_3_all() {
      var val1 = document.getElementById("plan_q3_3_val1");
      var val2 = document.getElementById("plan_q3_3_val2");
      var val3 = document.getElementById("plan_q3_3_val3");
      var val4 = document.getElementById("plan_q3_3_val4");
      var val5 = document.getElementById("plan_q3_3_val5");
      var valAll = document.getElementById("plan_q3_3_all");

      if (valAll.checked) {
        val1.checked = true;
        val2.checked = true;
        val3.checked = true;
        val4.checked = true;
        val5.checked = false;
        this.plan.q3_3_ans = ["1", "2", "3", "4"];
      }
      else {
        val1.checked = false;
        val2.checked = false;
        val3.checked = false;
        val4.checked = false;
        val5.checked = true;
        this.plan.q3_3_ans = ["5"];
      }
      this.q3_3_nopreference = false;
    },
    onChangeQ5() {
      // var val1 = document.getElementById("plan_q5_val1");
      var val2 = document.getElementById("plan_q5_val2");
      var val3 = document.getElementById("plan_q5_val3");
      var val4 = document.getElementById("plan_q5_val4");
      var val5 = document.getElementById("plan_q5_val5");
      var valAll = document.getElementById("plan_q5_all");

      if (val2.checked && val3.checked && val4.checked && val5.checked) {
        valAll.checked = true;
      }
      else {
        valAll.checked = false;
      }
    },
    onChangeQ5_all() {
      // var val1 = document.getElementById("plan_q5_val1");
      var val2 = document.getElementById("plan_q5_val2");
      var val3 = document.getElementById("plan_q5_val3");
      var val4 = document.getElementById("plan_q5_val4");
      var val5 = document.getElementById("plan_q5_val5");
      var valAll = document.getElementById("plan_q5_all");

      if (valAll.checked) {
        // val1.checked = true;
        val2.checked = true;
        val3.checked = true;
        val4.checked = true;
        val5.checked = true;
        // this.plan.q5_ans = ["1", "2", "3", "4", "5"];
        this.plan.q5_ans = ["2", "3", "4", "5"];
      }
      else {
        // val1.checked = false;
        val2.checked = false;
        val3.checked = false;
        val4.checked = false;
        val5.checked = false;
        this.plan.q5_ans = [];
      }
      this.q5_nopreference = false;
    },
    onSubmit(event) {
      event.preventDefault()
      if(this.plan.q1_ans == 2) {
        window.$('#ifr').css('height', 0);
        window.$('#ifr').css('border', 0);
        window.$('#ifrSec').css('position', 'absolute');
        this.setSuggestionOffice();

        this.currentStep = 5;
        window.scrollTo(0, 0);
      }
      else {
        if (this.submitValid) {
          if (this.q3_3_nopreference) {
            this.plan.q3_3_ans = ["999"];
          }
          if (this.q5_nopreference) {
            this.plan.q5_ans = ["999"];
          }
          window.$('#ifr').css('height', 0);
          window.$('#ifr').css('border', 0);
          window.$('#ifrSec').css('position', 'absolute');
          this.setSuggestion();
  
          this.currentStep++;
          window.scrollTo(0, 0);
        }
        else {
          if (this.plan.priority_1 == this.plan.priority_2 || this.plan.priority_1 == this.plan.priority_3 || this.plan.priority_2 == this.plan.priority_3) {
            alert('순위 항목은 서로 달라야합니다.');
          }
          else {
            document.getElementById("submitBtn").disabled = true;
            window.$('#submitBtn').css('opacity', 0.5);
          }
          event.stop();
        }
      }
    },
    async setSuggestion() {
      this.suggestion = [];
      this.fetching = true;
      var planList = await Api.get('/api/v1/plan/list');
      this.fetching = false;
      var dbTable = [];

      //dbTable[i][0]: id
      //dbTable[i][1]: exclusive_area
      //dbTable[i][2]: num_of_room
      //dbTable[i][3]: num_of_bath
      //dbTable[i][4]: num_of_toilet
      //dbTable[i][5]: ancillary_powder
      //dbTable[i][6]: ancillary_dress
      //dbTable[i][7]: ancillary_bath
      //dbTable[i][8]: ancillary_toilet
      //dbTable[i][9]: ancillary_balcony
      //dbTable[i][10]: storey(flat/duflex)
      //dbTable[i][11]: roof(flat/gable)
      //dbTable[i][12]: masterrooms_ancillary
      //dbTable[i][13]: dining
      //dbTable[i][14]: plan_type
      //dbTable[i][15]: connect_living_kitchen
      //dbTable[i][16]: southward_living
      //dbTable[i][17]: southward_masterroom
      //dbTable[i][18]: cost

      planList.data.forEach((plan) => {
        dbTable.push([plan.id,
        plan.exclusiveArea,
        plan.numberRoom,
        plan.numberBath,
        plan.numberToilet,
        plan.ancillaryPowder,
        plan.ancillaryDress,
        plan.ancillaryBath,
        plan.ancillaryToilet,
        plan.ancillaryBalcony,
        plan.storey,
        plan.roof,
        plan.ancillaryMasterroom,
        Number(plan.dining),
        Number(plan.planType),
        Number(plan.connectLivingKitchen),
        Number(plan.southwardLiving),
        Number(plan.southwardMasterroom),
        plan.expns,
        plan.pdImg1,  //19
        plan.pdImg2,  //20
        plan.pdImg3]) //21
      })

      // dbTable = [
      //   //    0           1       2   3   4   5   6   7   8   9     10        11      12  13  14  15  16  17  18
      //   ['CNK-01-00-00', 90.86,   2,  2,  0,  1,  0,  0,  1,  0,  'flat',   'gable',  2,  0,  1,  0,  1,  1,  0],
      //   ['CNK-02-00-00', 99.87,   3,  2,  1,  1,  0,  1,  0,  0,  'duplex', 'flat',   2,  1,  1,  0,  1,  1,  0],
      //   ['CNK-03-00-00', 96.16,   2,  2,  0,  0,  1,  1,  0,  0,  'flat',   'flat',   2,  0,  0,  0,  1,  1,  0],
      //   ['CNK-04-00-00', 90.42,   3,  2,  0,  1,  0,  1,  0,  0,  'flat',   'gable',  2,  1,  0,  0,  1,  1,  0],     //다락=알파룸
      //   ['CNK-05-00-00', 99.89,   3,  2,  0,  1,  0,  1,  0,  0,  'flat',   'gable',  2,  1,  0,  0,  1,  1,  0],     //다락=알파룸
      //   ['CNK-06-00-00', 99.73,   3,  2,  1,  0,  0,  1,  0,  0,  'duplex', 'flat',   1,  1,  0,  0,  1,  1,  0],
      //   ['CNK-07-00-00', 96.16,   3,  2,  0,  1,  0,  1,  0,  0,  'flat',   'flat',   2,  1,  0,  0,  1,  1,  0],     //다락=알파룸
      //   ['CNK-08-00-00', 98.17,   3,  1,  1,  1,  0,  1,  0,  0,  'duplex', 'flat',   2,  1,  0,  1,  1,  1,  0],
      //   ['CNK-09-00-00', 99.70,   3,  2,  1,  1,  0,  1,  0,  0,  'duplex', 'flat',   2,  1,  1,  0,  1,  1,  0],
      //   ['CNK-10-00-00', 99.75,   2,  2,  0,  0,  1,  1,  0,  0,  'flat',   'flat',   2,  1,  0,  0,  1,  1,  0],
      //   ['CWK-01-00-00', 99.11,   4,  2,  1,  0,  0,  1,  0,  0,  'duplex', 'flat',   1,  1,  0,  1,  1,  1,  0],
      //   ['CWK-02-00-00', 99.42,   2,  2,  1,  0,  1,  1,  0,  0,  'duplex', 'flat',   2,  1,  0,  0,  1,  1,  0],
      //   ['CWK-03-00-00', 99.42,   4,  2,  1,  1,  0,  1,  0,  1,  'duplex', 'flat',   2,  1,  0,  0,  1,  1,  0],
      //   ['CWK-04-00-00', 118.03,  2,  2,  1,  0,  1,  1,  0,  0,  'duplex', 'flat',   2,  1,  0,  0,  0,  0,  0],
      //   ['CWK-05-00-00', 118.03,  2,  2,  1,  0,  1,  1,  0,  0,  'duplex', 'flat',   2,  1,  0,  0,  0,  0,  0],
      //   ['CWK-06-00-00', 103.38,  3,  2,  1,  0,  1,  1,  0,  1,  'duplex', 'flat',   2,  1,  0,  0,  0,  0,  0],
      //   ['CWK-07-00-00', 98.28,   3,  2,  1,  1,  0,  1,  0,  0,  'duplex', 'flat',   2,  1,  0,  0,  1,  1,  0],
      //   ['CWK-08-00-00', 98.28,   3,  2,  1,  0,  0,  1,  0,  0,  'duplex', 'flat',   1,  1,  1,  1,  1,  0,  0],
      //   ['CWK-09-00-00', 119.17,  3,  2,  1,  0,  1,  1,  0,  1,  'duplex', 'flat',   2,  1,  0,  0,  0,  0,  0],
      //   ['CWK-10-00-00', 150.75,  3,  2,  1,  0,  1,  1,  0,  1,  'duplex', 'flat',   2,  1,  1,  0,  1,  0,  0],
      //   ['PRO-01-00-00', 51.18,   1,  1,  0,  0,  0,  0,  0,  0,  'flat',   'flat',   0,  0,  0,  0,  1,  1,  0],
      //   ['PRO-02-00-00', 61.85,   2,  1,  0,  0,  0,  0,  0,  0,  'flat',   'gable',  0,  1,  0,  0,  1,  1,  0],     //다락=알파룸
      //   ['PRO-03-00-00', 61.85,   2,  1,  0,  0,  0,  0,  0,  0,  'flat',   'gable',  0,  0,  0,  0,  1,  1,  0],
      //   ['PRO-04-00-00', 61.85,   1,  1,  0,  0,  0,  0,  0,  0,  'flat',   'gable',  0,  0,  0,  0,  1,  1,  0],
      //   ['PRO-05-00-00', 61.85,   1,  1,  0,  0,  0,  0,  0,  0,  'flat',   'flat',   0,  0,  0,  0,  1,  1,  0],
      //   ['PRO-06-00-00', 50.97,   1,  1,  0,  0,  0,  0,  0,  0,  'flat',   'flat',   0,  0,  0,  0,  1,  1,  0],
      //   ['PRO-07-00-00', 61.85,   2,  1,  0,  0,  0,  0,  0,  0,  'flat',   'gable',  0,  1,  0,  0,  1,  1,  0],
      //   ['PRO-08-00-00', 61.85,   2,  1,  0,  0,  0,  0,  0,  0,  'flat',   'flat',   0,  1,  0,  0,  1,  1,  0],
      //   ['SHH-01-00-00', 81.26,   2,  2,  0,  0,  1,  1,  0,  0,  'flat',   'flat',   2,  0,  0,  0,  1,  1,  0],
      //   ['SHH-02-00-00', 87.25,   2,  2,  0,  0,  1,  1,  0,  0,  'flat',   'flat',   2,  0,  0,  0,  1,  1,  0],
      //   ['SHH-03-00-00', 97.88,   2,  2,  0,  0,  1,  1,  0,  0,  'flat',   'flat',   2,  0,  0,  0,  1,  1,  0],
      //   ['SHH-04-00-00', 96.90,   2,  2,  1,  0,  1,  1,  0,  0,  'duplex', 'flat',   2,  0,  0,  0,  1,  1,  0],
      //   ['SHH-05-00-00', 97.74,   2,  2,  1,  0,  1,  1,  0,  0,  'duplex', 'flat',   2,  0,  0,  0,  1,  1,  0],
      //   ['SHH-06-00-00', 95.73,   3,  2,  1,  0,  1,  1,  0,  0,  'duplex', 'flat',   2,  1,  0,  0,  1,  1,  0]
      // ];

      var q2_ans = this.plan.q2_ans;
      var q3_1_ans = this.plan.q3_1_ans;
      var q3_2_ans = this.plan.q3_2_ans;
      var q3_3_ans = this.plan.q3_3_ans;
      var q4_ans = this.plan.q4_ans;
      var q5_ans = this.plan.q5_ans;

      // if(q2_ans){
      //   q2_ans[0] = Number(q2_ans[0])
      //   q2_ans[1] = Number(q2_ans[1])
      //   q3_1_ans = Number(q3_1_ans)
      //   q3_2_ans = Number(q3_2_ans)
      //   q3_3_ans = Array.isArray(q3_3_ans)? q3_3_ans : [String(q3_3_ans)];
      //   q4_ans = Number(q4_ans)
      //   q5_ans = Array.isArray(q5_ans)? q5_ans : [String(q5_ans)];
      // }

      var q2_isnecessary = this.plan.q2_isnecessary;
      var q3_1_isnecessary = this.plan.q3_1_isnecessary;
      var q3_2_isnecessary = this.plan.q3_2_isnecessary;
      var q3_3_isnecessary = this.plan.q3_3_isnecessary;
      var q4_isnecessary = this.plan.q4_isnecessary;
      var q5_isnecessary = this.plan.q5_isnecessary;

      // q2_isnecessary = typeof q2_isnecessary == 'string'? JSON.parse(q2_isnecessary) : q2_isnecessary;
      // q3_1_isnecessary = typeof q3_1_isnecessary == 'string'? JSON.parse(q3_1_isnecessary) : q3_1_isnecessary;
      // q3_2_isnecessary = typeof q3_2_isnecessary == 'string'? JSON.parse(q3_2_isnecessary) : q3_2_isnecessary;
      // q3_3_isnecessary = typeof q3_3_isnecessary == 'string'? JSON.parse(q3_3_isnecessary) : q3_3_isnecessary;
      // q4_isnecessary = typeof q4_isnecessary == 'string'? JSON.parse(q4_isnecessary) : q4_isnecessary;
      // q5_isnecessary = typeof q5_isnecessary == 'string'? JSON.parse(q5_isnecessary) : q5_isnecessary;


      //Q2
      //dbTable[i][0]: id
      //dbTable[i][1]: exclusive_area
      const scoreMap2 = {}
      for (var i = 0; i < dbTable.length; i++)
        scoreMap2[dbTable[i][0]] = 0

      for (i = 0; i < dbTable.length; i++) {
        if (q2_ans[1] > 148.5) {                // 무한대 설정
          if (dbTable[i][1] >= q2_ans[0])     // 최소만 비교
            scoreMap2[dbTable[i][0]] = 100;
          else if (q2_ans[0] >= 16.5 && dbTable[i][1] >= q2_ans[0] - 16.5)
            scoreMap2[dbTable[i][0]] = 50;
          else
            scoreMap2[dbTable[i][0]] = 0
        }
        else {
          if (dbTable[i][1] >= q2_ans[0] && dbTable[i][1] <= q2_ans[1])
            scoreMap2[dbTable[i][0]] = 100;
          else if (q2_ans[0] >= 16.5 && dbTable[i][1] >= q2_ans[0] - 16.5 && dbTable[i][1] <= q2_ans[1] + 16.5)
            scoreMap2[dbTable[i][0]] = 50;
          else
            scoreMap2[dbTable[i][0]] = 0
        }
        if (q2_isnecessary && scoreMap2[dbTable[i][0]] < 100) {
          dbTable.splice(i, 1);
          i--;
        }
      }

      //Q3_1
      //dbTable[i][0]: id
      //dbTable[i][2]: num_of_room
      const scoreMap3_1 = {}
      for (i = 0; i < dbTable.length; i++)
        scoreMap3_1[dbTable[i][0]] = 0

      for (i = 0; i < dbTable.length; i++) {
        if (Math.abs(q3_1_ans - dbTable[i][2]) < 1)         //0개 차이
          scoreMap3_1[dbTable[i][0]] = 100
        else if (Math.abs(q3_1_ans - dbTable[i][2]) < 2)   //1개 차이
          scoreMap3_1[dbTable[i][0]] = 75
        else if (Math.abs(q3_1_ans - dbTable[i][2]) < 3)   //2개 차이
          scoreMap3_1[dbTable[i][0]] = 50
        else if (Math.abs(q3_1_ans - dbTable[i][2]) < 4)   //3개 차이
          scoreMap3_1[dbTable[i][0]] = 25
        else                                                    //그 이상
          scoreMap3_1[dbTable[i][0]] = 0

        if (q3_1_isnecessary && scoreMap3_1[dbTable[i][0]] < 100) {
          dbTable.splice(i, 1);
          i--;
        }
      }

      //Q3_2
      //dbTable[i][0]: id
      //dbTable[i][3]: num_of_bath
      //dbTable[i][4]: num_of_toilet
      const scoreMap3_2 = {}
      for (i = 0; i < dbTable.length; i++)
        scoreMap3_2[dbTable[i][0]] = 0

      for (i = 0; i < dbTable.length; i++) {
        if (q3_2_ans == 0) {
          scoreMap3_2[dbTable[i][0]] = 100
        }
        else if (q3_2_ans == 1) {
          if (dbTable[i][3] == 0) {
            if (dbTable[i][4] == 0)
              scoreMap3_2[dbTable[i][0]] = 0
            else
              scoreMap3_2[dbTable[i][0]] = 50
          }
          else if (dbTable[i][3] == 1)
            scoreMap3_2[dbTable[i][0]] = 100;
          else
            scoreMap3_2[dbTable[i][0]] = 80;
        }
        else if (q3_2_ans == 2) {
          if (dbTable[i][3] == 0) {
            if (dbTable[i][4] == 0)
              scoreMap3_2[dbTable[i][0]] = 0
            else
              scoreMap3_2[dbTable[i][0]] = 40
          }
          else if (dbTable[i][3] == 1)
            if (dbTable[i][4] == 0)
              scoreMap3_2[dbTable[i][0]] = 60
            else if (dbTable[i][4] == 1)
              scoreMap3_2[dbTable[i][0]] = 100;
            else
              scoreMap3_2[dbTable[i][0]] = 80;
        }
        else if (q3_2_ans == 3) {
          if (dbTable[i][3] == 0) {
            if (dbTable[i][4] == 0)
              scoreMap3_2[dbTable[i][0]] = 0
            else
              scoreMap3_2[dbTable[i][0]] = 20
          }
          else if (dbTable[i][3] == 1) {
            if (dbTable[i][4] == 0)
              scoreMap3_2[dbTable[i][0]] = 40
            else
              scoreMap3_2[dbTable[i][0]] = 60
          }
          else {
            if (dbTable[i][4] == 0)
              scoreMap3_2[dbTable[i][0]] = 100
            else
              scoreMap3_2[dbTable[i][0]] = 80
          }
        }
        else if (q3_2_ans == 4) {
          if (dbTable[i][3] == 0) {
            if (dbTable[i][4] < 1)
              scoreMap3_2[dbTable[i][0]] = 0
            else
              scoreMap3_2[dbTable[i][0]] = 10
          }
          else if (dbTable[i][3] == 1) {
            if (dbTable[i][4] < 1)
              scoreMap3_2[dbTable[i][0]] = 30
            else
              scoreMap3_2[dbTable[i][0]] = 50
          }
          else {
            if (dbTable[i][4] == 0)
              scoreMap3_2[dbTable[i][0]] = 80
            else
              scoreMap3_2[dbTable[i][0]] = 100
          }
        }

        if (q3_2_isnecessary && scoreMap3_2[dbTable[i][0]] < 100) {
          dbTable.splice(i, 1);
          i--;
        }
      }

      //Q3_3
      //dbTable[i][0]: id
      //dbTable[i][5]: ancillary_powder
      //dbTable[i][6]: ancillary_dress
      //dbTable[i][7]: ancillary_bath
      //dbTable[i][8]: ancillary_toilet
      //dbTable[i][9]: ancillary_balcony
      //parseInt()
      const scoreMap3_3 = {}
      for (i = 0; i < dbTable.length; i++)
        scoreMap3_3[dbTable[i][0]] = 0

      for (i = 0; i < dbTable.length; i++) {
        var tmp = 0
        for (var j = 0; j < q3_3_ans.length; j++) {
          if (q3_3_ans[j] == 1)
            if (dbTable[i][5])
              tmp += 100
          if (q3_3_ans[j] == 2)
            if (dbTable[i][6])
              tmp += 100
          if (q3_3_ans[j] == 3)
            if (dbTable[i][7] || dbTable[i][8])
              tmp += 100
          if (q3_3_ans[j] == 4)
            if (dbTable[i][9])
              tmp += 100
          if (q3_3_ans[j] == 999)
            tmp = 100
        }
        scoreMap3_3[dbTable[i][0]] = tmp / q3_3_ans.length

        if (q3_3_isnecessary && scoreMap3_3[dbTable[i][0]] < 100) {
          dbTable.splice(i, 1);
          i--;
        }
      }

      //Q4
      //dbTable[i][0]: id
      //dbTable[i][10]: storey(flat/duflex)
      //dbTable[i][11]: roof(flat/gable)
      const scoreMap4 = {}
      for (i = 0; i < dbTable.length; i++)
        scoreMap4[dbTable[i][0]] = 0

      for (i = 0; i < dbTable.length; i++) {
        scoreMap4[dbTable[i][0]] = 0
        if (q4_ans == 0) {
          scoreMap4[dbTable[i][0]] = 100
        }
        else if (q4_ans == 1) {
          if (dbTable[i][10] == "flat")
            scoreMap4[dbTable[i][0]] += 50
          if (dbTable[i][11] == "flat")
            scoreMap4[dbTable[i][0]] += 50
        }
        else if (q4_ans == 2) {
          if (dbTable[i][10] == "flat")
            scoreMap4[dbTable[i][0]] += 50
          if (dbTable[i][11] == "gable")
            scoreMap4[dbTable[i][0]] += 50
        }
        else if (q4_ans == 3) {
          if (dbTable[i][10] == "duplex")
            scoreMap4[dbTable[i][0]] += 50
          if (dbTable[i][11] == "flat")
            scoreMap4[dbTable[i][0]] += 50
        }
        else if (q4_ans == 4) {
          if (dbTable[i][10] == "duplex")
            scoreMap4[dbTable[i][0]] += 50
          if (dbTable[i][11] == "gable")
            scoreMap4[dbTable[i][0]] += 50
        }
        if (q4_isnecessary && scoreMap4[dbTable[i][0]] < 100) {
          dbTable.splice(i, 1);
          i--;
        }
      }

      //Q5
      //dbTable[i][0]: id
      //dbTable[i][12]: masterrooms_ancillary
      //dbTable[i][13]: dining
      //dbTable[i][14]: plan_type
      //dbTable[i][15]: connect_living_kitchen
      //dbTable[i][16]: southward_living
      //dbTable[i][17]: southward_masterroom
      //parseInt()
      const scoreMap5 = {}
      for (i = 0; i < dbTable.length; i++)
        scoreMap5[dbTable[i][0]] = 0

      for (i = 0; i < dbTable.length; i++) {
        tmp = 0
        for (j = 0; j < q5_ans.length; j++) {
          // if (q5_ans[j] == 1){
          //   if (dbTable[i][12] > 1)
          //     tmp += 100
          //   else if (dbTable[i][12] > 0)
          //     tmp += 50
          // }
          if (q5_ans[j] == 2)
            if (dbTable[i][13])
              tmp += 100
          if (q5_ans[j] == 3) {
            if (dbTable[i][14] > 1)
              tmp += 100
            else if (dbTable[i][14] > 0)
              tmp += 50
          }
          if (q5_ans[j] == 4)
            if (dbTable[i][15])
              tmp += 100
          if (q5_ans[j] == 5) {
            if (dbTable[i][16] && dbTable[i][17])
              tmp += 100
            else if (dbTable[i][16] && !dbTable[i][17])
              tmp += 60
            else if (!dbTable[i][16] && dbTable[i][17])
              tmp += 40
          }
          if (q5_ans[j] == 999) {
            tmp = 100
          }
        }
        scoreMap5[dbTable[i][0]] = tmp / q5_ans.length

        if (q5_isnecessary && scoreMap5[dbTable[i][0]] < 100) {
          dbTable.splice(i, 1);
          i--;
        }
      }

      // var rank = [];
      // rank[0] = null;
      // rank[this.plan.q2_priority] = 'q2';
      // rank[this.plan.q3_1_priority] = 'q3_1';
      // rank[this.plan.q3_2_priority] = 'q3_2';
      // rank[this.plan.q3_3_priority] = 'q3_3';
      // rank[this.plan.q4_priority] = 'q4';
      // rank[this.plan.q5_priority] = 'q5';

      // var weightMap = [0, 0.3230, 0.2604, 0.1978, 0.1352, 0.0726, 0.01]
      var weight = { q2: 0.05, q3_1: 0.05, q3_2: 0.05, q3_3: 0.05, q4: 0.05, q5: 0.05 };

      // for(var x=1; x<7; x++) {
      //   weight[rank[x]] = weightMap[x];
      // }

      weight[this.plan.priority_1] = 0.3510;
      weight[this.plan.priority_2] = 0.2830;
      weight[this.plan.priority_3] = 0.2160;

      // console.log(rank)
      // console.log(weight)

      //final
      //dbTable[i][0]: id
      //dbTable[i][18]: cost
      const scoreMapFinal = {}
      for (i = 0; i < dbTable.length; i++)
        scoreMapFinal[dbTable[i][0]] = {
          score: (scoreMap2[dbTable[i][0]] * weight.q2
            + scoreMap3_1[dbTable[i][0]] * weight.q3_1
            + scoreMap3_2[dbTable[i][0]] * weight.q3_2
            + scoreMap3_3[dbTable[i][0]] * weight.q3_3
            + scoreMap4[dbTable[i][0]] * weight.q4
            + scoreMap5[dbTable[i][0]] * weight.q5).toFixed(2),
          legacy_score: ((scoreMap2[dbTable[i][0]]
            + scoreMap3_1[dbTable[i][0]]
            + scoreMap3_2[dbTable[i][0]]
            + scoreMap3_3[dbTable[i][0]]
            + scoreMap4[dbTable[i][0]]
            + scoreMap5[dbTable[i][0]]) / 6).toFixed(2),
          exclusive_area: dbTable[i][1],
          imgPath1: dbTable[i][19],
          imgPath2: dbTable[i][20],
          expns: dbTable[i][18]
        }
      // console.log("Q2")
      // console.log(scoreMap2)
      // console.log("Q3")
      // console.log(scoreMap3_1)
      // console.log(scoreMap3_2)
      // console.log(scoreMap3_3)
      // console.log("Q4")
      // console.log(scoreMap4)
      // console.log("Q5")
      // console.log(scoreMap5)
      // console.log("Final")
      // console.log(scoreMapFinal)
      let sortedScoreMap = Object.entries(scoreMapFinal).sort((a, b) => b[1].score - a[1].score);

      // console.log(sortedScoreMap)
      sortedScoreMap = sortedScoreMap.slice(0, 3);

      for (let element of sortedScoreMap) {
        // console.log(element[0] + ": " + element[1]);
        this.suggestion.push({
          planId : element[0],
          score : element[1].score,
          imgPath1 : element[1].imgPath1,
          imgPath2 : element[1].imgPath2,
          cost : element[1].expns,
          area : element[1].exclusive_area
        })
      }
    },
    setSuggestionOffice() {
      this.suggestion = [];
      var max_score = 0;
      
      var filteredDatabase = this.modelListOffice.filter((element) => {
          return element['number_of_floor'] >= this.plan.floors -1 && element['number_of_floor'] <= this.plan.floors +1
      })

      for (let i = 0; i < filteredDatabase.length; i++) {
        let element = filteredDatabase[i]
        let vector = {}

        vector['area'] = element.area - (0.5 * (Number(this.plan.q2_ans[0]) + Number(this.plan.q2_ans[1])));
        vector['number-of-floor'] = element['number_of_floor'] - this.plan.floors;
        if (this.plan.core){
            vector['core-type'] = element["m3Code"][2] == this.plan.core ? 0 : 70;
        }

        let sum = 0;
        for (let key in vector) {
            sum += Math.pow(vector[key], 2)
        }

        filteredDatabase[i].score = Math.sqrt(sum)
        if (filteredDatabase[i].score > max_score) {
            max_score = filteredDatabase[i].score
        }
      }
      filteredDatabase.sort((a, b) => (a.score > b.score) ? 1 : -1)

      for (let i = 0; i < filteredDatabase.length; i++) {
          filteredDatabase[i].score = ((1 - filteredDatabase[i].score / max_score) * 100).toFixed(2)
      }
      filteredDatabase = filteredDatabase.slice(0, 3);

      for (let element of filteredDatabase) {
        this.suggestion.push({
          planId : element.m3Code,
          score : element.score,
          area : element.area,
          imgPath1 : element.render.split(',')[0],
          imgPath2 : element.render.split(',')[1],
          render : element.render,
          plan : element.plan,
          bim : element.bim
        })
      }
    },
    simulate(designId) {
      if (this.currentDesignId != designId) {
        this.currentDesignId = designId
        var ifcDataUpperFolder = '';
        switch (designId.substring(0, 3)) {
          case 'CNK': ifcDataUpperFolder = '01-' + 'CNK';
            break;
          case 'CWK': ifcDataUpperFolder = '02-' + 'CWK';
            break;
          case 'PRO': ifcDataUpperFolder = '03-' + 'PRO';
            break;
          case 'SHH': ifcDataUpperFolder = '04-' + 'SHH';
            break;
          case 'PRS': ifcDataUpperFolder = '11-' + 'PRS';
            break;
        }
        gIfrmMobuilder.postMessage({
          functionName: "fLoadIfcByParent",
          para: [ifcDataUpperFolder, designId, designId, this.user ? this.user.id : '']
        }, "*");
      }

      gIfrmMobuilder.postMessage({
        functionName: "fSelectSidebarMenu",
        para: ["model", "", ""]
      }, "*");

      gIfrmMobuilder.postMessage({
        functionName: "fSidebarClose",
        para: []
      }, "*");

      this.isSimuleOpened = true;
      window.$('#ifr').css('height', this.isMobile ? '600px' : '1000px')
      window.$('#ifr').css('border', '1px solid #eee');
      window.$('#ifrSec').css('z-index', 1000);
      window.$('#bottomSpace').css('height', this.isMobile ? '50px' : '300px')
      window.scrollTo(0, 0);
    },
    simulateOffice(model) {
      if (this.currentDesignId != model.planId) {
        this.currentDesignId = model.planId;
      }
      if (this.currentModelId != model.planId) {
        this.currentModelId = model.planId;
        
        if(this.gltfModel) {
          this.gltfModel.destroy();
        }
        this.viewer.camera.eye = [-24,20,20]; // Eye position
        this.viewer.camera.look = [0, 0, 0]; // Look-at position
        this.viewer.camera.up = [0, 1, 0]; // Up vector
  
        this.gltfModel = this.gltfLoader.load({
          src: 'https://s3.' + this.s3region + '.amazonaws.com/' + this.s3bucket + '/' + model.bim.split('.')[0] + '.glb',
          edges: true,
          rotation: [0, 0, 0]
        });
  
        window.$('.sk-fading-circle:first').parent().css('top','50%');
      }
      this.isSimuleOpened = true;
      window.$('#my-canvas').css('height', this.isMobile ? '600px' : '1000px')
      window.$('#my-canvas').css('border', '1px solid #eee');
      window.$('#ifrSec').css('z-index', 1000);
      window.$('#bottomSpace').css('height', this.isMobile ? '50px' : '300px')
      window.scrollTo(0, 0);
    },
    showPlanInfo(model, planMode) {
      if (this.currentDesignId != model.planId) {
        this.currentDesignId = model.planId
      }
      this.planImg = planMode? model.plan.split(',') : model.render.split(',');
      this.isSimuleOpened = planMode? 1 : 2;
      window.$('#planInfo').css('height', this.isMobile ? (planMode? '600px' : '400px') : '900px')
      window.$('#planInfo').css('border', '1px solid #eee');
      window.$('#ifrSec').css('z-index', 1000);
      window.$('#bottomSpace').css('height', this.isMobile ? '50px' : '300px')
      window.scrollTo(0, 0);
    },
    closeSimule() {
      this.isSimuleOpened = false;
      window.$('#ifr').css('height', 0);
      window.$('#ifr').css('border', 0);
      window.$('#planInfo').css('height', 0);
      window.$('#planInfo').css('border', 0);
      window.$('#my-canvas').css('height', 0);
      window.$('#my-canvas').css('border', 0);
      window.$('#ifrSec').css('z-index', 1);
      window.$('#bottomSpace').css('height', '50px');
      this.planImg = [];
    },
    disableScroll() {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    },
    enableScroll() {
      document.body.style.overflow = ''; // Enable scrolling
    },
    showModulePlanModal() {
      if (!this.isMobileDevice) {
        window.$('#v-ModluePlan').modal('show');
      }
    },
    showHelpModal() {
      this.helpIndex == 0;
      window.$('#v-help').modal('show');
    },
    loadCospec() {
      switch (this.plan.q4_ans) {
        case '2':
          this.conditionStyle = '단층 (평지붕)';
          break;
        case '1':
          this.conditionStyle = '단층 (박공지붕/다락)';
          break;
        case '3':
          this.conditionStyle = '복층 (평지붕)';
          break;
        case '4':
          this.conditionStyle = '복층 (박공지붕)';
          break;
      }

      switch (this.plan.q3_2_ans) {
        case '1':
          this.conditionBath = 1;
          break;
        case '2':
          this.conditionBath = 1.5;
          break;
        case '3':
          this.conditionBath = 2;
          break;
        case '4':
          this.conditionBath = 2.5;
          break;
      }

      if (this.plan.q3_3_ans.includes('1'))
        this.conditionAncillary.push('파우더룸');
      if (this.plan.q3_3_ans.includes('2'))
        this.conditionAncillary.push('드레스룸');
      if (this.plan.q3_3_ans.includes('3'))
        this.conditionAncillary.push('욕실/화장실');
      if (this.plan.q3_3_ans.includes('4'))
        this.conditionAncillary.push('개별발코니');

      if (this.plan.q5_ans.includes('2'))
        this.conditionOthers.push('넓은 식당 공간');
      if (this.plan.q5_ans.includes('3'))
        this.conditionOthers.push('집 가운데 정원');
      if (this.plan.q5_ans.includes('4'))
        this.conditionOthers.push('카페 같은 거실');
      if (this.plan.q5_ans.includes('5'))
        this.conditionOthers.push('햇살 가득한 남향 공간');

      const url = 'https://www.inno-lab.co.kr/dfma/ModluePlan.application'
        + '?modelID=' 
        + this.suggestion[0].planId.split('-')[0].toLowerCase() + this.suggestion[0].planId.split('-')[1] + ','
        + this.suggestion[1].planId.split('-')[0].toLowerCase() + this.suggestion[1].planId.split('-')[1] + ','
        + this.suggestion[2].planId.split('-')[0].toLowerCase() + this.suggestion[2].planId.split('-')[1]
        + '&selectedIdx=' + (this.suggestion_ans == null ? 0 : this.suggestion_ans)
        + '&addr=' + encodeURIComponent(this.plan.addr)
        + '&condition=' + encodeURIComponent(this.conditionGen())

      console.log(url);
      location.href = url;
    },
    conditionGen() {
      if(this.plan.q1_ans == 1) {
        switch (this.plan.q4_ans) {
          case '2':
            this.conditionStyle = '단층 (평지붕)';
            break;
          case '1':
            this.conditionStyle = '단층 (박공지붕/다락)';
            break;
          case '3':
            this.conditionStyle = '복층 (평지붕)';
            break;
          case '4':
            this.conditionStyle = '복층 (박공지붕)';
            break;
        }
  
        switch (this.plan.q3_2_ans) {
          case '1':
            this.conditionBath = 1;
            break;
          case '2':
            this.conditionBath = 1.5;
            break;
          case '3':
            this.conditionBath = 2;
            break;
          case '4':
            this.conditionBath = 2.5;
            break;
        }
  
        if (this.plan.q3_3_ans.includes('1'))
          this.conditionAncillary.push('파우더룸');
        if (this.plan.q3_3_ans.includes('2'))
          this.conditionAncillary.push('드레스룸');
        if (this.plan.q3_3_ans.includes('3'))
          this.conditionAncillary.push('욕실/화장실');
        if (this.plan.q3_3_ans.includes('4'))
          this.conditionAncillary.push('개별발코니');
  
        if (this.plan.q5_ans.includes('2'))
          this.conditionOthers.push('넓은 식당 공간');
        if (this.plan.q5_ans.includes('3'))
          this.conditionOthers.push('집 가운데 정원');
        if (this.plan.q5_ans.includes('4'))
          this.conditionOthers.push('카페 같은 거실');
        if (this.plan.q5_ans.includes('5'))
          this.conditionOthers.push('햇살 가득한 남향 공간');
  
        return '면적: ' + this.plan.q2_ans[0] + '~' + this.plan.q2_ans[1] + '㎡, '
          + '주택 스타일: ' + this.conditionStyle + ', '
          + '방: ' + this.plan.q3_1_ans + '개, '
          + '화장실: ' + this.conditionBath + '개, '
          + '안방 부속실: ' + this.conditionAncillary + ', '
          + '기타 요구사항: ' + this.conditionOthers;
      }
      else if(this.plan.q1_ans == 2) {
        var coreType = '선택없음';
        switch(this.plan.core) {
          case 'U' :
            coreType = '위'
            break;
          case 'B' :
            coreType = '아래'
            break;
          case 'L' :
            coreType = '왼쪽'
            break;
          case 'R' : 
            coreType = '오른쪽'
            break;
        }

        return '면적: ' + this.plan.q2_ans[0] + '~' + this.plan.q2_ans[1] + '㎡, ' 
            + '층수: ' + this.plan.floors + '층, '
            + '지하층수: ' + this.plan.underground + '층, '
            + '코어위치: ' + coreType;
      }
      else {
        return '층수: ' + this.plan.floors + '층, '
            + '지하층수: ' + this.plan.underground + '층'
      }
    },
    queryStringGen(fieldName) {
      var rtn = ''
      this.plan[fieldName].forEach(item => {
        rtn += '&' + fieldName + '=' + item
      })
      return rtn
    },
    savePlan() {
      localStorage.setItem('activeSave', 1)
      localStorage.setItem('tempSuggestion', JSON.stringify(this.suggestion))
      localStorage.setItem('tempPlanSellection', JSON.stringify(this.plan))
      alert('추천설계안 임시 저장이 완료되었습니다.');
    },
    async onSuggestionClick() {
      var query = {}
      query.q1_ans = Number(this.$route.query.q1_ans);
      query.q2_ans = this.$route.query.q2_ans;
      query.q3_1_ans = this.$route.query.q3_1_ans;
      query.q3_2_ans = this.$route.query.q3_2_ans;
      query.q3_3_ans = this.$route.query.q3_3_ans;
      query.q4_ans = this.$route.query.q4_ans;
      query.q5_ans = this.$route.query.q5_ans;

      query.q2_isnecessary = this.$route.query.q2_isnecessary;
      query.q3_1_isnecessary = this.$route.query.q3_1_isnecessary;
      query.q3_2_isnecessary = this.$route.query.q3_2_isnecessary;
      query.q3_3_isnecessary = this.$route.query.q3_3_isnecessary;
      query.q4_isnecessary = this.$route.query.q4_isnecessary;
      query.q5_isnecessary = this.$route.query.q5_isnecessary;

      this.submitting = true;
      const isLoggedIn = await Auth.isLogedIn();
      if (!isLoggedIn) {
        if (confirm("로그인 후 생성하실 수 있습니다.\n로그인 화면으로 이동하시겠습니까?")) {
          localStorage.setItem('activeSave', 0)
          localStorage.setItem('tempSuggestion', JSON.stringify(this.suggestion))
          localStorage.setItem('tempPlanSellection', JSON.stringify(this.plan))
          this.$router.push({ path: '/member/login', query: { fromSuggestion: 1 } })
        } else {
          this.submitting = false;
          return false;
        }
      } else {
        var designs = [];
        if(this.suggestion_ans) {
          designs.push(this.suggestion[this.suggestion_ans].planId);
        }

        var purpose = '';
        var planId = '';
        switch (Number(this.plan.q1_ans)) {
          case 1: { purpose = '주거용 건물'; planId = designs[0] }
            break;
          case 2: { purpose = '사무용 건물'; planId = designs[0] }
            break;
          case 3: { purpose = '상업용 건물'; planId = 'CMC' }
            break;
          case 4: purpose = '그 외';
            break;
        }
        if (this.plan.q1_ans_addnl == 1) {
          purpose += ', 주거시설';
        }
        var addr = this.plan.addr;
        var buildingLandRatio = this.plan.buildingLandRatio;
        var floorAreaRatio = this.plan.floorAreaRatio;
        var floors = this.plan.floors;
        var underground = this.plan.underground;
        var dsgn_rqst = this.conditionGen();
        var prjNm = this.addrTokenizer(this.plan.addr_jibun) + ' ' + (purpose.indexOf(',') > 0 ? purpose.split(',')[0] : purpose) + ';' + addr;
        var imgPath1 = Number(this.plan.q1_ans) == 2? this.suggestion[this.suggestion_ans].imgPath1 : null;
        var imgPath2 = Number(this.plan.q1_ans) == 2? this.suggestion[this.suggestion_ans].imgPath2 : null;
        var plans =  Number(this.plan.q1_ans) == 2? this.suggestion[this.suggestion_ans].plan.split(',') : null

        var rtnval = await Api.post('/api/v1/project/create', {
          prjNm: prjNm, 
          planId: planId, 
          addr: addr, 
          purpose: purpose, 
          buildingLandRatio: buildingLandRatio, 
          floorAreaRatio: floorAreaRatio, 
          floors: floors, 
          floorsUnderground: underground, 
          dsgnRqst: dsgn_rqst, 
          pdImg1: imgPath1, 
          pdImg2: imgPath2, 
          plans: plans
        });
        this.submitting = false;
        if (rtnval.result) {
          location.href = '/project/dashboard';
        }
        else {
          alert(rtnval.errormsg);
        }
      }
    },

    addrTokenizer(addr) {
      if (addr) {
        if (addr.indexOf('동 ') > 0) {
          var addrHead = addr.split('동 ')[0];
          var token = addrHead.split(' ');
          return token[token.length - 1] + '동'
        }

        if (addr.indexOf('면 ') > 0) {
          addrHead = addr.split('면 ')[0];
          token = addrHead.split(' ');
          return token[token.length - 1] + '면'
        }

        if (addr.indexOf('읍 ') > 0) {
          addrHead = addr.split('읍 ')[0];
          token = addrHead.split(' ');
          return token[token.length - 1] + '읍'
        }

        token = addr.split(' ');
        return token.length > 0 ? (token[token.length - 2] + ' ' + token[token.length - 1]) : '';
      }
      else {
        return this.user.info.fullName + '님의';
      }

    },

    expandIframe(mode) {
      if (mode == 'expand') {
        this.ifrSecPosBak = window.$('#ifrSec').css('position');
        this.ifrHeightBak = window.$('#ifr').css('height');
        if (window.innerWidth < 768) {
          window.$('#ifrSec').css('width', '100%');
          window.$('#ifrSec').css('left', 0);
        }
        window.$('#ifrSec').css('margin-top', 0);
        window.$('#ifrSec').css('z-index', 1031);
        window.$('#ifrSec').css('position', 'fixed');
        window.$('#ifr').css('height', '100vh');
      }
      else if (mode == 'return') {
        if (window.innerWidth < 768) {
          window.$('#ifrSec').css('width', 'calc(100% + 60px)');
          window.$('#ifrSec').css('left', '-30px');
        }
        window.$('#ifrSec').css('margin-top', '20px');
        window.$('#ifrSec').css('z-index', 1);
        window.$('#ifrSec').css('position', this.ifrSecPosBak);
        window.$('#ifr').css('height', this.ifrHeightBak);
        this.ifrSecPosBak = '';
        this.ifrHeightBak = '';
      }
    },

    testExpand() {
      if (this.ifrSecPosBak) {
        this.expandIframe('return');
      }
      else {
        this.expandIframe('expand');
      }
    },

    //---------- 추가 (조주원) ---------------
    fGoto: function (toWhere) {
      router.push(toWhere);
      window.scrollTo(0, 0);
      //---------- 추가 여기까지 ---------------
    }
  }
};
</script>

<style>
/*------- 추가 여기부터 (조주원) ----------------------------*/
@import "../../assets/css/custom.css";


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/*------- 추가 여기까지 ------------------------------------*/

#plan_q3_3_all,
#plan_q5_all {
  display: inline-block;
}

#suggestion_all {
  display: inline-block;
}

.imgGroup {
  width: auto;
  min-height: 350px;
}

.imgGroup>img {
  width: 100%;
  max-height: 350px;
}

#txt1PlusBtn:disabled,
#txt2PlusBtn:disabled,
#txt3PlusBtn:disabled,
#txt1PlusBtn:disabled:hover,
#txt2PlusBtn:disabled:hover,
#txt3PlusBtn:disabled:hover {
  width: 90%;
  max-width: 300px;
  background-color: white;
  border: 1.5px solid lightgray;
  color: lightgray;
  font-size: 14px;
}

#txt1PlusBtn,
#txt2PlusBtn,
#txt3PlusBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 300px;
  height: 32px;
  margin-left: 8px;
  margin-left: 8px;
  background-color: white;
  border: 3px solid var(--sub-color);
  border-radius: 3px;
  color: var(--main-color);
}

#txt1PlusBtn:hover,
#txt2PlusBtn:hover,
#txt3PlusBtn:hover,
#txt1PlusBtn:focus,
#txt2PlusBtn:focus,
#txt3PlusBtn:focus {
  width: 90%;
  max-width: 300px;
  background-color: var(--main-color);
  border: 0px;
  color: white;
}

.inputTxt {
  margin: 10px 0px;
  width: 100%;
  height: 200px;
}

/*-------------------------------------------*/

input[type="radio"]:checked+.scale_none,
input[type="checkbox"]:checked+.scale_none {
  transform: scale(1.1);
}

input[type="radio"]:checked+.scale,
input[type="checkbox"]:checked+.scale {
  transform: scale(1.1);
  border: 3px solid #000;
  border-radius: 1rem;
  background-color: lightgray;
}

#ifrSec {
  margin-top: 20px;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.ifr {
  width: 100%;
  height: 1000px;
  background-color: #fff;
  border: 1px solid #eee;
  transition: 0.2s;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: #000;
  border-radius: 12px;
}

.carousel-indicators button {
  background-color: #000 !important;
}

@media (max-width:479.98px) {
  #ifrSec {
    width: calc(100% + 60px);
    left: -30px;
  }

  .ifr {
    height: 600px
  }
}

@media (min-width:480px) and (max-width:767.98px) {
  #ifrSec {
    width: calc(100% + 60px);
    left: -30px;
  }

  .ifr {
    height: 600px
  }
}

@media (min-width:768px) and (max-width:1023.98px) {}

/* component style */
.vue-slider-disabled .vue-slider-process {
  background-color: #a7a7a7;
}

.vue-slider-disabled .vue-slider-dot-handle {
  border-color: #a7a7a7;
}

.vue-slider-disabled .vue-slider-mark-step-active {
  box-shadow: 0 0 0 2px #a7a7a7;
}

/* rail style */
.vue-slider-rail {
  background-color: whitesmoke;
  border-radius: 15px;
  transition: background-color 0.3s;
}

.vue-slider:hover .vue-slider-rail {
  background-color: #e1e1e1;
}

/* process style */
.vue-slider-process {
  background-color: lightgray;
  border-radius: 15px;
  transition: background-color 0.3s;
}

.vue-slider:hover .vue-slider-process {
  background-color: #4e0000;
}

/* mark style */
.vue-slider-mark-step {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #e8e8e8;
  background-color: #fff;
}

.vue-slider-mark-step-active {
  box-shadow: 0 0 0 2px lightgray;
}

.vue-slider:hover .vue-slider-mark-step-active {
  box-shadow: 0 0 0 2px #4e0000;
}

.vue-slider-mark-label {
  font-size: 12px;
  white-space: nowrap;
}

/* dot style */
.vue-slider-dot-handle {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid lightgray;
  box-sizing: border-box;
  transition: box-shadow 0.3s, border-color 0.3s;
}

.vue-slider:hover .vue-slider-dot-handle {
  border-color: #4e0000;
}

.vue-slider-dot-handle-focus {
  border-color: #2f0000;
  box-shadow: 0 0 0 5px lightgray6a;
}

.vue-slider:hover .vue-slider-dot-handle-focus {
  border-color: #2f0000;
}

.vue-slider-dot-handle:hover {
  border-color: #2f0000;
}

.vue-slider:hover .vue-slider-dot-handle:hover {
  border-color: #2f0000;
}

.vue-slider-dot-handle-disabled {
  cursor: not-allowed;
  border-color: #ddd !important;
}

.vue-slider-dot-tooltip {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.vue-slider-dot-tooltip-inner {
  font-size: 14px;
  white-space: nowrap;
  padding: 6px 8px;
  color: #fff;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.75);
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transform: scale(0.9);
  transition: transform 0.3s;
}

.vue-slider-dot-tooltip-inner::after {
  content: "";
  position: absolute;
}

.vue-slider-dot-tooltip-inner-top::after {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-top-color: inherit;
}

.vue-slider-dot-tooltip-inner-bottom::after {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-bottom-color: inherit;
}

.vue-slider-dot-tooltip-inner-left::after {
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-left-color: inherit;
}

.vue-slider-dot-tooltip-inner-right::after {
  right: 100%;
  top: 50%;
  transform: translate(0, -50%);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-right-color: inherit;
}

.vue-slider-dot-tooltip-inner-top {
  transform-origin: 50% 100%;
}

.vue-slider-dot-tooltip-inner-bottom {
  transform-origin: 50% 0;
}

.vue-slider-dot-tooltip-inner-left {
  transform-origin: 100% 50%;
}

.vue-slider-dot-tooltip-inner-right {
  transform-origin: 0% 50%;
}

.vue-slider-dot:hover .vue-slider-dot-tooltip,
.vue-slider-dot-tooltip-show {
  opacity: 1;
  visibility: visible;
}

.vue-slider-dot:hover .vue-slider-dot-tooltip .vue-slider-dot-tooltip-inner,
.vue-slider-dot-tooltip-show .vue-slider-dot-tooltip-inner {
  transform: scale(1);
}

/*# sourceMappingURL=antd.css.map */

.scrollSpy {
  position: fixed;
  bottom: 50px;
  display: block;
  z-index: 999;
}

</style>

<style scoped>
.custom-radio .custom-control-label {
  font-size: .85rem;
}
.custom-radio .custom-control-label::before {
  top: unset;
}
.custom-radio .custom-control-label::after {
  top: 0;
}
</style>