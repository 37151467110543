<template>
     <div class="d-flex">
        <LNB :user="user"/>
        <div class="ca_main">
            <div class="x_div_center">
                <div class="pg_stage">

                    <div class="x_div_center pt-5">
						<div class="" style="width:70%"><span class="fkr-set1 fs-16" style="color:#333333">{{prjNm}}</span></div>
						<div class="text-end" style="width:30%"><span class="fkr-set2" style="color:#484848">설계 > <span class="fw-bold">{{prcStpNm}}</span></span></div>
					</div>

                    <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                    <div style="height:80px"></div>

                    <div class="pg_m_set" style="margin-top: 20px" v-if="fetching">
                        <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                    </div>
                    <div v-else>
                        <div class="table-responsive" style=" border-radius:8px">
                            <table class="table-borderless w-100">
                            <tr style="height:55px; background-color:#eaeaea; border-bottom:1px solid #c9c9c9">
                                <td class="text-center br_0" width="50%"><span class="fkr-set1 fs-09">허가 접수</span></td>
                                <td class="text-center" width="50%"><span class="fkr-set1 fs-09">{{ approvalState }}</span></td>
                            </tr>
                            <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                                <td class="text-center br_0" v-if="(user.info.usrRole == 'OW' || user.info.usrRole == 'SL') || (user.info.usrRole == 'DS' && prmtRcv)">
                                    <span class="fkr-set2">{{ prmtRcv? prmtRcv.split('T')[0] : '' }}</span>
                                </td>
                                <td class="text-center br_0" v-else-if="(user.info.usrRole == 'DS' && !prmtRcv)">
                                    <Datepicker @update-date="setRcvDate" class="form-control ff-eng1 text-center" style="width:70%; left: 15%; position: relative;"/>
                                </td>
                                <td class="text-center" v-if="(user.info.usrRole == 'OW' || user.info.usrRole == 'SL') || (user.info.usrRole == 'DS' && prmtCmpltn)">
                                    <span class="fkr-set2">{{ prmtCmpltn? prmtCmpltn.split('T')[0] : '' }}</span>
                                </td>
                                <td class="text-center" v-else-if="(user.info.usrRole == 'DS' && !prmtCmpltn)">
                                    <Datepicker @update-date="setCmpltnDate" class="form-control ff-eng1 text-center" style="width:70%; left: 15%; position: relative;"/>
                                </td>
                            </tr>
                            </table>
                        </div>
                    </div>

                    <div class="x_div_end" v-if="user.info.usrRole == 'DS'">
                        <div class="py-2 pe-1"><button class="fkr-set2 fs-095" @click="saveDates()" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LNB from '@/components/LNB.vue'
import Datepicker from '@/components/Datepicker.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'PrcStpDsgnApproval',
    components: {
		LNB,
        Datepicker
	},
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            prcTree: null,
            prjId: null,
            prjNm: '',
            prcId: null,
            prcStpId: null,
            prcStpNm: '',
            status: 0,
            prmtCmpltn: null,
            prmtCmpltnInput: '',
            prmtPrgr: null,
            prmtRcv: null,
            prmtRcvInput: '',
            approvalState: '',
            fetching: true,
            submitting: false
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           if(this.$route.query.prjId){
               this.$router.push({ path: '/member/login', query: { fromTalk: encodeURIComponent("/project" + location.href.split("/project")[1]) } });
           }
           else {
               location.href = '/member/login'
           }
        }

        if(this.user.info.usrRole != 'OW' && this.user.info.usrRole != 'SL' && this.user.info.usrRole != 'DS') {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }

        if(this.$route.query.prjId){
            var rtnval = await Api.get('/api/v1/process/list/prc/tree?prjId=' + this.$route.query.prjId);
            if(rtnval.result) {
                sessionStorage.setItem('prcTree', JSON.stringify(rtnval.data));
                sessionStorage.setItem('prjId', this.$route.query.prjId);
                sessionStorage.setItem('prjNm', rtnval.data.prjNm);
                var rtnvalue = await Api.get('/api/v1/user/list/authorized/prc?usrRole=' + this.user.info.usrRole);
                if(rtnvalue.result) {
                    var accessiblePrc = [];
                    rtnvalue.data.processes.forEach((prc) => {
                        accessiblePrc.push(prc.code);
                    })
                    sessionStorage.setItem('accessiblePrc', JSON.stringify(accessiblePrc));
                    location.href = location.href.split("?")[0];
                }
                else {
                    alert(rtnvalue.errormsg)
                }
            }
            else {
                alert(rtnval.errormsg)
            }
        }
        else {
            this.prjId = sessionStorage.getItem('prjId');
            this.prjNm = sessionStorage.getItem('prjNm');
            this.prcTree = JSON.parse(sessionStorage.getItem('prcTree'));
        }

        this.prcTree.processList.forEach((prc) => {
            if(prc.code == 'DS') {
                prc.prcStpList.forEach((prcStp) => {
                    if(prcStp.code == 'DA') {
                        this.prcStpNm = prcStp.name;
                    }
                })
            }
        })

        if(this.prjId == 'null') {
            alert('My Projects 페이지에서 프로젝트를 선택하셔야 합니다.');
            location.href = '/project/dashboard'
        }

        var rtnval = await Api.post('/api/v1/process/get', {prjId: this.prjId, prcCd: 'DS'});
        if(rtnval.result) {
            this.prcId = rtnval.data.prcId;
        }
        else {
            alert(rtnval.errormsg)
        }

        await this.fetchApprovalInfo();
        this.fetching = false;
    },
    methods: {
        async fetchApprovalInfo() {
            const rtnvalue = await Api.post('/api/v1/prcess/step/get', {prjId: this.prjId, code: 'DA'});
            if(rtnvalue.result) {
                if(rtnvalue.data.prcStpId) {
                    this.prcStpId = rtnvalue.data.prcStpId;
                    this.prmtRcv = rtnvalue.data.strtDt;
                    this.prmtCmpltn = rtnvalue.data.endDt;
                    this.status = rtnvalue.data.stts;
                }

                if(this.prmtRcv && !this.prmtCmpltn) {
                    this.approvalState = '허가 진행중'
                }
                else if(this.prmtRcv && this.prmtCmpltn) {
                    this.approvalState = '허가 완료'
                }
                if(this.user.info.usrRole == 'DS') {
                    this.approvalState = '허가 완료'
                }
            }
            else {
                alert(rtnvalue.errormsg);
            }
        },
        setRcvDate(date) {
            this.prmtRcvInput = new Date(date).toISOString();
        },
        setCmpltnDate(date) {
            this.prmtCmpltnInput = new Date(date).toISOString();
        },
        async saveDates() {
            if(!this.prmtRcv && this.prmtCmpltnInput) {
                alert('허가 접수일을 먼저 저장하신 후\n허가 완료일을 저장해주세요.');
                return 1;
            }

            var prmtRcv = this.prmtRcvInput? this.prmtRcvInput : this.prmtRcv;
            var prmtCmpltn = this.prmtCmpltnInput? this.prmtCmpltnInput : this.prmtCmpltn;
            this.submitting = true;
            const rtnval = await Api.post('/api/v1/prcess/step/set', {prcStpId: this.prcStpId, prcId: this.prcId, code: 'DA', strtDt: prmtRcv, endDt: prmtCmpltn, stts: prmtCmpltn? 2 : (prmtRcv? 1 : 0)});
            if(rtnval.result) {
                await this.fetchApprovalInfo();
            }
            else {
                alert(rtnval.errormsg);
            }
            this.submitting = false;
        }
    }
}
</script>