import Login from '@/views/member/Login.vue'
import Signin from '@/views/member/Signin.vue'
import Profile from '@/views/member/Profile.vue'
import Modify from '@/views/member/Modify.vue'
import ModifyPass from '@/views/member/ModifyPass.vue'
import FindId from '@/views/member/FindId.vue'
import ResetPass from '@/views/member/ResetPass.vue'

export default [
    {
    path: '/member/login',
    name: 'Login',
    component: Login
    },
    {
    path: '/member/signin',
    name: 'Signin',
    component: Signin
    },
    {
    path: '/member/profile',
    name: 'Profile',
    component: Profile
    },
    {
    path: '/member/modify',
    name: 'Modify',
    component: Modify
    },
    {
    path: '/member/modifypass',
    name: 'ModifyPass',
    component: ModifyPass
    },
    {
    path: '/member/findid',
    name: 'FindId',
    component: FindId
    },
    {
    path: '/member/resetpass',
    name: 'ResetPass',
    component: ResetPass
    }
]