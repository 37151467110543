import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import './registerServiceWorker'
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/m3systems.css'
import '@/assets/css/m3_me.css'
import '@/assets/plugin/fontawesome-free-6.2.0-web/css/all.min.css'
import "@/assets/scss/globals.scss"
import "@/assets/scss/common.scss"
import "@/assets/scss/style.scss"
import "@/assets/scss/mobile.scss"
import Vuelidate from 'vuelidate'

// Session Storage에서 menuId 로드
const savedMenuId = sessionStorage.getItem('menuId');
if (savedMenuId) {
  store.commit('setMenuId', savedMenuId);
}

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(Vuelidate);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
