<template>
    <div id="mobileQuestion" class="mobile inquiry">
        <div class="group">
            <div class="text-wrapper">문의하기</div>
            <div class="text-wrapper-2">최선을 다해 도와드리겠습니다</div>
        </div>
        <div class="contents">
            <div class="register">
                <form class="gform" method="POST" data-email="info@m3.co.kr"
                      action="https://script.google.com/macros/s/AKfycbx81-4k1Dgw8805gsfnT40h4E03YVdcynhv0wFKgeL3VIwO7NLsOfaMss8c1dMJ_0Ou4w/exec"
                      target='blankifr' id="frm" ref="form">
                    <div class="subject">
                        <div>
                            <div class="title">상담문의</div>
                            <img class="img" src="@/assets/img/inquiry/line.svg" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="th">
                            <span class="name">이름</span>
                        </div>
                        <td class="td">
                            <input v-model="state.name" type="text" name="name" required placeholder="이름을 입력해주세요"/>
                        </td>
                    </div>
                    <div class="row">
                        <div class="th">
                            <span class="name">연락처</span>
                        </div>
                        <div class="td">
                            <input v-model="state.phone" type="text" name="phone" required
                                   placeholder="전화번호를 입력해주세요(기호, 공백 제외)"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="th">
                            <span class="name">이메일</span>
                        </div>
                        <div class="td">
                            <input v-model="state.email" type="text" name="email" required placeholder="이메일을 입력해주세요"/>
                        </div>
                    </div>
                    <div class="row line">
                        <div class="th">
                            <span class="name">건축용도</span>
                        </div>
                        <div class="td">
                            <label>
                                <input v-model="state.category" type="radio" name="category" value="1"/>
                                <span class="radio">단독주택</span>
                            </label>
                            <label>
                                <input v-model="state.category" type="radio" name="category" value="2"/>
                                <span class="radio">공동주택</span>
                            </label>
                            <label>
                                <input v-model="state.category" type="radio" name="category" value="3"/>
                                <span class="radio">상업용</span>
                            </label>
                            <label>
                                <input v-model="state.category" type="radio" name="category" value="4"/>
                                <span class="radio">사무용</span>
                            </label>
                            <label>
                                <input v-model="state.category" type="radio" name="category" value="5"/>
                                <span class="radio">학교시설</span>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="th lg">
                            <span class="name">건축희망모델</span>
                        </div>
                        <div class="td">
                            <input v-model="state.desiredModel" type="text" name="desireModel"
                                    placeholder="주거용 건축상담의 경우 필요에 따라 기입(예: CNK-01)"/>
                        </div>
                    </div>
                    <div class="row line">
                        <div class="th">
                            <span class="name">구조</span>
                        </div>
                        <div class="td">
                            <label>
                                <input v-model="state.material" type="radio" name="material" value="강구조"/>
                                <span class="radio">강구조</span>
                            </label>
                            <label>
                                <input v-model="state.material" type="radio" name="material" value="목구조"/>
                                <span class="radio">목구조</span>
                            </label>
                        </div>
                    </div>
                    <div class="row line">
                        <div class="th">
                            <span class="name">건축예상평수</span>
                        </div>
                        <div class="td">
                            <label>
                                <input v-model="state.grossArea" type="radio" name="grossArea" value="20평이하"/>
                                <span class="radio">20평이하</span>
                            </label>
                            <label>
                                <input v-model="state.grossArea" type="radio" name="grossArea" value="20평대"/>
                                <span class="radio">20평대</span>
                            </label>
                            <label>
                                <input v-model="state.grossArea" type="radio" name="grossArea" value="30평대"/>
                                <span class="radio">30평대</span>
                            </label>
                            <label>
                                <input v-model="state.grossArea" type="radio" name="grossArea" value="40평대"/>
                                <span class="radio">40평대</span>
                            </label>
                            <label>
                                <input v-model="state.grossArea" type="radio" name="grossArea" value="50평대"/>
                                <span class="radio">50평대</span>
                            </label>
                            <label>
                                <input v-model="state.grossArea" type="radio" name="grossArea" value="60평이상"/>
                                <span class="radio">60평이상</span>
                            </label>
                        </div>
                    </div>
                    <div class="row line">
                        <div class="th">
                            <span class="name">건축예정시기</span>
                        </div>
                        <div class="td">
                            <label>
                                <input v-model="state.schedulePlan" type="radio" name="schedulePlan" value="6개월이내"/>
                                <span class="radio">6개월이내</span>
                            </label>
                            <label>
                                <input v-model="state.schedulePlan" type="radio" name="schedulePlan" value="1년이내"/>
                                <span class="radio">1년이내</span>
                            </label>
                            <label>
                                <input v-model="state.schedulePlan" type="radio" name="schedulePlan" value="2년이상"/>
                                <span class="radio">2년이상</span>
                            </label>
                        </div>
                    </div>
                    <div class="row line">
                        <div class="th">
                            <span class="name">건축예상금액</span>
                        </div>
                        <div class="td">
                            <label>
                                <input v-model="state.expectedCost" type="radio" name="expectedCost" value="1-2억"/>
                                <span class="radio">1-2억</span>
                            </label>
                            <label>
                                <input v-model="state.expectedCost" type="radio" name="expectedCost" value="2-5억"/>
                                <span class="radio">2-5억</span>
                            </label>
                            <label>
                                <input v-model="state.expectedCost" type="radio" name="expectedCost" value="5-10억"/>
                                <span class="radio">5-10억</span>
                            </label>
                            <label>
                                <input v-model="state.expectedCost" type="radio" name="expectedCost" value="10억이상"/>
                                <span class="radio">10억 이상</span>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="block">
                            <span class="name">상담내용</span>
                            <textarea v-model="state.content" name="content" placeholder="상담내용을 자세하게 작성해주세요."/>
                        </div>
                    </div>
                    <div class="row solid">
                        <div class="block">
                            <div class="box">
                                <label>
                                    <input type="checkbox" v-model="state.privacyConsent" value="true" required>
                                    <span class="checkbox">개인정보 수집 및 이용에 동의합니다.</span>
                                </label>
                            </div>
                            <div class="box">
                                <span @click="showPrivecyPolicy">개인정보활용 전문보기</span>
                                <img class="icon" src="@/assets/img/button/icon1.svg"/>
                            </div>
                            <div class="box left">
                                <label>
                                    <input :checked="state.receiveTextReply === 'Y'" v-model="state.receiveText" type="checkbox"
                                           name="mobileMessage" value="문자가능"/>
                                    <span class="checkbox">문자로 답변을 받겠습니다.</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="btn-area" style="width: 100%;">
                        <button class="submit" type="button" @click="onSubmit"><span>상담신청<i v-if="submitting" class='fa fa-spinner fa-spin' style="margin-left:8px"></i></span></button>
                    </div>
                </form>
            </div>
        </div>
        <privacy-policy v-if="isShowPrivecyPolicy" @close="closePrivecyPolicy"/>
        <iframe name='blankifr' style='display:none;'></iframe>
    </div>
</template>

<script>
import store from '@/store'
import Api from '@/api'
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';

export default {
    name: 'MobileQuestion',
    components: {PrivacyPolicy},
    data() {
        return {
            questionType: 0,
            state: {
                name: '', // 이름
                phone: '', // 연락처
                email: '', // 이메일
                content: '', // 내용
                desiredModel: null, // 건축희망모델
                grossArea: null, // 건축예상평수
                schedulePlan: null, // 건축예정시기
                expectedCost: null, //건축예상금액
                material: null, // 구조
                category: 1, // 건축용도
                privacyConsent: false, // 개인정보 수집 및 이용 동의 여부
                receiveTextReply: 'N', // 문자 답변 여부
                receiveText: false
            },
            submitting: false,
            isShowPrivecyPolicy: false
        }
    },
    mounted() {
        if(this.$route.query.id) {
            this.state.desiredModel = this.$route.query.id;
            if(store.state.search) {
                if(store.state.search.selectedMaterials.length == 1) {
                    switch(store.state.search.selectedMaterials[0]) {
                        case "2" : this.state.material = "강구조"
                            break;
                        case "1" : this.state.material = "목구조"
                            break;
                    }
                }
                if(store.state.search.selectedAreas.length > 0) {
                    var index = store.state.search.selectedAreas.length -1
                    switch(store.state.search.selectedAreas[index]) {
                        case "01" : this.state.grossArea = "20평이하"
                            break;
                        case "02" : this.state.grossArea = "20평이하"
                            break;
                        case "03" : this.state.grossArea = "20평대"
                            break;
                        case "04" : this.state.grossArea = "30평대"
                            break;
                        case "05" : this.state.grossArea = "40평대"
                            break;
                    }
                }
                if(store.state.search.maxCost) {
                    switch(parseInt(Math.ceil(store.state.search.maxCost / 100000000), 0)) {
                        case 1 :
                        case 2 : this.state.expectedCost = "1-2억"
                            break;
                        case 3 :
                        case 4 :
                        case 5 : this.state.expectedCost = "2-5억"
                            break;
                        case 6 :
                        case 7 :
                        case 8 :
                        case 8 :
                        case 10 : this.state.expectedCost = "5-10억"
                            break;
                        default : this.state.expectedCost = "10억이상"
                            break;
                    }
                }
            }
        }
    },
    methods: {
        showPrivecyPolicy() {
            this.isShowPrivecyPolicy = true;
        },
        closePrivecyPolicy() {
            this.isShowPrivecyPolicy = false;
        },
        onPrivacyConsent(event) {
            const checked = event.target.checked;
            this.state.privacyConsent = checked ? 'Y' : 'N';
        },
        onReceiveTextReply(event) {
            const checked = event.target.checked;
            this.state.receiveTextReply = checked ? 'Y' : 'N';
        },
        async onSubmit() {
            if (this.validation()) {
                const content = this.state.content.replace(/\n/g, '<br/>') + (this.state.receiveText? '<br><br>※ 문자로 답변받기를 원하시는 고객입니다.' : '')
                var request = {
                    email: this.state.email,
                    fullName: this.state.name,
                    phone: this.state.phone,
                    desc: content,
                    ctgry: this.state.category,
                    desiredModel: this.state.desiredModel,
                    grossArea: this.state.grossArea,
                    schedulePlan: this.state.schedulePlan,
                    expectedCost: this.state.expectedCost,
                    material: this.state.material,
                };
                this.submitting = true;
                var rtnval = await Api.post('/api/v1/admin/usr/mng/send/question', request);

                if(rtnval.result) {
                    alert("제출되었습니다. 빠른 시일 내에 " + (!this.state.receiveText? "메일 혹은 전화로 " : "") + "답변 드리겠습니다.");
                    store.commit('setMenuId', null);
                    location.href = '/';
                }
                else {
                    alert(rtnval.errormsg);
                }
                this.submitting = false;
            }
        },
        validation() {
            if (!this.state.name) {
                alert('이름은 필수 입력사항입니다.');
                return false;
            }
            if (!this.state.phone) {
                alert('연락처는 필수 입력사항입니다.');
                return false;
            }
            if (!this.state.email) {
                alert('이메일은 필수 입력사항입니다.');
                return false;
            }
            if (this.state.content == '') {
                alert('문의 내용을 입력해주세요.');
                return false;
            }
            if (!this.state.privacyConsent) {
                alert('이용약관 및 개인정보처리방침은 필수 동의사항입니다.');
                return false;
            }
            return true;
        }
    }
}
</script>

<style scoped>
.title {
    display: unset;
    justify-content: unset;
    align-items: unset;
    height: unset;
}
.row > * {
    width: unset;
}
</style>