<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">사이트관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>사업현황 프로젝트 목록</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <!-- <span class="fkr-set2 fs-10" style="padding: 5px">{{ currentCtgry }} 게시물 수: {{ projectList.length }} 건</span> -->
            <!-- <select class="custom-select ff-eng1 fs-085" v-model="pageSize" @change="switchPageSize()" style="width:120px; height:37px; margin-left: 20px">
                <option value="10">페이지당 10개</option>
                <option value="20">페이지당 20개</option>
            </select> -->

            <select class="custom-select ff-eng1 fs-085" v-model="menuFilter" @change="switchMenu()" style="width:120px; height:37px; margin-left: 20px">
              <option v-for="(item, i) in menuList" :key="i" :value="item.id">{{ item.menuName }}</option>
            </select>
            <div class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="setMenu(0)" style="width:60px; height:32px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">추가</button></div>
            <div v-if="menuFilter" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="setMenu(0)" style="width:60px; height:32px; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">수정</button></div>
            <div v-if="menuFilter" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="removeMenu()" style="width:60px; height:32px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제</button></div>
            
            <select class="custom-select ff-eng1 fs-085" v-model="ctgryFilter" @change="switchCtgry()" style="width:120px; height:37px; margin-left: 20px">
              <option v-for="(item, i) in ctgryList" :key="i" :value="item.id">{{ item.ctgryName }}</option>
            </select>
            <div class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="setCtgry(0)" style="width:60px; height:32px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">추가</button></div>
            <div v-if="ctgryFilter" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="setCtgry(1)" style="width:60px; height:32px; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">수정</button></div>
            <div v-if="ctgryFilter" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="removeCtgry()" style="width:60px; height:32px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제</button></div>
            
            <div class="ps-2" :style="'margin-left: auto; padding-left: ' + calcPaddingSize() + ' !important;'"><button class="fkr-set2 fs-095" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;" @click="editPrj(null)">새 프로젝트</button></div>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findArticle()"><i class="fa-solid fa-magnifying-glass"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="findArticle()" style="border:0; width:170px; height:30px; padding:0 .3rem" placeholder="제목 검색"></div>
              </div>
            </div>
          </div>
          


            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">메뉴</span></div>
                <div class="x_div_center" style="width:25%"><span class="fkr-set1 fs-9">제목</span></div>
                <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">하위게시물</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">분류</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">등록일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">편집</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in projectListPaged" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.menu.menuName}}</span></div>
                  <div class="x_div_center m_line" style="width:25%; justify-content: left; padding-left: 20px;"><span class="fkr-set2 fs-9">{{item.sbjct}}</span></div>
                  <div class="x_div_center m_line" style="width:30%">
                    <select class="custom-select ff-eng1 fs-085" v-model="recentId" @change="editArticle(item.recentPackageId)" style="width:85%; height:36px; padding: 1px 0 1px 5px; margin:0">
                      <option :value="recent.recentId" v-for="(recent, kintex) in item.recentList" :key="kintex">{{ recent.sbjct }}</option>
                    </select>
                  </div>
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9">{{item.ctgry.ctgryName}}</span></div>
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9">{{item.crtDt? item.crtDt.split('T')[0] : ''}}</span></div>
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9"><a :href="'/admin/site/recent-edit?projectId=' + item.recentPackageId"><i class="fas fa-pen" style="margin-right:15px"/></a><i class="fa fa-edit" style="cursor:pointer" @click="editPrj(item)"/></span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; renderList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>

      <div id="editPrj" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:10001;">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">

            <div class="w-100">
              <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">프로젝트 편집</span></div>
                <div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()" style="color:#4e0000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
              </div>

              <div class="x_div_center py-2 px-4">
                <div class="w-100">
                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">프로젝트 제목</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:460px; height:36px" v-model="sbjctEditting"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">프로젝트 메뉴</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <select class="custom-select ff-eng1 fs-085" v-model="menuEditing" style="width:180px; height:36px;">
                          <option v-for="(item, i) in menuList" :key="i" :value="item.id">{{ item.menuName }}</option>
                        </select>
                      </div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">프로젝트 분류</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <select class="custom-select ff-eng1 fs-085" v-model="ctgryEditing" style="width:180px; height:36px;">
                          <option v-for="(item, i) in ctgryList" :key="i" :value="item.id">{{ item.ctgryName }}</option>
                        </select>
                      </div>
                  </div>
                </div>
              </div>

              <div class="x_div_center py-4">
                <div class="px-2" v-if="editMode"><button @click="removePrj()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">삭제</span></button></div>
                <div class="px-2"><button @click="closeModal()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
                <div class="px-2"><button @click="setPrj()" class="x_div_center" :disabled="submitting" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">{{ editMode? '수정' : '등록' }}<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
              </div>

              <div style="height:20px"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "RecentList",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: true,
        submitting: false,
        searchWord: '',
        ctgryList: [],
        menuList: [],
        projectList:[],
        projectListPaged: [],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        recentId: null,
        ctgryFilter: '',
        menuFilter: '',
        ctgryEditing: null,
        menuEditing: null,
        sbjctEditting: '',
        prjEditing: null,
        recentList: [],
        editMode: false
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    const rtnval = await Api.get('/api/v1/recent/ctgry/list');
    if(rtnval.result) {
        this.ctgryList = rtnval.data;
        this.ctgryList = [{id: '', ctgryName: '분류', ctgryNameEn: 'Category', crtDt: null}].concat(this.ctgryList);
        const rtnvalue = await Api.get('/api/v1/recent/menu/list');
        if(rtnvalue.result) {
            this.menuList = rtnvalue.data;
            this.menuList = [{id: '', menuName: '메뉴', menuNameEn: 'Menu', crtDt: null}].concat(this.menuList);
            await this.fetchProjects(null, null, null);
            this.setPaging();
            this.renderList();
        }
        else {
            alert(rtnvalue.errormsg);
        }
    }
    else {
        alert(rtnval.errormsg);
    }

    if(this.$route.query.projectId) {
      if(this.$route.query.projectId == '0') {
        this.ctgryEditing = null;
        this.menuEditing = this.$route.query.menuId;
        this.sbjctEditting = '';
        this.prjEditing = null;
        this.editMode = false;
        window.$('#editPrj').modal('show');
      }
      else {
        this.projectList.forEach((prj) => {
          if(String(prj.recentPackageId) == this.$route.query.projectId) {
            this.editPrj(prj);
          }
        })
      }
      history.replaceState({}, null, location.pathname);
    }
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async fetchProjects(ctgryId, menuId, keyword) {
      this.fetching = true;
      this.projectList = [];
      const returnvalue = await Api.post('/api/v1/recent/find/project/list', {asc: false, recentCtgryId: ctgryId, recentMenuId: menuId, keyword: keyword});
      if(returnvalue.result) {
          this.projectList = returnvalue.data.resultList;
          this.projectList.forEach((prj) => {
            prj.recentList.push({recentId: null, sbjct: '게시물 수: ' + prj.recentList.length})
          })
      }
      else {
          alert(returnvalue.errormsg);
      }
      this.fetching = false;
    },

    async findArticle() {
      await this.fetchProjects(null, null, this.searchWord);
      this.setPaging();
      this.renderList();
    },

    renderList() {
      this.projectListPaged = [];
      for(var j = (this.currentPage-1) * this.pageSize; j < (this.projectList.length > this.currentPage * this.pageSize? this.currentPage * this.pageSize : this.projectList.length); j++) {
        this.projectListPaged.push(this.projectList[j]);
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.projectList.length / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    async switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        this.setPaging();
        this.renderList();
      }
    },

    calcPaddingSize() {
      if(this.ctgryFilter && this.menuFilter) {
        return '0';
      }
      else if(this.ctgryFilter || this.menuFilter) {
        return '100px';
      }
      else {
        return '250px';
      }
    },

    async switchPageSize() {
      this.currentPage = 1;
      this.setPaging();
      this.renderList();
    },

    async switchCtgry() {
      this.currentPage = 1;
      await this.fetchProjects(this.ctgryFilter, this.menuFilter, null);
      this.setPaging();
      this.renderList();
    },

    async switchMenu() {
      this.currentPage = 1;
      await this.fetchProjects(this.ctgryFilter, this.menuFilter, null);
      this.setPaging();
      this.renderList();
    },

    async setCtgry(option) {
      var ctgryName = prompt((option? '수정할' : '등록할') + ' 카테고리명을 입력하세요.');
      var ctgryNameEn = prompt((option? '수정할' : '등록할') + ' 카테고리명(영문)을 입력하세요.');
      if(!ctgryName || !ctgryNameEn) {
        alert('카테고리명 및 카테고리 영문명은 필수입니다.')
        return;
      }

      const rtnvalue = await Api.post('/api/v1/recent/ctgry/set', {
        ctgryName: ctgryName,
        ctgryNameEn: ctgryNameEn,
        id: option? this.ctgryFilter : null
      })
      if(rtnvalue.result) {
        alert('정상적으로 등록되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },

    async setMenu(option) {
      var menuName = prompt((option? '수정할' : '등록할') + ' 메뉴명을 입력하세요.');
      var menuNameEn = prompt((option? '수정할' : '등록할') + ' 메뉴명(영문)을 입력하세요.');
      if(!menuName || !menuNameEn) {
        alert('메뉴명 및 메뉴 영문명은 필수입니다.')
        return;
      }

      const rtnvalue = await Api.post('/api/v1/recent/menu/set', {
        menuName: menuName,
        menuNameEn: menuNameEn,
        id: option? this.menuFilter : null
      })
      if(rtnvalue.result) {
        alert('정상적으로 등록되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },

    async removeCtgry() {
      if(this.projectList.length > 0) {
        alert('해당 카테고리에 속한 프로젝트가 존재합니다.\n카테고리를 삭제할 수 없습니다.')
        return;
      }

      const rtnvalue = await Api.get('/api/v1/recent/ctgry/delete?ctgryId=' + this.ctgryFilter)
      if(rtnvalue.result) {
        alert('정상적으로 삭제되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },

    async removePrj() {
      if(this.recentList.length > 1) {
        alert('해당 프로젝트에 속한 게시물이 존재합니다.\n프로젝트를 삭제할 수 없습니다.')
        return;
      }

      const rtnvalue = await Api.get('/api/v1/recent/project/delete?recentPackageId=' + this.prjEditing)
      if(rtnvalue.result) {
        alert('정상적으로 삭제되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },
    
    editPrj(prj) {
      if(prj == null) {
        this.ctgryEditing = null;
        this.menuEditing = null;
        this.sbjctEditting = '';
        this.prjEditing = null;
        this.editMode = false;
        this.recentList = [];
      }
      else {
        this.ctgryEditing = prj.ctgry.id;
        this.menuEditing = prj.menu.id;
        this.sbjctEditting = prj.sbjct;
        this.prjEditing = prj.recentPackageId;
        this.recentList = prj.recentList;
        this.editMode = true;
      }
      window.$('#editPrj').modal('show');
    },

    closeModal() {
			window.$('#editPrj').modal('hide');
		},

    async setPrj() {
      if(!this.sbjctEditting) {
        alert("프로젝트 제목을 입력하세요.")
        return;
      }
      if(!this.menuEditing) {
        alert("메뉴를 선택하세요.")
        return;
      }
      if(!this.ctgryEditing) {
        alert("분류를 선택하세요.")
        return;
      }

      this.submitting = true;
      const rtnvalue = await Api.post('/api/v1/recent/set/project', {
        recentCtgryId: this.ctgryEditing,
        recentMenuId: this.menuEditing,
        sbjct: this.sbjctEditting,
        id: this.prjEditing
      })
      if(rtnvalue.result) {
        alert('정상적으로 등록되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
      this.submitting = false;
    },

    editArticle(projectId) {
      location.href = '/admin/site/recent-edit/' + this.recentId + '?projectId=' + projectId;
    }
  }
}
</script>

