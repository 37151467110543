<template>
    <div class="ca_main">
		<div class="x_div_center">
			<div class="pg_stage">

				<div class="d-flex pt-5"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">{{adminYn != 'N' && adminYn != 'E' && adminYn != 'R'? '' : 'My '}}Projects</span></div>

				<div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

				<div style="height:14px"></div>

				<div class="w-100 x_div_center" style="border-bottom:1px solid #c9c9c9">
					<div style="width:32%">
						<div :class="'x_div_center tap' + (currentTap == 0 ? ' active' : '')"><a href="#" @click="currentTap = 0"><span :class="'fkr-set1' + (currentTap == 0 ? ' fc-w1' : '')">진행중 ({{numOfOngoing}}건)</span></a></div>
					</div>
					<div style="width:2%"></div>
					<div style="width:32%">
						<div :class="'x_div_center tap' + (currentTap == 1 ? ' active' : '')"><a href="#" @click="currentTap = 1"><span :class="'fkr-set1' + (currentTap == 1 ? ' fc-w1' : '')">{{((user.info.usrRole == 'OW' || user.info.usrRole == 'SL')? '완공' : '완료') + ' (' + numOfCompleted}}건)</span></a></div>
					</div>
					<div style="width:2%"></div>
					<div style="width:32%">
						<div :class="'x_div_center tap' + (currentTap == 2 ? ' active' : '')"><a href="#" @click="currentTap = 2"><span :class="'fkr-set1' + (currentTap == 2 ? ' fc-w1' : '')">보류 ({{numOfPending}}건)</span></a></div>
					</div>
				</div>

                <div class="py-4" v-if="adminYn != 'N' && adminYn != 'E' && adminYn != 'R'">
                    <div class="x_div_end d-flex">
                    <select class="custom-select ff-eng1 fs-085" v-model="searchType" style="width:200px; height:37px; margin-right: 20px">
                        <option value="EM">건축주명 또는 이메일 검색</option>
                        <option value="PJ">프로젝트명 검색</option>
                    </select>
                    <div class="d-flex" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                        <div style="padding:0 10px 0 10px"><input type="text" @keydown.enter="fetchPrjsAdmin()" v-model="searchWord"  class="form-control fa-kor-Noto" style="border:0; width:180px; height:30px; padding:0 .1rem" placeholder="" ></div>
                        <div style="padding:5px 0 0 14px; border-left:1px solid #d6d6d6" @click="fetchPrjsAdmin()"><i class="fa-solid fa-magnifying-glass"></i></div>
                    </div>
                    </div>
                </div>

				<div class="w-100 x_div_end py-4" v-else>
					<div class="x_div_center">
						<div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
							<div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="renderList()"><i class="fa-solid fa-magnifying-glass"></i></div>
							<div style="padding:0 0 0 5px"><input type="text" v-model="searchFilter" @keydown.enter="renderList()" class="form-control fa-kor-Noto" style="border:0; width:170px; height:30px; padding:0 .3rem" placeholder="프로젝트 검색"></div>
						</div>
					</div>
				</div>

                <div class="pg_m_set" v-if="fetching">
                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>

				<!-- 프로젝트 리스트 -->
                <div v-for="(item, index) in showList" :key="index">
                    <div class="pg_m_set">
                        <div class="pg_d_set1" v-for="(prj, i) in item" :key="i" :style="i < 2 ? 'margin-right: 23.7px' : ''">
                            <div class="card" v-if="prj" style="height:183px; border:1px solid #c9c9c9; border-radius:8px">
                                <a href="javascript:void(0)" @click="moveToPrj(prj.prjId)">
                                    <div class="x_div_center">
                                        <div style="position: relative; width: 183px; height: 181px; overflow: hidden; border-top-left-radius:8px; border-bottom-left-radius:8px">
                                            <img :style="cropImg(encodeURIComponent(prj.mainImgFlWebPath).replace(/\.|\%|\(|\)/g, '-'))" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + prj.mainImgFlWebPath">
                                        </div>
                                        <div class="x_div_center" style="flex:0 0 calc(100% - 183px); padding: 10px">
                                            <div>
                                                <div class="x_div_center"><span class="fkr-set1 fs-10" style="color:#4e0000; text-align: center;">{{prj.prjNm.split(';')[0]}}</span></div>
                                                <div class="x_div_center"><span class="fkr-set2">{{prj.addr? (prj.addr.split(' ')[0] + ' ' + prj.addr.split(' ')[1]) : ''}}</span></div>
                                                <div class="x_div_center"><span class="fkr-set2" style="border-bottom:2px solid #c9c9c9">{{prj.crrntStts}}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div v-for="(cat, i) in fetchedPrjs" :key="i">
                        <img v-for="(prj, j) in cat" :key="j" :id="encodeURIComponent(prj.mainImgFlWebPath).replace(/\.|\%|\(|\)/g, '-')" style="display: none" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + prj.mainImgFlWebPath">
                    </div>

                    <div class="pg_m_set0"></div>
                </div>

				<div class="x_div_end" v-if="this.user.info.usrRole == 'DS' || this.user.info.usrRole == 'MF' || this.user.info.usrRole == 'CT' || this.user.info.usrRole == 'IN'"><button onclick="location.href='/project/bidding/partner/instruction'" class="x_div_center" style="width:130px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">비딩 참여 관리</span></button></div>

				<div class="x_div_center">
					<button @click="prevPage()" class="x_div_center" style="width:23px; height:25px; background-color:#fff; border:1px solid #c9c9c9"><i class="fa-solid fa-angle-left"></i></button>
					<button @click="nextPage()" class="x_div_center" style="width:23px; height:25px; background-color:#fff; border:1px solid #c9c9c9"><i class="fa-solid fa-angle-right"></i></button>
				</div>

				<div style="height:50px"></div>

			</div>
		</div>
	</div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'
const tap = {
    ONGOING: 0,
    COMPLETED: 1,
    PENDING: 2
}
const PAGESIZE = 9

export default {
    name: 'DashboardPage',
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            currentTap: tap.ONGOING,
            currentPage: 1,
            searchFilter: '',
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET,
            fetching: true,
            numOfOngoing: 0,
            numOfCompleted: 0,
            numOfPending: 0,
            fetchedPrjs: [[]],
            filteredList: [],
            showList: [[]],
            adminYn: 'N',
            searchType: 'EM',
            searchWord: ''
        }
    },
    watch: {
        currentTap() {
            this.searchFilter = '';
            this.renderList();
        },
        searchFilter(val) {
            if(!val) {
                this.renderList();
            }
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }
        var rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId=' + this.user.id);
        if(rtnval.result && rtnval.data.adminYn){
            this.adminYn = rtnval.data.adminYn;
        }
        sessionStorage.setItem('prjId', null);
        sessionStorage.setItem('prjNm', null);
        sessionStorage.setItem('prcId', null);
        sessionStorage.setItem('prcCd', null);
        if(this.adminYn == 'N') {
            await this.fetchPrjs();
            this.renderList();
        }
        else {
            this.fetching = false;
        }
    },
    methods: {
        async fetchPrjs() {
            var rtnval = null;
            rtnval = await Api.post('/api/v1/project/find/prj/list', {usrId: this.user.id, completionYn: false})
            if(rtnval.result) {
                this.fetchedPrjs[tap.ONGOING] = (this.user.info.usrRole == 'OW' || this.user.info.usrRole == 'SL')? rtnval.data.findPrjDtoList.sort((a, b) => {
                        return Number(Boolean(b.lastUpdatedPrcInfo.totalAmounts)) - Number(Boolean(a.lastUpdatedPrcInfo.totalAmounts))
                     }) : rtnval.data.findPrjDtoList;
                this.numOfOngoing = rtnval.data.findPrjDtoList.length;
            }
            rtnval = await Api.post('/api/v1/project/find/prj/list', {usrId: this.user.id, completionYn: true})
            if(rtnval.result) {
                this.fetchedPrjs[tap.COMPLETED] = rtnval.data.findPrjDtoList;
                this.numOfCompleted = rtnval.data.findPrjDtoList.length;
            }
            rtnval = await Api.post('/api/v1/project/find/prj/list', {usrId: this.user.id, completionYn: true})
            this.fetching = false;
        },
        async fetchPrjsAdmin() {
            if(!this.searchWord) {
                alert('검색어를 입력하세요.');
                return 1;
            }

            this.showList = [[]];
            this.fetching = true;
            var rtnval = null;
            rtnval = await Api.post('/api/v1/project/find/prj/list', {searchType: this.searchType, searchWord: this.searchWord, completionYn: false})
            if(rtnval.result) {
                this.fetchedPrjs[tap.ONGOING] = rtnval.data.findPrjDtoList.sort((a, b) => {
                        return Number(Boolean(b.lastUpdatedPrcInfo.totalAmounts)) - Number(Boolean(a.lastUpdatedPrcInfo.totalAmounts))
                     });
                this.numOfOngoing = rtnval.data.findPrjDtoList.length;
            }
            rtnval = await Api.post('/api/v1/project/find/prj/list', {searchType: this.searchType, searchWord: this.searchWord, completionYn: true})
            if(rtnval.result) {
                this.fetchedPrjs[tap.COMPLETED] = rtnval.data.findPrjDtoList;
                this.numOfCompleted = rtnval.data.findPrjDtoList.length;
            }
            rtnval = await Api.post('/api/v1/project/find/prj/list', {usrId: this.user.id, completionYn: true})
            this.fetching = false;
            this.renderList()
        },
        renderList() {
            this.currentPage = 1;
            this.filteredList = [];
            this.fetchedPrjs[this.currentTap].forEach((val) => {
                if(!this.searchFilter || (val.prjNm.indexOf(this.searchFilter) >= 0 || val.addr.indexOf(this.searchFilter) >= 0)){
                    this.filteredList.push(val);
                }
            });
            this.setPageList();
        },
        setPageList() {
            const start = PAGESIZE * (this.currentPage - 1);
            const end = start + (PAGESIZE-1) < this.filteredList.length ? start + (PAGESIZE-1) : this.filteredList.length - 1;

            this.showList = [[null, null, null], [null, null, null], [null, null, null]];
            for(var i = start, j = 0, k = 0; i <= end; i++, k++) {
                if(k > 0 && k%3 == 0){
                    j++;
                }
                this.showList[j][k%3] = this.filteredList[i];
            }
        },
        prevPage() {
            if(this.currentPage > 1) {
                this.currentPage--;
                this.setPageList();
            }
        },
        nextPage() {
            if(this.currentPage < Math.ceil(this.filteredList.length / PAGESIZE)) {
                this.currentPage++;
                this.setPageList();
            }
        },
        async moveToPrj(prjId) {
            var rtnval = await Api.get('/api/v1/process/list/prc/tree');
            if(rtnval.result) {
                sessionStorage.setItem('prcTree', JSON.stringify(rtnval.data));
            }
            else {
                alert(rtnval.errormsg)
            }

            rtnval = await Api.get('/api/v1/user/list/authorized/prc?usrRole=' + this.user.info.usrRole);
            if(rtnval.result) {
                var accessiblePrc = [];
                rtnval.data.processes.forEach((prc) => {
                    accessiblePrc.push(prc.code);
                })
                sessionStorage.setItem('accessiblePrc', JSON.stringify(accessiblePrc));
            }
            else {
                alert(rtnval.errormsg)
            }

            sessionStorage.setItem('prjId', prjId);
            location.href='/project/summary/' + prjId
        },
        cropImg(imgPath) {
            if(window.$('#' + imgPath).height() < window.$('#' + imgPath).width()) {
                return 'position: absolute; top: 0; left: -25%; width: auto; height: 181px'
            }
            else {
                return 'position: absolute; top: -25%; left: 0; width: 183px; height: auto'
            }
        }
    }
}
</script>

<style scoped>
.x_div_center.tap {
    height:50px;
    background-color:#fff;
}
.x_div_center.tap.active {
    height:50px;
    background-color:#4e0000;
    border-top-left-radius:8px;
    border-top-right-radius:8px
}

span {
    font-family: 'Noto Sans KR', sans-serif !important;
}
</style>