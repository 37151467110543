<template>
     <div class="m3_contetns" style="margin-top: 50px">

        <!-- 회원가입 box 시작 { -->
        <div class="m_ipt_box">

            <!-- 회원가입 기본정보 시작 { -->
            <div class="mib_mod">
                <div class="w-100">
                    <div class="x_div_center pb-3" style="border-bottom:1px solid #d9d9d9"><div class="h3 ff-kor1 fc-w6">비밀번호수정</div></div>

                    <!-- 기존비밀번호 -->
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">기존비밀번호</span></div>
                        <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                        <div class="mib_a3"><input type="password" class="form-control fkr-set2" style="width:180px; height:36px" placeholder="기존비밀번호을 입력하세요" v-model="psswrdOld"></div>
                    </div>

                    <!-- 새비밀번호 -->
                    <div class="mib_box_b">
                        <div class="mib_set1 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">새비밀번호</span></div>
                            <div class="mib_b2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                            <div class="mib_b3"><input type="password" class="form-control fkr-set2" style="width:180px; height:36px" placeholder="새비밀번호를 입력하세요" v-model="psswrd"></div>
                        </div>
                        <div class="mib_set2 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">새비밀번호 확인</span></div>
                            <div class="mib_b2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                            <div class="mib_b3"><input type="password" class="form-control fkr-set2" @keydown.enter="submit()" style="width:180px; height:36px" placeholder="새비밀번호 확인" v-model="psswrdCnfrm"></div>
                        </div>
                    </div>

                    <div class="x_div_center pt-4">
                        <div class="px-2"><button class="fkr-set1" @click="submit()" style="width:150px; height:37px; border:0; background-color:#000; border-radius:4px; color:#fff">정보수정</button></div>
                        <div class="px-2"><button class="fkr-set1" onclick="location.href='/member/profile'" style="width:150px; height:37px; border:0; background-color:#000; border-radius:4px; color:#fff">취소</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'ModifyPass',
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            psswrd: '',
            psswrdOld: '',
            psswrdCnfrm: ''
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }
    },
    methods: {
        async submit() {
            if(!this.psswrd) {
                alert('새비밀번호는 필수 입력사항입니다.')
                return false;
            }
            if(this.psswrd != this.psswrdCnfrm) {
                alert('확인 비밀번호가 일치하지 않습니다.')
                return false;
            }
            if(!this.psswrdOld) {
                alert('기존비밀번호는 필수 입력사항입니다.')
                return false;
            }

            const rtnval = await Api.post('/api/v1/user/update/psswrd', {id: this.user.id, newPsswrd: this.psswrd, psswrd: this.psswrdOld});
            if(rtnval.result){
                alert('정상적으로 수정되었습니다.')
                location.href='/member/profile';
            }
            else{
                alert(rtnval.errormsg);
            }
        }
    }
}
</script>