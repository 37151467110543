<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">식샤를 합시다</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>점심파티 목록</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">파티 수: {{ partyList.length }}</span>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <button class="fkr-set2 fs-095" @click="openEditParty(null)" style="width:80px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">파티 등록</button>
            </div>
          </div>

            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:50%"><span class="fkr-set1 fs-9">파티명</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">점심시간</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">식사요일</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">오늘은 비가와요</span></div>
                <div class="x_div_center" style="width:5%"><span class="fkr-set1 fs-9">편집</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in partyListPaged" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center m_line" style="width:50%;"><a :href="'/admin/site/party-enlist/' + item.id"><span class="fkr-set2 fs-9">{{item.partyNm}}</span></a></div>
                  <div class="x_div_center m_line" style="width:15%;"><span class="fkr-set2 fs-9">{{ renderPolltime(item.pollTime) }}</span></div>
                  <div class="x_div_center m_line" style="width:15%;"><span class="fkr-set2 fs-9">{{item.daysForLunch}}</span></div>
                  <div class="x_div_center m_line" style="width:15%;"><span class="fkr-set2 fs-9"><button class="fkr-set2 fs-095" @click="unlockVoteTime(item)" :disabled="submitting" :style="'width:100px; height:35px; border-radius:4px; ' + (item.isRaining? 'border:0; background-color:#4e0000; color:#fff;' : 'border:1px solid #4e0000 ; background-color:#eee7e7; color:#4e0000;')">지금 투표<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></span></div>
                  <div class="x_div_center m_line" style="width:5%;"><span class="fkr-set2 fs-9"><a href="javascript:void(0)" @click="openEditParty(item)"><i class="fa fa-edit"/></a></span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; renderList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>

      <div id="editParty" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:10001;">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">

            <div class="w-100">
              <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">점심파티 편집</span></div>
                <div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()" style="color:#4e0000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
              </div>

              <div class="x_div_center py-2 px-4">
                <div class="w-100">
                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">파티 이름</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:460px; height:36px" v-model="partyNmInput"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">식사 시간</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <select class="custom-select ff-eng1 fs-085" v-model="pollTimeHour" style="width:80px; height:27px; padding: 1px 0 1px 10px; margin:0 10px 0 0">
                            <option :value="hour" v-for="(hour, index) in selectHourSet" :key="index">{{String(hour).padStart(2, "0")}}</option>
                        </select>
                        <select class="custom-select ff-eng1 fs-085" v-model="pollTimeMin" style="width:80px; height:27px; padding: 1px 0 1px 10px; margin:0 10px 0 0">
                            <option :value="min" v-for="(min, index) in selectMinSet" :key="index">{{String(min).padStart(2, "0")}}</option>
                        </select>
                      </div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">식사 요일</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3" style="display:flex;">
                        <li class="custom-checkbox">
                            <input type="checkbox" id="days1" name="days" class="custom-control-input" v-model="days" value="월" >
                            <label class="custom-control-label" for="days1"><span class="fkr-set2">월</span></label>
                        </li>
                        <li class="custom-checkbox">
                            <input type="checkbox" id="days2" name="days" class="custom-control-input" v-model="days" value="화" >
                            <label class="custom-control-label" for="days2"><span class="fkr-set2">화</span></label>
                        </li>
                        <li class="custom-checkbox">
                            <input type="checkbox" id="days3" name="days" class="custom-control-input" v-model="days" value="수" >
                            <label class="custom-control-label" for="days3"><span class="fkr-set2">수</span></label>
                        </li>
                        <li class="custom-checkbox">
                            <input type="checkbox" id="days4" name="days" class="custom-control-input" v-model="days" value="목" >
                            <label class="custom-control-label" for="days4"><span class="fkr-set2">목</span></label>
                        </li>
                        <li class="custom-checkbox">
                            <input type="checkbox" id="days5" name="days" class="custom-control-input" v-model="days" value="금" >
                            <label class="custom-control-label" for="days5"><span class="fkr-set2">금</span></label>
                        </li>
                    </div>
                  </div>
                </div>
              </div>

              <div class="x_div_center py-4">
                <div class="px-2" v-if="editMode"><button @click="removeParty()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">삭제</span></button></div>
                <div class="px-2"><button @click="closeModal()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
                <div class="px-2"><button @click="setParty()" class="x_div_center" :disabled="submitting" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">{{ editMode? '수정' : '등록' }}<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
              </div>

              <div style="height:20px"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "PartyList",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: true,
        submitting: false,
        partyList: [],
        partyListPaged: [],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        partyId: null,
        partyNmInput: '',
        selectHourSet: [],
        selectMinSet: [],
        pollTimeHour: 11,
        pollTimeMin: 0,
        days: [],
        editMode: true,
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);

    for(var i=0; i<24; i++) {
      this.selectHourSet.push(i);
    }
    for(i=0; i<6; i++) {
      this.selectMinSet.push(i*10);
    }

    await this.fetchParties();
    this.setPaging();
    this.renderList();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async fetchParties() {
      this.fetching = true;
      this.partyList = [];
      const returnvalue = await Api.get('/api/v1/sicksha/party/list');
      if(returnvalue.result) {
          this.partyList = returnvalue.data.sort((a,b) => b.id - a.id);
      }
      else {
          alert(returnvalue.errormsg);
      }
      this.fetching = false;
    },

    renderList() {
      this.partyListPaged = [];
      for(var j = (this.currentPage-1) * this.pageSize; j < (this.partyList.length > this.currentPage * this.pageSize? this.currentPage * this.pageSize : this.partyList.length); j++) {
        this.partyListPaged.push(this.partyList[j]);
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.partyList.length / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        this.setPaging();
        this.renderList();
      }
    },

    renderPolltime(pollTime) {
      return String(Math.floor(pollTime / 60)).padStart(2, "0") + ':' + String(pollTime % 60).padStart(2, "0")
    },

    async unlockVoteTime(party) {
      this.submitting = true;
      const returnvalue = await Api.get('/api/v1/sicksha/vote/force?partyId=' + party.id);
      if(returnvalue.result) {
          alert('투표를 시작했습니다.\n카카오톡을 확인해주세요.');
          party.isRaining = true;
      }
      else {
          alert(returnvalue.errormsg);
      }
      this.submitting = false;
    },

    openEditParty(partyToEdit) { 
      if(partyToEdit) {
        this.partyId = partyToEdit.id;
        this.partyNmInput = partyToEdit.partyNm;
        this.pollTimeHour = Math.floor(partyToEdit.pollTime / 60);
        this.pollTimeMin = partyToEdit.pollTime % 60
        this.days = partyToEdit.daysForLunch.split(',');
        this.editMode = true;
      }
      else {
        this.partyId = null;
        this.partyNmInput = '';
        this.pollTimeHour = 11;
        this.pollTimeMin = 0;
        this.days = ['월','화','수','목','금'];
        this.editMode = false;
      }
      window.$('#editParty').modal('show');
    },

    async setParty() {
      this.submitting = true;

      const rtnval = await Api.post('/api/v1/sicksha/party/set', {
        id: this.partyId,
        partyNm: this.partyNmInput,
        pollTime: this.pollTimeHour*60 + this.pollTimeMin,
        daysForLunch: this.days.join(',')
      })
      if(rtnval.result) {
        alert('정상적으로 등록되었습니다.');
        window.$('#editParty').modal('hide');
        await this.fetchParties();
        this.setPaging();
        this.renderList();
      }
      else {
        alert(rtnval.errormsg)
      }
      this.submitting = false;
    },

    async removeParty() {
      this.submitting = true;

      const rtnval = await Api.get('/api/v1/sicksha/party/delete?partyId=' + this.partyId)
      if(rtnval.result) {
        alert('정상적으로 삭제되었습니다.');
        window.$('#editParty').modal('hide');
        await this.fetchParties();
        this.setPaging();
        this.renderList();
      }
      else {
        alert(rtnval.errormsg)
      }
      this.submitting = false;
    },

    closeModal() {
			window.$('#editParty').modal('hide');
		},
  }
}
</script>

<style scoped>
.mib_box_a {
    width: auto;
}
.custom-checkbox {
  margin-right: 15px;
}
</style>
