<template>
    <div class="d-flex">
        <AdminLNB/>
        <div class="ca_main">
            <div class="x_div_center">
                <div class="pg_stage">

                    <div class="d-flex pt-5">
                        <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">사이트관리</span></div>
                        <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold">FAQ</span></span></div></div>
                    </div>

                    <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                    <div style="height:20px"></div>

                    <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                      <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                    </div>
                    <div v-else>
                      <div class="pg_m_set3 w-100">
                          <div class="pg_d_set3a">
                              <div class="mib_box_c w-100 pt-3" style="padding-left: 15px"><h3 class="fkr-set1 fs-11">제목</h3></div>
                              <div style="padding: 15px"><input type="text" class="w-100 form-control" v-model="title"></div>
                          </div>
                          <div class="pg_d_set3b" style="width:25% !important">
                              <div class="mib_box_c w-100 pt-3" style="padding-left: 15px"><h3 class="fkr-set1 fs-11">분류</h3></div>
                              <div style="padding: 15px">
                                  <select class="custom-select ff-eng1 fs-085" v-model="category" style="width:200px; height:37px;">
                                    <option v-for="(item, i) in ctgryList" :key="i" :value="item.id">{{ item.ctgryName }}</option>
                                  </select>
                              </div>
                          </div>
                          <div class="pg_d_set3b" style="width:25% !important">
                              <div class="mib_box_c w-100 pt-3" style="padding-left: 15px"><h3 class="fkr-set1 fs-11">홈화면 노출순서</h3></div>
                              <div style="padding: 15px"><input type="text" class="form-control" v-model="homeExpsredOrder" placeholder="순서를 지정하면 메인페이지에 노출됩니다."></div>
                          </div>
                      </div>
  
                      <div class="mib_box_c w-100 pt-3" style="padding-left: 15px"><h3 class="fkr-set1 fs-11">내용</h3></div>
  
                      <div style="padding: 15px"><textarea class="w-100 form-control" v-model="contents" style="height:250px; border:1px solid #c9c9c9; border-radius:4px"></textarea></div>
  
                      <div class="x_div_end">
                          <div class="py-2 pe-1"><button class="fkr-set2 fs-095" onclick="location.href='/admin/site/faq-list'" style="width:100px; height:37px; border:0; background-color:#fff; color:#4e0000; border-radius:4px; border:1px solid #4e0000">취소</button></div>
                          <div class="py-2 ps-1"><button class="fkr-set2 fs-095" @click="saveFaq()" style="width:100px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                      </div>
                    </div>

                    <div style="height:20px"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'FaqEdit',
    components: {
		AdminLNB
	},
  data() {
      return {
          fetching: true,
          submitting: false,
          title: '',
          contents: '',
          homeExpsredOrder: null,
          ctgryList: [],
          category: 1,
      }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    const rtnvalue = await Api.get('/api/v1/faq/ctgry/list');
    if(rtnvalue.result) {
        this.ctgryList = rtnvalue.data;
    }
    else {
        alert(rtnvalue.errormsg);
    }

    if(this.$route.params.faqId > 0) {
      const rtnval = await Api.post('/api/v1/faq/find/faq/dtl', {faqId: this.$route.params.faqId});
      if(rtnval.result) {
        this.title = rtnval.data.sbjct;
        this.category = rtnval.data.ctgry.id;
        this.homeExpsredOrder = rtnval.data.isHomeExpsred;
        this.contents = rtnval.data.cntnt.replace(/<br>/g, '\n');
      }
      else {
        alert(rtnval.errormsg);
      }
    }
    this.fetching = false;
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },
    async saveFaq() {
      if(!this.title) {
        alert('제목을 입력하세요.');
        return 1;
      }
      if(!this.contents) {
        alert('내용을 입력하세요.');
        return 1;
      }

      this.submitting = true;
      var rtnval = null;
      if(this.$route.params.faqId > 0) {
        rtnval = await Api.post('/api/v1/faq/update/faq', {sbjct: this.title, ctgryId: this.category, cntnt: this.contents.replace(/\n/g, '<br>'), homeExpsredOrder: parseInt(this.homeExpsredOrder), faqId: this.$route.params.faqId});
      }
      else {
        rtnval = await Api.post('/api/v1/faq/create', {sbjct: this.title, ctgryId: this.category, cntnt: this.contents.replace(/\n/g, '<br>'), homeExpsredOrder: parseInt(this.homeExpsredOrder)})
      }
      if(rtnval.result) {
        alert('정상적으로 등록되었습니다.')
        location.href='/admin/site/faq-list'
      }
      else {
        alert(rtnval.errormsg);
      }
      this.submitting = false;
    }
  }
}
</script>