<template>
    <div class="m3_contetns" style="margin-top: 50px">
        <div class="mib_mod">
            <div class="w-100">
                <div v-if="!fetching" class="x_div_center pb-3" style="text-align: justify; display: block !important;">
                    <span v-if="user.info.cnslrLv == 1" class="fkr-set2 fs-10" :style="'position:relative; padding-left: 10px; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" onclick="location.href='/panda/prj-edit'"><i class="fa fa-pen"></i></span>
                    <select v-else-if="!isMobile" class="custom-select ff-eng1 fs-085" v-model="ctgry" @change="switchCtgry()" style="position:absolute; width:120px; height:27px; padding: 1px 0 1px 10px; margin:40px 10px 0 0">
                        <option value="0">전체</option>
                        <option value="1">M House</option>
                        <option value="2">M Office</option>
                        <option value="3">M School</option>
                    </select>
                    <span v-else-if="ctgry == 0" class="fkr-set2 fs-10" :style="'position:relative; padding-left: 10px; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" @click="ctgry = 1; switchCtgry()"><b>ALL</b></span>
                    <span v-else-if="ctgry == 1" class="fkr-set2 fs-10" :style="'position:relative; padding-left: 10px; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" @click="ctgry = 2; switchCtgry()"><i class="fas fa-home"></i></span>
                    <span v-else-if="ctgry == 2" class="fkr-set2 fs-10" :style="'position:relative; padding-left: 10px; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" @click="ctgry = 3; switchCtgry()"><i class="fas fa-building"></i></span>
                    <span v-else-if="ctgry == 3" class="fkr-set2 fs-10" :style="'position:relative; padding-left: 10px; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" @click="ctgry = 0; switchCtgry()"><i class="fas fa-school"></i></span>
                    <span class="fkr-set2 fs-10" :style="'position:relative; left: ' + (user.info.cnslrLv != 1 && !isMobile? '30%' : '10%') + '; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" @click="collapseAll = !collapseAll"><i v-if="collapseAll" class="far fa-plus-square"></i><i v-else class="fas fa-minus-square"></i></span>
                    <span class="fkr-set2 fs-10" :style="'position:relative; left: 20%; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" onclick="location.href='/panda/prj-list'"><i class="fas fa-list"></i></span>
                    <img :style="'position:relative; width:100px;' + (user.info.cnslrLv != 1 && !isMobile? 'margin-left:39px' : '')" src="@/assets/img_legacy/panda_logo.jpg">
                    <span class="fkr-set2 fs-10" id="search_tooltip" style="position:relative; float: right; bottom:-40px; cursor:pointer"><i v-if="!searchWord" onclick="alert('주소, 고객명 및 지금까지의 상담에 참여한 담당자들의 이름을 대상으로 프로젝트를 검색합니다.')" class="far fa-question-circle"></i><i v-else class="fa fa-undo" @click="searchWord = null; searchPrj()"></i></span>
                    <div class="x_div_center" style="position:relative; float: right; bottom:-35px; width:170px; height:34px; border:1px solid #d6d6d6; border-radius:4px;">
                        <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="searchPrj()"><i class="fa-solid fa-magnifying-glass"></i></div>
                        <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="searchPrj()" style="border:0; width:100px; height:30px; padding:0 .3rem; opacity: 0.7;"></div>
                    </div>
                </div>

                <div class="pg_m_set" v-if="fetching">
                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div v-else class="pid_2">
                    <div class="mib_box_c">
                        <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color: #4e0000;">
                            <div class="x_div_center" style="width:33%"><span class="fkr-set2 fc-w1 fs-075">프로젝트</span></div>
                            <div class="x_div_center" style="width:25%"><span class="fkr-set2 fc-w1 fs-075">용도</span></div>
                            <div class="x_div_center" style="width:8%"><span class="fkr-set2 fc-w1 fs-075">구조</span></div>
                            <div class="x_div_center" style="width:12%"><span class="fkr-set2 fc-w1 fs-075">면적</span></div>
                            <div class="x_div_center" style="width:10%"><span class="fkr-set2 fc-w1 fs-075">층수</span></div>
                            <div class="x_div_center" style="width:12%"><span class="fkr-set2 fc-w1 fs-075">예산</span></div>
                        </div>
                        
                        <div v-for="(prj, index) in prjList" :key="index">
                            <div class="x_div_center ls_bg1" style="background-color:#f4f4f4;">
                                <div class="x_div_center px-1" style="width:33%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ prj.prjNm }} <i class="fas fa-external-link-alt" @click="openPrj(prj.primPrjId)" style="margin-left: 8px; cursor: pointer;"></i> </span></div>
                                <div class="x_div_center px-1" style="width:25%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ prj.usage }} </span></div>
                                <div class="x_div_center px-1" style="width:8%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ prj.structure == '강구조'? '剛' : (prj.structure == '목구조'? '木' : '') }} </span></div>
                                <div class="x_div_center px-1" style="width:12%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ prj.totalGroundAreaPy? (prj.totalGroundAreaPy + '평') : ''}}</span></div>
                                <div class="x_div_center px-1" style="width:10%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ prj.floors? (prj.floors + '층') : ''}}</span></div>
                                <div class="x_div_center px-1" style="width:12%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ prj.expectedCost? (prj.expectedCost + '억') : ''}}</span></div>
                            </div>
                            <div :class="'x_div_center ls_bg1 ' + (prj.collapse? 'bottom_bold' : '')" :style="'background-color: ' + (prj.collapse? '#fff' : '#f4f4f4')">
                                <div class="x_div px-1" style="width:60%"><a :href="prj.maplink" target="_blank"><span class="fkr-set2 fs-085" style="text-align: left; margin-left: 10px;"><u> {{ prj.addr }} </u></span></a></div>
                                <div class="x_div_center px-1" style="width:32%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ prj.buildScheduleMonth == 0? '미정' : (prj.buildScheduleYear + '년 ' + prj.buildScheduleMonthDp + ' 예정') }} </span></div>
                                <div class="x_div_center px-1" style="width:8%; cursor: pointer;" @click="toggleCollapseEtc(index)"><span class="fkr-set2 fs-085" style="text-align: center;"><i v-if="prj.collapse" class="fas fa-chevron-down"></i><i v-else class="fas fa-chevron-up"></i></span></div>
                            </div>
                            <div v-if="!prj.collapse" :id="'etcInfo_'+index" class="x_div_center bottom_bold" style="padding: 5px 0 5px 0; display: block !important;">
                                <div class="x_div px-1" style="width:100%; padding-left: 20px !important; margin-bottom: 25px; max-height: 105px; overflow-y: auto;"><span class="fkr-set2 fs-085" style="text-align: left;" v-html="prj.etc"></span></div>
                                <div v-show="isMobile" v-for="(task, kintex) in prj.taskList" :key="kintex" style="padding-left: 20px">
                                    <div class="x_div_center ls_bg8" :style="'background-color:' + task.sttsColor">
                                        <div class="x_div px-1" style="width:50%"><span class="fkr-set2 fs-085" style="text-align: left; margin-left: 10px;"> {{ task.cnslrNm }} </span></div>
                                        <div class="x_div px-1" style="width:50%"><span class="fkr-set2 fs-085" style="text-align: left; margin-left: 10px;">
                                        {{ task.recentContactTime? task.recentContactTime.split('T')[0] + ' | ' + task.recentContactTime.split('T')[1] : 
                                        (task.scheduledContactTimeForToday && kintex+1 == prj.taskList.length? task.scheduledContactTimeForToday.split('T')[0] + ' (예정)' : '') }} 
                                        </span></div>
                                    </div>
                                    <div class="x_div px-1" style="width:100%; padding-left: 20px !important; margin: 5px 0 5px 0; max-height: 105px; overflow-y: auto;"><span class="fkr-set2 fs-085" style="text-align: left;" v-html="task.cnslCntnt"></span></div>
                                </div>
                                <div v-show="!isMobile" v-for="(bucket, syntax) in prj.taskListGrid" :key="syntax" style="display: flex;">
                                    <div v-for="(task, vertex) in bucket" :key="vertex" style="width:25%; padding: 5px">
                                        <div class="x_div_center ls_bg8" :style="'background-color:' + task.sttsColor">
                                            <div class="x_div px-1" style="width:30%"><span class="fkr-set2 fs-085" style="text-align: left; margin-left: 10px;"> {{ task.cnslrNm }} </span></div>
                                            <div class="x_div px-1" style="width:70%"><span class="fkr-set2 fs-085" style="text-align: left; margin-left: 10px;">
                                            {{ task.recentContactTime? task.recentContactTime.split('T')[0] + ' | ' + task.recentContactTime.split('T')[1] : 
                                            (task.scheduledContactTimeForToday && vertex+1 == bucket.length? task.scheduledContactTimeForToday.split('T')[0] + ' (예정)' : '') }} 
                                            </span></div>
                                        </div>
                                        <div class="x_div px-1" style="width:100%; padding-left: 20px !important; margin: 5px 0 5px 0; max-height: 105px; overflow-y: auto;"><span class="fkr-set2 fs-085" style="text-align: left;" v-html="task.cnslCntnt"></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="x_div_center py-2" style="margin-top:5px">
                            <a class="px-2 fs-10" @click="switchPage(-1)" style="cursor:pointer; margin-right: 20px;"><i class="fa-solid fa-angles-left"></i></a>
                            <a class="px-2 fs-10" @click="switchPage(0)" style="cursor:pointer; margin-right: 20px;"><i class="fa-solid fa-angle-left"></i></a>
                            <a v-show="!isMobile" v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; saveSessionData(); fetchData();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                            <span v-if="!isMobile && pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                            <a class="px-2 fs-10" @click="switchPage(1)" style="cursor:pointer; margin-right: 20px; margin-left: 20px;"><i class="fa-solid fa-angle-right"></i></a>
                            <a class="px-2 fs-10" @click="switchPage(2)" style="cursor:pointer;"><i class="fa-solid fa-angles-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'
export default {
    name: 'PrjDashboard',
    data() {
        return {
            fetching: true,
            isMobile: false,
            collapseAll: false,
            numOfPrj: 0,
            prjList: [],
            pageQueue: [],
            currentPage: 1,
            numOfPage: 0,
            pageSize: 5,
            ctgry: 0,
            searchWord: null,
            buildMode: process.env.NODE_ENV
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        },
        isMobileDevice: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        collapseAll(val) {
            this.prjList.forEach((prj) => {
                prj.collapse = val;
            })
            this.$forceUpdate();
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
            location.href = '/member/login'
        }

        if(!this.user.info.cnslrLv) {
            alert('접근권한이 없습니다.');
            location.href = '/';
        }

        this.isMobile = window.innerWidth < 768;

        if(sessionStorage.getItem('ctgry')) {
            this.ctgry = parseInt(sessionStorage.getItem('ctgry'));
            // this.pageSize = parseInt(sessionStorage.getItem('pageSize'));
            // this.currentPage = parseInt(sessionStorage.getItem('currentPage'));
            this.searchWord = sessionStorage.getItem('searchWord') == 'null'? null : sessionStorage.getItem('searchWord');
        }

        await this.fetchData();
        this.setPaging();
    },
    methods: {
        async fetchData() {
            this.prjList = [];
            this.fetching = true;
            const rtnvalue = await Api.post('/api/v1/primprj/list/primprj/dashboard', {
                sortColumn: 2,
                asc: false,
                ctgry: this.ctgry,
                pageSize: this.pageSize,
                page: this.currentPage,
                searchWord: this.searchWord
            });
            if(rtnvalue.result){
                this.numOfPrj = rtnvalue.data.count; 
                this.prjList = rtnvalue.data.findPrimPrjDtoList;
                this.prjList.forEach((prj) => {
                    var prjNmHeadArray = prj.addr? prj.addr.split(' ').filter((token) => token[token.length-1] == '시' || token[token.length-1] == '군') : null
                    prj.prjNm = prjNmHeadArray? prjNmHeadArray[0].replace('특별', '').replace('광역', '').replace('자치', '') + ' ' + prj.customerNm : prj.customerNm;
                    prj.maplink = this.isMobileDevice? 'https://m.map.naver.com/search2/search.naver?query=' + prj.addr + '#/map' : 'https://map.naver.com/search?query=' + prj.addr
                    if(this.isMobile && prj.addr) {
                        prj.addr = prj.addr.split('(')[0];
                    }
                    prj.totalGroundAreaPy = parseInt(prj.totalGroundArea / 3.3);
                    if(prj.buildScheduleMonth %1 == 0) {
                        prj.buildScheduleMonthDp = parseInt(prj.buildScheduleMonth) + '월';
                    }
                    else {
                        switch(prj.buildScheduleMonth + '') {
                            case '3.5' : prj.buildScheduleMonthDp = '1분기'
                                break;
                            case '6.5' : prj.buildScheduleMonthDp = '2분기'
                                break;
                            case '9.5' : prj.buildScheduleMonthDp = '3분기'
                                break;
                            case '12.5' : prj.buildScheduleMonthDp = '4분기'
                                break;
                            case '6.9' : prj.buildScheduleMonthDp = '상반기'
                                break;
                            case '12.9' : prj.buildScheduleMonthDp = '하반기'
                                break;
                        }
                    }
                    prj.collapse = this.collapseAll;

                    prj.taskListGrid = [[]];
                    var bucket = [];

                    for(var i=0; i < prj.taskList.length; i++) {
                        var task = prj.taskList[i];
                        const prevTask = i == 0? null : prj.taskList[i-1];
                        
                        if(!task.recentContactTime && !task.recallYn) {
                            task.sttsColor = '#c2e3ff';
                        }
                        else if(!task.recentContactTime && task.recallYn) {
                            task.sttsColor = '#feffc4';
                        }
                        if(task.nextCnslr || task.recentContactTime) {
                            task.sttsColor = '#dbffd7';
                        }

                        task.scheduledContactTimeForToday = prevTask? prevTask.scheduledContactTime : null;

                        bucket.push(task);
                        if(i%4 == 3) {
                            prj.taskListGrid.push(bucket);
                            bucket = [];
                        }
                    }

                    if(bucket.length >0) {
                        prj.taskListGrid.push(bucket);
                    }
                })
            }
            else {
                alert(rtnvalue.errormsg);
            }
            this.fetching = false;
        },

        setPaging() {
            this.numOfPage = Math.ceil(this.numOfPrj / this.pageSize);
            this.pageQueue = [];
            if(this.currentPage < 11) {
                for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
                this.pageQueue.push(i);
                }
            }
            else {
                for(i = this.currentPage - 9; i <= this.currentPage; i++) {
                this.pageQueue.push(i);
                }
            }
        },

        async switchPage(mode) {
            var render = false;
            switch(mode) {
                case -1 : if(this.currentPage > 1) {
                            this.currentPage = 1; 
                            render = true;
                        }
                break;
                case 0 : if(this.currentPage > 1) {
                            this.currentPage--;
                            render = true;
                        }
                break;
                case 1 : if(this.currentPage < this.numOfPage) {
                            this.currentPage++;
                            render = true;
                        }
                break;
                case 2 : if(this.currentPage < this.numOfPage) {
                            this.currentPage = this.numOfPage;
                            render = true;
                        } 
            }
            if(render) {
                this.saveSessionData();
                await this.fetchData();
                this.setPaging();
            }
        },

        async switchCtgry() {
            this.currentPage = 1;
            this.saveSessionData();
            await this.fetchData();
            this.setPaging();
        },

        async searchPrj() {
            this.currentPage = 1;
            this.saveSessionData();
            await this.fetchData();
            this.setPaging();
        },
        
        saveSessionData() {
            sessionStorage.setItem('ctgry', this.ctgry);
            // sessionStorage.setItem('pageSize', this.pageSize);
            // sessionStorage.setItem('currentPage', this.currentPage);
            sessionStorage.setItem('searchWord', this.searchWord);
        },

        toggleCollapseEtc(index) {
            var prj = this.prjList[index];
            prj.collapse = !prj.collapse;
            this.$forceUpdate();
            if(!prj.collapse){
                this.$nextTick(() => {
                    window.$('#etcInfo_'+index).css('opacity', 0);
                    window.$('#etcInfo_'+index).animate({opacity: 1}, 'fast');
                });
            }
        },

        openPrj(primPrjId) {
            window.open('about:blank').location.href='/panda/prj/' + primPrjId;
        }
    }
}
</script>

<style scoped>
img {
    left:39%;
}
#search_tooltip {
    right:25%;
}
@media screen and (max-width: 768px) {
    img {
        left:55%;
    }   
    #search_tooltip {
        right:55%;
    }
}

.ls_bg1 {
    padding: 5px 0 5px 0 !important;
}

.ls_bg8 {
    padding: 5px 0 5px 0 !important;
    border-bottom: 1px solid #e7e7e7;
}
.bottom_bold {
    border-bottom: 2px solid #bfbfbf !important;
    transition: 0.2s;
}
</style>
