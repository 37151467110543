<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">회원관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>이메일</strong></span></td>
                  <td class="text-center" width="70%"><span class="ff-eng1" style="font-size:12pt">{{email}}</span></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>이름</strong></span></td>
                  <td class="text-center"><span class="ff-kor1" style="font-size:1rem">{{fullName}}</span></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>연락처</strong></span></td>
                  <td class="text-center"><span class="ff-kor1" style="font-size:1rem">{{phone}}</span></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>유형</strong></span></td>
                  <td class="text-center">
                    <span class="ff-kor1" style="font-size:1rem">
                      <span v-if="usrRole == 'OW'">
                        고객
                      </span>
                      <span v-else-if="usrRole == 'DS'">
                        설계사
                      </span>
                      <span v-else-if="usrRole == 'MF'">
                        제조사
                      </span>
                      <span v-else-if="usrRole == 'CT'">
                        시공사
                      </span>
                      <span v-else-if="usrRole == 'IN'">
                        인테리어사
                      </span>
                      <span v-else-if="usrRole == 'SL'">
                        사업파트너
                      </span>
                      <span v-else-if="usrRole == 'M3'">
                        M3관계자
                      </span>
                    </span>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>사업자 등록번호</strong></span></td>
                  <td class="text-center"><span class="ff-kor1" style="font-size:1rem">{{bsnssNmbr}}</span></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>회사주소</strong></span></td>
                  <td class="text-center"><span class="ff-kor1" style="font-size:1rem">{{addr1}} {{addr2}}</span></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>홈페이지</strong></span></td>
                  <td class="text-center"><a :href="hmpg" target="_blank"><span class="ff-eng1" style="font-size:1rem">{{hmpg}}</span></a></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>포트폴리오 링크</strong></span></td>
                  <td class="text-center"><a :href="link" target="_blank"><span class="ff-eng1" style="font-size:1rem">{{link}}</span></a></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>사업자등록증</strong></span></td>
                  <td class="text-center">
                    <span class="ff-kor1" style="font-size:1rem">
                      <form method="post" action="/api/v1/user/bsnss/filedownload">
                        <input type="hidden" name="fileName" :value="bsnssFileName"/>
                        <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ bsnssOrgNm }} </span></a>
                      </form>
                    </span>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>지명원</strong></span></td>
                  <td class="text-center">
                    <span class="ff-kor1" style="font-size:1rem">
                      <form method="post" action="/api/v1/user/prof/filedownload">
                        <input type="hidden" name="fileName" :value="profFileName"/>
                        <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ profOrgNm }} </span></a>
                      </form>
                    </span>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>국가 기술 자격증</strong></span></td>
                  <td class="text-center">
                    <span class="ff-kor1" style="font-size:1rem">
                      <form method="post" action="/api/v1/user/ntnl/filedownload">
                        <input type="hidden" name="fileName" :value="ntnlFileName"/>
                        <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ ntnlOrgNm }} </span></a>
                      </form>
                    </span>
                  </td>
                </tr>
                <tr style="height:55px" v-if="awardList.length > 0">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>수상 이력</strong></span></td>
                  <td class="text-center">
                    <span class="ff-kor1" style="font-size:1rem" v-for="(item, index) in awardList" :key="index" v-html="item.awardNm + ' / ' + item.awardPrj + ' / ' + item.awardInst + ' / ' + item.awardYear + '<br>'"></span>
                  </td>
                </tr>
              </table>
            </div>

          </div>

          <div class="">
            <div class="x_div_end py-2"><button class="fkr-set2 fs-095" @click="findUsrList()" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">목록</button></div>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
  name: "UsrDtl",
  components: {
		AdminLNB
	},
  data(){
    return {
      email: '',
      fullName: '',
      phone: '',
      usrRole: '',
      bsnssNmbr: '',
      addr1:'',
      addr2:'',
      hmpg:'',
      link:'',
      awardList: [],

      bsnssOrgNm:'',
      bsnssFileName: '',
      profFileName: '',
      ntnlFileName: '',

      profOrgNm: '',
      ntnlOrgNm: '',
      fetching: true,
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    this.fetching = true;
    var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/dtl?usrId='+ this.$route.params.usrId);
    this.fetching = false;
    if(rtnval.result){
      this.email = rtnval.data.email;
      this.fullName = rtnval.data.fullName;
      if(!this.phone){
        this.phone = (rtnval.data.phone).replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      }
      this.usrRole = rtnval.data.usrRole;
      this.bsnssNmbr = rtnval.data.bsnssNmbr;
      this.addr1 = rtnval.data.addr1;
      this.addr2 = rtnval.data.addr2;
      this.hmpg = rtnval.data.hmpg;
      this.link = rtnval.data.link;
      this.awardList = rtnval.data.awardList;

      this.bsnssOrgNm = rtnval.data.bsnssOrgNm;
      this.bsnssFileName = rtnval.data?.bsnssImgPath;

      if(this.bsnssFileName != null)  {
        this.bsnssFileName = this.bsnssFileName.replace("platform/usr/bsnss/", '')
      }
      this.profFileName = rtnval.data?.profImgPath;
      if(this.profFileName != null)  {
        this.profFileName = this.profFileName.replace("platform/usr/prof/", '')
      }
      this.ntnlFileName = rtnval.data?.ntnlImgPath;
      if(this.ntnlFileName != null) {
        this.ntnlFileName = this.ntnlFileName.replace("platform/usr/ntnl/", '')
      }

      this.profOrgNm = rtnval.data.profOrgNm;
      this.ntnlOrgNm = rtnval.data.ntnlOrgNm;
    }


  },

  methods:{
    findUsrList(){
      location.href = '/admin/usr/usr-mng?p=' + this.$route.query.p + '&w=' + this.$route.query.w;
    },

    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },
  }
}
</script>

<style scoped>

</style>