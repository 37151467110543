import BiddingCustomer from './bidding-customer'
import BiddingPartner from './bidding-partner'
import Plan from '@/views/project/Plan.vue'
import Dashboard from '@/views/project/Dashboard.vue'
import Summary from '@/views/project/Summary.vue'
import PrcSummary from '@/views/project/process/Summary.vue'
import PrcStpDsgn from '@/views/project/process/PrcStpDsgn.vue'
import PrcStpDsgnApproval from '@/views/project/process/PrcStpDsgnApproval.vue'
import PrcStpDsgnInfo from '@/views/project/process/PrcStpDsgnInfo.vue'
import PrcStpSingle from '@/views/project/process/PrcStpSingle.vue'
import PrcStpMultiple from '@/views/project/process/PrcStpMultiple.vue'
import Maintainance from '@/views/project/Maintainance.vue'
import Review from '@/views/project/Review.vue'

export default [
    ...BiddingCustomer,
    ...BiddingPartner,
    {
        path: '/plan',
        name: 'Plan',
        component: Plan
    },
    {
        path: '/project/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/project/review/:prcCd',
        name: 'Review',
        component: Review
    },
    {
        path: '/project/summary/:prjId',
        name: 'Summary',
        component: Summary
    },
    {
        path: '/project/:prcCd/summary',
        name: 'PrcSummary',
        component: PrcSummary
    },
    {
        path: '/project/:prcCd/:prcStpCd/dsgn',
        name: 'PrcStpDsgn',
        component: PrcStpDsgn
    },
    {
        path: '/project/DS/DA/dsgn-approval',
        name: 'PrcStpDsgnApproval',
        component: PrcStpDsgnApproval
    },
    {
        path: '/project/DS/dsgn-info',
        name: 'PrcStpDsgnInfo',
        component: PrcStpDsgnInfo
    },
    {
        path: '/project/:prcCd/:prcStpCd',
        name: 'PrcStpSingle',
        component: PrcStpSingle
    },
    {
        path: '/project/:prcCd/:prcStpCd/multiple',
        name: 'PrcStpMultiple',
        component: PrcStpMultiple
    },
    {
        path: '/project/maintainance',
        name: 'Maintainance',
        component: Maintainance
    }
]