<template>
    <div>
        <div style="height:80px"></div>
        <div class="p-2"><span class="fkr-set2 fs-16 fc-w0"><strong>내건물 짓기 시작</strong></span></div>
        <div class="x_div_center">
            <div class="iw-1 p-2">
                <div class="iw-3">
                    <a href="/plan">
                    <div class="if_mbox1 x_div_center p-4">
                        <div class="w-100">
                            <div class="fk-set1 fs-14"><strong>실감나게 살아보기</strong></div>
                            <br>
                            <div class="fk-set1 fs-095">프리미엄 모듈러 건축 원스톱 서비스, 지금 시작해보세요 !</div>
                        </div>
                        <div class="px-2"><i class="fa-solid fa-circle-right" style="font-size:2rem"></i></div>
                    </div>
                    </a>
                </div>
                <div class="iw-b"></div>
                <div class="iw-3">
                    <a href="/cs/inquiry">
                    <div class="if_mbox1 x_div_center p-4">
                        <div class="w-100">
                            <div class="fk-set1 fs-14"><strong>문의하기</strong></div>
                            <br>
                            <div class="fk-set1 fs-095">무엇이든 물어보세요. 전문 컨설턴트가 맞춤 상담을 해드립니다</div>
                        </div>
                        <div class="px-2"><i class="fa-solid fa-circle-right" style="font-size:2rem"></i></div>
                    </div>
                    </a>
                </div>
            </div>
        </div>
        <div style="height:80px"></div>
    </div>
</template>

<script>
export default {
    name: 'MenuFooter'
}
</script>