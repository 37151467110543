import Question from '@/views/cs/inquiry/Question.vue'
import MobileQuestion from '@/views/cs/inquiry/MobileQuestion.vue'
import Faq from '@/views/cs/FAQ.vue'
import Download from '@/views/cs/Download.vue'

export default [
  {
    path: '/cs/inquiry',
    component: Question,
    name: 'Question'
  },
  {
    path: '/mobile/cs/inquiry',
    component: MobileQuestion,
    name: 'MobileQuestion'
  },
  {
    path: '/cs/faq',
    component: Faq,
    name: 'Faq'
  },
  {
    path: '/cs/download',
    component: Download,
    name: 'Download'
  },
]