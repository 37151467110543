<template>
    <div class="ca_main">
		<div class="x_div_center">
			<div class="pg_stage">

				<div class="d-flex pt-5">
					<div style="flex:0 0 calc(100% - 100px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 참여 관리</span></div>
					<div class="x_div_center"><div class="text-end" style="width:100px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
				</div>

				<div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

				<div style="height:14px"></div>

				<div class="sw-1">
					<div class="sw-2" style="border-bottom:1px solid #c9c9c9">
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/bidding-participate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/bidding-participate'"><span class="fkr-set1 fs-095">1. 제안 참여 의향 등록</span></div>
						</div>
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/bidding-proposal') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/bidding-proposal'"><span class="fkr-set1 fs-095">2. 제안 제출 목록 (선정시)</span></div>
						</div>
					</div>
					<div class="sw-2" style="border-bottom:1px solid #c9c9c9">
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/bidding-result') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/bidding-result'"><span class="fkr-set1 fs-095">3. 제안 평가 결과 확인</span></div>
						</div>
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/contract-list') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/contract-list'"><span class="fkr-set1 fs-095">4. 계약 목록 (선정시)</span></div>
						</div>
					</div>
				</div>

				<div style="height:50px"></div>

				<div>
					<div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>
					<div v-else class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
						<table class="table-borderless w-100" style="table-layout: fixed;">
							<tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
								<td class="text-center br_0" width="20%"><span class="ff-kor1" style="font-size:12pt"><strong>고객</strong></span></td>
								<td class="text-center br_0" width="13%"><span class="ff-kor1" style="font-size:12pt"><strong>선정<br v-if="isMobile"/> 날짜</strong></span></td>
								<td class="text-center br_0" width="13%"><span class="ff-kor1" style="font-size:12pt"><strong>계약일</strong></span></td>
								<td class="text-center br_0" width="20%"><span class="ff-kor1" style="font-size:12pt"><strong>고객<br v-if="isMobile"/> 연락처</strong></span></td>
								<!-- <td class="text-center br_0" width="20%"><span class="ff-kor1" style="font-size:12pt"><strong>건축 위치</strong></span></td> -->
								<td class="text-center br_0" width="10%"><span class="ff-kor1" style="font-size:12pt"><strong>{{isMobile? '타입' : '건축물 타입'}}</strong></span></td>
								<td class="text-center br_0" width="12%"><span class="ff-kor1" style="font-size:12pt"><strong>상세 정보</strong></span></td>
								<td class="text-center" width="12%"><span class="ff-kor1" style="font-size:12pt"><strong>계약 상세</strong></span></td>
							</tr>
						</table>
						<table v-for="(prj, i) in prjList" :key="i" class="table-borderless w-100" style="table-layout: fixed;">
							<tr style="height:55px; border-bottom:1px solid #c9c9c9">
								<td class="text-center br_0" width="20%"><span class="ff-kor1" style="font-size:11pt">{{prj.ownrName}}</span></td>
								<td class="text-center br_0" width="13%"><span class="ff-eng1" style="font-size:11pt">{{renderDateForm(prj.evaluatedDt)}}</span></td>
								<td class="text-center br_0" width="13%"><span class="ff-eng1" style="font-size:11pt">{{renderDateForm(prj.cntrctDt)}}</span></td>
								<td class="text-center br_0" width="20%"><span class="ff-kor1" style="font-size:11pt">{{phoneNumberFormatter(prj.ownrPhone)}}</span></td>
								<!-- <td class="text-center br_0" width="20%"><a :href="isMobileDevice? prj.maplinkMobile : prj.maplink" target="_blank"><span class="ff-kor1" style="font-size:11pt"><u>{{prj.addr}}</u></span></a></td> -->
								<td class="text-center br_0" width="10%"><span class="ff-kor1" style="font-size:11pt">{{isMobile? prj.purpose.replace('용 건물', '') : prj.purpose}}</span></td>
								<td class="text-center br_0" width="12%"><div class="x_div_center"><button @click="$refs.BiddingPrjDetail.showDetail(prj.prjId)" class="x_div_center" :style="'height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000; ' + (isMobile? 'width:50px' : 'width:90px')"><span class="fkr-set2">보기</span></button></div></td>
								<td class="text-center" width="12%"><div class="x_div_center">
									<button v-if="!prj.cntrctDt" class="x_div_center" @click="moveToContractDetail(prj.prjId)" :style="'height:30px; border:0; background-color:#4e0000; border-radius:4px; ' + (isMobile? 'width:50px' : 'width:90px')"><span class="fkr-set2 fc-w1">{{isMobile? '진행' : '계약 진행'}}</span></button>
									<button v-else class="x_div_center" @click="moveToContractDetail(prj.prjId)" :style="'height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000; ' + (isMobile? 'width:50px; ' : 'width:90px; ')"><span class="fkr-set2">보기</span></button>
								</div></td>
							</tr>
							<!-- <tr v-if="isMobile" style="height:55px; border-bottom:1px solid #c9c9c9">
								<td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt">고객<br/>연락처</span></td>
								<td><span class="ff-kor1" style="font-size:11pt; width:100%; display: block; white-space: nowrap; position: relative; left: 190%">{{phoneNumberFormatter(prj.ownrPhone)}}</span></td>
							</tr> -->
						</table>
					</div>
				</div>

				<!-- 상세정보 레이어 시작 { -->
				<BiddingPrjDetail ref="BiddingPrjDetail" :prcCd="prcCd" @prjList="prjListUpdated"/>

				<div style="height:50px"></div>

			</div>
		</div>
	</div>
</template>

<script>
import Auth from '@/api/auth'
import BiddingPrjDetail from '@/components/BiddingPrjDetail.vue'

const processCode = {
    DS: 'DS',
    MF: 'MA',
    CT: 'CN',
	IN: 'IN'
}

export default {
    name: 'BiddingContract',
	components: {
		BiddingPrjDetail
	},
    data() {
        return {
            url : '',
            usrRole : this.user.info.usrRole,
            prcCd : processCode[this.user.info.usrRole],
            fetching : true,
			isMobile : false,
			prjList : [],
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        },
		isMobileDevice: {
			type: Boolean,
            default: false
		}
    },
    created() {
        this.url = location.href;
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }
        else if(Auth.partnerRoleTypes.indexOf(this.usrRole) <0) {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }

		this.isMobile = window.innerWidth < 768;
        await this.$refs.BiddingPrjDetail.fetchBiddableProjects();
		this.fetching = false;
    },
    methods: {
        prjListUpdated(prjList) {
			this.prjList = [];
			prjList.forEach((prj) => {
				if(prj.evalRank == 1) {
					this.prjList.push(prj);
				}
			})
		},
		moveToContractDetail(prjId) {
			location.href = '/project/bidding/partner/contract/' + prjId;
		},
		phoneNumberFormatter(phone) {
            if(phone.length == 10) {
                return phone.substring(0,3) + '-' + phone.substring(3,6) + '-' + phone.substring(6);
            }
            else{
                return phone.substring(0,3) + '-' + phone.substring(3,7) + '-' + phone.substring(7);
            }
        },
		renderDateForm(datetime) {
            if (this.isMobile) {
                return datetime? datetime.split('T')[0].substring(5) : '';
            }
            else {
                return datetime? datetime.split('T')[0] : '';
            }
        },
		renderEmailForm(email) {
            if (this.isMobile) {
                return email? (email.split('@')[0] + '@\n' + email.split('@')[1]) : '';
            }
            else {
                return email;
            }
        }
    }
}
</script>