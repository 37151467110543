<template>
  <div v-if="isScrolltoTop" class="scroll-to-top" :style="(!scrollSpyOn || isModelDetail? 'bottom:100px; top:unset' : 'top:85%') + '; cursor:pointer'" :class="{mobile: isMobile}" @click="scrollToTop()">
    <img src="@/assets/img/common/top.svg" />
  </div>
</template>
<script>
import store from '@/store'

export default {
  name: 'ScrollToTopButton',
  props: {
    isModelDetail: {
      type: Boolean,
      default: false
    },
    scrollSpyOn: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isScrolltoTop() {
      return store.state.isScrolltoTop;
    },
    isMobile() {
      return store.state.isMobile;
    }    
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
</script>