<template>
    <div class="d-flex">
        <LNB :user="user"/>
        <div class="ca_main">
            <div class="x_div_center">
                <div class="pg_stage">

                    <div class="x_div_center pt-5">
						<div class="" style="width:80%"><span class="fkr-set1 fs-16" style="color:#333333">{{prjNm}}</span></div>
						<div class="text-end" style="width:20%"><span class="fkr-set2" style="color:#484848"><span class="fw-bold">유지보수</span></span></div>
					</div>

                    <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                    <div style="height:20px"></div>

                    <div>

                        <div class="pg_m_set" v-if="fetching">
                            <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                        </div>
                        <div v-else class="table-responsive" style="border:0px solid #c9c9c9; border-radius:8px">
                            <table class="table-borderless w-100" style="table-layout:fixed">
                            <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                                <td class="text-center" width="10%"><span class="fkr-set1 fs-09">번호</span></td>
                                <td class="text-center" width="17%"><span class="fkr-set1 fs-09">신청일자</span></td>
                                <td class="text-center" width="17%"><span class="fkr-set1 fs-09">내용</span></td>
                                <td class="text-center" width="17%"><span class="fkr-set1 fs-09">담당회사</span></td>
                                <td class="text-center" width="19%"><span class="fkr-set1 fs-09">진행예정일</span></td>
                                <td class="text-center" width="17%"><span class="fkr-set1 fs-09">처리일자</span></td>
                                <td class="text-center" width="3%"></td>
                            </tr>

                            <tr v-for="(item, index) in issueList" :key="index" style="height:55px; border-bottom:1px solid #c9c9c9">
                                <td class="text-center br_0"><span class="fen-set2">{{item.mntnId}}</span></td>
                                <td class="text-center br_0"><span class="fen-set2">{{renderDateForm(item.crtDt)}}</span></td>
                                <td class="text-center br_0 px-2"><div class="x_div_center"><button @click="showIssueDetail(item)" style="width:70px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000" href="#v-view" data-toggle="modal"><span class="fkr-set1">보기</span></button></div></td>
                                <td :class="'text-center ' + (isMobile && user.info.usrRole == 'SL' && !item.expctdPrgrssDt? '' : 'br_0')">
                                    <div v-if="user.info.usrRole == 'SL' && !item.expctdPrgrssDt" class="x_div_center" :style="isMobile? 'width:220%' : ''">
                                        <select v-model="item.cmpnyId" @change="asignCompany(item)" class="custom-select fkr-set1 text-center" style="width:90%; height:37px">
                                            <option value="">미정</option>
                                            <option v-for="(comp, i) in companyInfo" :key="i" :value="comp.id">{{comp.name}}</option>
                                        </select>
                                    </div>
                                    <span v-else class="fen-set2" style="width:100%; display: block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{item.cmpnyNm}}</span>
                                </td>
                                <td class="text-center br_0">
                                    <div v-if="(user.info.usrRole == 'DS' || user.info.usrRole == 'MF' || user.info.usrRole == 'CT' || user.info.usrRole == 'IN') && !item.expctdPrgrssDt && item.cmpnyId == user.id" class="x_div_center">
                                        <Datepicker :isMobile="isMobile" @update-date="setExpctdPrgrssDt(item, $event)" class="form-control fen-set2 text-center" style="width:85%; height:36px; padding: 0;"/>
                                    </div>
                                    <span v-else class="fen-set2">
                                        {{renderDateForm(item.expctdPrgrssDt)}}
                                    </span>
                                </td>
                                <td class="text-center">
                                    <div v-if="(user.info.usrRole == 'DS' || user.info.usrRole == 'MF' || user.info.usrRole == 'CT' || user.info.usrRole == 'IN') && !item.prcssngDt && item.cmpnyId == user.id" class="x_div_center">
                                        <Datepicker :isMobile="isMobile" @update-date="setPrcssngDt(item, $event)" class="form-control fen-set2 text-center" style="width:85%; height:36px; padding: 0;"/>
                                    </div>
                                    <span v-else class="fen-set2">
                                        {{renderDateForm(item.prcssngDt)}}
                                    </span>
                                </td>
                                <td><div v-if="user.info.usrRole == 'OW' && !item.cmpnyId" class="x_div_center px-1" :style="'width:5%; ' + (isMobile? 'position:relative; left:-350%' : '')"><a href="javascript:void(0)" @click="removeIssue(item, index)" class="rm_frm"><i class="fa-solid fa-circle-minus fs-12" style="color:#4e0000"></i></a></div></td>
                            </tr>

                            </table>
                        </div>

                        <!-- 신청내용 레이어 시작 { -->
                        <div id="v-view" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
                            <div class="modal-dialog modal-lg" role="document">
                                <div class="modal-content">

                                    <div class="w-100">
                                        <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                                            <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">하자보수 신청내용</span></div>
                                            <div class="text-end pe-4" style="width:100px"><a href="#@" style="color:#4e0000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                                        </div>

                                        <div class="pt-3 ps-4"><span class="fkr-set2 fs-11 fw-bold">하자내용</span></div>

                                        <div class="x_div_center py-2 px-4">
                                            <div class="w-100 py-3" style="border-top:1px solid #c9c9c9">
                                                <div class="py-2 px-2 fkr-set2" v-html="mntnDesc"></div>
                                            </div>
                                        </div>

                                        <div class="x_div_center px-4">
                                            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                                                <table class="table-borderless w-100">
                                                <tr style="height:45px;">
                                                    <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="fkr-set1">관련사진</span></td>
                                                    <td class="text-center" width="70%"><span class="fkr-set2"><a :href="imgLink" :download="orgNm">{{orgNm}}</a></span></td>
                                                </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <div class="x_div_center py-2 px-4">
                                            <div class="w-100 x_div_center py-3">
                                                <div class="x_div_center px-2"><img :src="imgLink" width="100%" style="border-raidus:8px"></div>
                                            </div>
                                        </div>

                                        <div class="x_div_center py-4">
                                            <div class="px-2"><button class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
                                        </div>

                                        <div style="height:20px"></div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- } 상세정보 레이어 끝 -->

                        <!-- 신규신청 레이어 시작 { -->
                        <div id="v-reg" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
                            <div class="modal-dialog modal-lg" role="document">
                                <div class="modal-content">

                                    <div class="w-100">
                                        <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                                            <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">하자보수 신규신청</span></div>
                                            <div class="text-end pe-4" style="width:100px"><a href="#@" style="color:#4e0000" @click="closeModal()" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                                        </div>

                                        <div class="pt-3 ps-4"><span class="fkr-set2 fs-11 fw-bold">하자내용</span></div>

                                        <div class="x_div_center py-2 px-4">
                                            <div class="w-100 py-3" style="border-top:1px solid #c9c9c9">
                                                <div><textarea v-model="mntnDescInput" style="width:100%; height:80px; border:1px solid #e1e1e1"></textarea></div>
                                            </div>
                                        </div>

                                        <div class="x_div_center px-4">
                                            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                                                <table class="table-borderless w-100">
                                                <tr style="height:45px;">
                                                    <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="fkr-set1">관련사진</span></td>
                                                    <td class="text-center" width="70%"><div class="x_div_center"><input type="file" id="file" @change="fileHandling" class="form-control fkr-set2 fc-w5 text-center" style="width:70%; height:36px; border-radius:4px; background-color:#f6f2f2; border:1px solid #e1e1e1" placeholder=""></div></td>
                                                </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <div class="x_div_center py-4">
                                            <div class="px-2"><button class="x_div_center" @click="addNewIssue()" style="width:90px; height:30px; border:0; background-color:#4e0000; color:#fff; border-radius:4px" data-dismiss="modal">등록</button></div>
                                            <div class="px-2"><button class="x_div_center" @click="closeModal()" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
                                        </div>

                                        <div style="height:20px"></div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- } 상세정보2 레이어 끝 -->

                    </div>

                    <div class="x_div_center py-4">
                        <div class="py-2 pe-1" v-if="user.info.usrRole == 'OW'"><button class="fkr-set1" @click="showRegForm()" style="width:90px; height:37px; border:0; background-color:#fff; color:#4e0000; border-radius:4px; border:1px solid #4e0000">신규신청</button></div>
                        <div class="py-2 ps-1"><button class="fkr-set1" @click="saveIssueList()" :disabled="submitting" style="width:90px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LNB from '@/components/LNB.vue'
import Datepicker from '@/components/Datepicker.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'ProjectMaintainance',
    components: {
		LNB,
        Datepicker
	},
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            prjId: null,
            prjNm: '',
            fetching: true,
            submitting: false,
            isMobile: false,
            issueList: [],
            companyInfo: [],
            mntnDescInput: '',
            mntnDesc: '',
            orgNm: '',
            imgLink: '',
            file: null,
            fileName: '',
            isConstructionCompleted: false,
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           if(this.$route.query.prjId){
               this.$router.push({ path: '/member/login', query: { fromTalk: encodeURIComponent("/project" + location.href.split("/project")[1]) } });
           }
           else {
               location.href = '/member/login'
           }
        }

        if(this.$route.query.prjId){
            var rtnval = await Api.get('/api/v1/process/list/prc/tree?prjId=' + this.$route.query.prjId);
            if(rtnval.result) {
                sessionStorage.setItem('prcTree', JSON.stringify(rtnval.data));
                sessionStorage.setItem('prjId', this.$route.query.prjId);
                sessionStorage.setItem('prjNm', rtnval.data.prjNm);
                var rtnvalue = await Api.get('/api/v1/user/list/authorized/prc?usrRole=' + this.user.info.usrRole);
                if(rtnvalue.result) {
                    var accessiblePrc = [];
                    rtnvalue.data.processes.forEach((prc) => {
                        accessiblePrc.push(prc.code);
                    })
                    sessionStorage.setItem('accessiblePrc', JSON.stringify(accessiblePrc));
                    location.href = location.href.split("?")[0];
                }
                else {
                    alert(rtnvalue.errormsg)
                }
            }
            else {
                alert(rtnval.errormsg)
            }
        }
        else {
            this.prjId = sessionStorage.getItem('prjId');
            this.prjNm = sessionStorage.getItem('prjNm');
        }

        if(this.prjId == 'null') {
            alert('My Projects 페이지에서 프로젝트를 선택하셔야 합니다.');
            location.href = '/project/dashboard'
        }

        this.isMobile = window.innerWidth < 768;

        const returnvalue = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + this.prjId);
        if(returnvalue.result) {
            this.isConstructionCompleted = returnvalue.data.prj.prjCmpltnYn == "Y";
        }

        this.fetchIssueList();
    },
    methods: {
        async fetchIssueList() {
            this.companyInfo = [];
            this.fetching = true;
            const rtnval = await Api.get('/api/v1/mntn/find/mntns?prjId=' + this.prjId)
            this.fetching = false;
            if(rtnval.result) {
                this.issueList = rtnval.data.findMntnDtoList.sort((a,b) => { return new Date(a.crtDt) - new Date(b.crtDt) });
                if(rtnval.data.mntnCompanyInfo){
                    rtnval.data.mntnCompanyInfo.forEach((val) => {
                        if(val.prcCd != 'DS') {
                            this.companyInfo.push({id: val.prtnrId, name: val.prtnrNm})
                        }
                    })
                }
            }
        },
        showIssueDetail(issue) {
            this.mntnDesc = issue.mntnDesc;
            this.orgNm = issue.orgNm;
            this.imgLink = '';
            if(issue.mntnId && issue.attchImg) {
                var tokens = issue.orgNm.split('.');
                var mimeType = tokens[tokens.length-1].toLowerCase();
                var fileType = mimeType == 'pdf'? 'application/' + mimeType : 'image/' + mimeType;

                const byteCharacters = atob(issue.attchImg);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {type: fileType});
                this.imgLink = URL.createObjectURL(blob);
            }
            else if(issue.file) {
                this.imgLink = URL.createObjectURL(issue.file);
            }
        },
        showRegForm() {
            if(this.isConstructionCompleted) {
                this.mntnDescInput = '';
                this.file = null;
                this.fileName = '';
                window.$('#file').val('');
                window.$('#v-reg').modal('show');
            }
            else {
                alert('준공완료후 신청할 수 있습니다.')
            }
        },
        addNewIssue() {
            if(this.mntnDescInput) {
                this.issueList.push({
                    mntnId: null,
                    mntnDesc: this.mntnDescInput.replace(/\n/g, '<br/>'),
                    cmpnyId: null,
                    cmpnyNm: null,
                    crtDt: null,
                    expctdPrgrssDt: null,
                    prcssngDt: null,
                    orgNm: this.fileName,
                    attchImg: null,
                    file: this.file
                })
                window.$('#v-reg').modal('hide');
            }
            else {
                alert('하자내용을 입력하세요.');
            }
        },
        closeModal() {
            window.$('#v-reg').modal('hide');
        },
        fileHandling(e) {
            var tokens = e.target.files[0].name.split('.');
            var fileType = tokens[tokens.length-1].toLowerCase();
            var allowed = ['jpg', 'jpeg', 'png', 'pdf', 'zip']
            if(allowed.indexOf(fileType) < 0){
                alert('이미지파일 또는 pdf형식 외의 파일은\nzip 형태로 압축하여 업로드 해 주시기 바랍니다.');
                window.$('#file').val('')
                return 1;
            }

            this.file = e.target.files[0];
            this.fileName = e.target.files[0].name;
        },
        async removeIssue(issue, index) {
            if(issue.mntnId) {
                if(confirm('하자보수 신청을 취소하시겠습니까?')){
                    this.submitting = true;
                    const rtnval = await Api.post('/api/v1/mntn/delete/mntn', {
                        mntnIdList: [issue.mntnId],
                        prjId: this.prjId
                    })
                    this.submitting = false;
                    if(rtnval.result) {
                        this.issueList = this.issueList.filter((v,i) => i != index );        
                    }
                    else {
                        alert(rtnval.errormsg);
                        this.fetchIssueList();
                    }
                }
            }
            else {
                this.issueList = this.issueList.filter((v,i) => i != index );
            }
        },
        renderDateForm(datetime) {
            if (this.isMobile) {
                return datetime? datetime.split('T')[0].substring(5) : '';
            }
            else {
                return datetime? datetime.split('T')[0] : '';
            }
        },
        asignCompany(issue) {
            issue.cmpnyNm = null;
        },
        setExpctdPrgrssDt(issue, date) {
            issue.expctdPrgrssDtInput = new Date(date).toISOString();
        },
        setPrcssngDt(issue, date) {
            issue.prcssngDtInput = new Date(date).toISOString();
        },
        async saveIssueList() {
            if(this.user.info.usrRole == 'OW') {
                var mntnDtoList = [];
                const formData = new FormData();
                this.issueList.forEach((item) => {
                    if(!item.mntnId) {
                        mntnDtoList.push({mntnDesc: item.mntnDesc, orgNm: item.orgNm})
                        formData.append("attchmnt", item.file);
                    }
                })
                if(mntnDtoList.length > 0) {
                    var request = {
                        mntnDtoList: mntnDtoList,
                        prjId: this.prjId,
                        usrId: this.user.id,
                        usrRole: "OW"
                    }
        
                    formData.append(
                        "request",
                        new Blob([JSON.stringify(request)], { type: "application/json" })
                    );
        
                    this.submitting = true;
                    const rtnval = await Api.post('/api/v1/mntn/create/mntn', formData);
                    this.submitting = false;
                    if(rtnval.result) {
                        alert('정상적으로 접수신청되었습니다.')
                        this.fetchIssueList();
                    }
                    else {
                        alert(rtnval.errormsg)
                    }
                }
                else {
                    alert('저장할 하자보수 신청내역이 없습니다.')
                }
            }
            else if(this.user.info.usrRole == 'SL') {
                var saveCmpnyDtoList = []
                this.issueList.forEach((item) => {
                    if(item.cmpnyId && !item.cmpnyNm) {
                        saveCmpnyDtoList.push({
                            cmpnyId: item.cmpnyId,
                            mntnId: item.mntnId
                        })
                    }
                })

                if(saveCmpnyDtoList.length > 0) {
                    this.submitting = true;
                    const rtnval = await Api.post('/api/v1/mntn/save/cmpny/info', {prjId: this.prjId, saveCmpnyDtoList: saveCmpnyDtoList});
                    this.submitting = false;
                    if(rtnval.result) {
                        alert('담당회사 지정이 정상적으로 처리되었습니다.')
                        this.fetchIssueList();
                    }
                    else {
                        alert(rtnval.errormsg)
                    }
                }
                else {
                    alert('담당회사가 지정된 하자보수건이 없습니다.')
                }
            }
            else {
                var mntnDtDtoList = []
                this.issueList.forEach((item) => {
                    if((!item.expctdPrgrssDt && item.expctdPrgrssDtInput) || (!item.prcssngDt && item.prcssngDtInput)) {
                        mntnDtDtoList.push({
                            expctdPrgrssDt: item.expctdPrgrssDt? item.expctdPrgrssDt : item.expctdPrgrssDtInput,
                            prcssngDt: item.prcssngDt? item.prcssngDt : item.prcssngDtInput,
                            mntnId: item.mntnId
                        })
                    }

                })

                if(mntnDtDtoList.length > 0) {
                    this.submitting = true;
                    const rtnval = await Api.post('/api/v1/mntn/save/mntn/dt', {prjId: this.prjId, mntnDtDtoList: mntnDtDtoList});
                    this.submitting = false;
                    if(rtnval.result) {
                        alert('일정 저장이 정상적으로 처리되었습니다.')
                        this.fetchIssueList();
                    }
                    else {
                        alert(rtnval.errormsg)
                    }
                }
                else {
                    alert('날짜가 지정된 하자보수건이 없습니다.')
                }
            }
        }
    },
}
</script>