import HouseHome from '@/views/mhouse/model/Home.vue'
import MobileHouseHome from '@/views/mhouse/model/MobileHome.vue'
import HouseModelList from '@/views/mhouse/model/HouseModelList.vue'
import MobileHouseModelList from '@/views/mhouse/model/MobileHouseModelList.vue'
import HouseModelDetail from '@/views/mhouse/model/HouseModelDetail.vue'
import MobileHouseModelDetail from '@/views/mhouse/model/MobileHouseModelDetail.vue'
import RealtimeEstimation from '@/views/mhouse/RealtimeEstimation.vue'
import BrandStory from '@/views/mhouse/story/BrandStory.vue'
import MobileBrandStory from '@/views/mhouse/story/MobileBrandStory.vue'

export default [
  {
    path: '/mhouse/home',
    component: HouseHome,
    name: 'HouseHome'
  },
  {
    path: '/mobile/mhouse/home',
    component: MobileHouseHome,
    name: 'MobileHouseHome'
  },
  {
    path: '/mhouse/model',
    component: HouseModelList,
    name: 'HouseModelList'
  },
  {
    path: '/mobile/mhouse/model',
    component: MobileHouseModelList,
    name: 'MobileHouseModelList'
  },
  {
    path: '/mhouse/modelDetail/:id',
    component: HouseModelDetail,
    name: 'HouseModelDetail'
  },
  {
    path: '/mobile/mhouse/modelDetail/:id',
    component: MobileHouseModelDetail,
    name: 'MobileHouseModelDetail'
  },
  {
    path: '/mhouse/estimation',
    component: RealtimeEstimation,
    name: 'RealtimeEstimation'
  },
  {
    path: '/menu/story',
    component: BrandStory,
    name: 'BrandStory'
  },
  {
    path: '/mobile/menu/story',
    component: MobileBrandStory,
    name: 'MobileBrandStory'
  }
]