<template>
    <div class="d-flex">
        <LNB :user="user"/>
        <div class="ca_main">
            <div class="x_div_center">
                <div class="pg_stage">

                    <div class="x_div_center pt-5">
						<div class="" style="width:75%"><span class="fkr-set1 fs-16" style="color:#333333">{{prjNm}}</span></div>
						<div class="text-end" style="width:25%"><span class="fkr-set2" style="color:#484848">설계 > <span class="fw-bold">설계 요약</span></span></div>
					</div>

                    <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                    <div style="height:30px"></div>

                    <div class="mib_box_c w-100 pt-3">
                        <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                            <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-09">구조</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-09">층수</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-09">주차대수</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-09">건축면적</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-09">연면적</span></div>
                        </div>

                        <div class="x_div_center ls_bg1" style="height:55px">
                            <div class="x_div_center px-1" style="width:20%" v-if="(user.info.usrRole == 'OW' || user.info.usrRole == 'SL')">
                                <span class="fkr-set2">{{ strctr }}</span>
                            </div>
                            <div class="x_div_center px-1" style="width:20%" v-else-if="(user.info.usrRole == 'DS')">
                                <input type="text" v-model="strctrInput" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px; border-radius:4px">
                            </div>
                            <div class="x_div_center px-1" style="width:20%" v-if="(user.info.usrRole == 'OW' || user.info.usrRole == 'SL')">
                                <span class="fkr-set2">{{ floors == 0 ? '' : floors }}</span>
                            </div>
                            <div class="x_div_center px-1" style="width:20%" v-else-if="(user.info.usrRole == 'DS')">
                                <input type="text" v-model="floorsInput" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px; border-radius:4px">
                            </div>
                            <div class="x_div_center px-1" style="width:20%" v-if="(user.info.usrRole == 'OW' || user.info.usrRole == 'SL')">
                                <span class="fkr-set2">{{ parking }}</span>
                            </div>
                            <div class="x_div_center px-1" style="width:20%" v-else-if="(user.info.usrRole == 'DS')">
                                <input type="text" v-model="parkingInput" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px; border-radius:4px">
                            </div>
                            <div class="x_div_center px-1" style="width:20%" v-if="(user.info.usrRole == 'OW' || user.info.usrRole == 'SL')">
                                <span class="fkr-set2">{{ buildingArea? buildingArea + '㎡' : '' }}</span>
                            </div>
                            <div class="x_div_center px-1" style="width:20%" v-else-if="(user.info.usrRole == 'DS')">
                                <input type="text" v-model="buildingAreaInput" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px; border-radius:4px">
                            </div>
                            <div class="x_div_center px-1" style="width:20%" v-if="(user.info.usrRole == 'OW' || user.info.usrRole == 'SL')">
                                <span class="fkr-set2">{{ totalGroundArea? totalGroundArea + '㎡' : '' }}</span>
                            </div>
                            <div class="x_div_center px-1" style="width:20%" v-else-if="(user.info.usrRole == 'DS')">
                                <input type="text" v-model="totalGroundAreaInput" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px; border-radius:4px">
                            </div>
                        </div>
                    </div>

                    <div style="height:20px"></div>

                    <div class="mib_box_c w-100 x_div_center pt-3"><div style="border-bottom:2px solid #c9c9c9"><h3 class="fkr-set1 fs-11">설계 대표 이미지</h3></div></div>

                    <div style="height:20px"></div>

                    <div class="pg_a_set3">
                        <div class="pg_a_set3a">
                            <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                                <h3 v-if="!dsgnRprsnttvImgPath"><i class='fa fa-spinner fa-spin'></i></h3>
                                <div v-else class="x_div_center"><img id="img1" style="width: 100%; border:0px solid #c9c9c9; border-radius:8px" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + dsgnRprsnttvImgPath"></div>
                            </div>
                        </div>
                        <div class="pg_a_set3c"></div>
                        <div class="pg_a_set3b">
                            <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                                <h3 v-if="!dsgnRprsnttvImgPath2"><i class='fa fa-spinner fa-spin'></i></h3>
                                <div v-else class="x_div_center"><img id="img2" style="border:0px solid #c9c9c9; border-radius:8px" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + dsgnRprsnttvImgPath2" width="100%"></div>
                            </div>
                        </div>
                    </div>
                    <div style="height:20px"></div>

                    <div class="table-responsive" v-if="user.info.usrRole == 'DS'">
                        <table class="table-borderless w-100">
                            <tr>
                                <td class="text-center br_0" width="50%">
                                    <input type="file" @change="fileHandling($event, 1)" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px; border-radius:4px; background-color:#f6f2f2; border:1px solid #4e0000" placeholder="">
                                </td>
                                <td class="text-center" width="50%">
                                    <input type="file" @change="fileHandling($event, 2)" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px; border-radius:4px; background-color:#f6f2f2; border:1px solid #4e0000" placeholder="">
                                </td>
                            </tr>
                        </table>
                    </div>
                    
                    <div class="x_div_end" v-if="user.info.usrRole == 'DS'">
                        <div class="py-2 pe-1"><button class="fkr-set2 fs-095" @click="saveInfo()" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                    </div>

                    <div class="x_div_end" v-if="user.info.usrRole == 'OW'">
                        <div class="py-2 pe-1"><button class="fkr-set2 fs-095" @click="moveToReview()" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">후기 작성</button></div>
                    </div>

                    <div style="height:20px"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LNB from '@/components/LNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'PrcStpDsgnInfo',
    components: {
		LNB
	},
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            prjId: null,
            prjNm: '',
            dsgnRprsnttvImg: [null, null],
            dsgnRprsnttvImgOrgNm: [null, null],
            dsgnRprsnttvImgId: [null, null],
            dsgnRprsnttvImgPath: null,
            dsgnRprsnttvImgPath2: null,
            strctr: null,
            floors: 0,
            parking: null,
            buildingArea: null,
            totalGroundArea: null,
            strctrInput: null,
            floorsInput: null,
            parkingInput: null,
            buildingAreaInput: null,
            totalGroundAreaInput: null,
            submitting: false,
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET,
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }

        if(this.user.info.usrRole != 'OW' && this.user.info.usrRole != 'SL' && this.user.info.usrRole != 'DS') {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }

        this.prjId = sessionStorage.getItem('prjId');
        this.prjNm = sessionStorage.getItem('prjNm');

        if(this.prjId == 'null') {
            alert('My Projects 페이지에서 프로젝트를 선택하셔야 합니다.');
            location.href = '/project/dashboard'
        }

        this.fetchDesignInfo();
    },
    methods: {
        async fetchDesignInfo() {
            const rtnval = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + this.prjId);
            if(rtnval.result) {
                this.strctr = rtnval.data.prj.strctr;
                this.floors = rtnval.data.prj.floors;
                this.parking = rtnval.data.prj.parking;
                this.buildingArea = rtnval.data.prj.buildingArea;
                this.totalGroundArea = rtnval.data.prj.totalGroundArea;
                this.strctrInput = rtnval.data.prj.strctr;
                this.floorsInput = rtnval.data.prj.floors;
                this.parkingInput = rtnval.data.prj.parking;
                this.buildingAreaInput = rtnval.data.prj.buildingArea;
                this.totalGroundAreaInput = rtnval.data.prj.totalGroundArea;
                this.dsgnRprsnttvImgId = rtnval.data.dsgnRprsnttvImgId;
                this.dsgnRprsnttvImgPath = rtnval.data.dsgnRprsnttvImgPath[0];
                this.dsgnRprsnttvImgPath2 = rtnval.data.dsgnRprsnttvImgPath[1];
            }
            else {
                alert(rtnval.errormsg)
            }
        },
        fileHandling(e, index) {
            this.dsgnRprsnttvImg[index-1] = e.target.files[0];
            this.dsgnRprsnttvImgOrgNm[index-1] = e.target.files[0].name;

            window.$('#img' + index).attr('src', URL.createObjectURL(e.target.files[0]));
        },
        async saveInfo() {
            this.submitting = true;
            const rtnvalue = await Api.post('/api/v1/project/update/prj/dtl', {
                buildingArea: this.buildingArea? this.buildingArea : this.buildingAreaInput,
                floors: this.floors? this.floors : this.floorsInput,
                parking: this.parking? this.parking : this.parkingInput,
                strctr: this.strctr? this.strctr : this.strctrInput,
                totalGroundArea: this.totalGroundAreaInput,
                prjId: this.prjId
            });
            if(rtnvalue.result) {
                const formData = new FormData();
                var attchFlCtgry = [];
                var attchOrgNmList = [];
                var deleteFlIdList = [];
                var dirNmList = [];
                var prjId = [];

                this.dsgnRprsnttvImgOrgNm.forEach((item, i) => {
                    if(item) {
                        formData.append("attchmntList", this.dsgnRprsnttvImg[i]);
                        attchFlCtgry.push('DR');
                        attchOrgNmList.push(item);
                        deleteFlIdList.push(this.dsgnRprsnttvImgId[i]);
                        dirNmList.push('platform/dsgn/rprsnttv');
                        prjId.push(this.prjId);
                    }
                })
                if(attchOrgNmList.length > 0) {
                    var request = {
                        attchFlCtgry: attchFlCtgry,
                        attchOrgNmList: attchOrgNmList,
                        deleteFlIdList: deleteFlIdList,
                        dirNmList: dirNmList,
                        parentId: prjId
                    }
                    formData.append(
                        "request",
                        new Blob([JSON.stringify(request)], { type: "application/json" })
                    );
                    const rtnval = await Api.post('/api/v1/common/set/attachments', formData)
                    if(!rtnval.result) {
                        alert(rtnval.errormsg)
                    }
                }
                await this.fetchDesignInfo();
            }
            else {
                alert(rtnvalue.errormsg)
            }
            this.submitting = false;
        },
        moveToReview() {
            location.href = '/project/review/DS';
        }
    }
}
</script>