<template>
<div class="d-flex">
    <LNB :user="user"/>
    <div class="ca_main">
        <div class="x_div_center">
            <div class="pg_stage">

                <div class="x_div_center pt-5">
                    <div class="" style="width:70%"><span class="fkr-set1 fs-16" style="color:#333333">{{prjNm}}</span></div>
                    <div class="text-end" style="width:30%"><span class="fkr-set2" style="color:#484848">{{prcNm}} > <span class="fw-bold">만족도 평가</span></span></div>
                </div>

                <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                <div>

                    <div class="w-100 x_div_center pt-5"><div style="border-bottom:2px solid #c9c9c9"><h3 class="fkr-set1 fs-11">만족도 평가</h3></div></div>

                    <div class="w-100 x_div_center pt-5"><div><span class="fkr-set2 fs-10">파트너기관 만족도가 어떠셨나요?</span></div></div>

                    <div class="form-group">
                        <div class="table-responsive x_div_center">
                            <table class="table-borderless">
                            <tr style="height:55px">
                                <td class="text-center" width="110"><span class="fa-kor-Noto" style="font-size:12pt">매우만족</span></td>
                                <td class="text-center" width="110"><span class="fa-kor-Noto" style="font-size:12pt">만족</span></td>
                                <td class="text-center" width="110"><span class="fa-kor-Noto" style="font-size:12pt">보통</span></td>
                                <td class="text-center" width="110"><span class="fa-kor-Noto" style="font-size:12pt">불만</span></td>
                                <td class="text-center" width="110"><span class="fa-kor-Noto" style="font-size:12pt">매우불만</span></td>
                            </tr>
                            <tr>
                                <td class="text-center"><div class="custom-control custom-radio"><input type="radio" name="cf" v-model="prtnrStsfctn" value="5" class="custom-control-input" id="cf-1"><label class="custom-control-label" for="cf-1"></label></div></td>
                                <td class="text-center"><div class="custom-control custom-radio"><input type="radio" name="cf" v-model="prtnrStsfctn" value="4" class="custom-control-input" id="cf-2"><label class="custom-control-label" for="cf-2"></label></div></td>
                                <td class="text-center"><div class="custom-control custom-radio"><input type="radio" name="cf" v-model="prtnrStsfctn" value="3" class="custom-control-input" id="cf-3"><label class="custom-control-label" for="cf-3"></label></div></td>
                                <td class="text-center"><div class="custom-control custom-radio"><input type="radio" name="cf" v-model="prtnrStsfctn" value="2" class="custom-control-input" id="cf-4"><label class="custom-control-label" for="cf-4"></label></div></td>
                                <td class="text-center"><div class="custom-control custom-radio"><input type="radio" name="cf" v-model="prtnrStsfctn" value="1" class="custom-control-input" id="cf-5"><label class="custom-control-label" for="cf-5"></label></div></td>
                            </tr>
                            </table>
                        </div>
                    </div>

                    <div class="w-100 x_div_center pt-5"><div><span class="fkr-set2 fs-10">M3 Systems의 파트너기관 연결 서비스 만족도가 어떠셨나요?</span></div></div>

                    <div class="form-group">
                        <div class="table-responsive x_div_center">
                            <table class="table-borderless">
                            <tr style="height:55px">
                                <td class="text-center" width="110"><span class="fa-kor-Noto" style="font-size:12pt">매우만족</span></td>
                                <td class="text-center" width="110"><span class="fa-kor-Noto" style="font-size:12pt">만족</span></td>
                                <td class="text-center" width="110"><span class="fa-kor-Noto" style="font-size:12pt">보통</span></td>
                                <td class="text-center" width="110"><span class="fa-kor-Noto" style="font-size:12pt">불만</span></td>
                                <td class="text-center" width="110"><span class="fa-kor-Noto" style="font-size:12pt">매우불만</span></td>
                            </tr>
                            <tr>
                                <td class="text-center"><div class="custom-control custom-radio"><input type="radio" name="cr" v-model="srvcStsfctn" value="5" class="custom-control-input" id="cr-1"><label class="custom-control-label" for="cr-1"></label></div></td>
                                <td class="text-center"><div class="custom-control custom-radio"><input type="radio" name="cr" v-model="srvcStsfctn" value="4" class="custom-control-input" id="cr-2"><label class="custom-control-label" for="cr-2"></label></div></td>
                                <td class="text-center"><div class="custom-control custom-radio"><input type="radio" name="cr" v-model="srvcStsfctn" value="3" class="custom-control-input" id="cr-3"><label class="custom-control-label" for="cr-3"></label></div></td>
                                <td class="text-center"><div class="custom-control custom-radio"><input type="radio" name="cr" v-model="srvcStsfctn" value="2" class="custom-control-input" id="cr-4"><label class="custom-control-label" for="cr-4"></label></div></td>
                                <td class="text-center"><div class="custom-control custom-radio"><input type="radio" name="cr" v-model="srvcStsfctn" value="1" class="custom-control-input" id="cr-5"><label class="custom-control-label" for="cr-5"></label></div></td>
                            </tr>
                            </table>
                        </div>
                    </div>


                    <div class="w-100 x_div_center pt-5 pb-3"><div><span class="fkr-set2 fs-10">{{prcNm}} 과정에서의 의견을 자유로이 기술해주세요.</span></div></div>

                    <div><textarea class="w-100 form-control" v-model="opinion" style="height:250px; border:1px solid #c9c9c9; border-radius:4px"></textarea></div>

                </div>

                <div style="height:20px"></div>

                <div class="">
                    <div class="x_div_end py-2"><button class="fkr-set2 fs-095" @click="submit()" :disabled="submitting" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">응답완료<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                </div>

                <div style="height:50px"></div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import LNB from '@/components/LNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'PartnerReview',
    components: {
		LNB
	},
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            prjId: null,
            prjNm: null,
            prcTree: null,
            prcNm: null,
            prtnrId: null,
            prtnrStsfctn: null,
            srvcStsfctn: null,
            opinion: '',
            submitting: false,
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }

        if(this.user.info.usrRole != 'OW') {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }

        this.prjId = sessionStorage.getItem('prjId');
        this.prjNm = sessionStorage.getItem('prjNm');
        this.prcTree = JSON.parse(sessionStorage.getItem('prcTree'));
        this.prcTree.processList.forEach((prc) => {
            if(prc.code == this.$route.params.prcCd) {
                this.prcNm = prc.name;
            }
        })

        if(this.prjId == 'null') {
            alert('My Projects 페이지에서 프로젝트를 선택하셔야 합니다.');
            location.href = '/project/dashboard'
        }

        const rtnval = await Api.post('/api/v1/process/get', {prjId: this.prjId, prcCd: this.$route.params.prcCd});
        if(rtnval.result) {
            this.prtnrId = rtnval.data.prtnrInfo.usrId;
        }
        else {
            alert(rtnval.errormsg)
        }
    },
    methods: {
        async submit() {
            if(!this.prtnrStsfctn || !this.srvcStsfctn) {
                alert('평가항목을 기입해주세요.');
                return 1;
            }

            this.submitting = true;
            const rtnval = await Api.post('/api/v1/user/evaluate/prtnr/rate', {
                opinion: this.opinion.replace(/\n/g, '<br/>'),
                ownrId: this.user.id,
                prtnrId: this.prtnrId,
                prtnrStsfctn: this.prtnrStsfctn,
                srvcStsfctn: this.srvcStsfctn
            });
            this.submitting = false;
            if(rtnval.result) {
                alert('만족도 평가가 정상적으로 제출되었습니다.\n귀중한 의견 감사드립니다.');
                location.href = '/project/summary/' + this.prjId;
            }
            else {
                alert(rtnval.errormsg)
            }
        }
    }
}
</script>