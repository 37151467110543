<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">출타현황 조회용 키워드 설정</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>키워드목록</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">키워드 추가</span></div>
                <div class="x_div_center" style="width:70%"><span class="fkr-set1 fs-9">키워드 목록</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div class="x_div_center" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center" style="width:30%;">
                    <span class="fkr-set2 fs-9" style="display:flex">
                      <input type="text" v-model="newKeyword" @keyup.enter="setKeyword()" class="form-control fkr-set2 fc-w5" style="width:70%; height:27px; border-radius:4px">
                      <a href="javascript:void(0)" @click="setKeyword()" style="margin: 3px 0 0 10px "><i class="fas fa-plus-circle"></i></a>
                    </span>
                  </div>
                  <div class="x_div_center" style="width:70%; justify-content:left">
                    <span class="fkr-set2 fs-9" style="display:flex;">
                      <p v-for="(keyword, i) in keywordList" :key="i" style="margin: 0 15px 0 0">{{ keyword }}&nbsp;
                        <a href="javascript:void(0)" @click="deleteKeyword(keyword)"><i class="fas fa-backspace"></i></a>
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <!-- <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; renderList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div> -->

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "EventKeywordList",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: true,
        keywordList: [],
        keywordListPaged: [],
        newKeyword: null,
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);

    const returnvalue = await Api.get('/api/v1/admin/schedule/list/keyword');
    if(returnvalue.result) {
        this.keywordList = returnvalue.data;
    }
    else {
        alert(returnvalue.errormsg);
    }
    this.fetching = false;
    // this.setPaging();
    // this.renderList();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    renderList() {
      this.keywordListPaged = [];
      for(var j = (this.currentPage-1) * this.pageSize; j < (this.keywordList.length > this.currentPage * this.pageSize? this.currentPage * this.pageSize : this.keywordList.length); j++) {
        this.keywordListPaged.push(this.keywordList[j]);
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.keywordList.length / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },
  
    async setKeyword() {
      if(this.newKeyword) {
        const rtnval = await Api.post('/api/v1/admin/schedule/set/keyword', {param1: this.newKeyword})
        if(rtnval.result) {
          if(rtnval.data) {
            alert('정상적으로 등록되었습니다.');
            location.reload();
          }
          else {
            alert('이미 등록된 키워드입니다.');
          }
        }
        else {
          alert(rtnval.errormsg)
        }
        this.newKeyword = null;
      }
      else {
        alert('키워드를 올바로 입력하세요.');
        return;
      }
    },

    async deleteKeyword(keyword) {
      if(confirm('해당 키워드를 삭제하시겠습니까?')) {
        const rtnval = await Api.post('/api/v1/admin/schedule/delete/keyword', {param1: keyword})
        if(rtnval.result) {
          if(rtnval.data) {
            alert('정상적으로 삭제되었습니다.');
            location.reload();
          }
          else {
            alert('등록되지 않은 키워드입니다.');
          }
        }
        else {
          alert(rtnval.errormsg)
        }
      }
    },
  }
}
</script>

<style scoped>
.mib_box_a {
    width: auto;
}
</style>
