<template>
    <div class="m3_contetns" style="margin-top: 50px">

        <!-- mypage 시작 { -->
        <div class="m_mem_box">

            <div style="width:90%">

                <div class="">
                    <div class="w-100 x_div_center pb-3"><div class="h3 ff-kor1 fw-bold fc-w6">내정보 관리</div></div>
                </div>

                <div>
                    <div class="x_div_center py-3 ls_bg1" style="border-top:1px solid #e7e7e7">
                        <div class="x_div_center" style="width:180px">
                            <img v-if="this.user.info.usrType == 'MA'" src="@/assets/img/M3_short_Icon.png" style="width:64px; height:auto">
                            <i v-else class="fa-solid fa-circle-user fc-w3" style="font-size:4rem"></i>
                        </div>
                        <div class="w-100"><span class="fkr-set1 fs-12"> {{ this.user.info.fullName }} </span></div>
                    </div>
                    <div class="x_div_center py-3 ls_bg1">
                        <div class="x_div_center" style="width:180px"><span class="fen-set1">E-Mail</span></div>
                        <div class="w-100"><span class="fen-set1"> {{ this.user.info.email }} </span></div>
                    </div>
                    <div class="x_div_center py-3 ls_bg1">
                        <div class="x_div_center" style="width:180px"><span class="fen-set1">휴대폰번호</span></div>
                        <div class="w-100"><span class="fen-set1"> {{ phoneDashed }} </span></div>
                    </div>
                    <div v-if="this.user.info.usrType == 'MA'">
                        <div class="x_div py-3 ls_bg1">
                            <ul class="custom-control custom-checkbox" style="margin-left:45px">
                                <li class="chk_box"><input type="checkbox" id="scheduleReportAgree" name="scheduleReportAgree" class="custom-control-input" v-model="scheduleReportRcvYn" value="true" >
                                <label class="custom-control-label" for="scheduleReportAgree"><span class="fkr-set2">출타현황 매일보고 수신</span></label></li>
                            </ul>
                        </div>
                        <div class="x_div_center py-3 ls_bg1" style="margin-bottom: 8px;">
                            <div class="x_div_center" style="width:180px"><span class="fen-set1">조회대상부서</span></div>
                            <div class="w-100">
                                <span class="fen-set1">
                                    <span class="fkr-set2 fs-9" :style="isMobile? '' : 'display:flex; flex-wrap:wrap'">
                                        <p v-for="(subsc, i) in orgUnitSubscList" :key="i" style="margin: 0 15px 5px 0 !important">{{ subsc }}&nbsp;
                                            <a href="javascript:void(0)" @click="deleteSubsc(subsc)"><i class="fas fa-backspace"></i></a>
                                        </p>
                                        <a v-if="!editSubsc" href="javascript:void(0)" @click="editSubsc = true;" style="margin-left: 10px"><i class="fas fa-plus-circle"></i></a>
                                        <p>
                                            <select v-if="editSubsc" class="custom-select ff-eng1 fs-085" v-model="unitSelected" style="width:120px; height:27px; padding: 1px 0 1px 5px; margin:0">
                                                <option v-for="(unit, j) in orgUnitList" :key="j" :value="unit">{{ unit }}</option>
                                            </select>
                                            <a v-if="editSubsc" href="javascript:void(0)" @click="addSubsc(unitSelected)" style="margin-left: 10px"><i class="fas fa-check"></i></a>
                                            <a v-if="editSubsc" href="javascript:void(0)" @click="editSubsc = false;" style="margin-left: 10px"><i class="fas fa-undo"></i></a>
                                        </p>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <span :style="'color: red; font-size: smaller;' + (isMobile? '' : 'margin-left: 40px')">캘린더 출타일정 등록시 이벤트 제목에 다음 키워드를 포함시키세요.</span>
                        <div class="x_div_center py-3 ls_bg1" style="padding-top: 5px !important">
                            <div class="x_div_center" style="width:180px"><span class="fen-set1"></span></div>
                            <div class="w-100">
                                <span class="fen-set1">
                                    <span class="fkr-set2 fs-9" style="display:flex; flex-wrap:wrap">
                                        <p v-for="(keyword, i) in eventKeywordList" :key="i" style="margin: 0 15px 5px 0 !important">{{ keyword }}&nbsp;</p>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-if="this.user.info.usrType == 'PA'">
                        <div class="x_div_center py-3 ls_bg1">
                            <div class="x_div_center" style="width:180px"><span class="fen-set1">주소</span></div>
                            <div class="w-100"><span class="fen-set1"> {{ addr }} </span></div>
                        </div>
                        <div class="x_div_center py-3 ls_bg1">
                            <div class="x_div_center" style="width:180px"><span class="fkr-set1">사업자정보</span></div>
                            <div class="w-100" style="display:flex">
                                <span class="fen-set1"> {{ bsnssNmbrDashed }} </span> &nbsp; &nbsp; 
                                <form method="post" action="/api/v1/user/bsnss/filedownload">
                                    <input type="hidden" name="fileName" :value="bsnssFileName"/>
                                    <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ this.user.info.bsnssOrgNm }} </span></a>
                                </form>
                            </div>
                        </div>
                        <div class="x_div_center py-3 ls_bg1">
                            <div class="x_div_center" style="width:180px"><span class="fkr-set1">지명원</span></div>
                            <div class="w-100">
                                <form method="post" action="/api/v1/user/prof/filedownload">
                                    <input type="hidden" name="fileName" :value="profFileName"/>
                                    <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ this.user.info.profOrgNm }} </span></a>
                                </form>
                            </div>
                        </div>
                        <div class="x_div_center py-3 ls_bg1">
                            <div class="x_div_center" style="width:180px"><span class="fkr-set1">국가기술자격증</span></div>
                            <div class="w-100">
                                <form method="post" action="/api/v1/user/ntnl/filedownload">
                                    <input type="hidden" name="fileName" :value="ntnlFileName"/>
                                    <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ this.user.info.ntnlOrgNm }} </span></a>
                                </form>
                            </div>
                        </div>
                        <div class="x_div_center py-3 ls_bg1">
                            <div class="x_div_center" style="width:180px"><span class="fkr-set1">홈페이지</span></div>
                            <div class="w-100"><a :href="this.user.info.hmpg" target="_blank"><span class="fen-set2"> {{ this.user.info.hmpg }} </span></a></div>
                        </div>
                        <div class="x_div_center py-3 ls_bg1">
                            <div class="x_div_center" style="width:180px"><span class="fkr-set1">포트폴리오</span></div>
                            <div class="w-100"><a :href="this.user.info.link" target="_blank"><span class="fen-set2"> {{ this.user.info.link }} </span></a></div>
                        </div>
                    </div>
                </div>

                <div class="y_div_blank1rem"></div>

                <div v-if="this.user.info.usrType == 'PA'">

                <div class="w-100 pt-3" style="padding-bottom: 10px"><h3 class="fkr-set1 fc-w6">수상이력</h3></div>
                    <div>
                        <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background:#000;">
                            <div class="x_div_center" style="width:25%"><span class="fkr-set2 fc-w1">수상명</span></div>
                            <div class="x_div_center" style="width:25%"><span class="fkr-set2 fc-w1">수상기관</span></div>
                            <div class="x_div_center" style="width:30%"><span class="fkr-set2 fc-w1">프로젝트</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">수상연도</span></div>
                        </div>

                        <div class="x_div_center ls_bg1" style="height:55px" v-for="(award, index) in this.user.info.awardList" :key="index">
                            <div class="x_div_center br_1" style="width:25%"><span class="fkr-set2"> {{ award.awardNm }} </span></div>
                            <div class="x_div_center br_1" style="width:25%"><span class="fkr-set2"> {{ award.awardInst }} </span></div>
                            <div class="x_div_center br_1" style="width:30%"><span class="fkr-set2"> {{ award.awardPrj }} </span></div>
                            <div class="x_div_center" style="width:20%"><span class="fen-set2"> {{ award.awardYear }} </span></div>
                        </div>

                    </div>
                </div>

                <div class="x_div_center pt-4">
                    <div class="px-2"><button class="fkr-set1" onclick="location.href='/member/modify'" style="width:150px; height:37px; border:0; background-color:#000; border-radius:4px; color:#fff">내정보 수정하기</button></div>
                    <div class="px-2"><button class="fkr-set1" v-if="this.user.info.oauthType == 'NONE'" onclick="location.href='/member/modifypass'" style="width:150px; height:37px; border:0; background-color:#000; border-radius:4px; color:#fff">비밀번호 수정하기</button></div>
                </div>

                <div class="x_div_center py-3" style="border-bottom:1px solid #e7e7e7">
                    <!-- <button class="fkr-set1 fc-w1" style="width:180px; height:36px; background-color:#4f5962; border:0" onclick="location.href='/member/modify'">내정보 수정하기</button> -->
                </div>
                

                <div>
                    <div class="x_div_center py-3">
                        <div class="w-100"><span class="fkr-set1">M3시스템즈 홈페이지에서 탈퇴를 원하시는 경우</span></div>
                        <div class="x_div_center" style="width:180px"><button class="fkr-set1 fc-w1" style="width:120px; height:36px; background-color:#c00; border:0" @click="removeMembership()">회원 탈퇴하기</button></div>
                    </div>
                </div>
            </div>

        </div>
        <!-- } mypage 끝 -->

    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'ProfilePage',
    props: {
        user: {
            type: Object,
            default: null
        },
        isMobile: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            addr: '',
            phoneDashed: '',
            bsnssNmbrDashed: '',
            bsnssFileName: '',
            ntnlFileName: '',
            profFileName: '',
            scheduleReportRcvYn: null,
            orgUnitSubscList: [],
            orgUnitList: [],
            unitSelected: null,
            eventKeywordList: [],
            editSubsc: false
        }
    },
    watch: {
        async scheduleReportRcvYn(newVal, oldVal) {
            if(oldVal != null) {
                var rtnval = await Api.post('/api/v1/user/set/schedulereportyn', {param1: newVal? "Y" : "N"});
                if(!rtnval.result){
                    alert(rtnval.errormsg);
                }
            }
        }
    },
    created() {
        this.scheduleReportRcvYn = this.user.info.scheduleReportRcvYn && this.user.info.scheduleReportRcvYn == 'Y'? true : false;
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login?fromProfile=1'
        }

        const phone = this.user.info.phone;
        if(phone.length == 10) {
            this.phoneDashed = phone.substring(0,3) + '-' + phone.substring(3,6) + '-' + phone.substring(6);
        }
        else{
            this.phoneDashed = phone.substring(0,3) + '-' + phone.substring(3,7) + '-' + phone.substring(7);
        }

        if(this.user.info.usrType == 'PA') {
            this.addr = '(' + this.user.info.postCd + ') ' + this.user.info.addr1 + ' ' + this.user.info.addr2;
            const bsnssNmbr = this.user.info.bsnssNmbr;
            this.bsnssNmbrDashed = bsnssNmbr.substring(0,3) + '-' + bsnssNmbr.substring(3,5) + '-' + bsnssNmbr.substring(5); 
            this.bsnssFileName = this.user.info.bsnssImgPath.replace("platform/usr/bsnss/", '');
            this.ntnlFileName = this.user.info.ntnlImgPath? this.user.info.ntnlImgPath.replace("platform/usr/ntnl/", '') : '';
            this.profFileName = this.user.info.profImgPath? this.user.info.profImgPath.replace("platform/usr/prof/", '') : '';
        }

        if(this.user.info.usrType == 'MA') {
            var rtnval = await Api.get('/api/v1/user/get/orgunitlist');
            if(rtnval.result){
                this.orgUnitSubscList = rtnval.data.orgUnitSubscList;
                this.orgUnitList = rtnval.data.orgUnitList;
            }
            else {
                alert(rtnval.errormsg);
            }

            rtnval = await Api.get('/api/v1/admin/schedule/list/keyword');
            if(rtnval.result){
                this.eventKeywordList = rtnval.data;
            }
            else {
                alert(rtnval.errormsg);
            }
        }
    },
    methods: {
        async removeMembership() {
            if(confirm('탈퇴하시면 모든 데이터와 회원정보가 삭제됩니다.\n회원 탈퇴를 진행하시겠습니까?')){
                var rtnval = await Api.post('/api/v1/user/delete/user', {id: this.user.id});
                if(rtnval.result){
                    alert('정상적으로 탈퇴 처리 되었습니다.');
                    Auth.logOut();
                    location.href = '/';
                }
                else {
                    alert(rtnval.errormsg);
                }
            }
        },

        async addSubsc(unitSelected) {
            const rtnval = await Api.post('/api/v1/user/set/orgunit/subsc', {param1: unitSelected})
            if(rtnval.result) {
                this.orgUnitSubscList.push(unitSelected);
            }
            else {
                alert(rtnval.errormsg)
            }
            this.editSubsc = false;
        },

        async deleteSubsc(orgUnitPath) {
            const rtnval = await Api.post('/api/v1/user/delete/orgunit/subsc', {param1: orgUnitPath})
            if(rtnval.result) {
                this.orgUnitSubscList = this.orgUnitSubscList.filter((unit) => unit != orgUnitPath);
            }
            else {
                alert(rtnval.errormsg)
            }
        },
    }
}
</script>