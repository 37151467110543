import { render, staticRenderFns } from "./BddngListMng.vue?vue&type=template&id=06a018da&"
import script from "./BddngListMng.vue?vue&type=script&lang=js&"
export * from "./BddngListMng.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports