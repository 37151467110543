import PrjList from '@/views/primitive_project/PrjList.vue'
import PrjEdit from '@/views/primitive_project/PrjEdit.vue'
import PrjDashboard from '@/views/primitive_project/Dashboard.vue'
import Prj from '@/views/primitive_project/Prj.vue'
import Task from '@/views/primitive_project/Task.vue'

export default [
    {
        path: '/panda',
        name: 'PrjList',
        component: PrjList
    },
    {
        path: '/panda/prj-list',
        name: 'PrjList',
        component: PrjList
    },
    {
        path: '/panda/prj-dashboard',
        name: 'PrjDashboard',
        component: PrjDashboard
    },
    {
        path: '/panda/prj-edit',
        name: 'PrjEdit',
        component: PrjEdit
    },
    {
        path: '/panda/prj-edit/:prjId',
        name: 'PrjEdit',
        component: PrjEdit
    },
    {
        path: '/panda/prj/:prjId',
        name: 'Prj',
        component: Prj
    },
    {
        path: '/panda/task',
        name: 'Task',
        component: Task
    },
    {
        path: '/panda/task/:tskId',
        name: 'Task',
        component: Task
    },
]