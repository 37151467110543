<template>
    <div class="m3_contetns" style="margin-top: 50px">

        <div class="m_ipt_box">

            <div class="mib_mod">
                <div class="pg_m_set" v-if="fetching">
                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div v-show="!fetching" class="w-100">

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">위치</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex; flex-wrap:wrap">
                            <span class="fkr-set2" v-if="maplink"><a :href="maplink" target="_blank"><u> {{ addr }} </u></a></span>
                            <span class="fkr-set2" v-else>{{ addr }}</span>
                        </div>
                    </div>

                    <div class="mib_box_a py-3" style="border-bottom:0px !important">
                        <div class="mib_a1 text-end"><span class="fkr-set1">모듈 구성</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex; flex-wrap:wrap">
                        </div>
                    </div>

                    <div class="mib_box_c">
                        <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background:#000;">
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">용도</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">개수</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">폭(m)</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">길이(m)</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">높이(m)</span></div>
                        </div>

                        <div class="x_div_center ls_bg1" style="height:55px" v-for="(cmpnt, index) in cmpntList" :key="index">
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2">{{ cmpnt.type }}</span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2">{{ cmpnt.amnt }}</span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2">{{ cmpnt.width }}</span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2">{{ cmpnt.length }}</span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2">{{ cmpnt.height }}</span></div>
                        </div>
                    </div>

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">구조</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex; flex-wrap:wrap">
                            <li class="custom-radio">
                                <input type="radio" id="structure1" name="structure" class="custom-control-input" v-model="structure" value="1" disabled>
                                <label class="custom-control-label" for="structure1"><span class="fkr-set2">강구조</span></label>
                            </li>
                            <li class="custom-radio">
                                <input type="radio" id="structure2" name="structure" class="custom-control-input" v-model="structure" value="0" disabled>
                                <label class="custom-control-label" for="structure2"><span class="fkr-set2">목구조</span></label>
                            </li>
                        </div>
                    </div>

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">사용기간</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex;">
                            <span class="fkr-set2">{{ period }}년</span>
                        </div>
                    </div>

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">인계희망일자</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3">
                            <span class="fkr-set2">{{ handoverDt? handoverDt.split('T')[0] : handoverDt }}</span>
                        </div>
                    </div>

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">가격 <br v-if="isMobile">(부가세별도)</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex;">
                            <span class="fkr-set2">{{ price.toLocaleString() }} 원</span>
                            <span class="x_div_center fen-set2" v-if="user && sellerId == user.id" style="padding-left:5px">(빌드마켓에 납부할 수수료 : {{ charge.toLocaleString() }} 원)</span>
                        </div>
                    </div>

                    <div class="mib_box_b" v-if="user && sellerId == user.id">
                        <div class="mib_set1 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">도면</span></div>
                            <div class="mib_b2 x_div_center"></div>
                            <div class="mib_b3">
                                <span v-if="floorPlanFetched" class="fkr-set2"><a :href="floorPlanFetched.fileLink" :download="floorPlanFetched.attchNm">{{ floorPlanFetched.attchNm }}</a></span>
                            </div>
                        </div>
                        <div class="mib_set2 py-3" v-if="!isMobile">
                            <div class="mib_b1 text-end" style="opacity: 0">foobar</div>
                            <div class="mib_b2 x_div_center"></div>
                            <div class="mib_b3">
                                <span v-if="floorPlanFetched" class="fkr-set2"><a style="opacity: 0">{{ floorPlanFetched.attchNm }}</a></span>
                            </div>
                        </div>
                    </div>

                    <div class="mib_box_a py-3" style="border: 0; display: block !important;">
                        <div v-for="(bucket, i) in picturesFetchedList" :key="i" class="pg_a_set3" style="margin-top: 15px">
                            <div class="pg_a_set3a">
                                <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                                    <div :class="'x_div_center' + (bucket[0].deleted? ' diagonal' : '')">
                                        <img v-if="['pdf', 'zip'].indexOf(bucket[0].mimeType) < 0" :style="'width: 100%; border:0px solid #c9c9c9; border-radius:8px;' + (bucket[0].deleted? 'opacity: 0.5;' : '')" :src="bucket[0].fileLink">
                                        <span v-else class="fen-set2 fs-10"><a :href="bucket[0].fileLink" :download="bucket[0].attchNm">{{ bucket[0].attchNm }}</a></span>
                                    </div>
                                </div>
                            </div>
                            <div class="pg_a_set3c"></div>
                            <div class="pg_a_set3b">
                                <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                                    <div :class="'x_div_center' + (bucket[1].deleted? ' diagonal' : '')">
                                        <img v-if="['pdf', 'zip'].indexOf(bucket[1].mimeType) < 0" :style="'border:0px solid #c9c9c9; border-radius:8px;' + (bucket[1].deleted? 'opacity: 0.5;' : '')" :src="bucket[1].fileLink" width="100%">
                                        <span v-else class="fen-set2 fs-10"><a :href="bucket[1].fileLink" :download="bucket[1].attchNm">{{ bucket[1].attchNm }}</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mib_box_b" v-if="sellerNm">
                        <div class="mib_set1 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">판매자명</span></div>
                            <div class="mib_b2 x_div_center"></div>
                            <div class="mib_b3">
                                <span class="fkr-set2">{{ sellerNm }}</span>
                            </div>
                        </div>
                        <div class="mib_set2 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">판매자 연락처</span></div>
                            <div class="mib_b2 x_div_center"></div>
                            <div class="mib_b3">
                                <span class="fkr-set2">{{ renderPhoneNumber(sellerPhone) }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="mib_box_b" v-if="user && (sellerId == user.id || user.info.adminYn)">
                        <div class="mib_set1 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">예치금 <br v-if="isMobile">납부영수증</span></div>
                            <div class="mib_b2 x_div_center"></div>
                            <div class="mib_b3">
                                <span v-if="receipt" class="fkr-set2"><a :href="receipt.fileLink" :download="receipt.attchNm">{{ receipt.attchNm }}</a></span>
                                <span v-else class="fkr-set2">예치금 납부 전입니다.</span>
                                <a v-if="receipt && user && user.info.adminYn" href="javascript:void(0)" @click="deleteReceipt()" style="margin-left:5px; padding-top: 2px"><i class="fa fa-trash"/></a>
                            </div>
                        </div>
                        <div class="mib_set2 py-3">
                            <span v-if="receipt" class="fkr-set2" style="opacity: 0"><a>foobar</a></span>
                            <span v-else class="fkr-set2">예치금 납부계좌 : 우리은행 1005-004-412977 <br v-if="isMobile">/ 주식회사 엠쓰리시스템즈
                                <i class="far fa-question-circle" style="cursor:pointer; margin-left:5px" 
                                    onclick="alert('예치금은 빌드마켓에서 거래를 시작하기위한 보증금입니다.\n거래가 성사될경우 예치금은 거래 수수료에서 차감되며, 거래가 성사되지 않을 경우 등록된 매물의 삭제와 함께 예치금은 판매자님께 환불됩니다.')"/>
                            </span>
                        </div>
                    </div>

                    <div class="mib_box_a py-3" style="border-bottom:0px !important" v-if="user && sellerId == user.id">
                        <div class="mib_a1 text-end"><span class="fkr-set1">구매희망자 <br v-if="isMobile">목록</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex; flex-wrap:wrap">
                        </div>
                    </div>


                    <div class="mib_box_c" v-if="user && sellerId == user.id">
                        <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background:#000;">
                            <div class="x_div_center" style="width:30%"><span class="fkr-set2 fc-w1">구매희망자</span></div>
                            <div class="x_div_center" style="width:40%"><span class="fkr-set2 fc-w1">구매희망일시</span></div>
                            <div class="x_div_center" style="width:30%"><span class="fkr-set2 fc-w1">판매의사전달</span></div>
                        </div>

                        <div class="x_div_center ls_bg1" style="height:55px" v-for="(prchsr, index) in prspctPrchsrList" :key="index">
                            <div class="x_div_center px-1" style="width:30%"><span class="fkr-set2">{{ prchsr.prchsrPhone.indexOf("*") <0? renderPhoneNumber(prchsr.prchsrPhone) : prchsr.prchsrPhone }}</span></div>
                            <div class="x_div_center px-1" style="width:40%"><span class="fkr-set2">{{ prchsr.prchsDsrDt.split('.')[0].replace('T', ' | ') }}</span></div>
                            <div class="x_div_center px-1" style="width:30%">
                                <button v-if="prchsr.stts == 0" class="fkr-set1" style="width:70px; height:35px; background-color:#000; border:0; border-radius:4px; color:#fff;" @click="acceptTransaction(0, prchsr.prspctPrchsId)">거래시작<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button>
                                <button v-else-if="prchsr.stts == 1" class="fkr-set1" style="width:70px; height:35px; background-color:lightgray; border:1px solid #000; border-radius:4px; color:#000;" @click="acceptTransaction(1, prchsr.prspctPrchsId)">거래취소<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button>
                                <span v-else-if="prchsr.stts == 2" class="fkr-set2">거래 취소됨</span>
                                <span v-else class="fkr-set2">거래완료</span>
                            </div>
                        </div>
                    </div>

                    
                    <div class="x_div_center pt-4">
                        <div class="px-2"><button class="fkr-set1" style="width:90px; height:37px; background-color:#fff; border:1px solid #000; border-radius:4px; color:#000;" onclick="location.href = '/usdmtrl/list'">목록</button></div>
                        <div class="x_div_center pt-4" style="padding: 0 !important" v-if="user && sellerId == user.id">
                            <div class="px-2"><button class="fkr-set1" style="width:90px; height:37px; background-color:lightgray; border:1px solid #000; border-radius:4px; color:#000;" @click="editMtrl()">수정</button></div>
                            <div class="px-2"><button class="fkr-set1" style="width:90px; height:37px; background-color:#c00; border:0; border-radius:4px; color:#fff;" @click="deleteMtrl()">삭제<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                        </div>
                        <div class="x_div_center pt-4" style="padding: 0 !important" v-else>
                            <div class="px-2" v-if="sttsPrspct == null"><button class="fkr-set1" :style="'width:90px; height:37px; background-color:#000; border:0; border-radius:4px; color:#fff;' + (stts == 1? '' : 'opacity: 0.5')" :disabled="stts != 1" @click="requestTransaction(0)">구매요청<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                            <div class="px-2" v-else-if="sttsPrspct == 0"><button class="fkr-set1" style="width:90px; height:37px; background-color:lightgray; border:1px solid #000; border-radius:4px; color:#000;" @click="requestTransaction(1)">구매요청취소<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                            <div class="px-2" v-else-if="sttsPrspct == 1"><button class="fkr-set1" style="width:90px; height:37px; background-color:#000; border:0; border-radius:4px; color:#fff;" @click="requestTransaction(2)">계약<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import Api from '@/api'
export default {
    name: 'MtrlDetail',
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    watch: {
        price(val){
            const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;
            // 한글, 영문 체크
            if(reg.exec(val)!==null) val = String(val).replace(/[^0-9]/g,'');
            this.price = String(val).replace(/[^\d]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // .... 만 입력하게 될 경우 체크
            if(isNaN(parseFloat(val))) this.price = '';
            this.charge = Number(this.price.replace(/[^\d]+/g, '')) * 0.05
        },
    },
    data() {
        return {
            fetching: true,
            submitting: false,
            isMobile: false,
            usdMtrlId: null,
            sellerId: null,
            sellerNm: null,
            sellerPhone: null,
            maplink: null,
            addr: null,
            cmpntList: [],
            structure: 1,
            period: 1,
            handoverDt: null,
            price: 0,
            charge: 0,
            receipt: null,
            floorPlanFetched: null,
            picturesFetchedList: [],
            prspctPrchsrList: [],
            stts: null,
            sttsPrspct: null,
            prspctPrchsId: null,
            daumObj: null,
        }
    },
    async mounted() {

        this.isMobile = window.innerWidth < 768;

        this.usdMtrlId = this.$route.params.usdMtrlId;
        const rtnval = await Api.post('/api/v1/usdmtrl/find/usdmtrl', {usdMtrlId: this.usdMtrlId, usrId: this.user? this.user.id : null});
        if(rtnval.result){
            this.sellerId = rtnval.data.sellerId;
            this.sellerNm = rtnval.data.sellerNm;
            this.sellerPhone = rtnval.data.sellerPhone;
            this.addr = rtnval.data.addr;
            this.structure = rtnval.data.structure;
            this.period = rtnval.data.period;
            this.handoverDt = rtnval.data.handoverDt;
            this.price = rtnval.data.price;
            this.cmpntList = rtnval.data.cmpntList;
            this.stts = rtnval.data.stts;
            this.sttsPrspct = rtnval.data.sttsPrspct;
            this.prspctPrchsId = rtnval.data.prspctPrchsId;
            this.prspctPrchsrList = rtnval.data.prspctPrchsrList;
        }
        else {
            alert(rtnval.errormsg);
        }

        this.cmpntList.forEach(((cmpnt) => {
            switch(cmpnt.usage) {
                case 0 : cmpnt.type = '교실';
                    break;
                case 1 : cmpnt.type = '복도';
                    break;
                case 2 : cmpnt.type = '계단';
                    break;
                case 3 : cmpnt.type = '화장실';
                    break;
            }
        }))

        if(this.addr.split(" ").length > 1) {
            this.maplink = this.isMobileDevice? 'https://m.map.naver.com/search2/search.naver?query=' + this.addr + '#/map' : 'https://map.naver.com/search?query=' + this.addr
        }

        var pictureAttchFiles = [];
        if(rtnval.data.attchflList) {
            for(var i=0; i<rtnval.data.attchflList.length; i++) {
                var usdMtrlAttchFlCtgry = rtnval.data.attchflList[i].usdMtrlAttchFlCtgry.code;
                if( usdMtrlAttchFlCtgry == "USEDIM" ) {
                    pictureAttchFiles.push(rtnval.data.attchflList[i])
                }
                else if( usdMtrlAttchFlCtgry == "USEDFP" || usdMtrlAttchFlCtgry == "USEDRC") {
                    var tokens = rtnval.data.attchflList[i].attchNm.split('.');
                    var mimeType = tokens[tokens.length-1].toLowerCase();
                    var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;

                    const byteCharacters = atob(rtnval.data.attchflList[i].attchData);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let j = 0; j < byteCharacters.length; j++) {
                        byteNumbers[j] = byteCharacters.charCodeAt(j);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], {type: fileType});
                    const fileLink = URL.createObjectURL(blob);

                    if(usdMtrlAttchFlCtgry == "USEDFP") {
                        this.floorPlanFetched = {id: rtnval.data.attchflList[i].id, attchNm: rtnval.data.attchflList[i].attchNm, fileLink: fileLink, mimeType: mimeType, deleted: false};
                    }
                    else {
                        this.receipt = {id: rtnval.data.attchflList[i].id, attchNm: rtnval.data.attchflList[i].attchNm, fileLink: fileLink, mimeType: mimeType, deleted: false};
                    }
                }
            }
        }
        var tempBucket = [];
        var k=0;
        if(pictureAttchFiles) {
            for(i=0; i<pictureAttchFiles.length; i++) {
                var tokens = pictureAttchFiles[i].attchNm.split('.');
                var mimeType = tokens[tokens.length-1].toLowerCase();
                var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;

                const byteCharacters = atob(pictureAttchFiles[i].attchData);
                const byteNumbers = new Array(byteCharacters.length);
                for (let j = 0; j < byteCharacters.length; j++) {
                    byteNumbers[j] = byteCharacters.charCodeAt(j);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {type: fileType});
                const fileLink = URL.createObjectURL(blob);

                tempBucket.push({id: pictureAttchFiles[i].id, attchNm: pictureAttchFiles[i].attchNm, fileLink: fileLink, mimeType: mimeType, deleted: false})
                if((k++)%2 == 1) {
                    this.picturesFetchedList.push(tempBucket);
                    tempBucket = [];
                }
                else {
                    if(i == pictureAttchFiles.length -1) {
                        tempBucket.push({id: null, attchNm: null, fileLink: "/./files/img_legacy/nofileimage.jpg", deleted: false});
                        this.picturesFetchedList.push(tempBucket);
                    }
                }
            }
        }
        this.fetching = false;
    },
    methods: {
        renderPhoneNumber(phoneNumber) {
            if(phoneNumber.length == 10) {
                return phoneNumber.substring(0,3) + '-' + phoneNumber.substring(3,6) + '-' + phoneNumber.substring(6);
            }
            else{
                return phoneNumber.substring(0,3) + '-' + phoneNumber.substring(3,7) + '-' + phoneNumber.substring(7);
            }
        },
        editMtrl() {
            location.href = '/usdmtrl/edit/' + this.$route.params.usdMtrlId;
        },
        async deleteMtrl() {
            if(confirm('등록한 매물을 삭제하시겠습니까?')) {
                this.submitting = true;
                const returnvalue = await Api.post('/api/v1/usdmtrl/update/usdmtrl', {usdMtrlId: this.usdMtrlId, stts: 4});
                if(returnvalue.result){
                    alert('정상적으로 삭제되었습니다.');
                    location.href = '/usdmtrl/list';
                }
                else {
                    alert(returnvalue.errormsg);
                }
                this.submitting = false;
            }
            
        },
        async acceptTransaction(option, prspctPrchsId) {
            var url = option == 0? '/api/v1/usdmtrl/start/prspctprchs' : '/api/v1/usdmtrl/cancel/prspctprchs';
            var request = {prspctPrchsId: prspctPrchsId, usdMtrlId: this.usdMtrlId};
            var message = option == 0? '거래가 시작되었습니다.\n구매희망자의 연락처를 확인 후 연락을 취하시기 바랍니다.' : '거래가 정상적으로 취소되었습니다.';

            this.submitting = true;
            const returnvalue = await Api.post(url, request);
            if(returnvalue.result){
                if(returnvalue.data.prspctPrchsId) {
                    alert(message);
                    location.reload();
                }
                else {
                    alert('등록한 매물의 최초 거래시작을 위해서는 예치금 납부가 필요합니다.\n안내된 계좌로 예치금 50만원을 입금해주세요.');
                }
            }
            else {
                alert(returnvalue.errormsg);
            }
            this.submitting = false;
        },
        async requestTransaction(option) {
            if(!this.user){
                location.href = '/member/login?usdMtrl=' + this.$route.params.usdMtrlId;
            }
            var url = '';
            var request = null;
            var message = '';
            switch(option) {
                case 0 : 
                    url = '/api/v1/prspctprchs/set/prspctprchs';
                    request = {usdMtrlId: this.usdMtrlId};
                    message = '구매요청이 정상적으로 등록되었습니다.';
                    break;
                case 1 :
                    url = '/api/v1/usdmtrl/delete/prspctprchs';
                    request = {prspctPrchsId: this.prspctPrchsId, usdMtrlId: this.usdMtrlId};
                    message = '구매요청이 정상적으로 취소되었습니다.';
                    break;
                case 2 : 
                    url = '/api/v1/usdmtrl/contract/usdmtrl';
                    request = {prspctPrchsId: this.prspctPrchsId, usdMtrlId: this.usdMtrlId};
                    message = '계약요청이 정상적으로 전송되었습니다.';
                    break;
            }

            this.submitting = true;
            const returnvalue = await Api.post(url, request);
            if(returnvalue.result){
                alert(message);
                location.reload();
            }
            else {
                alert(returnvalue.errormsg);
            }
            this.submitting = false;
        },
        async deleteReceipt() {
            if(confirm('등록된 영수증을 삭제하시겠습니까?')) {
                var request = {
                    parentId: [],
                    attchFlCtgry: [],
                    deleteFlIdList: [this.receipt.id],
                    attchOrgNmList: []
                }
                const formData = new FormData();
                formData.append(
                    "request",
                    new Blob([JSON.stringify(request)], { type: "application/json" })
                );
                const rtnvalue = await Api.post('/api/v1/common/set/attachments', formData);
                if(rtnvalue.result) {
                    alert('정상적으로 삭제되었습니다.');
                    location.reload();
                }
                else {
                    alert(rtnvalue.errormsg)
                }
            }
        }
    }
}
</script>

<style scoped>
li {
    margin: 5px 15px 5px 0;
}
</style>
