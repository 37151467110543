<template>
  <div class="mobile-footer" :style="isModelDetail? 'padding-bottom: 100px;' : ''">
    <div class="content">
      <div v-if="companyInfo" class="left">
        <a href="https://m3systems.co.kr" target="_blank"><img class="rectangle" src="@/assets/img/rectangle-129.png" /></a>
        <h3 style="margin-bottom:5px">{{ companyInfo.companyName }}</h3>
        <p class="text">
          <span class="span">{{ '사업자등록번호 : ' + companyInfo.businessNumber + '&nbsp;&nbsp; |&nbsp;&nbsp; 대표자: ' + companyInfo.ceoName + '&nbsp;&nbsp; |&nbsp;&nbsp; 대표전화: ' + companyInfo.phoneNumber }}</span>
        </p>
        <p class="text" v-for="(addr, index) in companyInfo.addrList" :key="index">
          <span class="span">{{ '[' + addr.title + ']&nbsp;&nbsp;' + addr.addr }}</span>
        </p>
      </div>
      <div class="right">
        <div class="box">
          <a href="https://www.instagram.com/m3s.korea" target="_blank">
            <!-- <div class="overlap-group-wrapper">
              <div class="overlap-group">
                <div class="ellipse-2"></div>
                <div class="ellipse"></div>
              </div>
            </div> -->
            <img class="img" src="@/assets/img/f_instagram.png" />
          </a>
          <a href="https://www.youtube.com/@m3systems537" target="_blank" rel="noopener noreferrer">
            <!-- <div class="vector-wrapper">
              <img class="vector" src="@/assets/img/youbube.svg" />
            </div> -->
            <img class="img" src="@/assets/img/f_youtube.png" />
          </a>
          <a href="https://blog.naver.com/m3systems" target="_blank">
            <img class="img" src="@/assets/img/f_blog.png" />
          </a>
        </div>
        <div class="info-area">
          <p class="text">
            <span @click="isShowTermsOfService = true">이용약관</span>
            <span class="txt">|</span>
            <span @click="isShowPrivecyPolicy = true">개인정보처리방침</span>
          </p>
          <p class="text">Copyright 2023 m3systems.All Rights Reserved.</p>
        </div>
      </div>        
    </div>
    <privacy-policy v-if="isShowPrivecyPolicy" @close="isShowPrivecyPolicy = false"/>
    <terms-of-service v-if="isShowTermsOfService" @close="isShowTermsOfService = false"/>
  </div>
</template>
<script>
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import TermsOfService from '@/components/TermsOfService.vue';

export default {
  name: 'MobileFooter',
  components: { PrivacyPolicy, TermsOfService },
  props: {
    isModelDetail: {
      type: Boolean,
      default: false
    },
    companyInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isShowPrivecyPolicy: false, // 개인정보처리방침 팝업 여부
      isShowTermsOfService: false, // 이용약관 팝업 여부
    }
  }
}
</script>

<style scoped>
.span {
  display: block;
  height: 10px;
}
</style>