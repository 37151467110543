<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">회사정보</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>회사정보 수정</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>사명(국문/영문)</strong></span></td>
                  <td class="text-center td-flex" width="70%">
                    <input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 20px;" v-model="companyName">
                    <input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 40px;" v-model="companyNameEn">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>대표자명(국문/영문)</strong></span></td>
                  <td class="text-center td-flex" width="70%">
                    <input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 20px;" v-model="ceoName">
                    <input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 40px;" v-model="ceoNameEn">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>사업자등록번호</strong></span></td>
                  <td class="text-center" width="70%">
                    <input type="text" class="form-control fkr-set2" style="width:auto; height:36px; margin-left: 20px;" v-model="businessNumber">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>대표전화</strong></span></td>
                  <td class="text-center" width="70%">
                    <input type="text" class="form-control fkr-set2" style="width:auto; height:36px; margin-left: 20px;" v-model="phoneNumber">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>대표전화(해외)</strong></span></td>
                  <td class="text-center" width="70%">
                    <input type="text" class="form-control fkr-set2" style="width:auto; height:36px; margin-left: 20px;" v-model="phoneNumberForAbroad">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>주소 목록</strong></span></td>
                  <td class="text-center" width="50%" style="padding: 10px !important">
                    <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background:#000;">
                        <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">주소명(국문/영문)</span></div>
                        <div class="x_div_center" style="width:70%"><span class="fkr-set2 fc-w1">주소(국문/영문)</span></div>
                        <div class="x_div_center" style="width:10%"><span class="fkr-set2 fc-w1"></span></div>
                    </div>

                    <div v-for="(addr, index) in addrList" :key="index">
                      <div class="x_div_center ls_bg1" style="height:55px">
                          <div class="x_div_center px-1" style="width:20%">
                            <input type="text" v-model="addr.title" class="form-control fkr-set2 fc-w5 text-left" style="width:100%; height:36px">
                          </div>
                          <div class="x_div_center px-1" style="width:70%">
                            <input type="text" v-model="addr.addr" class="form-control fkr-set2 fc-w5 text-left" style="width:100%; height:36px">
                          </div>
                          <div class="x_div_center px-1" style="width:10%">
                          </div>
                      </div>
                      <div class="x_div_center ls_bg1" style="height:55px">
                          <div class="x_div_center px-1" style="width:20%">
                            <input type="text" v-model="addr.titleEn" class="form-control fkr-set2 fc-w5 text-left" style="width:100%; height:36px">
                          </div>
                          <div class="x_div_center px-1" style="width:70%">
                            <input type="text" v-model="addr.addrEn" class="form-control fkr-set2 fc-w5 text-left" style="width:100%; height:36px">
                          </div>
                          <div class="x_div_center px-1" style="width:10%">
                            <a href="javascript:void(0)" class="rm_frm" @click="removeAddr(addr, index)"><i class="fa-solid fa-circle-minus" style="color:#C00"></i></a>
                          </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-center" width="20%" style="padding: 20px !important">
                    <div class="x_div_center py-2">
                      <a href="javascript:void(0)" @click="addrList.push({id: null, title: '', titleEn: '', addr: '', addrEn: ''})">
                        <i class="fa-solid fa-circle-plus fs-15" style="color:#000"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>

          </div>

          <div class="x_div_end py-4">
            <div class="py-2"><button class="fkr-set2 fs-095" @click="saveCompanyInfo()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
  name: "CompanyInfo",
  components: {
		AdminLNB
	},
  data(){
    return {
      companyInfoId: null,
      companyName: '',
      companyNameEn: '',
      ceoName: '',
      ceoNameEn: '',
      businessNumber: '',
      phoneNumber: '',
      phoneNumberForAbroad: '',
      addrList: [],
      addrListNew: [],
      addrListSet: [],
      addrListDel: [],
      fetching: true,
      submitting: false
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    const returnvalue = await Api.get('/api/v1/companyinfo/list');
    if(returnvalue.result) {
      this.companyInfoId = returnvalue.data[0].id;
      this.companyName = returnvalue.data[0].companyName;
      this.companyNameEn = returnvalue.data[0].companyNameEn;
      this.ceoName = returnvalue.data[0].ceoName;
      this.ceoNameEn = returnvalue.data[0].ceoNameEn;
      this.businessNumber = returnvalue.data[0].businessNumber;
      this.phoneNumber = returnvalue.data[0].phoneNumber;
      this.phoneNumberForAbroad = returnvalue.data[0].phoneNumberForAbroad;
      this.addrList = returnvalue.data[0].addrList;
    }
    else {
      alert(returnvalue.errormsg)
    }

    this.fetching = false;
  },

  methods:{
    removeAddr(addr, index) {
      if(addr.id) {
        this.addrListDel.push(addr);
      }
      this.addrList = this.addrList.filter((v,i) => i != index );
    },

    async saveCompanyInfo() {

      this.addrList.forEach((addr) => {
        if(addr.id) {
          this.addrListSet.push(addr);
        }
        else {
          this.addrListNew.push(addr);
        }
      })

      this.submitting = true;

      const rtnval = await Api.post('/api/v1/companyinfo/update', {
        companyInfoId: this.companyInfoId,
        companyName: this.companyName,
        companyNameEn: this.companyNameEn,
        ceoName: this.ceoName,
        ceoNameEn: this.ceoNameEn,
        businessNumber: this.businessNumber,
        phoneNumber: this.phoneNumber,
        phoneNumberForAbroad: this.phoneNumberForAbroad,
        addrListNew: this.addrListNew,
        addrListSet: this.addrListSet,
        addrListDel: this.addrListDel
      })
      if(rtnval.result) {
        alert('정상적으로 등록되었습니다.');
        location.reload();
      }
      else {
        alert(rtnval.errormsg)
      }
      this.submitting = false;
    }
  }
}
</script>

<style scoped>
.td-flex {
  display: flex;
  padding-top: 8px;
}
</style>