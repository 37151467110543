<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">사이트관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span> 해피콜문자 템플릿 관리</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">{{ currentCnslr }} 수: {{ templateList.length }} 건</span>
            <select class="custom-select ff-eng1 fs-085" v-model="pageSize" @change="switchPageSize()" style="width:120px; height:37px; margin-left: 20px">
                <option value="10">페이지당 10개</option>
                <option value="20">페이지당 20개</option>
            </select>
            <select class="custom-select ff-eng1 fs-085" v-model="cnslrFilter" @change="switchCnslr()" style="width:120px; height:37px; margin-left: 20px">
              <option v-for="(item, i) in cnslrList" :key="i" :value="item.id">{{ item.fullName }}</option>
            </select>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="ps-2" ><button class="fkr-set2 fs-095" onclick="location.href = '/admin/site/template-edit/0'" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">템플릿 등록</button></div>
            </div>
          </div>

            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:60%"><span class="fkr-set1 fs-9">템플릿 내용</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">담당자</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">등록일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">편집</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in templateListPaged" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center m_line" style="width:60%; justify-content: left; padding-left: 20px;"><span class="fkr-set2 fs-9">{{item.templateRendered.length > 60? item.templateRendered.substring(0,60) + ".." : item.templateRendered}}</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.cnslrNm}}</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.crtDt? item.crtDt.split('T')[0] : ''}}</span></div>
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9"><a :href="'/admin/site/template-edit/' + item.textTemplateId"><i class="fa fa-edit"/></a></span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; renderList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "TextTemplate",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: true,
        currentCnslr: '전체',
        cnslrList: [],
        templateList:[],
        templateListPaged: [],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        isHomeExpsred: false,
        cnslrFilter: ''
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    const rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/all?option=1');
    if(rtnval.result){
        this.cnslrList = rtnval.data.findAllUsrDtoList;
        this.cnslrList = this.cnslrList.filter((val) => val.cnslrLv == 1).sort((a,b) => a.id - b.id);
        this.cnslrList = [{id: '', fullName: '전체'}].concat(this.cnslrList);
        await this.fetchTemplates(null);
        this.setPaging();
        this.renderList();
    }
    else {
        alert(rtnval.errormsg);
    }
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async fetchTemplates(cnslrId) {
      this.fetching = true;
      this.templateList = [];
      const returnvalue = await Api.post('/api/v1/primprj/list/templates', {cnslrId: cnslrId});
      if(returnvalue.result) {
          this.templateList = returnvalue.data.textTemplateDtoList.sort((a,b) => b.textTemplateId - a.textTemplateId);
          this.templateList.forEach((temp) => {
            temp.templateRendered = temp.template.replace(/\\n>/g, ' ');
          })
      }
      else {
          alert(returnvalue.errormsg);
      }
      this.fetching = false;
    },

    renderList() {
      this.templateListPaged = [];
      for(var j = (this.currentPage-1) * this.pageSize; j < (this.templateList.length > this.currentPage * this.pageSize? this.currentPage * this.pageSize : this.templateList.length); j++) {
        this.templateListPaged.push(this.templateList[j]);
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.templateList.length / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    async switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        this.setPaging();
        this.renderList();
      }
    },

    async switchPageSize() {
      this.currentPage = 1;
      this.setPaging();
      this.renderList();
    },

    async switchCnslr() {
      this.currentPage = 1;
      if(this.cnslrFilter) {
        this.cnslrList.forEach((cnslr) => {
          if(cnslr.id == this.cnslrFilter) {
            this.currentCnslr = cnslr.fullName;
          }
        })
      }
      else {
        this.currentCnslr = '전체';
      }

      await this.fetchTemplates(this.cnslrFilter);
      this.setPaging();
      this.renderList();
    }
  }
}
</script>

