<template>
    <div class="ca_main">
        <div class="x_div_center">
            <div class="pg_stage">

                <div class="d-flex pt-5">
                    <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 관리</span></div>
                    <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
                </div>

                <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                <div style="height:14px"></div>

                <div class="sw-1">
                    <div class="sw-2" style="border-bottom:1px solid #c9c9c9">
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/bidding-generate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/bidding-generate'"><span class="fkr-set1 fs-095">1. 참여 의향 요청</span></div>
                        </div>
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/bidding-candidate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/bidding-candidate'"><span class="fkr-set1 fs-095">2. 후보 업체 선정</span></div>
                        </div>
                    </div>
                    <div class="sw-2" style="border-bottom:1px solid #c9c9c9">
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/bidding-evaluate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/bidding-evaluate'"><span class="fkr-set1 fs-095">3. 후보 업체 평가 및 선정</span></div>
                        </div>
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/contract') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/contract'"><span class="fkr-set1 fs-095">4. 계약</span></div>
                        </div>
                    </div>
				</div>

				<div class="x_div_center py-5">
					<div class="text-center"><span class="fkr-set2 fs-10">파트너기관와 계약을 진행합니다.</span></div>
				</div>

				<div>

					<div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
						<table class="table-borderless w-100">
						<tr style="height:55px; border-bottom:1px solid #c9c9c9;">
							<td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="fkr-set2"><strong>계약서</strong></span></td>
							<td class="text-center" width="70%">
                                <form method="post" action="/api/v1/common/filedownload">
                                    <input type="hidden" name="fileName" :value="fnlPath"/>
                                    <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fkr-set2">{{fnlOrgNm}}<i v-if="fetching" class='fa fa-spinner fa-spin'></i></span></a>
                                </form>
                            </td>
						</tr>
						<tr style="height:55px">
							<td class="text-center br_0" style="background-color:#eaeaea"><span class="fkr-set2"><strong>사업파트너 이메일</strong></span></td>
							<td class="text-center">

								<div class="x_div_center" v-if="prcCd == 'DS'">
									<div class="py-1"><input type="text" v-model="bsnssPrtnrEmailHead" class="form-control ff-eng1" :style="'height:36px; ' + (isMobile? 'width:90px; margin-left:8px' : 'width:180px')" placeholder=""></div>
									<div class="x_div_center py-1 text-center" style="width:30px"><span class="ff-eng1 fs-09 fw-bold fc-w3">@</span></div>
									<div :class="'py-1' + (isMobile? '' : ' pe-2')"><input v-model="bsnssPrtnrEmailTail" type="text" class="form-control ff-eng1" :style="'height:36px; ' + (isMobile? 'width:90px' : 'width:180px')" placeholder=""></div>
									<div class="py-1" v-if="!isMobile">
										<select v-model="bsnssPrtnrEmailTail" class="custom-select ff-eng1 fs-085" style="width:180px; height:36px">
											<option value="">직접입력</option>
											<option value="naver.com">naver.com</option>
											<option value="gmail.com">gmail.com</option>
											<option value="daum.net">daum.net</option>
										</select>
									</div>
									<div class="px-2"><button class="fkr-set2 fs-095" @click="saveSalePartner()" :style="'height:36px; border:0; background-color:#4e0000; color:#fff; border-radius:4px; ' + (isMobile? 'width:50px' : 'width:80px')">저장</button></div>
								</div>
                                <span class="ff-kor1 fs-10" v-else>{{bsnssPrtnrEmail != 'support@m3.co.kr'? (bsnssPrtnrEmailHead + '@' + bsnssPrtnrEmailTail) : ''}}</span>

							</td>
						</tr>
						</table>
					</div>

				</div>

				<div style="height:50px"></div>

            </div>
        </div>
    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'BiddingContract',
    data() {
        return {
            url : '',
            fetching : true,
            isMobile : false,
            prjId : sessionStorage.getItem('prjId'),
            prcCd : sessionStorage.getItem('prcCd'),
            prcId : sessionStorage.getItem('prcId'),
            fnlOrgNm : null,
            fnlPath : '',
            bsnssPrtnrEmail : '',
            bsnssPrtnrEmailHead : '',
            bsnssPrtnrEmailTail : '',
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    created() {
        this.url = location.href;
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }
        else if(this.user.info.usrRole != 'OW') {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }
        else if(this.prjId == 'null') {
            alert('My Projects 페이지에서 프로젝트를 선택하셔야 합니다.');
            location.href = '/project/dashboard'
        }
        else if(this.prcCd == 'null') {
            alert('프로젝트 단계를 선택하셔야 합니다.');
            location.href = '/project/summary/' + this.prjId;
        }
        this.isMobile = window.innerWidth < 768;
        this.fetchContractDetail();
    },
    methods: {
        async fetchContractDetail() {
            this.fetching = true;
            const rtnvalue = await Api.post('/api/v1/process/get', {prjId: this.prjId, prcCd: this.prcCd});
            this.fetching = false;
            if(rtnvalue.result) {
                if(rtnvalue.data.attachFiles) {
                    rtnvalue.data.attachFiles.forEach((file) => {
                        if(file.prcAttchFlCtgry.code == 'CT') {
                            this.fnlOrgNm = file.attchNm;
                            this.fnlPath = file.attchWebPath;
                        }
                    })
                }
                const returnvalue = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + this.prjId);
                if(returnvalue.result) {
                    this.bsnssPrtnrEmail = returnvalue.data.prj.bsnssPrtnrEmail;
                    if(this.bsnssPrtnrEmail != 'support@m3.co.kr') {
                        this.bsnssPrtnrEmailHead = this.bsnssPrtnrEmail.split('@')[0];
                        this.bsnssPrtnrEmailTail = this.bsnssPrtnrEmail.split('@')[1];
                    }
                }
                else {
                    alert(returnvalue.errormsg)
                }
            }
            else {
                alert(rtnvalue.errormsg)
            }
        },
        async saveSalePartner() {
            if(this.bsnssPrtnrEmailHead && this.bsnssPrtnrEmailTail) {
                const rtnval = await Api.post('/api/v1/project/update/prj/dtl', {
                    bsnssPrtnrEmail: this.bsnssPrtnrEmailHead + '@' + this.bsnssPrtnrEmailTail,
                    prjId: this.prjId
                });
                
                if(rtnval.result) {
                    alert('저장되었습니다.');
                    this.fetchContractDetail();
                }
                else {
                    alert(rtnval.errormsg)
                }
            }
            else {
                alert('이메일 주소를 정확히 입력하세요.')
            }
        },
        phoneNumberFormatter(phone) {
            if(phone.length == 10) {
                return phone.substring(0,3) + '-' + phone.substring(3,6) + '-' + phone.substring(6);
            }
            else{
                return phone.substring(0,3) + '-' + phone.substring(3,7) + '-' + phone.substring(7);
            }
        }
    }
}
</script>