<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">사업파트너 관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div class="pt-3" v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">

                <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                  <td class="text-center br_0" width="17%"><span class="ff-kor1" style="font-size:12pt"><strong>이메일</strong></span></td>
                  <td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>이름</strong></span></td>
                  <td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>연락처</strong></span></td>
                  <td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>지역</strong></span></td>
                  <td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>등급</strong></span></td>
                  <td class="text-center" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>담당PP이메일</strong></span></td>
                </tr>

                <tr v-for="(item, index) in bsnssPrtnrList" :key="index" style="height:55px; border-bottom:1px solid #c9c9c9;" @click="onClickBsnssPrtnr(item)">
                  <td style="display: none">{{item.usrId}}}</td>
                  <td class="text-center br_0"><span class="ff-eng1" style="font-size:12pt">{{item.email}}</span></td>
                  <td class="text-center br_0"><span class="ff-kor1" style="font-size:10pt">{{item.fullName}}</span></td>
                  <td class="text-center br_0"><span class="ff-eng1" style="font-size:10pt">{{item.phone}}</span></td>
                  <td class="text-center br_0"><span class="ff-kor1" style="font-size:10pt">{{item.region}}</span></td>
                  <td class="text-center br_0"><span class="ff-eng1" style="font-size:10pt">{{item.grade}}</span></td>
                  <td class="text-center"><span class="ff-eng1" style="font-size:12pt">{{item.ptrEmail}}</span></td>
                </tr>

              </table>
            </div>

          </div>

          <!-- 사업파트너 정보 시작 { -->
          <div id="bsnss-prtnr-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">

                <div class="w-100">
                  <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                    <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">사업파트너 정보</span></div>
                    <div class="" style="width:100px"></div>
                  </div>

                  <div style="height:20px"></div>

                  <div class="x_div_center p-4">
                    <div class="x_div_center p-4" style="border-top:1px solid #c9c9c9; border-bottom:1px solid #c9c9c9;">
                      <div class="table-responsive w-100" style="border:1px solid #c9c9c9; border-radius:8px">
                        <table class="table-borderless w-100" style="table-layout:fixed">
                          <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                            <td class="text-center br_0" width="33%"><span class="ff-kor1" style="font-size:12pt"><strong>이메일</strong></span></td>
                            <td class="text-center br_0" width="34%"><span class="ff-kor1" style="font-size:12pt"><strong>이름</strong></span></td>
                            <td class="text-center" width="33%"><span class="ff-kor1" style="font-size:12pt"><strong>연락처</strong></span></td>
                          </tr>
                          <tr style="height:55px;">
                            <td style="display: none">{{usrId}}</td>
                            <td class="text-center br_0" id="bsnss-prtnr"><span class="ff-eng1" style="font-size:12pt">{{email}}</span></td>
                            <td class="text-center br_0"><span class="ff-kor1" style="font-size:10pt">{{fullName}}</span></td>
                            <td class="text-center"><span class="ff-eng1" style="font-size:10pt">{{phone}}</span></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="x_div_center">
                    <div class="x_div_center px-3">
                      <div class="table-responsive w-100">
                        <table class="table-borderless w-100" style="table-layout:fixed">
                          <tr style="height:55px;">
                            <td class="text-end pe-3" width="30%"><span class="ff-kor1 fw-bold" style="font-size:11pt">지역</span></td>
                            <td class="text-center" width="70%"><input type="text" class="form-control ff-kor1 text-center" @keyup.enter="updBsnssPrtnr" style="width:85%; height:36px" v-model="region"></td>
                          </tr>
                          <tr style="height:55px;">
                            <td class="text-end pe-3"><span class="ff-kor1 fw-bold" style="font-size:11pt">등급</span></td>
                            <td class="text-center"><input type="text" class="form-control ff-kor1 text-center" style="width:85%; height:36px" @keyup.enter="updBsnssPrtnr" v-model="grade"></td>
                          </tr>
                          <tr style="height:55px;">
                            <td class="text-end pe-3"><span class="ff-kor1 fw-bold" style="font-size:11pt">담당PP이메일</span></td>
                            <td class="text-center"><input type="text" class="form-control ff-eng1 text-center" style="width:85%; height:36px" @keyup.enter="updBsnssPrtnr" v-model="ptrEmail"></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>

            <div class="x_div_center">
              <div class="py-4"><button @click="hideModal" class="x_div_center" style="width:90px; height:30px; margin-right: 10px; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000;"><span class="fa-kor-Noto fs-085">취소</span></button></div>
              <div class="py-4"><button @click="updBsnssPrtnr" class="x_div_center" style="width:90px; height:30px; margin-left: 10px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">등록</span></button></div>
            </div>

            <div style="height:20px"></div>

          </div>

        </div>
      </div>
    </div>
    <!-- } 사업파트너 정보 끝 -->

    <div style="height:50px"></div>

    </div>
    </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "BsnssPrtnrMng",
  components:{
    AdminLNB
  },
  data(){
    return {
      bsnssPrtnrList:[],
      usrId:0,
      email:'',
      fullName:'',
      phone:'',
      region:'',
      grade:'',
      ptrEmail:'',
      fetching: true
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }
    //await this.checkAdmin();
    await this.getBsnssPrtnrList();
  },
  methods:{
    async getBsnssPrtnrList(){
      this.fetching = true;
      var rtnval = await Api.get('/api/v1/admin/usr/mng/findAll/bsnss/prtnr');
      this.fetching = false;
      if(rtnval.result){
        this.bsnssPrtnrList = rtnval.data.resultList;
      }
    },

    onClickBsnssPrtnr(item){
      this.usrId = item.usrId;
      this.email = item.email;
      this.fullName = item.fullName;
      this.phone = item.phone;
      this.region = item.region;
      this.grade = item.grade;
      this.ptrEmail = item.ptrEmail;

      window.$('#bsnss-prtnr-modal').modal('show');
    },

    async updBsnssPrtnr(){
      var rtnval = await Api.post('/api/v1/admin/usr/mng/save/bsnss/prtnr', {id: this.usrId, region: this.region, grade: this.grade, ptrEmail: this.ptrEmail});

      if(rtnval.result){
        await this.getBsnssPrtnrList();
        await this.hideModal();
      }else{
        alert(rtnval.errormsg);
      }
    },

    async hideModal() {
      window.$('#bsnss-prtnr-modal').modal('hide');
    },

    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },
  }
}
</script>

<style scoped>

</style>