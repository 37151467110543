import Instruction from '@/views/project/bidding/customer/Instruction.vue'
import BiddingGenerate from '@/views/project/bidding/customer/BiddingGenerate.vue'
import BiddingCandidate from '@/views/project/bidding/customer/BiddingCandidate.vue'
import BiddingEvaluate from '@/views/project/bidding/customer/BiddingEvaluate.vue'
import Contract from '@/views/project/bidding/customer/Contract.vue'

export default [
    {
        path: '/project/bidding/customer/instruction',
        name: 'Instruction',
        component: Instruction
    },
    {
        path: '/project/bidding/customer/bidding-generate',
        name: 'BiddingGenerate',
        component: BiddingGenerate
    },
    {
        path: '/project/bidding/customer/bidding-candidate',
        name: 'BiddingCandidate',
        component: BiddingCandidate
    },
    {
        path: '/project/bidding/customer/bidding-candidate/:prjId',
        name: 'BiddingCandidate',
        component: BiddingCandidate
    },
    {
        path: '/project/bidding/customer/bidding-evaluate',
        name: 'BiddingEvaluate',
        component: BiddingEvaluate
    },
    {
        path: '/project/bidding/customer/bidding-evaluate/:prjId',
        name: 'BiddingEvaluate',
        component: BiddingEvaluate
    },
    {
        path: '/project/bidding/customer/contract',
        name: 'Contract',
        component: Contract
    }
]