import Vote from '@/views/sicksha/Vote.vue'
import VoteCompletion from '@/views/sicksha/VoteCompletion.vue'

export default [
    {
        path: '/sicksha/:partyId',
        name: 'Vote',
        component: Vote
    },
    {
        path: '/sicksha/complete/poll/:partyId',
        name: 'VoteCompletion',
        component: VoteCompletion
    },
]