<template>
  <div>
    <div style="height:56px"></div>

    <div class="x_div_center">

      <div class="ca_main">
        <div class="x_div_center">
          <div class="pg_stage">

            <div class="d-flex pt-5"><span class="ff-kor1" style="font-weight:700; font-size:22pt; color:#333333">M3 제공 토지 찾기</span></div>

            <div style="height:5px; background-color:lightgray; border-radius:10rem"></div>

<!--              <div class="py-3 pt-5" style="border-bottom:1px solid #e1e1e1" v-if="buildMode === 'production'">-->
<!--                  <div class="iw-1 py-2 w-100">-->
<!--                      <h2>준비중입니다</h2>-->
<!--                  </div>-->
<!--              </div>-->

            <!-------  Form  -------------------------------------->
            <form style="position:relative">
                <!---------- 0. 건물 위치 -------------------------------------------->
                <div style="min-height:auto">
                  <div class="x_div_center pt-4">
                    <div class="x_div_center"><span class="fkr-set1 fs-15">아래 대지 목록, <br v-if="isMobile"/>혹은 지도의 빨간 점들 중 <br v-if="isMobile"/>원하시는 토지 주소를 선택하시고 <br v-if="isMobile"/>검토해보세요.</span></div>
                  </div>
                  <div style="height:50px"></div>
                  <span class="fkr-set2" v-if="isMobile">메뉴확장버튼( <i class="fa-sharp fa-solid fa-bars"/> )을 눌러서 사이드바 메뉴를 열고 닫을 수 있습니다.<br/><br/></span>
                </div>
                <div id="ifrSec">
                    <iframe id="ifr" ref="ifrm" src=""></iframe>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- <button @click="testExpand()" style="position:fixed; right:50px; bottom:50px; z-index:1032;">expand</button> -->
  </div>
</template>

<script>
import router from '@/router';
//import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
//import Auth from '@/api/auth';
//import Api from '@/api';

window.$('.scale_none').each(function() {
	var nowImg = window.$(this).find('img');  //호버한 부분의 img파일 찾기
	var srcName = nowImg.attr('src');  //호버한 부분의 이미지 주소값 src
	var newSrc = srcName.substring(0, srcName.lastIndexOf('.'));
  //.png , .jpg 와같이 파일명 자르기. 뒤에서부터 시작해서 '.'점있는 곳 까지 컷!

  window.$(this).hover(function() { 
    window.$(this).find('img').attr('src', newSrc+ '_on.' + /[^.]+$/.exec(srcName));
  }, function() {
    window.$(this).find('img').attr('src', newSrc + '.' + /[^.]+$/.exec(srcName));
  });
});

var gIfrmMobuilder = null;

export default {
  name: 'use-case-plan',
  components: {
  },
  props: {
      user: {
          type: Object,
          default: null
      },
      isMobileDevice: {
          type: Boolean,
          default: false
      }
  },
  metaInfo: {
    title: 'UseCase Plan',
    meta: [
      {
        name: 'description',
        content: 'Welcome to Vue.js frontend.'
      }
    ]
  },
  data() {
    return {
      submitting: false,
      isMobile: false,
      scrollSpyOn: true,
      windowWidth: 0,
      submitValid: false,
      fetching : false,
      gIfrmMobuilder : null,
      ifrSecPosBak : '',
      ifrHeightBak : '',
      buildMode: process.env.NODE_ENV
    }
  },
  computed: {
  },
  watch: {
    addr : function (val) {
      if(val) {
        document.getElementById("goNext").disabled = false;
        window.$('#goNext').css('opacity', 1);
      }
    }
  },
  created() {
    // this.getPlan()
    this.windowWidth = window.innerWidth;
  },
  mounted() {

    if(this.user && this.user.info.usrRole != 'OW') {
        alert('고객회원 계정으로 로그인 후 이용해주세요.');
        location.href = '/';
    }
    this.isMobile = window.innerWidth < 768;

    const $vue = this;
    window.addEventListener("scroll", function() {
        $vue.decideScrollSpy();
    });

    this.$refs.ifrm.src = "https://mobuild.cafe24.com/mobuilder/index/public/?ma=builder&mu=modeUserMain";

    const gIframe = document.getElementById("ifr");

    gIframe.addEventListener("load", function() {
        gIfrmMobuilder = (gIframe.contentWindow) ? gIframe.contentWindow :
          (gIframe.contentDocument.document) ? gIframe.contentDocument.document : gIframe.contentDocument;
    });

    window.addEventListener('message', (e) => {
        // console.log('================== post message ====================')
        // console.log(e.data);
        if( e.data.functionName == 'fInfoFromMobuilder' ) {
            const jsonObj = JSON.parse(e.data.para[1]);
            if(e.data.para[0] == 'land') {
              this.plan.addr = jsonObj.addressDoro? jsonObj.addressDoro : jsonObj.addressJibun;
              this.plan.addr_jibun = jsonObj.addressJibun;
              this.plan.buildingLandRatio = jsonObj.ratioCoverMax;
              this.plan.floorAreaRatio = jsonObj.ratioFloorAreaMax;
            }
            else if(e.data.para[0] == 'building') {
              //TBD
            }
        }
        else if( e.data.functionName == 'fExpandMobuilderFrame' ) {
          this.expandIframe(e.data.para[0]);
        }
        else if (e.data.functionName == 'fMobuilderCallback') {
          console.log("call fMobuilderCallback in postMessage(upward) event listener");
          this.fMobuilderCallback("모빌더 로딩완료");
        }
    });

    this.daumObj = new window.daum.Postcode({
        oncomplete: (data) => {
        var addr = ''; // 주소 변수
        var extraAddr = ''; // 참고항목 변수

        //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
        if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
        } else { // 사용자가 지번 주소를 선택했을 경우(J)
            alert('현재 도로명 주소만 지원됩니다.');
            return 1
            // addr = data.jibunAddress;
        }

        // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
        if(data.userSelectedType === 'R'){
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                extraAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if(data.buildingName !== '' && data.apartment === 'Y'){
                extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if(extraAddr !== ''){
                extraAddr = ' (' + extraAddr + ')';
            }
        }
        this.postcode = data.zonecode;
        this.addr = addr + extraAddr;
        this.plan.addr = this.addr;
        }
    });

    window.addEventListener('resize', this.handleResize);

    window.scrollTo(0,0);
  },
  methods: {
    searchAddr() {
      // this.daumObj.open();
    },
    closeModal() {
      window.$('#v-view').modal('hide');
      window.$('#v-ModluePlan').modal('hide');
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    decideScrollSpy() {
      const vh = window.innerHeight;
      const target = document.getElementById('footer'); // 요소의 id 값이 target이라 가정
      const clientRect = target.getBoundingClientRect(); // DomRect 구하기 (각종 좌표값이 들어있는 객체)
      const relativeTop = clientRect.top; // Viewport의 시작지점을 기준으로한 상대좌표 Y 값.
      if(vh > relativeTop) {
        this.scrollSpyOn = false;
      }
      else {
        this.scrollSpyOn = true;
      }
    },
    onSubmit() {
    },

    addrTokenizer(addr) {
      if(addr){
        if(addr.indexOf('동 ') > 0) {
          var addrHead = addr.split('동 ')[0];
          var token = addrHead.split(' ');
          return token[token.length-1] + '동'
        }
        
        if(addr.indexOf('면 ') > 0) {
          addrHead = addr.split('면 ')[0];
          token = addrHead.split(' ');
          return token[token.length-1] + '면'
        }
  
        if(addr.indexOf('읍 ') > 0) {
          addrHead = addr.split('읍 ')[0];
          token = addrHead.split(' ');
          return token[token.length-1] + '읍'
        }
  
        token = addr.split(' ');
        return token.length > 0? (token[token.length-2] + ' ' + token[token.length-1]) : '';
      }
      else {
        return this.user.info.fullName + '님의';
      }

    },
    
    expandIframe(mode) {
      if(mode == 'expand') {
        this.ifrSecPosBak = window.$('#ifrSec').css('position');
        this.ifrHeightBak = window.$('#ifr').css('height');
        if(window.innerWidth < 768) {
          window.$('#ifrSec').css('width', '100%');
          window.$('#ifrSec').css('left', 0);
        }
        window.$('#ifrSec').css('margin-top', 0);
        window.$('#ifrSec').css('z-index', 1031);
        window.$('#ifrSec').css('position', 'fixed');
        window.$('#ifr').css('height', '100vh');
      }
      else if(mode == 'return') {
        if(window.innerWidth < 768) {
          window.$('#ifrSec').css('width', 'calc(100% + 60px)');
          window.$('#ifrSec').css('left', '-30px');
        }
        window.$('#ifrSec').css('margin-top', '20px');
        window.$('#ifrSec').css('z-index', 1);
        window.$('#ifrSec').css('position', this.ifrSecPosBak);
        window.$('#ifr').css('height', this.ifrHeightBak);
        this.ifrSecPosBak = '';
        this.ifrHeightBak = '';
      }
    },
    fMobuilderCallback(m) {
      console.log("fMobuilderCallback called");

      if (gIfrmMobuilder) {

        window.clearTimeout(this.gTimesetMbCallback);
        console.log(m);

        const gjMenuSet = {
          "project": {            // 프로젝트
            "outline": true,    // - 사업 개요
            "info": true,       // - 사업 정보
          },
          "land": {               // 대지
              "address": false,    // - 주소 입력
              "danji": true,     // - 단지 선택
              "info": true        // - 대지 정보
          },
          "model": {              // 모델
              "outline": true,    // - 모델 개요
              "info": true,       // - 모델 정보
              "build": true       // - 모델 수정
          }
        }

        gIfrmMobuilder.postMessage({
          functionName: "fSidebarSetMenu",
          para: [JSON.stringify(gjMenuSet), window.location.host]
        }, "*");

        gIfrmMobuilder.postMessage({
          functionName: "fSelectSidebarMenu",
          para: ['land', 'address', '']
        }, "*");

        if (!this.isMobile) {
          gIfrmMobuilder.postMessage({
            functionName: "fSidebarOpen",
            para: []
          }, "*");
        }
      }
      else {
        this.gTimesetMbCallback = setTimeout(() => {
          console.log("setTimeout callback function called");
          this.fMobuilderCallback("모빌더 로딩 완료");
        }, 500)
      }
    },

    testExpand() {
      if(this.ifrSecPosBak) {
        this.expandIframe('return');
      }
      else {
        this.expandIframe('expand');
      }
    },

    fGoto: function(toWhere) {
      router.push(toWhere);
      window.scrollTo( 0, 0 );
    }
  }
};
</script>

<style>
/*------- 추가 여기부터 (조주원) ----------------------------*/
@import "../../assets/css/custom.css";


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}

/*------- 추가 여기까지 ------------------------------------*/

#plan_q3_3_all, #plan_q5_all {
  display: inline-block;
}

#suggestion_all {
  display: inline-block;
}

.imgGroup {
  width: auto;
  min-height: 350px;
}

.imgGroup > img {
  width: 100%;
  max-height: 350px;
}

#txt1PlusBtn:disabled, #txt2PlusBtn:disabled, #txt3PlusBtn:disabled,
#txt1PlusBtn:disabled:hover, #txt2PlusBtn:disabled:hover, #txt3PlusBtn:disabled:hover {
  width: 90%;
  max-width: 300px;
  background-color: white;
  border: 1.5px solid lightgray;
  color: lightgray;
  font-size: 14px;
}

#txt1PlusBtn, #txt2PlusBtn, #txt3PlusBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 300px;
  height: 32px;
  margin-left: 8px;
  margin-left: 8px;
  background-color: white;
  border: 3px solid var(--sub-color);
  border-radius: 3px;
  color: var(--main-color);
}

#txt1PlusBtn:hover, #txt2PlusBtn:hover, #txt3PlusBtn:hover,
#txt1PlusBtn:focus, #txt2PlusBtn:focus, #txt3PlusBtn:focus {
  width: 90%;
  max-width: 300px;
  background-color: var(--main-color);
  border: 0px;
  color: white;
}

.inputTxt {
  margin: 10px 0px;
  width: 100%;
  height: 200px;
}
/*-------------------------------------------*/

input[type="radio"]:checked+.scale_none, input[type="checkbox"]:checked+.scale_none {
  transform: scale(1.1);
}

input[type="radio"]:checked+.scale, input[type="checkbox"]:checked+.scale
{
  transform: scale(1.1);
	border:3px solid #4e0000;
	border-radius:1rem;
	background-color:#f6f2f1;
}

#ifrSec {
  margin-top: 20px;
  width: 100%;
  position:relative;
  top: 0;
  left: 0;
  z-index: 1;
}

#ifr {
  width:100%;
  height:1000px;
  border:1px solid #eee;
  transition: 0.2s;
}

@media (max-width:479.98px) {
  #ifrSec {
    width: calc(100% + 60px);
    left: -30px;
  }

  #ifr {
    height: 600px
  }
}
@media (min-width:480px) and (max-width:767.98px) {
  #ifrSec {
    width: calc(100% + 60px);
    left: -30px;
  }

  #ifr {
    height: 600px
  }
}
@media (min-width:768px) and (max-width:1023.98px) {

}

/* component style */
.vue-slider-disabled .vue-slider-process {
  background-color: #a7a7a7;
}
.vue-slider-disabled .vue-slider-dot-handle {
  border-color: #a7a7a7;
}
.vue-slider-disabled .vue-slider-mark-step-active {
  box-shadow: 0 0 0 2px #a7a7a7;
}

/* rail style */
.vue-slider-rail {
  background-color: whitesmoke;
  border-radius: 15px;
  transition: background-color 0.3s;
}
.vue-slider:hover .vue-slider-rail {
  background-color: #e1e1e1;
}

/* process style */
.vue-slider-process {
  background-color: #cab2b2;
  border-radius: 15px;
  transition: background-color 0.3s;
}
.vue-slider:hover .vue-slider-process {
  background-color: #4e0000;
}

/* mark style */
.vue-slider-mark-step {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #e8e8e8;
  background-color: #fff;
}
.vue-slider-mark-step-active {
  box-shadow: 0 0 0 2px #cab2b2;
}
.vue-slider:hover .vue-slider-mark-step-active {
  box-shadow: 0 0 0 2px #4e0000;
}

.vue-slider-mark-label {
  font-size: 12px;
  white-space: nowrap;
}
/* dot style */
.vue-slider-dot-handle {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #cab2b2;
  box-sizing: border-box;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.vue-slider:hover .vue-slider-dot-handle {
  border-color: #4e0000;
}

.vue-slider-dot-handle-focus {
  border-color: #2f0000;
  box-shadow: 0 0 0 5px #cab2b26a;
}
.vue-slider:hover .vue-slider-dot-handle-focus {
  border-color: #2f0000;
}

.vue-slider-dot-handle:hover {
  border-color: #2f0000;
}
.vue-slider:hover .vue-slider-dot-handle:hover {
  border-color: #2f0000;
}

.vue-slider-dot-handle-disabled {
  cursor: not-allowed;
  border-color: #ddd !important;
}

.vue-slider-dot-tooltip {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.vue-slider-dot-tooltip-inner {
  font-size: 14px;
  white-space: nowrap;
  padding: 6px 8px;
  color: #fff;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.75);
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transform: scale(0.9);
  transition: transform 0.3s;
}
.vue-slider-dot-tooltip-inner::after {
  content: "";
  position: absolute;
}
.vue-slider-dot-tooltip-inner-top::after {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-top-color: inherit;
}
.vue-slider-dot-tooltip-inner-bottom::after {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-bottom-color: inherit;
}
.vue-slider-dot-tooltip-inner-left::after {
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-left-color: inherit;
}
.vue-slider-dot-tooltip-inner-right::after {
  right: 100%;
  top: 50%;
  transform: translate(0, -50%);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-right-color: inherit;
}
.vue-slider-dot-tooltip-inner-top {
  transform-origin: 50% 100%;
}
.vue-slider-dot-tooltip-inner-bottom {
  transform-origin: 50% 0;
}
.vue-slider-dot-tooltip-inner-left {
  transform-origin: 100% 50%;
}
.vue-slider-dot-tooltip-inner-right {
  transform-origin: 0% 50%;
}

.vue-slider-dot:hover .vue-slider-dot-tooltip, .vue-slider-dot-tooltip-show {
  opacity: 1;
  visibility: visible;
}
.vue-slider-dot:hover .vue-slider-dot-tooltip .vue-slider-dot-tooltip-inner, .vue-slider-dot-tooltip-show .vue-slider-dot-tooltip-inner {
  transform: scale(1);
}

/*# sourceMappingURL=antd.css.map */

.scrollSpy {
	position:fixed;
	bottom:50px;
	display:block;
	z-index:999;
}

</style>
