<template>
    <div class="m3_contetns" style="margin-top: 50px">

        <div class="m_ipt_box">

            <div class="mib_mod">
                <div class="pg_m_set" v-if="fetching">
                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div v-show="!fetching" class="w-100">

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">건축예정지</span></div>
                        <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                        <div class="mib_a3" style="display:flex; flex-wrap:wrap">
                            <button class="fkr-set1 fc-w1" @click="searchAddr()" style="width:75px; height:26px; margin: 5px 15px 5px 0; background-color:#000; border-radius:4px;">주소검색</button>
                            <input type="text" class="form-control fkr-set2" :style="'height:36px; padding-left:10px; width:' + (isMobile? '180px' : '310px')" v-model="addr" readonly>
                        </div>
                    </div>

                    <div class="mib_box_a py-3" style="border-bottom:0px !important">
                        <div class="mib_a1 text-end"><span class="fkr-set1">모듈 구성</span></div>
                        <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                        <div class="mib_a3" style="display:flex; flex-wrap:wrap">
                        </div>
                    </div>

                    <div class="mib_box_c">
                        <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background:#000;">
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">용도</span></div>
                            <div class="x_div_center" style="width:15%"><span class="fkr-set2 fc-w1">개수</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">폭(m)</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">길이(m)</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">높이(m)</span></div>
                            <div class="x_div_center" style="width:5%"><span class="fkr-set2 fc-w1"></span></div>
                        </div>

                        <div class="x_div_center ls_bg1" style="height:55px" v-for="(cmpnt, index) in cmpntList" :key="index">
                            <div class="x_div_center px-1" style="width:20%">
                                <select class="custom-select ff-eng1 fs-085" v-model="cmpnt.usage" style="width:100%; height:36px; padding: 1px 0 1px 10px;">
                                    <option value="0">교실</option>
                                    <option value="1">복도</option>
                                    <option value="2">계단</option>
                                    <option value="3">화장실</option>
                                </select>
                            </div>
                            <div class="x_div_center px-1" style="width:15%"><input type="number" v-model="cmpnt.amnt" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px"></div>
                            <div class="x_div_center px-1" style="width:20%"><input type="number" v-model="cmpnt.width" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px"></div>
                            <div class="x_div_center px-1" style="width:20%"><input type="number" v-model="cmpnt.length" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px"></div>
                            <div class="x_div_center px-1" style="width:20%"><input type="number" v-model="cmpnt.height" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px"></div>
                            <div class="x_div_center px-1" style="width:5%"><a href="javascript:void(0)" class="rm_frm" @click="removeCmpnt(index)"><i class="fa-solid fa-circle-minus" style="color:#C00"></i></a></div>
                        </div>

                        <div id="v_frm"></div>

                        <div class="x_div_center py-2"><a href="javascript:void(0)" id="add_frm" @click="addCmpnt()"><i class="fa-solid fa-circle-plus fs-15" style="color:#000"></i></a></div>

                    </div>

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">구조</span></div>
                        <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                        <div class="mib_a3" style="display:flex; flex-wrap:wrap">
                            <li class="custom-radio">
                                <input type="radio" id="structure1" name="structure" class="custom-control-input" v-model="structure" value="1" >
                                <label class="custom-control-label" for="structure1"><span class="fkr-set2">강구조</span></label>
                            </li>
                            <li class="custom-radio">
                                <input type="radio" id="structure2" name="structure" class="custom-control-input" v-model="structure" value="0" >
                                <label class="custom-control-label" for="structure2"><span class="fkr-set2">목구조</span></label>
                            </li>
                        </div>
                    </div>

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">사용기간</span></div>
                        <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                        <div class="mib_a3" style="display:flex;">
                            <select class="custom-select ff-eng1 fs-085" v-model="period" style="width:80px; height:27px; padding: 1px 0 1px 10px; margin:0 10px 0 0">
                                <option value="1">1년</option>
                                <option value="1.5">1.5년</option>
                                <option value="2">2년</option>
                                <option value="2.5">2.5년</option>
                                <option value="3">3년</option>
                                <option value="3.5">3.5년</option>
                                <option value="4">4년</option>
                            </select>
                        </div>
                    </div>

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">인계희망일자</span></div>
                        <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                        <div class="mib_a3">
                            <Datepicker :setDate="handoverDt" @update-date="setHandoverDate" class="form-control fkr-set2 fc-w5 text-center" style="width:120px; height:27px; border-radius:4px; border:1px solid #c9c9c9"/>
                        </div>
                    </div>

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1" v-html="'가격' + (isMobile? '<br>' : '') + '(부가세별도)'"></span></div>
                        <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                        <div class="mib_a3" style="display:flex;">
                            <input type="text" class="form-control fkr-set2" :style="(isMobile? 'width:80px;' : 'width:180px;') + ' height:36px; margin-right: 5px;'" v-model="price">
                            <span class="x_div_center fen-set2"> (빌드마켓에 납부할 수수료 : {{ charge.toLocaleString() }})원</span>
                        </div>
                    </div>

                    <div class="mib_box_b">
                        <div class="mib_set1 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">도면</span></div>
                            <div class="mib_b2 x_div_center"></div>
                            <div class="mib_b3">
                                <span v-if="floorPlanFetched" class="fkr-set2"><a :href="floorPlanFetched.fileLink" :download="floorPlanFetched.attchNm">{{ floorPlanFetched.attchNm }}</a></span>
                                <input v-else id="floorPlanInput" type="file" class="form-control fkr-set2" style="width:100%; height:36px" @change="fileHandling($event, 0)">
                                &nbsp;&nbsp;
                                <a href="javascript:void(0)" @click="deleteFloorPlan()" :style="'padding-top: ' + (floorPlanFetched? '2px' : '10px')"><i class="fa fa-trash"/></a>
                            </div>
                        </div>
                        <div class="mib_set2 py-3" v-if="!isMobile">
                            <div class="mib_b1 text-end"></div>
                            <div class="mib_b2 x_div_center"></div>
                            <div class="mib_b3">
                                <span v-if="floorPlanFetched" class="fkr-set2"><a></a></span>
                                <input v-else type="file" class="form-control fkr-set2" style="opacity:0" disabled>
                                <a href="javascript:void(0)" @click="deleteFloorPlan()" style="padding-top: 2px; opacity: 0;"><i class="fa fa-trash"/></a>
                            </div>
                        </div>
                    </div>

                    <div class="mib_box_a py-3" style="border: 0; display: block !important;">
                        <div v-for="(bucket, i) in picturesFetchedList" :key="i" class="pg_a_set3" style="margin-top: 15px">
                            <div class="pg_a_set3a">
                                <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                                    <div :class="'x_div_center' + (bucket[0].deleted? ' diagonal' : '')">
                                        <img v-if="['pdf', 'zip'].indexOf(bucket[0].mimeType) < 0" :style="'width: 100%; border:0px solid #c9c9c9; border-radius:8px;' + (bucket[0].deleted? 'opacity: 0.5;' : '')" :src="bucket[0].fileLink">
                                        <span v-else class="fen-set2 fs-10"><a :href="bucket[0].fileLink" :download="bucket[0].attchNm">{{ bucket[0].attchNm }}</a></span>
                                        <div class="ig_d" v-show="user.id == sellerId" style="left:90%"><a href="javascript:void(0)" @click="bucket[0].deleted = true; deleteFlIdList.push(bucket[0].id)"><img src="@/assets/img_legacy/r_del.png"></a></div>
                                    </div>
                                </div>
                            </div>
                            <div class="pg_a_set3c"></div>
                            <div class="pg_a_set3b">
                                <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                                    <div :class="'x_div_center' + (bucket[1].deleted? ' diagonal' : '')">
                                        <img v-if="['pdf', 'zip'].indexOf(bucket[1].mimeType) < 0" :style="'border:0px solid #c9c9c9; border-radius:8px;' + (bucket[1].deleted? 'opacity: 0.5;' : '')" :src="bucket[1].fileLink" width="100%">
                                        <span v-else class="fen-set2 fs-10"><a :href="bucket[1].fileLink" :download="bucket[1].attchNm">{{ bucket[1].attchNm }}</a></span>
                                        <div class="ig_d" v-show="user.id == sellerId && bucket[1].id" style="left:90%"><a href="javascript:void(0)" @click="bucket[1].deleted = true; deleteFlIdList.push(bucket[1].id)"><img src="@/assets/img_legacy/r_del.png"></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="x_div_end py-4" style="padding:0 !important; margin-top: 20px;">
                        <button class="x_div_center" style="width:90px; height:30px; border:0; background-color:lightgray; border:1px solid #000; border-radius:4px; color:#000;">
                            <label for="imgUpload" class="fa-kor-Noto fs-085 s-upload">사진 첨부</label>
                        </button>
                        <input type="file" style="display:none" id="imgUpload" name="imgUpload" @change="fileHandling($event, 1)" multiple>
                    </div>
                    
                    <div class="x_div_end py-4" style="padding:0 0 10px 0 !important; border-bottom: 1px solid #d9d9d9;">
                        <div style="display:block; margin-top: 10px;">
                            <span v-for="(file, i) in picturesNewList" :key="i" class="fen-set2 fs-10">{{file.orgNm}}&nbsp;<a href="javascript:void(0)" @click="removeFile(i)"><i class="fas fa-times-circle"></i></a>&nbsp;&nbsp;</span>
                        </div>
                    </div>

                    
                    <div class="x_div_center pt-4">
                        <div class="px-2"><button class="fkr-set1" style="width:90px; height:37px; background-color:#fff; border:1px solid #000; border-radius:4px; color:#000;" @click="moveBack()">취소</button></div>
                        <div class="px-2" v-if="$route.params.usdMtrlId"><button class="fkr-set1" style="width:90px; height:37px; background-color:#000; border:0; border-radius:4px; color:#fff;" @click="saveMtrlInfo()">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                        <div class="px-2" v-else><button class="fkr-set1" style="width:90px; height:37px; background-color:#000; border:0; border-radius:4px; color:#fff;" @click="saveMtrlInfo()">매물 등록<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import Datepicker from '@/components/Datepicker.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
    name: 'MtrlEdit',
    components: {
        Datepicker
	},
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    watch: {
        price(val){
            const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;
            // 한글, 영문 체크
            if(reg.exec(val)!==null) val = String(val).replace(/[^0-9]/g,'');
            this.price = String(val).replace(/[^\d]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // .... 만 입력하게 될 경우 체크
            if(isNaN(parseFloat(val))) this.price = '';
            this.charge = Number(this.price.replace(/[^\d]+/g, '')) * 0.05
        },
    },
    data() {
        return {
            fetching: true,
            submitting: false,
            isMobile: false,
            usdMtrlId: null,
            sellerId: null,
            addr: null,
            cmpntList: [],
            structure: 1,
            period: 1,
            handoverDt: null,
            price: 0,
            charge: 0,
            postDt: null,
            depositYn: 0,
            stts: 0,
            daumObj: null,
            floorPlanFetched: null,
            floorPlanNew: null,
            picturesFetchedList: [],
            picturesNewList: [],
            deleteFlIdList: [],
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login?usdMtrl=enlist';
        }

        this.isMobile = window.innerWidth < 768;

        this.daumObj = new window.daum.Postcode({
            oncomplete: (data) => {
                var addr = ''; // 주소 변수
                var extraAddr = ''; // 참고항목 변수

                //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
                if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
                    addr = data.roadAddress;
                } else { // 사용자가 지번 주소를 선택했을 경우(J)
                    addr = data.jibunAddress;
                }

                // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
                if(data.userSelectedType === 'R'){
                    // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                    // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                    if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                        extraAddr += data.bname;
                    }
                    // 건물명이 있고, 공동주택일 경우 추가한다.
                    if(data.buildingName !== '' && data.apartment === 'Y'){
                        extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                    }
                    // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                    if(extraAddr !== ''){
                        extraAddr = ' (' + extraAddr + ')';
                    }
                }
                this.addr = addr + extraAddr;
            }
        });

        if(this.$route.params.usdMtrlId) {
            this.usdMtrlId = this.$route.params.usdMtrlId;
            const rtnval = await Api.post('/api/v1/usdmtrl/find/usdmtrl', {usdMtrlId: this.usdMtrlId});
            if(rtnval.result){
                if(rtnval.data.sellerId != this.user.id) {
                    alert('본인이 등록한 매물이 아닙니다.');
                    location.href = '/usdmtrl/list';
                }
                else {
                    this.sellerId = rtnval.data.sellerId;
                    this.addr = rtnval.data.addr;
                    this.structure = rtnval.data.structure;
                    this.period = rtnval.data.period;
                    this.handoverDt = rtnval.data.handoverDt;
                    this.price = rtnval.data.price;
                    // this.postDt = rtnval.data.postDt;
                    // this.depositYn = rtnval.data.depositYn;
                    this.stts = rtnval.data.stts;
                    this.cmpntList = rtnval.data.cmpntList;
                }
            }
            else {
                alert(rtnval.errormsg);
            }

            var pictureAttchFiles = [];
            if(rtnval.data.attchflList) {   // TBD
                for(var i=0; i<rtnval.data.attchflList.length; i++) {
                    var usdMtrlAttchFlCtgry = rtnval.data.attchflList[i].usdMtrlAttchFlCtgry.code;
                    if( usdMtrlAttchFlCtgry == "USEDIM" ) {
                        pictureAttchFiles.push(rtnval.data.attchflList[i])
                    }
                    else if( usdMtrlAttchFlCtgry == "USEDFP" ) {
                        var tokens = rtnval.data.attchflList[i].attchNm.split('.');
                        var mimeType = tokens[tokens.length-1].toLowerCase();
                        var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;

                        const byteCharacters = atob(rtnval.data.attchflList[i].attchData);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let j = 0; j < byteCharacters.length; j++) {
                            byteNumbers[j] = byteCharacters.charCodeAt(j);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], {type: fileType});
                        const fileLink = URL.createObjectURL(blob);

                        this.floorPlanFetched = {id: rtnval.data.attchflList[i].id, attchNm: rtnval.data.attchflList[i].attchNm, fileLink: fileLink, mimeType: mimeType, deleted: false};
                    }
                }
            }
            var tempBucket = [];
            var k=0;
            if(pictureAttchFiles) {
                for(i=0; i<pictureAttchFiles.length; i++) {
                    var tokens = pictureAttchFiles[i].attchNm.split('.');
                    var mimeType = tokens[tokens.length-1].toLowerCase();
                    var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;

                    const byteCharacters = atob(pictureAttchFiles[i].attchData);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let j = 0; j < byteCharacters.length; j++) {
                        byteNumbers[j] = byteCharacters.charCodeAt(j);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], {type: fileType});
                    const fileLink = URL.createObjectURL(blob);

                    tempBucket.push({id: pictureAttchFiles[i].id, attchNm: pictureAttchFiles[i].attchNm, fileLink: fileLink, mimeType: mimeType, deleted: false})
                    if((k++)%2 == 1) {
                        this.picturesFetchedList.push(tempBucket);
                        tempBucket = [];
                    }
                    else {
                        if(i == pictureAttchFiles.length -1) {
                            tempBucket.push({id: null, attchNm: null, fileLink: "/./files/img_legacy/nofileimage.jpg", deleted: false});
                            this.picturesFetchedList.push(tempBucket);
                        }
                    }
                }
            }
        }
        this.fetching = false;
    },
    methods: {
        searchAddr() {
          this.daumObj.open();
        },
        addCmpnt() {
            this.cmpntList.push({
                usage: 0,
                amnt: 0,
                width: 0,
                length: 0,
                height: 0
            })
        },
        removeCmpnt(index) {
            this.cmpntList = this.cmpntList.filter((v,i) => i != index );
        },
        setHandoverDate(date) {
            this.handoverDt = new Date(date).toISOString();
        },
        fileHandling(e, index) {
            for(var i=0; i<e.target.files.length; i++) {
                var tokens = e.target.files[i].name.split('.');
                var fileType = tokens[tokens.length-1].toLowerCase();
                var allowed = ['jpg', 'jpeg', 'png', 'pdf', 'zip']
                var allowedImg = ['jpg', 'jpeg', 'png']
                if(index == 0) {
                    if(allowed.indexOf(fileType) < 0){
                        alert('이미지파일 또는 pdf형식 외의 파일은\nzip 형태로 압축하여 업로드 해 주시기 바랍니다.');
                        return 1;
                    }
                }
                else {
                    if(allowedImg.indexOf(fileType) < 0){
                        alert('이미지파일만 업로드 가능합니다.');
                        return 1;
                    }
                }
            }

            if(index == 0) {
                this.floorPlanNew = {file: e.target.files[0], orgNm: e.target.files[0].name};
            }
            else {
                for(i=0; i<e.target.files.length; i++) {
                    this.picturesNewList.push({file: e.target.files[i], orgNm: e.target.files[i].name})
                }
            }

        },
        removeFile(i) {
            this.picturesNewList.splice(i,1);
        },
        deleteFloorPlan() {
            if(this.floorPlanFetched) {
                this.deleteFlIdList.push(this.floorPlanFetched.id);
                this.floorPlanFetched = null;
            }
            else {
                this.floorPlanNew = null;
                window.$('#floorPlanInput').val('')
            }
        },
        moveBack() {
            if(this.$route.params.usdMtrlId) {
                location.href = '/usdmtrl/store/' + this.$route.params.usdMtrlId;
            }
            else {
                location.href = '/usdmtrl/list';
            }
        },
        async saveMtrlInfo() {
            if(!this.addr) {
                alert('주소는 필수입력값입니다.');
                return;
            }
            if(this.cmpntList.length == 0) {
                alert('구성 모듈을 1개 이상 등록하세요.');
                return;
            }
            if(!this.handoverDt) {
                alert('인계희망일자는 필수입력값입니다.');
                return;
            }
            if(!this.price || Number(this.price.replace(/[^\d]+/g, '')) <= 0) {
                alert('가격을 정확히 입력해주세요.');
                return;
            }
            
            var setMtrlRequest = {
                usdMtrlId: this.usdMtrlId,
                addr: this.addr,
                structure: this.structure,
                period: this.period,
                handoverDt: this.handoverDt,
                price: Number(this.price.replace(/[^\d]+/g, '')),
                cmpntList: this.cmpntList
            }
            
            this.submitting = true;
            const returnvalue = await Api.post('/api/v1/usdmtrl/' + (this.usdMtrlId? 'update' : 'set') + '/usdmtrl', setMtrlRequest);
            if(returnvalue.result){
                const usdMtrlId = returnvalue.data.usdMtrlId;
                    if(this.floorPlanNew || this.picturesNewList.length > 0 || this.deleteFlIdList.length > 0) {
                        var request = {
                            parentId: [],
                            attchFlCtgry: [],
                            deleteFlIdList: this.deleteFlIdList,
                            attchOrgNmList: []
                        }
                        const formData = new FormData();
                        this.picturesNewList.forEach((file) => {
                            request.attchOrgNmList.push(file.orgNm);
                            request.attchFlCtgry.push('USEDIM');
                            request.parentId.push(usdMtrlId);
                            formData.append("attchmntList", file.file);
                        })
                        if(this.floorPlanNew) {
                            request.attchOrgNmList.push(this.floorPlanNew.orgNm);
                            request.attchFlCtgry.push('USEDFP');
                            request.parentId.push(usdMtrlId);
                            formData.append("attchmntList", this.floorPlanNew.file);
                        }
                        formData.append(
                            "request",
                            new Blob([JSON.stringify(request)], { type: "application/json" })
                        );
                        const rtnvalue = await Api.post('/api/v1/common/set/attachments', formData);
                        if(rtnvalue.result) {
                            if(this.usdMtrlId) {
                                alert('수정사항이 저장되었습니다.');
                                location.href = '/usdmtrl/store/' + this.$route.params.usdMtrlId;
                            }
                            else {
                                alert('정상적으로 등록되었습니다.\n관리자의 승인이 완료되면 매물 목록에 노출됩니다.');
                                location.href = '/usdmtrl/list';
                            }
                        }
                        else {
                            alert(rtnvalue.errormsg + "\n첨부파일을 다시 업로드하신 후 저장하세요.")
                            location.href = '/usdmtrl/edit/' + usdMtrlId;
                        }
                    }
                    else {
                        if(this.usdMtrlId) {
                            alert('수정사항이 저장되었습니다.');
                            location.href = '/usdmtrl/store/' + this.$route.params.usdMtrlId;
                        }
                        else {
                            alert('정상적으로 등록되었습니다.\n관리자의 승인이 완료되면 매물 목록에 노출됩니다.');
                            location.href = '/usdmtrl/list';
                        }
                    }
            }
            else {
                alert(returnvalue.errormsg);
            }
            this.submitting = false;
        }
    }
}
</script>

<style scoped>
li {
    margin: 5px 15px 5px 0;
}
.diagonal {
    width: 100%;
    height: 100%;
    background: linear-gradient(
    to top right,
    #fff calc(50% - 1px),
    red,
    #fff calc(50% + 1px)
    );
}
</style>
