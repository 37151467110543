<template>
	<div class="d-flex">
		<LNB :user="user"/>
		<div class="ca_main">
			<div class="x_div_center">
				<div class="pg_stage">

					<div class="x_div_center pt-5">
						<div class="" style="width:80%"><span class="fkr-set1 fs-16" style="color:#333333">{{fetchedData.prj.prjNm.split(';')[0]}}</span></div>
						<div class="text-end" style="width:20%"><span class="fkr-set2" style="color:#484848"><span class="fw-bold">개요</span></span></div>
					</div>

					<div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

					<div class="pg_m_set" style="margin-top: 20px" v-if="!fetchedData.prj.prjNm">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>
					
					<div v-else>
						<div class="x_div_end py-4">
							<div class="x_div_end py-2" v-if="user.info.usrRole == 'OW'"><button @click="removeProject()" class="fkr-set2 fs-095" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">프로젝트 삭제</button></div>
						</div>
						<div class="pg_a_set3">
							<div class="pg_a_set3a">
								<div class="card" style="border:1px solid #c9c9c9; border-radius:8px">

									<div class="pg_m_set3 w-100" style="height:178px">

										<div class="pg_d_set3a">

											<div class="x_div_center w-100">
												<div style="width:80%">
													<div class="x_div_center" style="height:50px; border-bottom:1px solid #c9c9c9"><span class="fa-kor-Noto" style="font-weight:700; font-size:14pt">{{ currentDate }}</span></div>
													<div class="x_div_center" style="height:128px">
														<div class="w-50">
															<div class="x_div_center py-2"><span class="fa-kor-Noto" style="font-weight:500; font-size:12pt">진행날짜</span></div>
															<div class="x_div_center"><span class="fa-kor-Noto fc-w11" style="font-weight:700; font-size:18pt">{{fetchedData.prgrssDt}}일</span></div>
														</div>
													</div>
												</div>
											</div>

										</div>

										<div class="pg_d_set3b">
											<div class="w-100 x_div_center">
												<div style="width:80%">
													<div class="x_div_center" style="height:50px; border-bottom:1px solid #c9c9c9"><span class="fa-kor-Noto" style="font-weight:700; font-size:14pt">설계 진척 상황</span></div>
													<div class="x_div_center" style="height:128px"><span class="fkr-set1 fs-12 fc-w11" style="font-weight:700; font-size:20pt">{{fetchedData.prcInfoDto[0].prcStts}}</span></div>
												</div>
											</div>
										</div>

									</div>

								</div>

								<div style="height:19px"></div>

								<div class="card" style="border:1px solid #c9c9c9; border-radius:8px">

									<div class="pg_m_set3 w-100" style="height:178px">

										<div class="pg_d_set3a" style="border-right:1px solid #c9c9c9">
											<div class="w-100 x_div_center">
												<div style="width:80%">
													<div class="x_div_center" style="height:50px; border-bottom:1px solid #c9c9c9"><span class="fa-kor-Noto" style="font-weight:700; font-size:14pt">제조 진행률</span></div>
													<div class="x_div_center" style="height:128px"><span class="fa-kor-Noto fc-w11" style="font-weight:700; font-size:20pt">{{mnfctPrgrss}}%</span></div>
												</div>
											</div>
										</div>

										<div class="pg_d_set3b x_div_center">
											<div class="w-100 x_div_center" style="border:0px solid red">
												<div style="width:80%">
													<div class="x_div_center" style="height:50px; border-bottom:1px solid #c9c9c9"><span class="fa-kor-Noto" style="font-weight:700; font-size:14pt">시공 진행률</span></div>
													<div class="x_div_center" style="height:128px"><span class="fa-kor-Noto fc-w11" style="font-weight:700; font-size:20pt">{{cntrctPrgrss}}%</span></div>
												</div>
											</div>
										</div>

									</div>

								</div>
							</div>
							<div class="pg_a_set3c"></div>
							<div class="pg_a_set3b">
								<div class="card x_div_center" style="height:375px; border:1px solid #c9c9c9; border-radius:8px">
									<div>
										<div class="x_div_center"><span class="fa-kor-Noto" style="font-weight:700; font-size:14pt">현재 단계</span></div>
										<div class="x_div_center"><span class="fkr-set1 fs-15 fc-w11" v-html="currentState"></span></div>
									</div>
								</div>
							</div>
						</div>

						<div style="height:20px"></div>


						<div class="pg_a_set3" v-if="this.user.info.usrRole == 'OW'">
							<div class="pg_a_set3a">
								<div class="card x_div_center" style="height:178px; border:1px solid #c9c9c9; border-radius:8px">
									<div class="x_div_center w-100">
										<div :style="'width:' + (isMobile? '95%' : '90%')">
											<div class="x_div_center" style="height:50px; border-bottom:1px solid #c9c9c9"><span class="fa-kor-Noto" style="font-weight:700; font-size:14pt">다음 지불 과정</span></div>
											<div class="x_div_center" style="height:128px">
												<div class="w-50">
													<div class="x_div_center py-2"><span class="fa-kor-Noto" style="font-weight:500; font-size:12pt">다음 지불 단계</span></div>
													<div class="x_div_center"><span class="fkr-set1 fs-10 fc-w11" v-html="pymntStg"></span></div>
													<div class="x_div_center" v-for="(pymnt, index) in pymntStgList" :key="index"><span class="fkr-set1 fs-10 fc-w11" v-html="pymnt"></span></div>
												</div>
												<div class="w-50">
													<div class="x_div_center py-2"><span class="fa-kor-Noto" style="font-weight:500; font-size:12pt">입금 요청일</span></div>
													<div class="x_div_center"><span class="fen-set1 fs-10 fc-w11">{{dpstRqstDt? dpstRqstDt.split('T')[0] : ''}}</span></div>
													<div class="x_div_center" v-for="(dpst, kintex) in dpstRqstDtList" :key="kintex"><span class="fen-set1 fs-10 fc-w11" v-html="dpst? dpst.split('T')[0] : '<br>'"></span></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="pg_a_set3c"></div>
							<div class="pg_a_set3b">
								<div class="card x_div_center" style="border:1px solid #c9c9c9; border-radius:8px">

									<div class="x_div_center w-100" style="height:178px">

										<div style="width:50%; border-right:1px solid #c9c9c9">
											<div class="w-100 x_div_center">
												<div style="width:80%">
													<div class="x_div_center" style="height:50px; border-bottom:1px solid #c9c9c9"><span class="fkr-set1 fs-10">현재까지 지불 비용</span></div>
													<div class="x_div_center" style="height:128px"><span class="fen-set1 fs-11 fc-w11">{{Number(pymnt).toLocaleString()}} 원</span></div>
												</div>
											</div>
										</div>

										<div class="x_div_center" style="width:50%">
											<div class="w-100 x_div_center" style="border:0px solid red">
												<div style="width:80%">
													<div class="x_div_center" style="height:50px; border-bottom:1px solid #c9c9c9"><span class="fkr-set1 fs-10">예상비용</span></div>
													<div class="x_div_center" style="height:128px"><span class="fen-set1 fs-11 fc-w11">{{Number(totalAmounts).toLocaleString()}} 원</span></div>
												</div>
											</div>
										</div>

									</div>

								</div>
							</div>
						</div>

						<div style="height:20px"></div>

						<div class="pg_a_set3">
							<div class="pg_a_set3a">
								<div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
									<div class="x_div_center"><img style="width: 100%; border:0px solid #c9c9c9; border-radius:8px" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + dsgnRprsnttvImgPath"></div>
								</div>
							</div>
							<div class="pg_a_set3c"></div>
							<div class="pg_a_set3b">
								<div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
									<div class="x_div_center"><img style="border:0px solid #c9c9c9; border-radius:8px" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + dsgnRprsnttvImgPath2" width="100%"></div>
								</div>
							</div>

						</div>

						<div style="height:20px"></div>

						<div>

							<div class="table-responsive" style="border-top:3px solid #c9c9c9; border-bottom:3px solid #c9c9c9">
								<table class="table-borderless w-100">

								<tr style="height:55px; border-bottom:1px solid #c9c9c9">
									<td class="text-center" width="15%"><span class="fkr-set1">대지 위치</span></td>
									<td class="text-center" width="5%"></td>
									<td class="" width="80%">
										<span class="fkr-set2" v-if="fetchedData.prj.addr">{{fetchedData.prj.addr}}</span>
										<div style="display:flex" v-else>
											<input type="text" v-model="addrInput" class="form-control fkr-set2 fc-w5" style="width:50%; height:36px; border-radius:4px" disabled>
											<button v-if="user.info.usrRole == 'OW'" @click="openModalForLandEdit()" class="fkr-set2 fs-095" style="width:80px; height:37px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000; margin-left:15px">검색</button>
											<button v-if="user.info.usrRole == 'OW'" @click="saveAddrInfo()" class="fkr-set2 fs-095" style="width:80px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px; margin-left:15px">저장</button>
										</div>
									</td>
								</tr>
								<tr v-show="user.info.adminYn == 'Y' || user.info.adminYn == 'S'" v-for="(dto, index) in fetchedData.prcInfoDto" :key="index" style="height:55px; border-bottom:1px solid #c9c9c9">
									<td class="text-center" width="15%"><span class="fkr-set1">{{ dto.prcNm + '요구사항' }}</span></td>
									<td class="text-center" width="5%"></td>
									<td class="" width="80%">
										<span class="fkr-set2" v-html="dto.bddngRqst"></span>
									</td>
								</tr>
								</table>

								<table class="table-borderless w-100">
								<tr style="height:55px; border-bottom:1px solid #c9c9c9">
									<td class="text-center" width="15%"><span class="fkr-set1">건폐율 / 용적률</span></td>
									<td class="text-center" width="35%">
										<span class="fkr-set2" v-if="fetchedData.prj.buildingLandRatio">{{(fetchedData.prj.buildingLandRatio? fetchedData.prj.buildingLandRatio + '%' : '') + ' / ' + (fetchedData.prj.floorAreaRatio? fetchedData.prj.floorAreaRatio + '%' : '')}}</span>
										<div style="display:flex; margin-left: 15%" v-else>
											<input type="text" v-model="buildingLandRatioInput" class="form-control fkr-set2 fc-w5" :style="'width:20%; height:36px; border-radius:4px; margin-right: 15px; ' + (isMobile? 'padding: 0' : '')" disabled> <span class="fkr-set2" style="padding-top: 5px"> / </span> <input type="text" v-model="floorAreaRatioInput" class="form-control fkr-set2 fc-w5" :style="'width:20%; height:36px; border-radius:4px; margin-left: 15px; ' + (isMobile? 'padding: 0' : '')" disabled>
										</div>
									</td>
									<td class="text-center" width="15%"><span class="fkr-set1">건축면적</span></td>
									<td class="text-center" width="35%"><span class="fkr-set2">{{fetchedData.prj.buildingArea? fetchedData.prj.buildingArea + '㎡' : ''}}</span></td>
								</tr>
								<tr style="height:55px; border-bottom:1px solid #c9c9c9">
									<td class="text-center" width="15%"><span class="fkr-set1">연면적</span></td>
									<td class="text-center" width="35%"><span class="fkr-set2">{{fetchedData.prj.totalGroundArea? fetchedData.prj.totalGroundArea + '㎡' : ''}}</span></td>
									<td class="text-center" width="15%"><span class="fkr-set1">구조</span></td>
									<td class="text-center" width="35%"><span class="fkr-set2">{{fetchedData.prj.strctr}}</span></td>
								</tr>
								<tr style="height:55px; border-bottom:1px solid #c9c9c9">
									<td class="text-center" width="15%"><span class="fkr-set1">층수</span></td>
									<td class="text-center" width="35%"><span class="fkr-set2">{{fetchedData.prj.floors > 0? fetchedData.prj.floors : ''}}</span></td>
									<td class="text-center" width="15%"><span class="fkr-set1">지하층수</span></td>
									<td class="text-center" width="35%"><span class="fkr-set2">{{fetchedData.prj.floorsUnderground > 0? fetchedData.prj.floorsUnderground : ''}}</span></td>
									
								</tr>
								<tr style="height:55px; border-bottom:1px solid #c9c9c9">
									<td class="text-center" width="15%"><span class="fkr-set1">용도</span></td>
									<td class="text-center" width="35%"><span class="fkr-set2">{{fetchedData.prj.purpose}}</span></td>
									<td class="text-center" width="15%"><span class="fkr-set1">주차대수</span></td>
									<td class="text-center" width="35%"><span class="fkr-set2">{{fetchedData.prj.parking}}</span></td>
								</tr>

								</table>
							</div>

						</div>
						<div class="x_div_end py-2" v-if="['CNK','CWK','PRO','SHH','PRS'].indexOf(fetchedData.prj.planId.substring(0,3)) >= 0"><button @click="openModalForSimulation()" class="fkr-set2 fs-095" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">시뮬레이션 보기</button></div>
						<div class="x_div_end py-2" v-else-if="['CMC','OFC'].indexOf(fetchedData.prj.planId) < 0"><button onclick="window.$('#v-view-office').modal('show');" class="fkr-set2 fs-095" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">시뮬레이션 보기</button></div>
					</div>

					<div style="height:50px"></div>

					<div id="v-view" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:10001;">
						<div class="modal-dialog modal-lg" role="document">
							<div class="modal-content">

								<div class="w-100">
									<div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
										<div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">{{ mobuilderTitle }}</span></div>
										<div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()" style="color:#4e0000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
									</div>

									<div class="x_div_center py-2 px-4">
										<div class="w-100 x_div_center py-3">
											<iframe id="ifr" class="ifr" ref="ifrm" src=""></iframe>
										</div>
									</div>

									<div class="x_div_center py-4">
										<div class="px-2"><button @click="closeModal()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
									</div>

									<div style="height:20px"></div>

								</div>
							</div>
						</div>
					</div>
					<div id="v-view-office" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:10001;">
						<div class="modal-dialog modal-lg" role="document">
							<div class="modal-content">

								<div class="w-100">
									<div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
										<div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">시뮬레이션</span></div>
										<div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()" style="color:#4e0000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
									</div>

									<div class="x_div_center py-2 px-4">
										<div class="w-100 x_div_center py-3">
											<canvas class="ifr" ref="myCanvas" id="my-canvas" style="background:lightblue" @mouseenter="disableScroll" @mouseleave="enableScroll"></canvas>
										</div>
									</div>

									<div class="x_div_center py-4">
										<div class="px-2"><button @click="closeModal()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
									</div>

									<div style="height:20px"></div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Viewer, GLTFLoaderPlugin } from '@xeokit/xeokit-sdk';
import LNB from '@/components/LNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

var gIfrmMobuilder = null;

export default {
    name: 'ProjectSummary',
	components: {
		LNB
	},
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            currentDate: '',
			addrInput: '',
			prjNmInput: '',
			floorAreaRatioInput :null,
			buildingLandRatioInput :null,
			isMobile: false,
			mobuilderTitle: '대지 위치 검색',
            currentState: '',
			pymntStg:'',
			pymntStgList:[],
			dpstRqstDt:null,
			dpstRqstDtList:[],
			pymnt:0,
			totalAmounts:0,
			mnfctPrgrss:0,
			cntrctPrgrss:0,
			dsgnRprsnttvImgPath:'',
			dsgnRprsnttvImgPath2:'',
            fetchedData: {
				prj: {
					prjNm:'',
					addr:'',
					buildingLandRatio:null,
					floorAreaRatio:null,
					buildingArea:null,
					constructionArea:null,
					totalGroundArea:null,
					floors:0,
					floorsUnderground:0,
					parking:null,
					strctr:null,
					purpose:'',
					planId:''
				},
				prgrssDt:0,
				dsgnRprsnttvImgPath: [],
				prcInfoDto: []
			},
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET,
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }

		this.isMobile = window.innerWidth < 768;
		
        const rtnval = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + this.$route.params.prjId);
        if(rtnval.result) {
            this.fetchedData = rtnval.data;
			sessionStorage.setItem('prjNm', rtnval.data.prj.prjNm.split(';')[0]);
        }
        else {
            alert(rtnval.errormsg)
        }

		this.viewer = new Viewer({
			canvasId: "my-canvas",
			transparent: true,
		});
		
		this.viewer.camera.eye = [-24,20,20]; // Eye position
        this.viewer.camera.look = [0, 0, 0]; // Look-at position
        this.viewer.camera.up = [0, 1, 0]; // Up vector
		
		this.gltfLoader = new GLTFLoaderPlugin(this.viewer);
  
        this.gltfModel = this.gltfLoader.load({
          src: 'https://s3.' + this.s3region + '.amazonaws.com/' + this.s3bucket + '/platform/plan/office/' + this.fetchedData.prj.planId + '/' + this.fetchedData.prj.planId + '.glb',
          edges: true,
          rotation: [0, 0, 0]
        });

		this.$refs.ifrm.src = "https://mobuild.cafe24.com/mobuilder/index/public/?ma=builder&mu=modeUserMain";

		const gIframe = document.getElementById("ifr");

		gIframe.addEventListener("load", function() {
			gIfrmMobuilder = (gIframe.contentWindow) ? gIframe.contentWindow :
			(gIframe.contentDocument.document) ? gIframe.contentDocument.document : gIframe.contentDocument;
		});


		window.addEventListener('message', (e) => {
			// console.log('================== post message ====================')
			// console.log(e.data);
			if( e.data.functionName == 'fInfoFromMobuilder' ) {
				const jsonObj = JSON.parse(e.data.para[1]);
				if(e.data.para[0] == 'land') {
					this.addrInput = jsonObj.addressDoro? jsonObj.addressDoro : jsonObj.addressJibun;
					this.prjNmInput = this.addrTokenizer(jsonObj.addressJibun) + ' ' + (this.fetchedData.prj.purpose.indexOf(',') >0 ? this.fetchedData.prj.purpose.split(',')[0] : this.fetchedData.prj.purpose) + ';' + this.addrInput;
					this.buildingLandRatioInput = jsonObj.ratioCoverMax;
					this.floorAreaRatioInput = jsonObj.ratioFloorAreaMax;
				}
				else if(e.data.para[0] == 'building') {
					//TBD
				}
			}
			else if( e.data.functionName == 'fExpandMobuilderFrame' ) {
				this.expandIframe(e.data.para[0]);
			}
			else if( e.data.functionName == 'fMobuilderCallback' ) {

				const gjMenuSet = {
				"project": {            // 프로젝트
					"outline": true,    // - 사업 개요
					"info": true,       // - 사업 정보
				},
				"land": {               // 대지
					"address": true,    // - 주소 입력
					"danji": false,     // - 단지 선택 <-- 숨기기
					"info": true        // - 대지 정보
				},
				"model": {              // 모델
					"outline": true,    // - 모델 개요
					"info": true,       // - 모델 정보
					"build": true       // - 모델 수정
				}
				}

				gIfrmMobuilder.postMessage({
					functionName : "fSidebarSetMenu",
					para : [JSON.stringify(gjMenuSet)] 
				}, "*" );
			}
		});

        this.currentDate = new Date().toLocaleString().split('. 오')[0].replace('. ', '-').replace('. ', '-');

		this.fetchedData.prcInfoDto.forEach((dto, index) => {
			var pymntStg = '';
			if(dto.pymntType < 0) {
				pymntStg = dto.totalAmounts > 0? dto.prcNm + ' 비용 지불 단계 ' + (this.isMobile && dto.prcTyp == 'IN'? '<br>' : '') + '미설정' : dto.prcNm + ' 계약 전';
			}
			else if(dto.pymntType == 0) {
				pymntStg = dto.prcNm + ' 선급금';
			}
			else if(dto.pymntType == 99) {
				if(dto.payedAmounts == dto.totalAmounts) {
					pymntStg = dto.prcNm + ' 비용 지급완료';
				}
				else {
					pymntStg = dto.prcNm + ' 잔금';
				}
			}
			else {
				pymntStg = dto.prcNm + ' ' + dto.pymntType + '차 중도금';
			}
			this.pymnt += dto.payedAmounts;
			this.totalAmounts += dto.totalAmounts;

			if(index == 0) {
				this.currentState = dto.prcNm + ' - ' + dto.prcStts;
				this.pymntStg = pymntStg;
				this.dpstRqstDt = dto.dpstRqstDt;
			}
			else {
				if(index == 1) {
					this.currentState = dto.prcNm + ' - ' + dto.prcStts;
				}
				else {
					this.currentState += '<br>' + dto.prcNm + ' - ' + dto.prcStts;
				}
				if(dto.prcStpAttchFlCtgryNm) {
					this.currentState += (this.isMobile || (dto.prcStpTypCd && ['MO', 'CO'].indexOf(dto.prcStpTypCd) >= 0)? '<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' : '') + '(' + dto.prcStpAttchFlCtgryNm + ')';
				}

				if(dto.prcTyp == 'MA') {
					this.mnfctPrgrss = dto.prcPrgrssPrcnt;
				}
				else if(dto.prcTyp == 'CN') {
					this.cntrctPrgrss = dto.prcPrgrssPrcnt;
				}
				this.pymntStg = '';
				this.dpstRqstDt = null;
				this.pymntStgList.push(pymntStg);
				this.dpstRqstDtList.push(dto.dpstRqstDt);
			}
		})

		this.dsgnRprsnttvImgPath = this.fetchedData.dsgnRprsnttvImgPath[0];
		this.dsgnRprsnttvImgPath2 = this.fetchedData.dsgnRprsnttvImgPath[1];
		
    },
	methods: {
		async removeProject() {
			if(confirm('정말로 삭제하시겠습니까?')) {
				const rtnval = await Api.post('/api/v1/project/delete/prj', {prjId: this.$route.params.prjId});
				if(rtnval.result) {
					alert('프로젝트가 삭제되었습니다.');
					sessionStorage.setItem('prjId', null);
					location.href = '/project/dashboard'
				}
				else {
					alert(rtnval.errormsg);
				}
			}
		},
		async saveAddrInfo() {
			if(confirm('대지 위치 정보를 저장하시겠습니까?')) {
				const rtnval = await Api.post('/api/v1/project/update/prj/dtl', {
					prjId: this.$route.params.prjId,
					prjNm: this.prjNmInput,
					addr: this.addrInput,
					buildingLandRatio: this.buildingLandRatioInput,
					floorAreaRatio: this.floorAreaRatioInput
				});
				if(rtnval.result) {
					location.reload();
				}
				else {
					alert(rtnval.errormsg);
				}
			}
		},
		addrTokenizer(addr) {
			if(addr.indexOf('동 ') > 0) {
			var addrHead = addr.split('동 ')[0];
			var token = addrHead.split(' ');
			return token[token.length-1] + '동'
			}
			
			if(addr.indexOf('면 ') > 0) {
			addrHead = addr.split('면 ')[0];
			token = addrHead.split(' ');
			return token[token.length-1] + '면'
			}
	
			if(addr.indexOf('읍 ') > 0) {
			addrHead = addr.split('읍 ')[0];
			token = addrHead.split(' ');
			return token[token.length-1] + '읍'
			}
	
			token = addr.split(' ');
			return token.length > 0? (token[token.length-2] + ' ' + token[token.length-1]) : '';
		},
		expandIframe(mode) {
			if(mode == 'expand') {
				window.$('#ifr').css('z-index', 10002);
				window.$('#ifr').css('position', 'fixed');
				window.$('#ifr').css('height', '100%');
			}
			else if(mode == 'return') {
				window.$('#ifr').css('z-index', 1);
				window.$('#ifr').css('position', 'relative');
				window.$('#ifr').css('height', '600px');
			}
		},
		openModalForLandEdit() {
			gIfrmMobuilder.postMessage({
				functionName : "fSelectSidebarMenu",
				para : ['land', 'address', '']
			}, "*" );

			gIfrmMobuilder.postMessage({
				functionName : "fSidebarOpen",
				para : []
			}, "*" );
			this.mobuilderTitle = '대지 위치 검색'
			this.openModal()
		},
		openModalForSimulation() {
			var ifcDataUpperFolder = '';
			switch(this.fetchedData.prj.planId.substring(0,3)) {
				case 'CNK' : ifcDataUpperFolder = '01-' + 'CNK';
					break;
				case 'CWK' : ifcDataUpperFolder = '02-' + 'CWK';
					break;
				case 'PRO' : ifcDataUpperFolder = '03-' + 'PRO';
					break;
				case 'SHH' : ifcDataUpperFolder = '04-' + 'SHH';
					break;
				case 'PRS' : ifcDataUpperFolder = '11-' + 'PRS';
					break;
			}
			gIfrmMobuilder.postMessage({
				functionName : "fLoadIfcByParent",
				para : [ifcDataUpperFolder, this.fetchedData.prj.planId, this.fetchedData.prj.planId, this.user? this.user.id : '']
			}, "*" );

			gIfrmMobuilder.postMessage({
				functionName : "fSelectSidebarMenu",
				para : ["model", "", ""]
			}, "*" );

			gIfrmMobuilder.postMessage({
				functionName : "fSidebarClose",
				para : []
			}, "*" );

			this.mobuilderTitle = '시뮬레이션'
			this.openModal()
		},
		openModal() {
			window.$('#v-view').modal('show');
		},
		closeModal() {
			window.$('.modal').modal('hide');
		},
		disableScroll() {
			document.body.style.overflow = 'hidden'; // Disable scrolling
		},
		enableScroll() {
			document.body.style.overflow = ''; // Enable scrolling
		}
	}
}
</script>

<style scoped>

.ifr {
  width:100%;
  height:600px;
  top:0;
  left:0;
  border:1px solid #eee;
}

</style>