  <template>
    <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </template>
  
  <script>
  import { Line as LineChartGenerator } from "vue-chartjs/legacy";
  
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
  } from "chart.js";
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  );
  
  export default {
    name: "LineChart",
    components: {
      LineChartGenerator,
    },
    props: {
      chartId: {
        type: String,
        default: "line-chart",
      },
      datasetIdKey: {
        type: String,
        default: "label",
      },
      width: {
        type: Number,
        default: 400,
      },
      height: {
        type: Number,
        default: 400,
      },
      cssClasses: {
        default: "",
        type: String,
      },
      styles: {
        type: Object,
        default: () => {},
      },
      plugins: {
        type: Array,
        default: () => [],
      },
      labels: {
        type: Array,
        default: () => [],
      },
      data: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        chartData: {
          labels: this.labels,
          datasets: [
            {
              label: "총 가입자 수",
              backgroundColor: "#f87979",
              borderColor: "#f87979",
              data: this.data[0],
            },
            {
              label: "고객 가입자 수",
              backgroundColor: "#ffbf00",
              borderColor: "#ffbf00",
              data: this.data[1],
            },
            {
              label: "설계사 가입자 수",
              backgroundColor: "#40ff00",
              borderColor: "#40ff00",
              data: this.data[2],
            },
            {
              label: "제조사 가입자 수",
              backgroundColor: "#00bfff",
              borderColor: "#00bfff",
              data: this.data[3],
            },
            {
              label: "시공사 가입자 수",
              backgroundColor: "#4000ff",
              borderColor: "#4000ff",
              data: this.data[4],
            },
            {
              label: "인테리어사 가입자 수",
              backgroundColor: "#bf00ff",
              borderColor: "#bf00ff",
              data: this.data[5],
            },
            {
              label: "사업파트너 가입자 수",
              backgroundColor: "#ff99ff",
              borderColor: "#ff99ff",
              data: this.data[6],
            }
          ],
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
        },
      };
    },
  };
  </script>
  