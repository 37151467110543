<template>
  <div class="x_div_center">
    <div class="pg_m_set" style="padding: 20px">
      <i class='h3 fa fa-spinner fa-spin'></i>
    </div>
  </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "ProxyPage",
  async created() {
    if(!await Auth.isLogedIn()){
      alert('로그인이 필요합니다.');
      window.close();
    }

    var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
    if(rtnval.result){
      let usrId = rtnval.data;

      rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
      if(rtnval.result){
        let adminYn = rtnval.data.adminYn;
        if(!adminYn || adminYn == 'E'){
          alert('접근권한이 없습니다.');
          window.close();
        }
        else {
          location.href = decodeURIComponent(this.$route.query.redirect);
        }
      }
    }
    else {
      alert(rtnval.errormsg)
      window.close();
    }
  }
}
</script>

