<template>
    <div class="m3_contetns" style="margin-top: 50px">
        <div class="mib_mod">
            <div class="w-100">
                <div class="x_div_center" style="margin-bottom: 15px"><span class='h3'>임직원 출타현황</span></div>

                <div class="pg_m_set" v-if="fetching">
                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div v-else class="pid_2">
                    <div class="mib_box_c">
                        <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color: #4e0000;">
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1 fs-075">출타자</span></div>
                            <div class="x_div_center" style="width:40%"><span class="fkr-set2 fc-w1 fs-075">사유</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1 fs-075">시작일</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1 fs-075">종료일</span></div>
                        </div>
                        
                        <div class="x_div_center ls_bg1" style="height:45px; border-bottom: 2px solid #e7e7e7;">
                            <div class="x_div_center px-1"><span class="fkr-set1 fs-085" style="text-align: center;">오늘</span></div>
                        </div>
                        <div class="x_div_center ls_bg1" style="height:45px;" v-for="(event, index) in eventList.today" :key="index">
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ event.fullName }} </span></div>
                            <div class="x_div_center px-1" style="width:40%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ event.summary }} </span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2 fs-085" style="text-align: center;"></span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2 fs-085" style="text-align: center;"></span></div>
                        </div>

                        <div class="x_div_center ls_bg1" style="height:45px; border-top: 2px solid #e7e7e7; border-bottom: 2px solid #e7e7e7;">
                            <div class="x_div_center px-1"><span class="fkr-set1 fs-085" style="text-align: center;">금주</span></div>
                        </div>
                        <div class="x_div_center ls_bg1" style="height:45px;" v-for="(event, index) in eventList.thisWeek" :key="index">
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ event.fullName }} </span></div>
                            <div class="x_div_center px-1" style="width:40%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ event.summary }} </span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ event.start }} </span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ event.end }} </span></div>
                        </div>

                        <div class="x_div_center ls_bg1" style="height:45px; border-top: 2px solid #e7e7e7; border-bottom: 2px solid #e7e7e7;">
                            <div class="x_div_center px-1"><span class="fkr-set1 fs-085" style="text-align: center;">차주</span></div>
                        </div>
                        <div class="x_div_center ls_bg1" style="height:45px;" v-for="(event, index) in eventList.nextWeek" :key="index">
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ event.fullName }} </span></div>
                            <div class="x_div_center px-1" style="width:40%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ event.summary }} </span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ event.start }} </span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ event.end }} </span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'
export default {
    name: 'ScheduleDashboard',
    data() {
        return {
            fetching: true,
            isMobile: false,
            eventList: {
                today: [],
                thisWeek: [],
                nextWeek: [],
            },
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        },
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
            location.href = '/member/login?fromScheduleBoard=1'
        }

        this.isMobile = window.innerWidth < 768;

        const rtn = await Api.get('/api/v1/common/get/employees/schedule');
        if(rtn.result) {
            this.eventList = {today: rtn.data.today? rtn.data.today : [], thisWeek: rtn.data.thisWeek? rtn.data.thisWeek : [], nextWeek: rtn.data.nextWeek? rtn.data.nextWeek : []};
        }
        else {
            alert(rtn.errormsg);
        }

        this.fetching = false;
    }
}
</script>

<style scoped>
img {
    left:39%;
}
#search_tooltip {
    right:25%;
}
@media screen and (max-width: 768px) {
    img {
        left:55%;
    }   
    #search_tooltip {
        right:55%;
    }
}

</style>