<template>
    <div class="m3_contetns" style="margin-top: 50px">

    <!-- 로그인 box 시작 { -->
    <div class="m_mem_box">

        <div style="width:90%">

            <div class="x_div_center pb-3"><div class="h3 ff-kor1 fc-w6">회원정보찾기</div></div>

            <div class="d-flex p-3 px-3" style="background-color:#e9e9e9; border:1px solid #d9d9d9; border-radius:.5rem">
                <div class="x_div_center pe-2"><i class="fa-solid fa-circle-info fs-12"></i></div>
                <div><span class="fkr-set1 fc-w5">회원가입시 등록하신 휴대폰 번호를 입력해주세요.</span></div>
            </div>

            <div class="x_div_center py-3 x_div_end">
                <div class="x_div_center">
                    <div class="x_div_center px-2">
                        <i class="fa-regular fa-circle-check pe-1"></i> <a href="/member/findid"><span class="ff-kor1 fs-085">아이디 찾기</span></a> │
                        <i class="fa-solid fa-circle-check pe-1"></i> <a href="/member/resetpass"><span class="ff-kor1 fs-085 fw-bold">비밀번호 재설정</span></a>
                    </div>
                </div>
            </div>

            <div>
                <div class="text-start" style="padding-bottom:.5rem"><span class="fkr-set1">아이디 / E-Mail</span></div>
                <div class="x_div_center pb-3" style="border-bottom:1px solid #d9d9d9">
                    <div style="width:300px"><input type="text" v-model="email" class="form-control ff-eng1" style="width:100%; height:36px; border:1px solid #d9d9d9; border-radius:2rem"></div>
                    <div style="width:20px"></div>
                    <div class="w-100"><button class="fkr-set1 fc-w1" @click="checkAccount()" style="width:130px; height:36px; background-color:#fff; border:1px solid #000; color:#000; border-radius:2rem">가입확인</button></div>
                </div>
                <div class="text-start" style="padding:1rem 0 .5rem 0"><span class="fkr-set1">휴대폰번호</span></div>
                <div class="x_div_center pb-3" style="border-bottom:1px solid #d9d9d9">
                    <div style="width:300px"><input type="text" v-model="phone" class="form-control ff-eng1" style="width:100%; height:36px; border:1px solid #d9d9d9; border-radius:2rem"></div>
                    <div style="width:20px"></div>
                    <div class="w-100"><button class="fkr-set1 fc-w1" @click="callAuth()" style="width:130px; height:36px; background-color:#fff; border:1px solid #000; color:#000; border-radius:2rem">인증요청</button></div>
                </div>
                <div class="text-start" style="padding:1rem 0 .5rem 0"><span class="fkr-set1">인증번호 입력</span></div>
                <div class="x_div_center pb-3" style="border-bottom:1px solid #d9d9d9">
                    <div style="width:300px"><input type="text" v-model="certNmbr" class="form-control ff-eng1"  style="width:100%; height:36px; border:1px solid #d9d9d9; border-radius:2rem"></div>
                    <div style="width:20px"></div>
                    <div class="w-100"><button class="fkr-set1 fc-w1" @click="confirmAuth()" style="width:130px; height:36px; background-color:#fff; border:1px solid #000; color:#000; border-radius:2rem">임시 비밀번호전송</button></div>
                </div>
            </div>

            <div class="px-3 py-3"><span class="fkr-set1 fc-w5">* 인증 완료시 해당 이메일로 임시 비밀번호가 발송됩니다.</span></div>

            <div class="x_div_center pt-3"><button class="fkr-set1 fc-w1" style="width:300px; height:36px; border:0; background-color:#000; border-radius:4px;" onclick="location.href='/member/login'">로그인 화면으로 돌아가기</button></div>


        </div>

    </div>
    <!-- } 로그인 box 끝 -->

    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'ResetPass',
    data() {
        return {
            isExsistsEmail: false,
            email: '',
            phone: '',
            certNmbr: ''
        }
    },
    async mounted() {
        if(await Auth.isLogedIn()){
            this.$router.push('/');
        }
    },
    watch: {
        phone(val) {
            this.phone = val.replace(/-/g, '').replace(/\s/g, '');
        }
    },
    methods: {
        async checkAccount() {
            if(!this.email) {
                alert('이메일을 입력해주세요!');
                return false;
            }

            var rtnval = await Api.post('/api/v1/user/find/usr/email', {email: this.email});
            if(rtnval.result){
                if(rtnval.data == null || rtnval.data.usrId == null) {
                    alert('가입되지않은 아이디입니다.');
                    return false;
                }
                else {
                    alert('가입확인되었습니다.\n휴대폰번호 인증을 진행해주세요.');
                    this.isExsistsEmail = true;
                }
            }
            else{
                alert(rtnval.errormsg);
            }
        },
        async callAuth() {
            if(!this.isExsistsEmail) {
                alert('가입확인을 진행해주세요!');
                return false;
            }
            if(!this.phone) {
                alert('휴대폰번호를 입력해주세요!');
                return false;
            }

            var phoneRegular = this.phone.replace(/^0/g, '82');
            var result = await Api.post('/api/v1/common/send/sms', {phone: phoneRegular});
            if(result.result){
                this.usrCertId = result.data.usrCertId;
                alert('입력하신 번호로 인증번호가 전송되었습니다.\n인증번호를 입력하고 확인 버튼을 눌러주세요.')
            }
            else{
                alert(result.errormsg);
            }
            
        },
        async confirmAuth() {
            var result = await Api.post('/api/v1/common/check/certNmbr', {certNmbr: this.certNmbr, usrCertId: this.usrCertId});
            if(result.result){
                var rtnval = await Api.post('/api/v1/common/send/tmprryPsswrd', {email: this.email});
                if(rtnval.result){
                    alert('가입하신 이메일로 임시비밀번호가 전송되었습니다.\n이메일을 확인해주세요.')
                }
                else{
                    alert(rtnval.errormsg);
                }
            }
            else{
                alert(result.errormsg);
            }
        }
    }
}
</script>