<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">파트너기관 정보</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>파트너기관명</strong></span></td>
                  <td class="text-center" width="70%"><input type="text" class="form-control fkr-set2" style="width:250px; height:36px; margin-left: 20px;" v-model="companyName"></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>파트너기관명(영문)</strong></span></td>
                  <td class="text-center"><input type="text" class="form-control fkr-set2" style="width:250px; height:36px; margin-left: 20px;" v-model="companyNameEn"></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>홈페이지</strong></span></td>
                  <td class="text-center"><input type="text" class="form-control fkr-set2" style="width:40%; height:36px; margin-left: 20px;" v-model="hmpg" placeholder="https://"></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>로고 이미지</strong></span></td>
                  <td class="">
                    <img id="logoImg" style="width: 50%; margin: 20px" :src="logoPath? 'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + logoPath : null">
                    <input type="file" @change="fileHandling($event, 1)" class="form-control fkr-set2 fc-w5 text-center" style="width:40%; height:36px; margin: 0 0 10px 20px; border-radius:4px; background-color:#f6f2f2; border:1px solid #4e0000" placeholder="">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>로고 이미지(영문)</strong></span></td>
                  <td class="">
                    <img id="logoImgEn" style="width: 50%; margin: 20px" :src="logoPathEn? 'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + logoPathEn : null">
                    <input type="file" @change="fileHandling($event, 2)" class="form-control fkr-set2 fc-w5 text-center" style="width:40%; height:36px; margin: 0 0 10px 20px; border-radius:4px; background-color:#f6f2f2; border:1px solid #4e0000" placeholder="">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>분류</strong></span></td>
                  <td class="">
                    <select class="custom-select ff-eng1 fs-085" style="width:180px; height:36px; margin-left: 20px;" v-model="ctgryId">
                      <option v-for="(item, i) in ctgryList" :key="i" :value="item.id">{{ item.ctgryName }}</option>
                    </select>
                  </td>
                </tr>
              </table>
            </div>

          </div>

          <div class="x_div_end py-4">
            <div class="py-2"><button class="fkr-set2 fs-095" @click="savePartnerInfo()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
            <div v-if="$route.params.partnerId > 0" class="py-2"><button class="fkr-set2 fs-095" @click="deletePartnerInfo()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제</button></div>
            <div class="py-2"><button class="fkr-set2 fs-095" @click="moveToList()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">{{ $route.params.partnerId > 0? '목록' : '취소' }}</button></div>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
  name: "PartnershipEdit",
  components: {
		AdminLNB
	},
  data(){
    return {
      companyName: '',
      companyNameEn: '',
      hmpg: '',
      logoPath: '',
      logoFileToUpload: '',
      logoNameToUpload: '',
      logoPathEn: '',
      logoFileEnToUpload: '',
      logoNameEnToUpload: '',
      ctgryId: 1,
      ctgryList: [],
      fetching: true,
      submitting: false,
      s3region: process.env.VUE_APP_S3_REGION,
      s3bucket: process.env.VUE_APP_S3_BUCKET
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    const rtnval = await Api.get('/api/v1/partners/ctgry/list');
    if(rtnval.result) {
        this.ctgryList = rtnval.data;
        if(this.$route.params.partnerId > 0) {
          const returnvalue = await Api.post('/api/v1/partners/partner/list', {asc: false, partnerId: this.$route.params.partnerId});
          if(returnvalue.result) {
            this.companyName = returnvalue.data[0].companyName;
            this.companyNameEn = returnvalue.data[0].companyNameEn;
            this.hmpg = returnvalue.data[0].hmpg;
            this.logoPath = returnvalue.data[0].logoFl.attchWebPath;
            this.logoPathEn = returnvalue.data[0].logoFlEn? returnvalue.data[0].logoFlEn.attchWebPath : null;
            this.ctgryId = returnvalue.data[0].ctgry.id;
          }
          else {
              alert(returnvalue.errormsg);
          }
        }
    }
    else {
        alert(rtnval.errormsg);
    }
    this.fetching = false;
  },

  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    fileHandling(e, option) {
      if(option == 1) {
        this.logoFileToUpload = e.target.files[0];
        this.logoNameToUpload = e.target.files[0].name;

        window.$('#logoImg').attr('src', URL.createObjectURL(e.target.files[0]));
      }
      else {
        this.logoFileEnToUpload = e.target.files[0];
        this.logoNameEnToUpload = e.target.files[0].name;

        window.$('#logoImgEn').attr('src', URL.createObjectURL(e.target.files[0]));
      }
    },

    async savePartnerInfo() {
      if(!this.companyName) {
        alert('파트너기관명은 필수 입력사항입니다.');
        return;
      }
      if(!this.companyNameEn) {
        alert('파트너기관명(영문)은 필수 입력사항입니다.');
        return;
      }
      if(!this.logoPath && !this.logoFileToUpload) {
        alert('로고 이미지는 필수 입력사항입니다.');
        return;
      }
      if(this.hmpg && this.hmpg.search(/^http/g) < 0) {
          alert('사이트 주소는 http:// 또는 https:// 로 시작해야합니다.');
          return;
      }

      this.submitting = true;
      var newLogoFlId = null;
      var newLogoFlEnId = null;

      if(this.logoFileToUpload || this.logoFileEnToUpload) {
        const formData = new FormData();
        var attchFlCtgry = [];
        var attchOrgNmList = [];
        var dirNmList = [];

        if(this.logoFileToUpload) {
          formData.append("attchmntList", this.logoFileToUpload);
          attchFlCtgry.push(null);
          attchOrgNmList.push(this.logoNameToUpload);
          dirNmList.push('platform/partnership');
        }
        if(this.logoFileEnToUpload) {
          formData.append("attchmntList", this.logoFileEnToUpload);
          attchFlCtgry.push(null);
          attchOrgNmList.push(this.logoNameEnToUpload);
          dirNmList.push('platform/partnership');
        }

        var request = {
            attchFlCtgry: attchFlCtgry,
            attchOrgNmList: attchOrgNmList,
            dirNmList: dirNmList,
        }
        formData.append(
            "request",
            new Blob([JSON.stringify(request)], { type: "application/json" })
        );
        const rtnval = await Api.post('/api/v1/common/set/attachments', formData)
        if(rtnval.result) {
          if(this.logoFileToUpload && !this.logoFileEnToUpload) {
            newLogoFlId = rtnval.data[0];
          }
          else if(!this.logoFileToUpload && this.logoFileEnToUpload) {
            newLogoFlEnId = rtnval.data[0];
          }
          else {
            newLogoFlId = rtnval.data[0];
            newLogoFlEnId = rtnval.data[1];
          }
        }
        else {
          alert(rtnval.errormsg)
          this.submitting = false;
          return;
        }
      }

      const rtnvalue = await Api.post('/api/v1/partners/partner/' + (this.$route.params.partnerId > 0? 'update' : 'create'), {
        companyName: this.companyName,
        companyNameEn: this.companyNameEn,
        ctgryId: this.ctgryId,
        hmpg: this.hmpg,
        logoFlId: newLogoFlId,
        logoFlEnId: newLogoFlEnId,
        partnerId: this.$route.params.partnerId > 0? this.$route.params.partnerId : null
      })
      if(rtnvalue.result) {
        alert('정상적으로 등록되었습니다.')
        location.href = '/admin/site/partnership-list';  
      }
      else {
        alert(rtnvalue.errormsg)
      }
      this.submitting = false;
    },

    async deletePartnerInfo() {
      if(confirm('정말로 삭제하시겠습니까?')) {
        const rtnvalue = await Api.get('/api/v1/partners/partner/delete?partnerId=' + this.$route.params.partnerId)
        if(rtnvalue.result) {
          alert('정상적으로 삭제되었습니다.')
          location.href = '/admin/site/partnership-list';  
        }
        else {
          alert(rtnvalue.errormsg)
        } 
      }
    },

    moveToList(){
      location.href = '/admin/site/partnership-list';
    }
  }
}
</script>