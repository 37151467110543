<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">마감 기한 관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:220px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:14px"></div>

          <div class="w-100 x_div_center" style="border-bottom:1px solid #c9c9c9">
            <div style="width:25%">
              <div :class="'x_div_center tap' + (currentTap == 0 ? ' active' : '')"><a href="#" @click="onClickTap(0)"><span :class="'fa-kor-Noto fs-10 fw-bold' + (currentTap == 0 ? ' fc-w1' : '')">설계</span></a></div>
            </div>
            <div style="width:25%">
              <div :class="'x_div_center tap' + (currentTap == 1 ? ' active' : '')"><a href="#" @click="onClickTap(1)"><span :class="'fa-kor-Noto fs-10 fw-bold' + (currentTap == 1 ? ' fc-w1' : '')">제조</span></a></div>
            </div>
            <div style="width:25%">
              <div :class="'x_div_center tap' + (currentTap == 2 ? ' active' : '')"><a href="#" @click="onClickTap(2)"><span :class="'fa-kor-Noto fs-10 fw-bold' + (currentTap == 2 ? ' fc-w1' : '')">시공</span></a></div>
            </div>
            <div style="width:25%">
              <div :class="'x_div_center tap' + (currentTap == 3 ? ' active' : '')"><a href="#" @click="onClickTap(3)"><span :class="'fa-kor-Noto fs-10 fw-bold' + (currentTap == 3 ? ' fc-w1' : '')">인테리어</span></a></div>
            </div>
          </div>

          <div class="w-100 x_div_end py-4"></div>

          <div class="x_div_center">
            <div class="py-2"><button class="fkr-set2 fs-095" @click="chkRqstDdln(currentTap)" style="width:250px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">제안 참여 요청 기간 마감 점검</button></div>
          </div>

          <div class="w-100 x_div_end py-4"></div>

          <div class="x_div_center">
            <div class="py-2"><button class="fkr-set2 fs-095" @click="chkSbmsnExprDt(currentTap)" style="width:250px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">후보 업체 선정 마감 점검</button></div>
          </div>

          <div class="w-100 x_div_end py-4"></div>

          <div class="x_div_center">
            <div class="py-2"><button class="fkr-set2 fs-095" @click="chkSggstDdln(currentTap)" style="width:250px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">제안 등록 마감 점검</button></div>
          </div>

          <div class="w-100 x_div_end py-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from "@/api/auth";
import Api from '@/api'

const tap = {
  DSGN: 0,
  MNFCT: 1,
  CNTRCT: 2,
  INTRR: 3
}
const process = ['dsgn', 'mnfct', 'cntrct', 'intrr'];
const processNm = ['설계', '제조', '시공', '인테리어'];

export default {
  name: "ExprDtMng",
  components:{
    AdminLNB
  },
  data(){
    return {
      currentTap: tap.DSGN,

    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }
    //await this.checkAdmin();
    await this.onClickTap(0);
  },

  methods:{
    async onClickTap(processCode){
      this.currentTap = processCode;
    },

    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async chkRqstDdln(current){
      var rtnval = null;
      rtnval = await Api.post('/api/v1/admin/' + process[current] + '/expr/dt/mng/chk/rqst/ddln');
      if(rtnval.result){
        alert(processNm[current] + ' 제안 참여 요청 기간 마감 점검이 완료되었습니다.');
      }
    },

    async chkSbmsnExprDt(current){
      var rtnval = null;
      rtnval = await Api.post('/api/v1/admin/' + process[current] + '/expr/dt/mng/chk/sbmsn/expr/dt');
      if(rtnval.result){
        alert(processNm[current] + ' 후보 업체 선정 마감 점검이 완료되었습니다.');
      }
    },

    async chkSggstDdln(current){
      var rtnval = null;
      rtnval = await Api.post('/api/v1/admin/' + process[current] + '/expr/dt/mng/chk/sggst/ddln');
      if(rtnval.result){
        alert(processNm[current] + ' 제안 등록 마감 점검이 완료되었습니다.');
      }
    }
  }
}
</script>

<style scoped>
.x_div_center.tap {
  height:50px;
  background-color:#fff;
}
.x_div_center.tap.active {
  height:50px;
  background-color:#4e0000;
  border-top-left-radius:8px;
  border-top-right-radius:8px
}
</style>