<template>
    <div class="ca_main">
		<div class="x_div_center">
			<div class="pg_stage">

				<div class="d-flex pt-5">
					<div style="flex:0 0 calc(100% - 100px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 참여 관리</span></div>
					<div class="x_div_center"><div class="text-end" style="width:100px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
				</div>

				<div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

				<div style="height:14px"></div>

				<div class="sw-1">
					<div class="sw-2" style="border-bottom:1px solid #c9c9c9">
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/bidding-participate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/bidding-participate'"><span class="fkr-set1 fs-095">1. 제안 참여 의향 등록</span></div>
						</div>
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/bidding-proposal') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/bidding-proposal'"><span class="fkr-set1 fs-095">2. 제안 제출 목록 (선정시)</span></div>
						</div>
					</div>
					<div class="sw-2" style="border-bottom:1px solid #c9c9c9">
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/bidding-result') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/bidding-result'"><span class="fkr-set1 fs-095">3. 제안 평가 결과 확인</span></div>
						</div>
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/contract-list') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/contract-list'"><span class="fkr-set1 fs-095">4. 계약 목록 (선정시)</span></div>
						</div>
					</div>
				</div>

				<div class="x_div_center py-5">
					<div class="text-center"><span class="fkr-set2 fs-10">참여 의향을 등록하실 고객 프로젝트들을 선택하세요.<br><br>향후 고객이 파트너님을 후보업체로 선정하실 경우, <br v-if="isMobile"/>제안 제출 요청이 들어옵니다.</span></div>
				</div>

				<div>
					<div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>
					<div v-else class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
						<table class="table-borderless w-100">
							<tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
								<!-- <td class="text-center br_0" width="18%"><span class="ff-kor1" style="font-size:12pt"><strong>고객</strong></span></td> -->
								<td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>등록<br v-if="isMobile"/>날짜</strong></span></td>
								<td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>참여 의향 등록<br v-if="isMobile"/>기한</strong></span></td>
								<td class="text-center br_0" width="38%"><span class="ff-kor1" style="font-size:12pt"><strong>건축<br v-if="isMobile"/>위치</strong></span></td>
								<td class="text-center br_0" width="10%"><span class="ff-kor1" style="font-size:12pt"><strong>{{isMobile? '타입' : '건축물 타입'}}</strong></span></td>
								<td class="text-center br_0" width="10%"><span class="ff-kor1" style="font-size:12pt"><strong>상세<br v-if="isMobile"/>정보</strong></span></td>
								<td class="text-center" width="10%"><span class="ff-kor1" style="font-size:12pt"><strong>참여 <br v-if="isMobile"/>의향 <br v-if="isMobile"/>등록</strong></span></td>
							</tr>

							<tr v-for="(prj, i) in prjList" :key="i" style="height:55px; border-bottom:1px solid #c9c9c9">
								<!-- <td class="text-center br_0"><span class="ff-kor1" style="font-size:11pt">{{renderEmailForm(prj.ownrEmail)}}</span></td> -->
								<td class="text-center br_0"><span class="ff-eng1" style="font-size:11pt">{{renderDateForm(prj.bddngCrtDt)}}</span></td>
								<td class="text-center br_0"><span class="ff-eng1" style="font-size:11pt">{{renderDateForm(prj.bddngJoinDdln)}}</span></td>
								<td class="text-center br_0"><a :href="isMobileDevice? prj.maplinkMobile : prj.maplink" target="_blank"><span class="ff-kor1" style="font-size:11pt"><u>{{prj.addr}}</u></span></a></td>
								<td class="text-center br_0"><span class="ff-kor1" style="font-size:11pt">{{isMobile? prj.purpose.replace('용 건물', '') : prj.purpose}}</span></td>
								<td class="text-center br_0"><div class="x_div_center"><button @click="$refs.BiddingPrjDetail.showDetail(prj.prjId)" class="x_div_center" :style="'height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000; ' + (isMobile? 'width:50px' : 'width:90px')"><span class="fkr-set2">보기</span></button></div></td>
								<td class="text-center"><div class="x_div_center">
									<button v-if="prj.bddngId == 0" @click="showParticipationForm(prj, false)" class="x_div_center" :style="'height:30px; border:0; background-color:#4e0000; border-radius:4px; ' + (isMobile? 'width:50px' : 'width:90px')"><span class="fkr-set2 fc-w1">등록</span></button>
									<button v-else class="x_div_center" @click="showParticipationForm(prj, true)" :disabled="prj.isRqstDdlnOver" :style="'height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000; ' + (isMobile? 'width:50px; ' : 'width:90px; ') + (prj.isRqstDdlnOver? ' opacity: 0.5' : '')"><span class="fkr-set2">수정</span></button>
								</div></td>
							</tr>
						</table>
					</div>

					<!-- 상세정보 레이어 시작 { -->
					<BiddingPrjDetail ref="BiddingPrjDetail" :prcCd="prcCd" @prjList="prjListUpdated"/>

					<!-- 참여의향 등록 레이어 시작 { -->
					<div id="modalForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
						<div class="modal-dialog modal-lg" role="document">
							<div class="modal-content">

								<div class="w-100">
									<div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
										<div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">참여의향 등록</span></div>
										<div class="text-end pe-4" style="width:100px"><a href="#@" style="color:#4e0000" @click="closeModal()" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
									</div>

									<div class="py-3 ps-4"><span class="fkr-set2 fs-11 fw-bold">대표자 인사</span></div>

									<div class="x_div_center px-4">
										<div class="w-100 py-3" style="border-top:1px solid #c9c9c9">
											<div class="x_div_center"><textarea class="form-control" v-model="ceoMessage" style="width:100%; height:80px"></textarea></div>
										</div>
									</div>

									<div class="x_div_center py-2">
										<div class="x_div_center px-4 w-100">

										<div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
											<table class="table-borderless w-100">
											<tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
												<td class="text-center br_0" width="50%"><span class="fkr-set2 fs-11"><strong>포트폴리오</strong></span></td>
												<td class="text-center" width="50%"><span class="fkr-set2 fs-11"><strong>기타홍보자료</strong></span></td>
											</tr>
											<tr style="height:55px;">
												<td class="text-center br_0">
													<div class="x_div_center" v-if="fileToDisplay[0].orgNm">
														<div class="x_div_center"><a :href="fileToDisplay[0].fileLink" :download="fileToDisplay[0].orgNm"><span class="fkr-set2">{{fileToDisplay[0].orgNm}}</span></a></div>
														<div class="x_div_center px-2"><a href="javascript:void(0)" @click="removeFile(0)" class="rm_frm"><i class="fa-solid fa-circle-minus fs-10" style="color:#C00"></i></a></div>
													</div>
													<div class="x_div_center" v-else><input type="file" id="file0" @change="fileHandling($event, 0)" class="form-control" style="width:90%; color:#4e0000"></div>
												</td>
												<td class="text-center">
													<div class="x_div_center" v-if="fileToDisplay[1].orgNm">
														<div class="x_div_center"><a :href="fileToDisplay[1].fileLink" :download="fileToDisplay[1].orgNm"><span class="fkr-set2">{{fileToDisplay[1].orgNm}}</span></a></div>
														<div class="x_div_center px-2"><a href="javascript:void(0)" @click="removeFile(1)" class="rm_frm"><i class="fa-solid fa-circle-minus fs-10" style="color:#C00"></i></a></div>
													</div>
													<div class="x_div_center" v-else><input type="file" id="file1" @change="fileHandling($event, 1)" class="form-control" style="width:90%; color:#4e0000"></div>
												</td>
											</tr>
											</table>
										</div>

										</div>
									</div>

									<div class="x_div_center py-4">
										<div class="px-2"><button class="x_div_center" @click="closeModal()" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set2">닫기</span></button></div>
										<div class="px-2"><button class="x_div_center" @click="submitParticipation()" :disabled="submitting" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000;"><span class="fa-kor-Noto fs-085">등록<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
										<div class="px-2" v-if="isModifiy"><button class="x_div_center" @click="cancelParticipation()" :disabled="submitting" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">등록취소<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
									</div>

									<div style="height:20px"></div>

								</div>

							</div>
						</div>
					</div>
					<!-- } 참여의향 등록 레이어 끝 -->

				</div>

				<div style="height:50px"></div>

			</div>
		</div>
	</div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'
import BiddingPrjDetail from '@/components/BiddingPrjDetail.vue'
const processCode = {
    DS: 'DS',
    MF: 'MA',
    CT: 'CN',
	IN: 'IN'
}

export default {
    name: 'BiddingPartnerParticipate',
	components: {
		BiddingPrjDetail
	},
    data() {
        return {
            url : '',
            usrRole : this.user.info.usrRole,
			prcCd : processCode[this.user.info.usrRole],
            fetching : true,
			submitting : false,
			isMobile : false,
			prjList : [],
			fileToDisplay: [
				{
					bddngId: null,
					fildId: null,
					orgNm: null,
					fileLink: null
				},
				{
					bddngId: null,
					fildId: null,
					orgNm: null,
					fileLink: null
				}
			],
			prcIdToParticipate: null,
			bddngIdToModify: null,
			ceoMessage: '',
			file: [null, null],
			fileName: [null, null],
			deleteFlIdList: [],
			isModifiy: false
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        },
		isMobileDevice: {
			type: Boolean,
            default: false
		}
    },
    created() {
        this.url = location.href;
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           this.$router.push({ path: '/member/login', query: { fromBidding: 1 } });
        }
        else if(Auth.partnerRoleTypes.indexOf(this.usrRole) <0) {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }

		this.isMobile = window.innerWidth < 768;
        await this.$refs.BiddingPrjDetail.fetchBiddableProjects();
		this.fetching = false;
    },
    methods: {
		prjListUpdated(prjList) {
			this.prjList = prjList;
		},
		async showParticipationForm(prj, editmode) {
			if(editmode) {
				const rtnval = await Api.post('/api/v1/common/get/attachments', {
					parentId: prj.bddngId,
					parentUnit: "BDDNG",
					limit: -1,
					ascByCrtDt: false
				})
				if(rtnval.result) {
					if(rtnval.data.attchments) {
						rtnval.data.attchments.forEach((item) => {
							var tokens = item.attchOrgNm.split('.');
							var mimeType = tokens[tokens.length-1].toLowerCase();
							var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;
		
							const byteCharacters = atob(item.attchImg);
							const byteNumbers = new Array(byteCharacters.length);
							for (let i = 0; i < byteCharacters.length; i++) {
								byteNumbers[i] = byteCharacters.charCodeAt(i);
							}
							const byteArray = new Uint8Array(byteNumbers);
							const blob = new Blob([byteArray], {type: fileType});
							var fileLink = URL.createObjectURL(blob);
							if(item.attchFlCtgry.code == 'PF') {
								this.fileToDisplay[0].bddngId = prj.bddngId;
								this.fileToDisplay[0].fildId = item.id;
								this.fileToDisplay[0].orgNm = item.attchOrgNm;
								this.fileToDisplay[0].fileLink = fileLink;
							}
							else if(item.attchFlCtgry.code == 'PR') {
								this.fileToDisplay[1].bddngId = prj.bddngId;
								this.fileToDisplay[1].fildId = item.id;
								this.fileToDisplay[1].orgNm = item.attchOrgNm;
								this.fileToDisplay[1].fileLink = fileLink;
							}
						})
					}
				}
				else {
					alert(rtnval.errormsg)
				}
				this.prcIdToParticipate = null;
				this.bddngIdToModify = prj.bddngId;
			}
			else {
				this.prcIdToParticipate = prj.prcId;
				this.bddngIdToModify = null;
			}
			this.isModifiy = editmode;
			this.ceoMessage = prj.ceoMessage? prj.ceoMessage.replace(/<br\/>/g, '\n') : prj.ceoMessage;
			this.file = [null, null];
			this.fileName = [null, null];
			window.$('#file0').val('');
			window.$('#file1').val('');
			window.$('#modalForm').modal('show');
		},
		fileHandling(e, index) {
            var tokens = e.target.files[0].name.split('.');
            var fileType = tokens[tokens.length-1].toLowerCase();
            var allowed = ['jpg', 'jpeg', 'png', 'pdf', 'zip']
            if(allowed.indexOf(fileType) < 0){
                alert('이미지파일 또는 pdf형식 외의 파일은\nzip 형태로 압축하여 업로드 해 주시기 바랍니다.');
                window.$('#file' + index).val('')
                return 1;
            }

            this.file[index] = e.target.files[0];
			this.fileName[index] = e.target.files[0].name;
        },
		removeFile(index) {
			this.deleteFlIdList = [];
			this.deleteFlIdList.push(this.fileToDisplay[index].fildId);
			this.fileToDisplay[index].orgNm = null;
			this.fileToDisplay[index].fileLink = null;
		},
		async submitParticipation() {
			this.submitting = true;
			const rtnval = await Api.post('/api/v1/bidding/set', {
				prcId: this.prcIdToParticipate,
				bddngId: this.bddngIdToModify,
				ceoMessage: this.ceoMessage.replace(/\n/g, '<br/>'),
				evalRank: 0
			})
			if(rtnval.result) {
				const formData = new FormData();
				var attchFlCtgry = [];
				var attchOrgNmList = [];
				var bddngId = [];
				if(this.file[0]) {
					formData.append("attchmntList", this.file[0]);
					attchFlCtgry.push('PF');
					attchOrgNmList.push(this.fileName[0]);
					bddngId.push(rtnval.data);
				}
				if(this.file[1]) {
					formData.append("attchmntList", this.file[1]);
					attchFlCtgry.push('PR');
					attchOrgNmList.push(this.fileName[1]);
					bddngId.push(rtnval.data);
				}
				if(attchOrgNmList.length > 0 || this.deleteFlIdList.length > 0) {
					var request = {
						attchFlCtgry: attchFlCtgry,
						attchOrgNmList: attchOrgNmList,
						deleteFlIdList: this.deleteFlIdList,
						parentId: bddngId
					}
					formData.append(
						"request",
						new Blob([JSON.stringify(request)], { type: "application/json" })
					);
					const rtnvalue = await Api.post('/api/v1/common/set/attachments', formData)
					if(rtnvalue.result) {
						alert('참여 요청 등록이 정상적으로 처리되었습니다.')
						this.fetching = true;
						this.$refs.BiddingPrjDetail.fetchBiddableProjects();
						this.fetching = false;
						this.closeModal();
					}
					else {
						alert(rtnvalue.errormsg)
					}
				}
				else {
					alert('참여 요청 등록이 정상적으로 처리되었습니다.')
					this.fetching = true;
					this.$refs.BiddingPrjDetail.fetchBiddableProjects();
					this.fetching = false;
					this.closeModal();
				}
			}
			else {
				alert(rtnval.errormsg)
			}
			this.submitting = false;
		},
		async cancelParticipation() {
			if(confirm('참여 의향 등록을 취소하시겠습니까?')) {
				const rtnval = await Api.post('/api/v1/bidding/delete?bddngId=' + this.bddngIdToModify);
				if(rtnval.result) {
					alert('정상적으로 등록취소 되었습니다.')
					this.fetching = true;
					this.$refs.BiddingPrjDetail.fetchBiddableProjects();
					this.fetching = false;
					this.ceoMessage = null;
					for(var i=0; i<2; i++) {
						this.fileToDisplay[i] = {
							bddngId: null,
							fildId: null,
							orgNm: null,
							fileLink: null
						};
						this.file[i] = null;
						this.fileName[i] = null;
					}
					this.closeModal();
				}
				else {
					alert(rtnval.errormsg);
				}
			}
		},
		renderDateForm(datetime) {
            if (this.isMobile) {
                return datetime? datetime.split('T')[0].substring(5) : '';
            }
            else {
                return datetime? datetime.split('T')[0] : '';
            }
        },
		renderEmailForm(email) {
            if (this.isMobile) {
                return email? (email.split('@')[0] + '@\n' + email.split('@')[1]) : '';
            }
            else {
                return email;
            }
        },
		closeModal() {
			window.$('#modalDetail0').modal('hide');
			window.$('#modalDetail1').modal('hide');
			window.$('#modalForm').modal('hide');
		}
    }
}
</script>