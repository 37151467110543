<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">중고모듈거래 관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">총 매물: {{ numOfMtrl }} 건</span>
            <select class="custom-select ff-eng1 fs-085" v-model="pageSize" @change="switchPageSize()" style="width:120px; height:37px; margin-left: 20px">
                <option value="10">페이지당 10건</option>
                <option value="20">페이지당 20건</option>
            </select>
            <select class="custom-select ff-eng1 fs-085" v-model="stts" @change="selectStts()" style="width:120px; height:37px; margin-left: 20px">
              <option value='null'>전체</option>
              <option value='0'>등록승인대기</option>
            </select>
            <!-- <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="searchOption = ''; selectCtgry()"><i class="fa-solid fa-magnifying-glass"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="searchOption = ''; selectCtgry()" style="border:0; width:170px; height:30px; padding:0 .3rem" placeholder="회원검색"></div>
              </div>
            </div> -->
          </div>
          


            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">판매자</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">위치</span></div>
                <div class="x_div_center" style="width:5%"><span class="fkr-set1 fs-9">개수</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">총 사용기간</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">인계희망날짜</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">게시일자</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">총 가격</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">상태</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">처리</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in storeList" :key="index" class="x_div_center m_line_ov" :style="'height:55px; border-bottom:1px solid #d9d9d9; cursor: pointer;' + item.color">
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9"><a href="#" @click="openStore(item.usdMtrlId)"><u>{{item.sellerNm}}</u></a></span></div>
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9">{{item.addr}}</span></div>
                  <div class="x_div_center m_line" style="width:5%"><span class="fkr-set2 fs-9">{{item.totalAmnt}}</span></div>
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9">{{ item.period }}년</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{ item.handoverDt.split('T')[0] }}</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{ item.postDt.split('T')[0] }}</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{ item.price.toLocaleString() }}</span></div>
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9" v-html="item.status"></span></div>
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9">
                    <button v-if="item.stts == 0" class="fkr-set2 fs-095" @click="approveStore(item)" style="width:80px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">등록승인<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button>
                    <button v-else-if="item.depositYn == 0 && item.stts < 4" class="fkr-set2 fs-095" @click="openUploadModal(item)" style="width:80px; height:35px; border:0; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">영수증등록<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button>
                  </span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; listUsdMtrls()" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
    <div id="modalForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">

                <div class="w-100">
                    <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                        <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">예치금 입금영수증 등록</span></div>
                        <div class="text-end pe-4" style="width:100px"><a href="#@" style="color:#4e0000" @click="closeModal()" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                    </div>

                    <div class="x_div_center py-2">
                        <div class="x_div_center px-4 w-100">

                        <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                            <table class="table-borderless w-100">
                            <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                                <td class="text-center br_0" width="50%"><span class="fkr-set1"><strong>영수증 파일</strong></span></td>
                            </tr>
                            <tr style="height:55px;">
                                <td class="text-center br_0">
                                    <div class="x_div_center"><input type="file" id="file0" @change="fileHandling" class="form-control" style="width:90%; color:#4e0000"></div>
                                </td>
                            </tr>
                            </table>
                        </div>

                        </div>
                    </div>

                    <div class="x_div_center py-4">
                        <div class="px-2"><button class="x_div_center" @click="closeModal()" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set2">닫기</span></button></div>
                        <div class="px-2"><button class="x_div_center" @click="uploadReceipt()" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">등록<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
                    </div>

                    <div style="height:20px"></div>

                </div>

            </div>
        </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "UsdMtrlList",
  components: {
		AdminLNB
	},
  props: {
      user: {
          type: Object,
          default: null
      },
  },
  data(){
    return {
        fetching: false,
        submitting: false,
        searchWord: null,
        numOfMtrl: '',
        storeList:[],
        receipt: null,
        storeToUploadReceipt: null,
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        stts: null,
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();
    await this.listUsdMtrls();
    this.setPaging();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async listUsdMtrls(){
      this.fetching = true;
      this.storeList = [];
      const rtnvalue = await Api.post('/api/v1/usdmtrl/list/usdmtrl', {
          sortColumn: 0,
          asc: false,
          ctgry: 0,
          stts: this.stts == null? null : [this.stts],
          usrId: this.user.id,
          pageSize: this.pageSize,
          page: this.currentPage
      });
      if(rtnvalue.result){
          this.numOfMtrl = rtnvalue.data.count; 
          this.storeList = rtnvalue.data.findUsdMtrlDtoList;
          this.storeList.forEach((store) => {
              switch(store.stts) {
                  case 0:
                      store.status = "<span style='color:blue'>승인대기</span>";
                      store.color = "color: gray; background-color: aliceblue";
                      break;
                  case 2: 
                      store.status = "<span style='color:coral !important'>예약중</span>";
                      store.color = "color: gray; background-color: lightyellow";
                      break;
                  case 3: 
                      store.status = "<span style='color:green !important'>판매완료</span>";
                      store.color = "color: lightgray;";
                      break;
                  case 4: 
                      store.status = "<span style='color:red !important'>삭제됨</span>";
                      store.color = "color: lightgray;";
                      break;
                  default:
                      store.status = "";
                      store.color = "";
                      
              }
          })
      }
      else {
          alert(rtnvalue.errormsg);
      }
      this.fetching = false;
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.numOfMtrl / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    async switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        await this.listUsdMtrls();
        this.setPaging();
      }
    },

    async switchPageSize() {
      this.currentPage = 1;
      await this.setPaging();
      this.listUsdMtrls();
    },

    async selectStts() {
      await this.listUsdMtrls();
      this.currentPage = 1;
      this.setPaging();
    },

    openStore(usdMtrlId){
      window.open('about:blank').location.href='/usdmtrl/store/' + usdMtrlId;
    },

    async approveStore(store) {
      this.submitting = true;
      const returnvalue = await Api.post('/api/v1/usdmtrl/update/usdmtrl', {usdMtrlId: store.usdMtrlId, stts: 1});
      if(returnvalue.result){
        store.stts = 1;
        store.status = "";
        store.color = "";
      }
      else {
        alert(returnvalue.errormsg);
      }
      this.submitting = false;
    },
    
    openUploadModal(store) {
      this.storeToUploadReceipt = store;
      window.$('#file0').val('');
      window.$('#modalForm').modal('show');
    },

    fileHandling(e) {
      var tokens = e.target.files[0].name.split('.');
      var fileType = tokens[tokens.length-1].toLowerCase();
      var allowed = ['jpg', 'jpeg', 'png', 'pdf', 'zip']
      if(allowed.indexOf(fileType) < 0){
          alert('이미지파일 또는 pdf형식 외의 파일은\nzip 형태로 압축하여 업로드 해 주시기 바랍니다.');
          return 1;
      }

      this.receipt = {file: e.target.files[0], orgNm: e.target.files[0].name};
    },

    async uploadReceipt() {
      this.submitting = true;
      var request = {
          parentId: [],
          attchFlCtgry: [],
          attchOrgNmList: []
      }
      const formData = new FormData();
      request.attchOrgNmList.push(this.receipt.orgNm);
      request.attchFlCtgry.push('USEDRC');
      request.parentId.push(this.storeToUploadReceipt.usdMtrlId);
      formData.append("attchmntList", this.receipt.file);
      formData.append(
          "request",
          new Blob([JSON.stringify(request)], { type: "application/json" })
      );
      const rtnvalue = await Api.post('/api/v1/common/set/attachments', formData);
      if(rtnvalue.result) {
        this.storeToUploadReceipt.depositYn = 1;
      }
      else {
        alert(rtnvalue.errormsg);
      }
      this.submitting = false;
      window.$('#modalForm').modal('hide');
    },

    closeModal() {
			window.$('#modalForm').modal('hide');
		}
  }
}
</script>

