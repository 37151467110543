<template>
    <div class="ca_main">
        <div class="x_div_center">
            <div class="pg_stage">

                <div class="d-flex pt-5">
                    <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 관리</span></div>
                    <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
                </div>

                <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                <div style="height:14px"></div>

                <div class="sw-1">
                    <div class="sw-2" style="border-bottom:1px solid #c9c9c9">
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/bidding-generate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/bidding-generate'"><span class="fkr-set1 fs-095">1. 참여 의향 요청</span></div>
                        </div>
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/bidding-candidate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/bidding-candidate'"><span class="fkr-set1 fs-095">2. 후보 업체 선정</span></div>
                        </div>
                    </div>
                    <div class="sw-2" style="border-bottom:1px solid #c9c9c9">
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/bidding-evaluate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/bidding-evaluate'"><span class="fkr-set1 fs-095">3. 후보 업체 평가 및 선정</span></div>
                        </div>
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/contract') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/contract'"><span class="fkr-set1 fs-095">4. 계약</span></div>
                        </div>
                    </div>
				</div>

				<div style="height:20px"></div>

                <div class="x_div_center py-5">
                    <div class="text-center">
                        <span class="fkr-set2 fs-10">
                            파트너분들께 참여 의향 표현을 요청합니다.
                            <span v-if="prcCd == 'DS'"><br/>이전 단계 결과물을 확인후 요청사항을 간단히 적어주세요.</span>
                            <br><br>

                            <span class="fw-bold" style="color:#4e0000">고객님은 영업일기준 5일 후 의향을 표현한 파트너분들을</span> 확인하실 수 있으며, <br v-if="!isMobile"/>향후 의향을 표현하신 파트너분들 중 비딩 요청할 파트너분들을 선정하시게 됩니다.
                        </span>
                    </div>
                </div>

                <div style="height:20px"></div>

                <div class="pg_m_set" v-if="fetching">
                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div v-else>
                    <div class="w-100 x_div_center pt-5 pb-3" style="padding: 30px !important"><div><span class="fkr-set2 fs-10"><strong>추천설계안 이미지</strong></span></div></div>
                    <div class="pg_a_set3">
                        <div class="pg_a_set3a">
                            <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                                <div class="x_div_center"><img style="width: 100%; border:0px solid #c9c9c9; border-radius:8px" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + dsgnRprsnttvImgPath"></div>
                            </div>
                        </div>
                        <div class="pg_a_set3c"></div>
                        <div class="pg_a_set3b">
                            <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                                <div class="x_div_center"><img style="border:0px solid #c9c9c9; border-radius:8px" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + dsgnRprsnttvImgPath2" width="100%"></div>
                            </div>
                        </div>
                    </div>
                    <div v-show="planImg.length">
                        <div class="w-100 x_div_center pt-5 pb-3" style="padding: 30px !important"><div><span class="fkr-set2 fs-10"><strong>추천설계안 평면도</strong></span></div></div>
                        <div class="pg_a_set3">
                            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" style="margin-top: 60px">
                                <div class="carousel-indicators">
                                    <button v-for="(v, i) in planImg" :key="i" type="button" data-bs-target="#carouselExampleCaptions" :data-bs-slide-to="i" :class="i == 0 ? 'active' : ''" :aria-current="i == 0 ? 'true' : ''" :aria-label="'Slide ' + (i+1)"></button>
                                </div>
                                <div class="carousel-inner">
                                    <div v-for="(v, i) in planImg" :key="i" :class="'carousel-item' + (i == 0 ? ' active' : '')">
                                        <div class="iw-1" style="display:flex; justify-content:center">
                                            <img :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + v" :style="isMobile? 'width:90%' : 'overflow-y:hidden'">
                                        </div>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="w-100 x_div_center pt-5 pb-3"><div><span class="fkr-set2 fs-10"><strong>참고자료</strong></span></div></div>
                    <div v-for="(bucket, i) in attachmentsFetchedList" :key="i" class="pg_a_set3" style="margin-top: 15px">
                        <div class="pg_a_set3a">
                            <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                                <div :class="'x_div_center' + (bucket[0].deleted? ' diagonal' : '')">
                                    <img v-if="['pdf', 'zip'].indexOf(bucket[0].mimeType) < 0" :style="'width: 100%; border:0px solid #c9c9c9; border-radius:8px;' + (bucket[0].deleted? 'opacity: 0.5;' : '')" :src="bucket[0].fileLink">
                                    <span v-else class="fen-set2 fs-10"><a :href="bucket[0].fileLink" :download="bucket[0].prcAttchOrgNm">{{ bucket[0].prcAttchOrgNm }}</a></span>
                                    <div class="ig_d" v-show="editing" style="left:90%"><a href="javascript:void(0)" @click="bucket[0].deleted = true; deleteFlIdList.push(bucket[0].id)"><img src="@/assets/img_legacy/r_del.png"></a></div>
                                </div>
                            </div>
                        </div>
                        <div class="pg_a_set3c"></div>
                        <div class="pg_a_set3b">
                            <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                                <div :class="'x_div_center' + (bucket[1].deleted? ' diagonal' : '')">
                                    <img v-if="['pdf', 'zip'].indexOf(bucket[1].mimeType) < 0" :style="'border:0px solid #c9c9c9; border-radius:8px;' + (bucket[1].deleted? 'opacity: 0.5;' : '')" :src="bucket[1].fileLink" width="100%">
                                    <span v-else class="fen-set2 fs-10"><a :href="bucket[1].fileLink" :download="bucket[1].prcAttchOrgNm">{{ bucket[1].prcAttchOrgNm }}</a></span>
                                    <div class="ig_d" v-show="editing && bucket[1].id" style="left:90%"><a href="javascript:void(0)" @click="bucket[1].deleted = true; deleteFlIdList.push(bucket[1].id)"><img src="@/assets/img_legacy/r_del.png"></a></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="x_div_end py-4" style="padding:0 !important; margin-top: 20px;">
                        <button class="x_div_center" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000;">
                            <label for="imgUpload" class="fa-kor-Noto fs-085 s-upload">파일 첨부</label>
                        </button>
                        <input type="file" style="display:none" id="imgUpload" name="imgUpload" @change="fileHandling" multiple>
                    </div>
                    <div class="x_div_end py-4" style="padding:0 !important; margin-bottom: 20px;">
                        <div style="display:block; margin-top: 10px;">
                            <span v-for="(file, i) in attachmentsNewList" :key="i" class="fen-set2 fs-10">{{file.orgNm}}&nbsp;<a href="javascript:void(0)" @click="removeFile(i)"><i class="fas fa-times-circle"></i></a>&nbsp;&nbsp;</span>
                        </div>
                    </div>

                    <div class="w-100 x_div_center pt-5 pb-3"><div><span class="fkr-set2 fs-10"><strong>요청사항</strong></span></div></div>
                    <div><textarea class="w-100 form-control" v-model="dsgnRqst" :readonly="!editing" style="height:250px; border:1px solid #c9c9c9; border-radius:4px"></textarea></div>
    
                    <div class="x_div_end py-4" v-if="prcCd == 'DS'">
                        <div class="px-2">
                            <span class="fkr-set2 fs-10">희망하는 면적 <br>(※반드시 <strong style="color:#4e0000">연면적</strong>으로 입력해주세요!)</span>
                        </div>
                        <div class="px-2">
                            <input type="number" v-model="floorAreaPy" :readonly="!editing" class="w-100 form-control" style="width: 90px !important"/>
                        </div>
                        <div class="px-2">
                            <span class="fkr-set2 fs-10" style="width: 80px; display:block"> 평 ({{ floorArea }}㎡)</span>
                        </div>
                    </div>

                    <div class="x_div_end py-4" v-if="prcCd == 'DS'">
                        <div class="px-2">
                            <span class="fkr-set2 fs-10">예상공사비용 <br>(소숫점 단위 입력가능)</span>
                        </div>
                        <div class="px-2">
                            <input type="number" v-model="expectedCost" :readonly="!editing" class="w-100 form-control" style="width: 90px !important"/>
                        </div>
                        <div class="px-2">
                            <span class="fkr-set2 fs-10" style="width: 80px; display:block"> 억원</span>
                        </div>
                    </div>
                    
                    <div class="x_div_end py-4">
                        <div class="px-2">
                            <button class="x_div_center" v-if="!rqstDdln" :disabled="submitting" @click="setBidding(false)" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000"><span class="fa-kor-Noto fs-085">임시저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button>
                        </div>
                        <div class="px-2">
                            <button v-if="editing" @click="setBidding(rqstDdln? false : true)" :disabled="submitting" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">제출<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button>
                            <button v-else class="x_div_center" @click="editing = true" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000;"><span class="fa-kor-Noto fs-085">수정</span></button>
                        </div>
                    </div>
                </div>

                <div style="height:50px"></div>

            </div>
        </div>
    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'BiddingCustomerGeneration',
    data() {
        return {
            url : '',
            fetching : true,
            isMobile : false,
            prjId : sessionStorage.getItem('prjId'),
            prcCd : sessionStorage.getItem('prcCd'),
            prcId : sessionStorage.getItem('prcId'),
            rqstDdln : null,
            sggstDdln : null,
            editing : true,
            dsgnRprsnttvImgPath: null,
            dsgnRprsnttvImgPath2: null,
            dsgnRqst: '',
            floorAreaPy: 0,
            floorArea: 0,
            expectedCost: 0,
            attachmentsNewList: [],
            attachmentsFetchedList: [],
            deleteFlIdList: [],
            planImg: [],
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET,
            submitting: false
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    watch: {
        floorAreaPy: function(val) {
            var fa = Math.round(val*3.305785);
            this.floorArea = parseInt(fa, 0);
        }
    },
    created() {
        this.url = location.href;
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }
        else if(this.user.info.usrRole != 'OW') {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }
        else if(this.prjId == 'null') {
            alert('My Projects 페이지에서 프로젝트를 선택하셔야 합니다.');
            location.href = '/project/dashboard'
        }
        else if(this.prcCd == 'null') {
            alert('프로젝트 단계를 선택하셔야 합니다.');
            location.href = '/project/summary/' + this.prjId;
        }
        this.isMobile = window.innerWidth < 768;
        this.fetchBiddingInfo();
    },
    methods: {
        fileHandling(e) {
            for(var i=0; i<e.target.files.length; i++) {
                var tokens = e.target.files[i].name.split('.');
                var fileType = tokens[tokens.length-1].toLowerCase();
                var allowed = ['jpg', 'jpeg', 'png', 'pdf', 'zip']
                if(allowed.indexOf(fileType) < 0){
                    alert('이미지파일 또는 pdf형식 외의 파일은\nzip 형태로 압축하여 업로드 해 주시기 바랍니다.');
                    return 1;
                }
            }

            for(i=0; i<e.target.files.length; i++) {
                this.attachmentsNewList.push({file: e.target.files[i], orgNm: e.target.files[i].name})
            }
        },
        removeFile(i) {
            this.attachmentsNewList.splice(i,1);
        },
        async fetchBiddingInfo() {
            this.fetching = true;
            const rtnval = await Api.post('/api/v1/process/get', {prjId: this.prjId, prcCd: this.prcCd});
            if(rtnval.result) {
                this.dsgnRqst = rtnval.data.bddngRqst? rtnval.data.bddngRqst.replace(/<br\/>/g, '\n') : rtnval.data.bddngRqst;
                this.sggstDdln =  rtnval.data.bddngProposalDdln;
                this.rqstDdln =  rtnval.data.bddngJoinDdln;
                this.editing = this.rqstDdln? false : true;
                if(this.prcCd == 'DS' && this.editing) {
                    // housing
                    if(this.dsgnRqst.indexOf('지하유무') < 0) {
                        this.dsgnRqst = '--기획하기에서 선택하신 선호사항입니다.\n--수정을 원하실경우 자유롭게 수정하시기 바랍니다.\n--아래 사항들 이외에도 층별 요구사항, 외부공간(마당, 주차장 등), 옥상공간 사용여부, 수익용/실거주용 등을 자세히 기재해주시면 기획에 많은 도움이 됩니다.\n--본 코멘트는 저장 혹은 제출 전에 삭제해주시기 바랍니다.\n\n' + this.dsgnRqst;
                    }
                    // commercial, office
                    else {
                        this.dsgnRqst = '--층별 요구사항, 외부공간(마당, 주차장 등), 옥상공간 사용여부, 임대용/입주용 등을 자세히 기재해주시면 기획에 많은 도움이 됩니다.\n--본 코멘트는 저장 혹은 제출 전에 삭제해주시기 바랍니다.\n\n' + this.dsgnRqst;
                    }
                }

                var biddingAttchFiles = [];
                if(rtnval.data.attachFiles) {
                    for(var i=0; i<rtnval.data.attachFiles.length; i++) {
                        var prcAttchFlCtgry = rtnval.data.attachFiles[i].prcAttchFlCtgry.code;
                        if( prcAttchFlCtgry == "BD" ) {
                            biddingAttchFiles.push(rtnval.data.attachFiles[i])
                        }
                        else if( prcAttchFlCtgry == "SP") {
                            this.planImg.push(rtnval.data.attachFiles[i].attchWebPath)
                        }
                    }
                }
                var tempBucket = [];
                var k=0;
                if(biddingAttchFiles) {
                    for(i=0; i<biddingAttchFiles.length; i++) {
                        var tokens = biddingAttchFiles[i].attchNm.split('.');
                        var mimeType = tokens[tokens.length-1].toLowerCase();
                        var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;
    
                        const byteCharacters = atob(biddingAttchFiles[i].attchData);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let j = 0; j < byteCharacters.length; j++) {
                            byteNumbers[j] = byteCharacters.charCodeAt(j);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], {type: fileType});
                        const fileLink = URL.createObjectURL(blob);

                        tempBucket.push({id: biddingAttchFiles[i].id, prcAttchOrgNm: biddingAttchFiles[i].attchNm, fileLink: fileLink, mimeType: mimeType, deleted: false})
                        if((k++)%2 == 1) {
                            this.attachmentsFetchedList.push(tempBucket);
                            tempBucket = [];
                        }
                        else {
                            if(i == biddingAttchFiles.length -1) {
                                tempBucket.push({id: null, prcAttchOrgNm: null, fileLink: "/./files/img_legacy/nofileimage.jpg", deleted: false});
                                this.attachmentsFetchedList.push(tempBucket);
                            }
                        }
                    }
                }
    
                const rtnvalue = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + this.prjId);
                if(rtnvalue.result) {
                    this.dsgnRprsnttvImgPath = rtnvalue.data.dsgnRprsnttvImgPath[0];
                    this.dsgnRprsnttvImgPath2 = rtnvalue.data.dsgnRprsnttvImgPath[1];
                    
                    this.floorArea = rtnvalue.data.prj.totalGroundArea;
                    this.expectedCost = rtnvalue.data.prj.expectedCost? rtnvalue.data.prj.expectedCost : 0;
                    var fap = Math.round(this.floorArea /3.305785);
                    this.floorAreaPy = parseInt(fap, 0);
                    this.fetching = false;
                }
                else {
                    alert(rtnvalue.errormsg)
                }
            }
            else {
                alert(rtnval.errormsg)
            }
        },
        async setBidding(startBid) {
            if(this.prcCd == 'DS') {
                if (!this.floorArea || this.floorArea == 0) {
                    alert('희망면적을 입력하세요.');
                    return;
                }
                else if (!this.expectedCost || this.expectedCost == 0) {
                    alert('예상공사비용을 입력하세요.');
                    return;
                }
                else {
                    const returnvalue = await Api.post('/api/v1/project/update/prj/dtl', {prjId: this.prjId, totalGroundArea: this.floorArea, expectedCost: this.expectedCost});
                    if(!returnvalue.result) {
                        alert(returnvalue.errormsg)
                        return;
                    }
                }
            }

            this.submitting = true;

            const rtnval = await Api.post('/api/v1/process/set', {
                prjId: this.prjId,
                prcId: this.prcId == null || this.prcId == 'null'? null : this.prcId,
                prcCd: this.prcCd,
                bddngRqst: this.dsgnRqst? this.dsgnRqst.replace(/\n/g, '<br/>') : '',
                bddngTyp: 1,
                startBid: startBid
            });
            if(rtnval.result) {
                this.prcId = rtnval.data;
                if(this.attachmentsNewList.length > 0 || this.deleteFlIdList.length > 0) {
                    var request = {
                        parentId: [],
                        attchFlCtgry: [],
                        deleteFlIdList: this.deleteFlIdList,
                        attchOrgNmList: []
                    }
                    const formData = new FormData();
                    this.attachmentsNewList.forEach((file) => {
                        request.attchOrgNmList.push(file.orgNm);
                        request.attchFlCtgry.push('BD');
                        request.parentId.push(this.prcId);
                        formData.append("attchmntList", file.file);
                    })
                    formData.append(
                        "request",
                        new Blob([JSON.stringify(request)], { type: "application/json" })
                    );
                    const rtnvalue = await Api.post('/api/v1/common/set/attachments', formData);
                    if(rtnvalue.result) {
                        if(startBid) {
                            alert('참여 의향 요청이 정상적으로 제출되었습니다.');
                            location.href = '/project/bidding/customer/bidding-candidate';
                        }
                        else {
                            if(this.rqstDdln) {
                                alert('참여 의향 요청이 정상적으로 제출되었습니다.');
                                location.href = '/project/bidding/customer/bidding-candidate';
                            }
                            else {
                                alert('요청사항이 정상적으로 저장되었습니다.');
                                location.reload()
                            }
                        }
                    }
                    else {
                        alert(rtnvalue.errormsg + "\n'수정'버튼을 눌러 첨부파일을 다시 업로드하신 후 저장하세요.")
                    }
                }
                else {
                    if(startBid) {
                        alert('참여 의향 요청이 정상적으로 제출되었습니다.');
                        location.href = '/project/bidding/customer/bidding-candidate';
                    }
                    else {
                        if(this.rqstDdln) {
                            alert('참여 의향 요청이 정상적으로 제출되었습니다.');
                            location.href = '/project/bidding/customer/bidding-candidate';
                        }
                        else {
                            alert('요청사항이 정상적으로 저장되었습니다.');
                            location.reload()
                        }
                    }
                }
            }
            else {
                alert(rtnval.errormsg)
            }
            this.submitting = false;
        },
        async saveBiddingRequest() {
            ////////////////////////////////////// legacy /////////////////////////////////////
            this.submitting = true;
            const rtnval = await Api.post('/api/v1/' + this.prcssInfo[this.process].name + '/bddng/cu/update', {dsgnRqst: this.dsgnRqst? this.dsgnRqst.replace(/\n/g, '<br/>') : '', floorArea: this.floorArea, prjId: this.prjId});
            if(rtnval.result) {
                var request = {
                    prjId: this.prjId,
                    prjAttchFlCtgry: this.attchCtgry[this.process],
                    deleteFlIdList: this.deleteFlIdList,
                    prcAttchOrgNmList: []
                }
                const formData = new FormData();
                this.attachmentsNewList.forEach((file) => {
                    request.prcAttchOrgNmList.push(file.orgNm);
                    formData.append("attchmntList", file.file);
                })
                if(request.prcAttchOrgNmList.length > 0 || request.deleteFlIdList.length > 0) {
                    formData.append(
                        "request",
                        new Blob([JSON.stringify(request)], { type: "application/json" })
                    );
                    const rtnvalue = await Api.post('/api/v1/dsgn/bddng/cu/save/bidding/attachments', formData);
                    if(rtnvalue.result) {
                        alert('정상적으로 저장되었습니다.');
                        location.reload()
                    }
                    else {
                        alert(rtnvalue.errormsg)
                    }
                }
                else {
                    alert('요청사항이 정상적으로 저장되었습니다.');
                    location.reload()
                }

            }
            else {
                alert(rtnval.errormsg)
            }
            this.submitting = false;
            ///////////////////////////////////////////////////////////////////////////////
        }
    }
}
</script>

<style scoped>
.form-control[readonly] {
    background-color: #fff;
    opacity: 1;
}
.diagonal {
    width: 100%;
    height: 100%;
    background: linear-gradient(
    to top right,
    #fff calc(50% - 1px),
    red,
    #fff calc(50% + 1px)
    );
}
</style>