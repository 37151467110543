import axios from 'axios';
import store from '@/store';

async function get(url) {
    return await axios.get(url, {validateStatus: (status) => {return status < 500}}).then((res) => {
        if(res.status == 200){
            return {result: true, data: res.data}
        }
        else{
            if(!res.data && res.status == 401){
                store.commit('setUserInfo', null);
            }
            return {result: false, errorcode: res.status, errorinfo: res.data ? res.data.info : '', errormsg: res.data ? res.data.message : '로그인 세션이 만료되었습니다.'};
        }
    }).catch((e) => {
        throw {result: false, errorcode: e.response.data.code, errormsg: e.response.data.message, errorinfo: e.response.data.info};
    });
}

async function post(url, data) {
    return await axios.post(url, data, {validateStatus: (status) => {return status < 500}}).then((res) => {
        if(res.status == 200){
            return {result: true, data: res.data}
        }
        else{
            if(!res.data && res.status == 401){
                store.commit('setUserInfo', null);
            }
            return {result: false, errorcode: res.status, errorinfo: res.data ? res.data.info : '', errormsg: res.data ? res.data.message : '로그인 세션이 만료되었습니다.'};
        }
    }).catch((e) => {
        throw {result: false, errorcode: e.response.data.code, errormsg: e.response.data.message, errorinfo: e.response.data.info};
    });
}

async function postFiles(url, data) {
    return await axios.post(url, data, {headers: {"Content-Type": "multipart/form-data"}, validateStatus: (status) => {return status < 500}}).then((res) => {
        if(res.status == 200){
            return {result: true, data: res.data}
        }
        else{
            if(!res.data && res.status == 401){
                store.commit('setUserInfo', null);
            }
            return {result: false, errorcode: res.status, errorinfo: res.data ? res.data.info : '', errormsg: res.data ? res.data.message : '로그인 세션이 만료되었습니다.'};
        }
    }).catch((e) => {
        throw {result: false, errorcode: e.response.data.code, errormsg: e.response.data.message, errorinfo: e.response.data.info};
    });
}

export default {get, post, postFiles};