import Instruction from '@/views/project/bidding/partner/Instruction.vue'
import BiddingParticipate from '@/views/project/bidding/partner/BiddingParticipate.vue'
import BiddingProposal from '@/views/project/bidding/partner/BiddingProposal.vue'
import BiddingResult from '@/views/project/bidding/partner/BiddingResult.vue'
import ContractList from '@/views/project/bidding/partner/ContractList.vue'
import Contract from '@/views/project/bidding/partner/Contract.vue'

export default [
    {
        path: '/project/bidding/partner/instruction',
        name: 'Instruction',
        component: Instruction
    },
    {
        path: '/project/bidding/partner/bidding-participate',
        name: 'BiddingParticipate',
        component: BiddingParticipate
    },
    {
        path: '/project/bidding/partner/bidding-proposal',
        name: 'BiddingProposal',
        component: BiddingProposal
    },
    {
        path: '/project/bidding/partner/bidding-proposal/:referer',
        name: 'BiddingProposal',
        component: BiddingProposal
    },
    {
        path: '/project/bidding/partner/bidding-result',
        name: 'BiddingResult',
        component: BiddingResult
    },
    {
        path: '/project/bidding/partner/bidding-result/:referer',
        name: 'BiddingResult',
        component: BiddingResult
    },
    {
        path: '/project/bidding/partner/contract-list',
        name: 'ContractList',
        component: ContractList
    },
    {
        path: '/project/bidding/partner/contract/:prjId',
        name: 'Contract',
        component: Contract
    }
]