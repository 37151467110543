<template>
    <div class="m3_contetns" style="margin-top: 50px">

        <!-- mypage 시작 { -->
        <div class="m_mem_box">

            <div class="pg_m_set" v-if="fetching">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
            </div>
            <div v-else style="width:90%">

                <div class="w-100 x_div_center pb-3">
                    <h3 class="ff-kor1 fw-bold fc-w6">{{partyNm}} 밥집 투표</h3>
                </div>
                
                <div class="y_div_blank1rem" style="margin-bottom:10px"></div>

                <div v-if="beforePolltime" class="w-100 x_div_center pb-3">
                    <h4 class="ff-kor1 fw-bold fc-w6">지금은 투표시간이 아닙니다 :)</h4>
                </div>

                <div v-else>
                    <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#4e0000;">
                        <div class="x_div_center" style="width:25%"><span class="fkr-set2 fc-w1">밥집</span></div>
                        <div class="x_div_center" style="width:50%"><span class="fkr-set2 fc-w1">현재 투표자</span></div>
                        <div class="x_div_center" style="width:25%"><span class="fkr-set2 fc-w1">선택</span></div>
                    </div>
    
                    <div class="x_div_center ls_bg1" style="height:55px; cursor: pointer;" v-for="(store, index) in this.storeList" :key="index">
                        <div class="x_div_center br_1" style="width:25%"><span class="fkr-set2"> {{ store.storeName }} </span></div>
                        <div class="x_div_center br_1" style="width:50%"><span class="fkr-set2"> {{ store.voters.join(', ') }} </span></div>
                        <div class="x_div_center" style="width:25%">
                            <span class="fen-set2">
                                <div class="custom-control custom-radio">
                                    <input name="selectStore" type="radio" :id="'radio'+index" :value="store.id" v-model="selectedStore" class="custom-control-input">
                                    <label :for="'radio'+index" class="custom-control-label"></label>
                                </div>
                            </span>
                        </div>
                    </div>
    
                    <div class="x_div_center pt-4">
                        <div class="px-2"><button class="fkr-set1" :disabled="submitted" :style="'width:120px; height:37px; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000;' + (submitted? 'opacity: 0.5' : '')" @click="submitVote()">투표 제출<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                    </div>
                </div>
            </div>

        </div>
        <!-- } mypage 끝 -->

    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'
export default {
    name: 'VoteCompletion',
    props: {
        user: {
            type: Object,
            default: null
        },
        isMobileDevice: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fetching: true,
            submitting: false,
            submitted: false,
            beforePolltime: false,
            partyNm: null,
            storeList: [],
            selectedStore: null,
            goalchi: false,
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login?voteComplete=' + this.$route.params.partyId;
        }

        const now = new Date();
        const today = new Date(now.getFullYear() + '-' + String(now.getMonth()+1).padStart(2, "0") + '-' + String(now.getDate()).padStart(2, "0") + 'T00:00:00');
        const currentMiliseconds = now.getTime() + 9*3600 - today.getTime();

        const rtnval = await Api.get('/api/v1/sicksha/party/get?partyId=' + this.$route.params.partyId);
        if(rtnval.result){
            var isMember = false;
            rtnval.data.memberInfoList.forEach((member) => {
                if(member.usrId == this.user.id) {
                    isMember = true;
                    return;
                }
            })
            if(!isMember) {
                alert('이 파티의 멤버가 아닙니다.');
                location.href = '/';
            }

            this.partyNm = rtnval.data.partyNm;
            this.beforePolltime = rtnval.data.pollTime*60000 > currentMiliseconds;
            this.storeList = rtnval.data.storeList.sort((a,b) => b.storeLink.length - a.storeLink.length);
            this.storeList.forEach((store) => {
                    store.voters = [];
                })
            this.goalchi = rtnval.data.memberInfoList.length == rtnval.data.voteList.length +1
            rtnval.data.voteList.forEach((vote) => {
                this.storeList.forEach((store) => {
                    if(store.id == vote.store.id) {
                        // store.voters.push(vote.voterNm);
                        store.voters.push(this.renderName(vote.voterId));
                    }
                })
                if(vote.voterId == this.user.id) {
                    this.submitted = true;
                    this.selectedStore = vote.store.id;
                }
            })
        }
        else {
            alert(rtnval.errormsg);
        }

        const returnvalue = await Api.get('/api/v1/sicksha/complete/poll?partyId=' + this.$route.params.partyId + '&usrId=' + this.user.id);
        if(returnvalue.result){
            if(returnvalue.data) {
                alert('메뉴선택을 완료하였습니다.\n모든 파티원의 메뉴선택이 끝나면 주문자가 배달을 주문합니다!');
            }
            else {
                alert('이미 메뉴선택완료 알림을 전송했습니다.\n주문자가 배달을 주문할 때 까지 기다려주세요.');
            }
        }
        else {
            alert(returnvalue.errormsg);
        }

        this.fetching = false;
    },
    methods: {
        renderName(voterId) {
            const date = new Date().getTime();
            var rtn = ''
            switch((date * voterId)%5) {
                case 0 : rtn = '하품하는 엠몽이';
                    break;
                case 1 : rtn = '개발하는 듀리';
                    break;
                case 2 : rtn = '딴짓하는 강백훈';
                    break;
                case 3 : rtn = '아름다운 채소영';
                    break;
                case 4 : rtn = '승진하는 우파루';
                    break;
            }
            return rtn;
        }
    }
}
</script>

<style scoped>
.ls_bg1 {
    padding: 10px 0 10px 0 !important;
}
.ls_bg1:hover {
    background-color: #fff !important;
}
</style>