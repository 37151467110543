<template>
    <div>
        <div v-if="!isMobile" class="inquiry-page">
            <div class="bg">
                <div class="overlap">
                    <img class="purchase" src="@/assets/img_legacy/s_cs/s_title_back.jpg" style="object-fit: cover;"/>
                    <div class="purchase-2"></div>
                    <div class="group">
                        <div class="text-wrapper">자주묻는 질문</div>
                        <div class="text-wrapper-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="main-title">
            <div class="text-wrapper">자주묻는 질문</div>
            <div class="text-wrapper-2"></div>
        </div>
        
        <div class="x_div_center">
            <div class="rp_set">

                <!-- FAQ -->
                <div class="w-100 py-3 pt-5" style="padding-top:unset !important;">
                    <div class="subject">
                        <div class="title">FAQ</div>
                        <img class="img" src="@/assets/img/inquiry/line.svg" />
                    </div>

                    <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                        <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                    </div>
                    <div v-else>
                        <div>
                            <div class="x_div_center" style="border:1px solid #e1e1e1">
                                <div :class="'pa_mbox1' + (!ctgryId? '_on' : '') +' py-3 text-center br_3'" :style="'width:' + 100/(ctgryList.length+1) + '%'" @click="ctgryId = null"><span class="fkr-set2">{{ lang == 'ko'? '전체' : 'All' }}</span></div>
                                <div v-for="(ctgry, index) in ctgryList" :key="index" :class="'pa_mbox1' + (ctgryId == ctgry.id? '_on' : '') +' py-3 text-center' + (index+1 == ctgryList.length? '' : ' br_3')" :style="'width:' + 100/(ctgryList.length+1) + '%'" @click="ctgryId = ctgry.id"><span class="fkr-set2">{{ lang == 'ko'? ctgry.ctgryName : ctgry.ctgryNameEn }}</span></div>
                            </div>
                        </div>
    
                        <div class="py-3">
                            <div style="border-top:1px solid #e7e7e7">
                                <div v-for="(faq, index) in faqList" :key="index">
                                    <a :href="'#mfaq'+index" data-bs-toggle="collapse" role="button" aria-expanded="false" :aria-controls="'mfaq'+index">
                                        <div class="p-3 ls_bg1"><span class="fkr-set1">{{faq.sbjct}}</span></div>
                                    </a>
                                    <div :id="'mfaq'+index" class="collapse p-4" style="background-color:#f2f2f2; border-bottom:1px solid #e1e1e1">
                                        <span class="fkr-set2" v-html="faq.cntnt"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MenuFooter v-if="lang == 'ko'"/>
                <MenuFooter_En v-else-if="lang == 'en'"/>

            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
import MenuFooter_En from '@/components/MenuFooterEn.vue'
import store from '@/store';
import Api from '@/api'

export default {
    name: 'MenuFAQ',
    props: {
        lang: {
            type: String,
            default: 'ko'
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    components: {
        MenuFooter, MenuFooter_En
    },
    data() {
        return {
            fetching: true,
            ctgryId: null,
            ctgryList: [],
            faqList: []
        }
    },
    watch: {
        ctgryId() {
            this.fetchFaqList();
        }
    },
    created() {
        store.commit('setMenuId', 'M501');
    },
    async mounted() {
        this.ctgryList = [];
        var rtnval = await Api.get('/api/v1/faq/ctgry/list');
        if(rtnval.result) {
            this.ctgryList = rtnval.data;
        }
        else {
            alert(rtnval.errormsg)
        }
        this.fetchFaqList();
        this.fetching = false;
    },
    methods:{
        async fetchFaqList() {
            this.fetching = true;
            this.faqList = [];
            const rtnval = await Api.post('/api/v1/faq/find/faq/list', {ctgryId: this.ctgryId});
            if(rtnval.result) {
                this.faqList = rtnval.data.resultList.sort((a,b) => { return a.faqId - b.faqId});
            }
            else {
                alert(rtnval.errormsg)
            }
            this.fetching = false;
        }
    }
}
</script>

<style scoped>
@media (max-width:768px) {
    .subject {
        width: 100%;
        padding: 23px 0;
        .title {
          color: #434343;
          font-family: Pretendard;
          font-size: 19.136px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          justify-content: unset;
          height: unset;
        }
        .img {
          width: 156px;
          height: 10px;
          display: block;
          position: relative;
          top: 4px;
          right: 0px;
        }
    }
}

@media (min-width:769px) {
    .subject {
        width: 100%;
        padding: 50px 0;
        .title {
            font-family: "Pretendard-SemiBold", Helvetica;
            font-weight: 600;
            color: #434343;
            font-size: 46px;
            letter-spacing: 0;
            line-height: normal;
            white-space: nowrap;
            display: unset;
            justify-content: unset;
            align-items: unset;
            height: unset;
        }
        .img {
            width: 378px;
            height: 3px;
            display: block;
            position: relative;
            top: 17px;
            right: 45px;
        }
    }
}
.main-title {
    width: 100%;
    height: 191px;
    background-image: url(../../assets/img_legacy/s_cs/s_title_back.jpg);
    background-size: 100% 100%;
    padding: 69px 0;
    .text-wrapper {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
      font-family: Pretendard;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: inline-block;
      width: 100%;
    }
    .text-wrapper-2 {
      letter-spacing: 0;
      white-space: nowrap;
      color: #FFF;
      text-align: center;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      display: inline-block;
      width: 100%;
    }          
  }
</style>